import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonCheckWrapper } from './style';
import { DOCUMENT_STATUS } from '../../utils/constant';

const ButtonCheck = ({ dataId, label, disabled, status, handleChange, type }) => {

  const [localStatus, setLocalStatus] = useState(status);

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  const handleClick = () => {
    if(!localStatus) {
      setLocalStatus(DOCUMENT_STATUS.SUCCESS);
      handleChange();
    }
  }
  
  return (
    <ButtonCheckWrapper
      disabled={disabled}
      onClick={!disabled && type !== 'link' ? handleClick : null}
      className={localStatus}
      data-id={`btn-${dataId}`}
    >
      { localStatus 
        ? <div className="icon" />
        : <div className="radio" /> }
      {label}
    </ButtonCheckWrapper>
  );
}

ButtonCheck.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  status: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.oneOf(['toggle', 'link']),
}

ButtonCheck.defaultProps = {
  disabled: false,
  handleChange: () => { },
  type: 'toggle'
}
 
export default ButtonCheck;