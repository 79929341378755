import React, { useEffect, useState } from 'react'

import AccordionDocumentsSection from '../../../../components/AccordionDocumentsSection/AccordionDocumentsSection'
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS, PROCESS_EVENTS } from '../../../../utils/constant';

const PurchaseOptionPaColpatria = ({ sections, handleCallEvent, handleUpdateDocument, addOptionalDocument, getEventStatus }) => {

  const [buttonEvents, setButtonEvents] = useState([]);

  useEffect(() => {
    if(sections.length>0) {
      const buttonEventsTemp = [
        {
          label: "Solicitar aprob. Opción de compra",
          event: PROCESS_EVENTS.REQUEST_PURCHASE_OPTION,
          status: getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION),
          disabled: sections[0].list_documents.some(document => !document.exist)
        },
        {
          label: "Opción de compra aprobada",
          event: PROCESS_EVENTS.PURCHASE_OPTION_APPROVED,
          status: getEventStatus(PROCESS_EVENTS.PURCHASE_OPTION_APPROVED),
          disabled: getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION) !== DOCUMENT_STATUS.SUCCESS
        },
      ];
      setButtonEvents(buttonEventsTemp);
    }
  }, [sections, getEventStatus]);

  return (
    <div>
      { 
        sections && 
        sections.map((section, indexSection) =>
          <AccordionDocumentsSection 
            section={ section }
            buttonEvents={ buttonEvents }
            handleCallEvent={ handleCallEvent }
            handleUpdateDocument={ handleUpdateDocument }
            addOptionalDocument={ addOptionalDocument }
            businessTypeId={ BUSINESS_TYPE.BUYERS }
            processName={ PROCESS.PURCHASE_OPTION_PA_COLPATRIA }
            showDocumentOptional
            showDelete={ getEventStatus(PROCESS_EVENTS.PURCHASE_OPTION_APPROVED) !== DOCUMENT_STATUS.SUCCESS}
            key={`section_${ section.id }`}
          />
      )}
    </div>
  )
}

export default PurchaseOptionPaColpatria
