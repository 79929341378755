import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import {
  getDocumentsProcess,
  getEventsProcess,
  getHistoryComments,
} from '../../apis/Process/processApis';
import { BUSINESS_TYPE, HTTP_STATUS, PROCESS } from '../../utils/constant';

export const getPropertyReceptionData = (nid, businessType, processName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const form = await getFormDataDependencies(
        nid,
        PROCESS.DATA_PROPERTY_RECEPTION,
        BUSINESS_TYPE.SELLERS
      );
      const events = await getEventsProcess({
        nid,
        businessType: BUSINESS_TYPE.SELLERS,
        processName: PROCESS.DATA_PROPERTY_RECEPTION,
      });
      const documents = await getDocumentsProcess({
        nid,
        businessType,
        processName,
        sections: false,
      });
      const comments = await getHistoryComments({
        nid,
        processName: PROCESS.MORTGAGE,
      });
      resolve({
        form: form.data.data,
        documents: documents.data.docs,
        events: events.data.data.events,
        comments: comments.data.history,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: [],
          events: [],
          documents: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
