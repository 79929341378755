import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import useDocuments from "../../hooks/useDocuments";
import BuyersForm from "./components/BuyersForm/BuyersForm";
import {PROCESS, BUSINESS_TYPE, ROUTES} from "../../utils/constant";
import EmptyData from "../../components/EmptyData/emptyData";
import OptionalDocument from "../../components/OptionalDocument/optionalDocument";
import DocumentComponent from "../../components/DocumentComponent/documentComponent";
import { Container, DocumentsGrid, SubTitle, Title } from '../../components/Styles/genericStyleComponent';
import { getFormBuyersFolderApi, postBussinesPublication } from "../../apis/Buyers/buyersApis";
import { hideLoading, showLoading } from "../../redux/actions/propertyData";
import { BuyersFolderWrapper } from "./style";
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const BuyersFolder = () => {
  const { documents, addOptionalDocument } = useDocuments({
    businessType: BUSINESS_TYPE.SELLERS,
    processName: PROCESS.BUYERS_FOLDER,
  });
  const [showConfirm, setShowConfirm] = useState(false);

  const [data, setData] = useState([]);
  const { list_documents: listDocuments } = documents;

  const dispatch = useDispatch();

  const nid = useSelector((state) => state.propertyData.nid);

  const showConfirmModal = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const getBuyerFolderData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const response = await getFormBuyersFolderApi({
        nid,
      });
      const { data } = await response;
      setData(data.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch, nid]);

  useEffect(() => {
    if (nid) {
      getBuyerFolderData();
    }
  }, [nid, getBuyerFolderData]);

  const handleSubmit = async (data) => {
    dispatch(showLoading());
    try {
      await postBussinesPublication({
        nid: +nid,
        business_type_id: BUSINESS_TYPE.SELLERS,
        origin_url: `${window.location.origin}${ROUTES.LISTING_WEB}`,
        data,
      });
      closeConfirm();
    } catch (error) {
      console.error(error);
      closeConfirm();
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <EmptyData>
      <BuyersFolderWrapper>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>Carpetas compradores</Title>
          <DocumentsGrid>
            {listDocuments.map((document, i) => (
              <DocumentComponent
                key={`document-${i}`}
                lastItemRow={(i + 1) % 5 === 0 ? true : false}
                documentParam={document}
                businessTypeId={BUSINESS_TYPE.SELLERS}
              />
            ))}
            <OptionalDocument
              businessType={BUSINESS_TYPE.SELLERS}
              processName={PROCESS.BUYERS_FOLDER}
              handleAddDocument={addOptionalDocument}
            />
          </DocumentsGrid>
          <hr className="line-separator" />
          <SubTitle marginBottom={64}>Publicación del negocio</SubTitle>
          <BuyersForm
            data={data}
            handleSubmit={handleSubmit}
            showConfirm={showConfirm}
            showConfirmModal={showConfirmModal}
            closeConfirm={closeConfirm}
          />
        </Container>
      </BuyersFolderWrapper>
    </EmptyData>
  );
};

export default BuyersFolder;
