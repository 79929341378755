import { getRegistrationTicketData } from '../../apis/RegistrationTicket/registrationTicketApis';
import { HTTP_STATUS } from '../../utils/constant';

export const mortgageRegistrationTicketData = ({
  nid,
  process_name,
  business_type_id,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getRegistrationTicketData({
        nid,
        process_name,
        business_type_id,
      });
      resolve({
        registrationTicketData: data,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          registrationTicketData: {},
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
