function propertyData(state = {}, action = {}) {
    switch (action.type) {
      case 'UPDATE_PROPERTY_DATA': {
        return { ...state, ...action.payload };
      }
      case 'UPDATE_GENERIC_DATA': {
        return { ...state, genericData: action.payload };
      }
      case 'RESET_PROPERTY_DATA': {
        return {};
      }
      case 'SHOW_LOADING': {
        return { ...state, loadingSearch: true };
      }
      case 'HIDE_LOADING': {
        return { ...state, loadingSearch: false };
      }
      case 'DATA_FOUND': {
        return { ...state, noData: false };
      }
      case 'DATA_NOT_FOUND': {
        return { ...state, noData: true };
      }
      default:
        return state;
    }
  }
  
  export default propertyData;
  