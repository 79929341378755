import styled from "styled-components";
import { brightTurquoise000, light_grey, openSansBoldFamily, openSansRegular, primary, scarpaGray100, scarpaGray600, skyBlue500, successPrimary } from "../../../../StylesConstants";

export const DocumentRowWrap = styled.div`
  min-height: 64px;
  border-top: 1px solid ${scarpaGray100};
  display: grid;
  grid-template-columns: 48px auto;

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${ light_grey };

    .success {
      background-color: ${ brightTurquoise000 };
    }
  }

  .detail {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      display: flex;
      align-items: center;
      color: ${ scarpaGray600 };
      font: 12px ${ openSansBoldFamily };
      gap: 14px;
      margin-left: 14px;
    }

    .success {
      color: ${ successPrimary}
    }

    .default {
      color: ${ scarpaGray100 }
    }

    .loaded {
      color: ${ skyBlue500 }
    }

    .options {
      align-items: center;
      display: grid;
      font: 12px ${ openSansRegular };
      grid-auto-flow: column;

      .opt {
        padding: 0 16px;
        border-left: 1px solid ${ scarpaGray100 };
      }

      .opt:first-child {
        border: 0;
      }

      .icon-document {
        color: ${ primary };
      }
    }
  }

  .loading {
    margin-right: 24px;
  }

  .icon-status {
    position: inherit;
  }
`;
