import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

import { ModalWrapper, OptionalDocumentWrapper } from './style';
import AddIcon from '../../static/assets/img/icons/icon-add.svg';
import CloseIcon from '../../static/assets/img/icons/icon-close.svg';
import { Title } from '../Styles/genericStyleComponent';
import { getListOptionalDocumentsApi } from '../../apis/Documents/documentsApis';

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  margin: 0,
  border: 0,
  outline: 0,
}

const OptionalDocument = ({ businessType, processName, handleAddDocument, optionalDocuments, sectionName, documentsToFilter }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  const getOptionalDocuments = useCallback(
    async () => {
      try {
        const response = await getListOptionalDocumentsApi({
          businessType,
          processName,
          sectionName
        });
        const listDocuments = documentsToFilter
          ? response.data.list_optional_documents.filter(document =>
            !documentsToFilter.some(docFilter => docFilter.label === document.label)
          )
          : response.data.list_optional_documents;
        setDocuments(listDocuments);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setDocuments([]);
        } else {
          console.log("error:::", error.response);
        }
      }
    }, [businessType, processName, sectionName, documentsToFilter],
  );

  useEffect(() => {
    if (!optionalDocuments) {
      getOptionalDocuments();
    } else {
      setDocuments(optionalDocuments);
    }
  }, [getOptionalDocuments, optionalDocuments]);

  const addDocument = (document) => {
    handleAddDocument(document, sectionName);
    setIsModalOpen(false);
  };

  const getOptionalDocumentsElement = () => {
    return (
      <div className="documents-container">
        <div>
          {documents.map(document => {
            return (
              <div
                className="document"
                onClick={() => addDocument(document)}
                key={`document${document.document_type_id}`}
              >
                <span>
                  {document.label}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    );
  };

  return (
    <OptionalDocumentWrapper>
      <div
        className="optional-grid"
        onClick={() => setIsModalOpen(true)}
      >
        <img src={AddIcon} alt="Icon Add" />
        <div>
          <div className="title">
            <span>
              Documento <br /> opcional
            </span>
          </div>
          <div className="add-option">
            <span>
              Agregar
            </span>
          </div>
        </div>
      </div>
      <Modal
        id="modal"
        open={isModalOpen}
        style={modalStyle}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={isModalOpen}>
          <ModalWrapper>
            <div
              className="close"
              onClick={() => setIsModalOpen(false)}
            >
              <img src={CloseIcon} alt="Cerrar" />
            </div>
            <Title>
              Crear nuevo documento
            </Title>
            <div className="text-container">
              <span>
                Selecciona el documento que quieres agregar
              </span>
            </div>
            {getOptionalDocumentsElement()}
          </ModalWrapper>
        </Fade>
      </Modal>
    </OptionalDocumentWrapper>
  )
};

export default OptionalDocument;
