import styled from "styled-components";
import {
  brightTurquoise000,
  brightTurquoise100,
  brightTurquoise200,
  brightTurquoise500,
  errorPrimary,
  openSansRegular,
  successPrimary,
  tomatoCoral000,
  tomatoCoral200,
} from "../../../StylesConstants";

export const SelectWithConfirmationWrap = styled.div`

  display: grid;
  gap: 6px;
  grid-template-columns: ${ p => p.showConfirm? 'auto 54px': 'auto' };
  align-content: center;

  .confirm-container {
    align-self: center;
    display: grid;
    gap: 6px;
    grid-template: 24px / 24px  24px;
  }

  .icon {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.confirm {
      background-color: ${brightTurquoise100};
      color: ${successPrimary};
    }
    &.cancel {
      background-color: ${tomatoCoral000};
      color: ${errorPrimary};
    }
    &.confirm:hover {
      background-color: ${brightTurquoise200};
    }
    &.cancel:hover {
      background-color: ${tomatoCoral200};
    }
    svg {
      width: 17px;
    }
  }
`;

export const reactSelectStyles = {
  control: (styles, { isFocused }) => ({ 
    ...styles, 
    fontFamily: openSansRegular,
    outlineColor: 'red',
    border: isFocused? `2px solid ${ brightTurquoise500 }`: `2px solid transparent`,
    boxShadow: '0 !important',
    ':hover': {
      ...styles[':hover'],
      borderColor: brightTurquoise500
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = brightTurquoise500;
    return {
      ...styles,
      fontFamily: openSansRegular,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color
        : isFocused
        ? brightTurquoise000
        : null,
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? color : null),
      },
    };
  },
  menu: styles => ({
    ...styles,
    border: 0,
    boxShadow: 0,
  }),
};