import API from '../Api';

export const getLifeCycleData = ({ nid, businessType }) => {
  return API.get(process.env.REACT_APP_GET_LIFE_CYCLE_DATA, {
    params: {
      nid: +nid,
      business_type_id: businessType,
    },
  });
};
