import styled from "styled-components";
import { brightTurquoise000, greySoft, muliBoldFamily, scarpaGray100 } from "../../../../StylesConstants";
import { ACTIVITY_STATUS } from "../../../../utils/constant";

export const StageWrapper = styled.div`

  .name-stage {
    background-color: ${ p => p.state === ACTIVITY_STATUS.LOCK? greySoft: brightTurquoise000 };
    border-radius: 8px;
    font-family: ${ muliBoldFamily };
    font-size: 20px;
    min-height: 56px;
    width: 100%;
  }
  
  .activities-container {
    margin-left: ${ p => p.isActivity? '0px': '40px'};
  }

  .sub-activities-container {
    margin-left: 50px;
  }

  .icon-state {
    margin-right: 28px;
    width: 20px;
  }
  
  .dot-line {
    border-left: ${ p => p.isActivity? '0px': `2px dashed ${ scarpaGray100 }` };
    margin-left: ${ p => p.isActivity? '0px': '24px' };
    padding: 24px 0 0 0;
    min-height: 32px;
    width: 100%;
  }

  .MuiPaper-root {
    box-shadow: none;
    background-color: #F7F6FF;
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiCollapse-hidden {
    min-height: 32px !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 56px
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: unset;
  }

  .MuiAccordion-root.Mui-disabled {
    background-color: unset;
  }

`;