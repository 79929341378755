import API from '../Api';

export const saveConfirmationDate = async ({ nid, businessTypeId, processName, date, agent }) => {
  return API.post(
    process.env.REACT_APP_POST_PROCESS_CONFIRMATION_DATE,
    {
      nid: parseInt(nid),
      business_type_id: businessTypeId,
      process_name: processName,
      date, 
      agent
    }
  );
};

export const saveProcessComment = async ({ nid, processName, agent, nameReviewerRole, comment, errorList, originUrl, noInteractions }) => {
  return API.post(
    process.env.REACT_APP_POST_PROCESS_COMMENT,
    {
      nid: parseInt(nid),
      process_name: processName,
      agent,
      name_reviewer_role: nameReviewerRole,
      comment,
      error_list: errorList,
      origin_url: originUrl,
      no_interactions: noInteractions
    }
  );
};

export const callEventProcess = async ({ nid, businessTypeId, processName, eventName, status, originUrl, comment, email }) => {
  return API.post(
    process.env.REACT_APP_POST_EVENT_PROCESS,
    {
      nid: parseInt(nid),
      business_type_id: businessTypeId,
      process_name: processName,
      event_name: eventName,
      status,
      origin_url: originUrl,
      comment,
      email
    }
  );
};

export const getEventsProcess = ({ nid, businessTypeId, processName }) => {
  return API.get(
    process.env.REACT_APP_GET_PROCESS_EVENTS,
    {
      params: {
        nid: parseInt(nid),
        business_type_id: businessTypeId,
        process_name: processName
      }
    }
  );
};

export const getDocumentsProcess = ({ nid, businessType, processName, sections }) => {
  return API.get(
    process.env.REACT_APP_GET_DOCUMENTS,
    {
      params: {
        nid,
        business_type: businessType,
        process_name: processName,
        sections
      }
    }
  );
};

export const getReviewsProcess = ({ nid, businessType, processName }) => {
  return API.get(
    process.env.REACT_APP_GET_REVIEWS_PCV,
    {
      params: {
        nid,
        business_type_id: businessType,
        process_name: processName
      }
    }
  );
};

export const getHistoryComments = ({ nid, processName }) => {
  return API.get(
    process.env.REACT_APP_GET_HISTORY_DATA,
    {
      params: {
        nid,
        process_name: processName
      }
    }
  );
};
