import React from 'react';
import { format } from "date-fns";

import { Title } from "../../../../components/Styles/genericStyleComponent";
import { DocumentInfoWrapper } from "./style";

const DocumentInfo = ({ label, updateDate, userCreation }) => {
  return (
    <DocumentInfoWrapper>
      <Title marginBottom={16}>
        {label}
      </Title>
      <div>
        Última modificación: {format(new Date(updateDate), 'dd/MM/yyyy')}
      </div>
      <div>
        Subido por: {userCreation}
      </div>
    </DocumentInfoWrapper>
  );
}
 
export default DocumentInfo;