import styled from 'styled-components';
import {
  openSansRegular,
  plainlightGray,
  scarpaGray500,
  white,
} from '../../StylesConstants';

export const LifeCycleWrapper = styled.div`
  background-color: ${(p) => (p.miniLifeCycle ? 'none' : white)};
  border-radius: 8px;
  padding: ${(p) => (p.isLoading ? '0' : '16px 24px 0 24px')};
  margin-bottom: 32px;
  box-shadow: ${(p) =>
    p.miniLifeCycle
      ? 'none'
      : `rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px,
  rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px`};
  min-height: 127px;
  display: ${(p) => (p.isLoading ? 'flex' : 'block')};

  .loader-container {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .steps-container {
    display: ${(p) => (p.isLoading ? 'none' : 'grid')};
    grid-template-columns: repeat(8, minmax(72px, 1fr));
    background-color: ${(p) => (p.miniLifeCycle ? white : plainlightGray)};
    border-radius: 8px;
    height: 56px;
    padding: 2px 4px;
  }

  .labels-container {
    display: ${(p) => (p.isLoading ? 'none' : 'grid')};
    grid-template-columns: repeat(8, minmax(72px, 1fr));
    padding: 16px 4px 0;
    .label {
      font-family: ${openSansRegular};
      font-size: 12px;
      color: ${scarpaGray500};
      text-align: center;
    }
  }
`;
