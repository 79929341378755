import styled from "styled-components";
import { nightBlue500, muliBoldFamily } from "@habi/habi-react-components/dist/StylesConstants";
import {
  lightGrey,
  muliRegular,
  openSansRegular,
  skyBlue100,
  skyBlue600,
} from '../../../StylesConstants';

export const SectionWrapper = styled.div`
  .title {
    font-family: ${muliBoldFamily};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: ${nightBlue500};
    margin: 0px 0px 40px;
  }
  .section-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 32px;
    align-items: center;
  }
  .line-separator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 24px 0px 40px;
    padding: 0;
  }
  .title-multiple-section {
    color: ${skyBlue600};
    background-color: ${skyBlue100};
    border-radius: 3px;
    font: 13px ${openSansRegular};
    line-height: 10px;
    margin-bottom: 32px;
    padding: 6px 8px;
    width: fit-content;
  }
  .multiple-section {
    margin-bottom: 32px;
  }
  .add-multiple-section {
    display: flex;
    justify-content: center;
  }

  .radio-buttons-container {
    .radio-button-label {
      color: ${nightBlue500};
      font-size: 13px;
      font-weight: bold;
    }
    &.radioOptionRounded {
      .radio-buttons {
        margin-top: 16px;
      }
    }
    .radio-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
    }
    .radio-buttons > div {
      max-width: 100%;
      heigth: 32px;
    }
    .radio-buttons + div {
      margin-right: 16px;
    }
    .radioOptionBlock {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  .radio-buttons-full-width {
    margin-top: 8px;
    margin-bottom: 0px !important;
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1 / 3;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    border-radius: 12px;
    .background {
      background-color: ${lightGrey};
    }
    & > div {
      grid-template-columns: none;
    }
    .radio-buttons:first-child {
      margin-right: 16px;
    }
    .radioOptionBlock,
    .subsection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      label {
        font-family: ${muliRegular};
        font-size: 16px;
        color: ${nightBlue500};
      }
    }
  }
`;