import styled from 'styled-components';
import { muliBoldFamily, muliRegular, openSansRegular, primary, scarpaGray500, scarpaGray600, white } from '../../StylesConstants';

export const LoginWrapper = styled.section`

  background: ${primary};
  width: 100vw;
  height: 100vh;
  margin-left: -256px;

  .decoration {
    display: grid;
    grid-template-columns: 200px 200px;
    height: 100vh;
    position: absolute;
    width: 100vw;
    justify-content: space-between;
    img:nth-child(2n) {
      align-self: end;
    }
  }

  .login-container {
    display: grid;
    position: absolute;
    width: 100vw;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    grid-template-rows: 10px auto 50px;
    justify-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .login-card {
    justify-items: center;
    background: ${white};
    border-radius: 19px;
    display: grid;
    gap: 16px;
    padding: 72px 64px 54px 64px;
    width: 270px;
    img {
      width: 60px;
    }
  }

  .login-form {
    display: grid;
    gap: 16px;
    width: 100%;
  }

  .google-button-container {
    -webkit-box-shadow: 10px 10px 69px -10px rgb(0 0 0 / 15%);
    -moz-box-shadow: 10px 10px 69px -10px rgb(0 0 0 / 15%);
    box-shadow: 10px 10px 40px -10px rgb(0 0 0 / 10%);
    border-radius: 8px !important;
    width: fit-content;
    height: fit-content;
  }

  .google-button button {
    border: 1px solid #F2F2F3 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    padding: 2px 12px !important;
    font: 16px ${openSansRegular} !important;
    color: ${scarpaGray600} !important;
    
  }

  .alternative-text {
    color: ${scarpaGray500} !important;
    font: 14px ${openSansRegular} !important;
    padding: 16px 0 8px 0;
    line-height: 24px;
  }

  .title {
    color: ${primary};
    font: 20px ${muliBoldFamily};
    margin-bottom: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }

  .loader-container {
    padding: 0;
    border-radius: 100px;
    width: fit-content;
    padding: 50px;
    background: #FFFFFF;
  }

  .habi-link {
    font: 15px ${muliRegular};
    text-decoration: none;
    color: ${white};
    margin-bottom: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    :hover {
      text-decoration: underline;
    }
  }

`;
