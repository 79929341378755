import API from '../Api';

export const getRegistrationTicketProcedureData = (nid) => {
  return API.get(
    process.env.REACT_APP_GET_REGISTRATION_TICKET_PROCEDURE,
    { params: { nid }}
  );
};

export const getRegistrationTicketData = ({
  nid,
  process_name,
  business_type_id,
}) => {
  return API.get(process.env.REACT_APP_GET_REGISTRATION_TICKET_DATA, {
    params: { nid, process_name, business_type_id },
  });
};

export const saveRegistrationTicketData = (data) => {
  return API.post(
    process.env.REACT_APP_SAVE_REGISTRATION_TICKET_PROCEDURE,
    data
  );
};