import styled from "styled-components";
import { hintPerano000, scarpaGray100 } from "../../StylesConstants";

export const PromiseOfSaleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 360px;
  height: 100%;

  .document-grid {
    align-items: center;
    display: grid;
    grid-template-columns: 40px 490px;
    margin-bottom: 24px;
  }

  .dot-line-download {
    border: 1px dashed ${scarpaGray100};
    position: absolute;
    height: 83px;
    top: 506px;
    margin-left: 13px;
  }

  .dot-line-documents {
    border: 1px dashed ${scarpaGray100};
    position: absolute;
    height: 162px;
    top: 614px;
    margin-left: 13px;
  }

  .document-grid .radio {
    background-color: ${hintPerano000} !important;
    z-index: 0;
  }

  .btn {
    cursor: pointer;
  }

  .btn-grid {
    display: flex;
    gap: 24px;
    flex-flow: wrap;
  }

  .btn-disabled {
    opacity: 0.6;
    cursor: default;
  }
`;