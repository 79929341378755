import { DOCUMENT_STATUS, EVENT_STATUS } from "../utils/constant";

export const getEventStatus = (events, eventName) => {
  const event = events.find((event) => event.name === eventName);

  if(event?.status === EVENT_STATUS.SUCCESS){
    return event.status
  } else if(event?.status === EVENT_STATUS.REJECT) {
    return DOCUMENT_STATUS.REJECTED
  } else {
    return null
  }
};

export const getButtonSelected = (events, eventName) => {
  const event = events.find((event) => event.name === eventName);
  return event?.status ?? null;
};

export const updateEvents = (events, eventName, status) => {
  if (events.some((event) => event.name === eventName)) {
    return events.map((event) => {
      return event.name === eventName ? { ...event, status } : event;
    });
  } else {
    return [...events, { name: eventName, status }];
  }
}