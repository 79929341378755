import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import { callEventProcess, saveProcessComment } from '../../apis/Process/processApis';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import Comments from '../../components/Comments/comments';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import Section from '../../components/DynamicForm/Section/section';
import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import RightSection from '../../components/RightSection/rightSection';
import Button from '../../components/shared/button/button';
import { ButtonGrid, Container, DocumentsGrid, FormContainer, GridForRightSection, Hr, Label, Title } from '../../components/Styles/genericStyleComponent';
import useDynamicForm from '../../hooks/useDynamicForm';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getNotarialExpensesData } from '../../services/data/notarialExpensesData';
import { updateDocuments } from '../../services/documentsService';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import { BUSINESS_TYPE, DOCUMENT_TYPE_NAME, EVENT_STATUS, PROCESS, PROCESS_EVENTS, ROLE } from '../../utils/constant';

const processName = PROCESS.NOTARIAL_EXPENSES;
const businessType = BUSINESS_TYPE.SELLERS;

const NotarialExpenses = () => {

  const nid = useSelector((state) => state.propertyData.nid);
  const { email, role } = useSelector((state) => state.loginData.data);
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState([]);
  const [comments, setComments] = useState([]);
  const [events, setEvents] = useState([]);
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave
  ] = useDynamicForm(form);

  const mapEvents = (eventsTemp) => {
    setEvents([
      {
        label: 'Pago de gastos notariales efectivo',
        name: PROCESS_EVENTS.PAYMENT_NOTARIAL_EXPENSES,
        status: getEventStatus(eventsTemp, PROCESS_EVENTS.PAYMENT_NOTARIAL_EXPENSES),
      }
    ]);
  };

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getNotarialExpensesData(nid, processName, businessType);
        setDocuments(response.documents);
        mapEvents(response.events);
        setForm(response.form);
        setComments(response.comments);
        dispatch(dataFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch],
  );

  const updateDocument = (status, newDocument) => {
    if (!newDocument.exist
      && getEventStatus(events, PROCESS_EVENTS.PAYMENT_NOTARIAL_EXPENSES) === EVENT_STATUS.SUCCESS
    ) {
      callEvent(PROCESS_EVENTS.PAYMENT_NOTARIAL_EXPENSES, EVENT_STATUS.REVIEW);
    }
    setDocuments(docs => updateDocuments(newDocument, docs));
  };

  const saveComment = async (comment) => {
    try {
      await saveProcessComment({
        nid: nid,
        processName: PROCESS.MORTGAGE,
        agent: email,
        nameReviewerRole: role[0],
        comment,
        originUrl: window.location.href,
        noInteractions: true
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role: role[0],
        },
        ...comments,
      ]);
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: businessType,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
      email
    });
    setEvents((evts) => updateEvents(evts, eventName, status));
  };

  const isEventDisabled = () => {
    const haveAuthorizedRole = role.some(role => role === ROLE.MORTGAGE_ANALYST_JUNIOR)
      || role.some(role => role === ROLE.MORTGAGE_ANALYST_SENIOR);
    return documents.some(doc => doc.url === '' || doc.url === null) || !haveAuthorizedRole;
  }

  const save = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      try {
        setLoading(true);
        await updateFormData({
          nid: typeof string ? parseInt(nid) : nid,
          agent: email,
          process_name: processName,
          fields: finalValues,
          business_type_id: BUSINESS_TYPE.SELLERS,
        });
      } catch (error) {
        console.error('ERROR:', error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [dispatch, nid, getData]);

  return (
    <EmptyData>
      <LoadingData>
        <GridForRightSection>
          <Container>
            <Title>
              Gastos notariales
            </Title>
            <Label>
              Pre-liquidación notaría <br /><br />
            </Label>
            <DocumentsGrid>
              <DocumentComponent
                businessTypeId={businessType}
                processName={processName}
                documentParam={documents.find(doc => doc.name === DOCUMENT_TYPE_NAME.NOTARIAL_PRE_LIQUIDATION_EXPENSES)}
                handleUpload={updateDocument}
                handleDelete={updateDocument}
              />
            </DocumentsGrid>
            <Hr />

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={save}
            >
              {(props) =>
                sections.length > 0 &&
                <>
                  <Form>
                    <FormContainer>
                      {sections.map(section =>
                        <Section
                          section={section}
                          formikProps={props}
                          key={`section${section.id}`}
                        />
                      )}
                    </FormContainer>
                    <Label>

                      Soporte de gastos notariales <br /><br />
                    </Label>
                    <DocumentsGrid>
                      {documents
                        .filter(doc => doc.name !== DOCUMENT_TYPE_NAME.NOTARIAL_PRE_LIQUIDATION_EXPENSES)
                        .map(doc =>
                          <DocumentComponent
                            businessTypeId={businessType}
                            processName={processName}
                            documentParam={doc}
                            handleUpload={updateDocument}
                            handleDelete={updateDocument}
                          />
                        )}
                    </DocumentsGrid>
                    <Hr />
                    <ButtonGrid>
                      <Button
                        dataId={'save'}
                        type="submit"
                        loading={loading}
                      >
                        Guardar
                      </Button>
                      {
                        events.length > 0 &&
                        <ButtonCheck
                          dataId={events[0].name}
                          label={events[0].label}
                          status={events[0].status === EVENT_STATUS.SUCCESS ? EVENT_STATUS.SUCCESS : null}
                          handleChange={() => callEvent(events[0].name, EVENT_STATUS.SUCCESS)}
                          disabled={isEventDisabled()}
                        />
                      }
                    </ButtonGrid>
                  </Form>
                </>
              }
            </Formik>
          </Container>
          <RightSection>
            <Comments
              commentsHistory={comments}
              handleSaveComment={saveComment}
            />
          </RightSection>
        </GridForRightSection>
      </LoadingData>
    </EmptyData>
  )
}

export default NotarialExpenses;
