import styled from 'styled-components';

export const DocumentsPCVWrapper = styled.div`

  display: grid;
  grid-template-columns: auto 360px;
  height: 100%;

  .select-grid {
    display: grid;
    grid-template-columns: 1fr 150px;
    gap: 24px;
    margin-bottom: 32px;
    align-items: flex-start;
  }

  .select-grid button {
    margin-top: 24px;
  }

`;