import { DEPENDENCY_CONDITION } from '../../../../utils/constant';
import {
  ANALYST,
  FINANCIAL_ENTITIES,
  NOTARY_SALE,
  PAYMENT_METHOD,
} from '../selectOptions';

export const salesRecord = {
  firstBasicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'purchase_financing',
      label: 'Financiación compra',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'obligation_number',
      label: 'Número de obligación',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: ANALYST,
      },
    },
    {
      name: 'notaria_venta',
      label: 'Notaría venta',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: NOTARY_SALE,
      },
    },
    {
      name: 'payment_method',
      label: 'Forma de pago',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: PAYMENT_METHOD,
      },
    },
    {
      name: 'financial_entity',
      label: 'Entidad financiera',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: FINANCIAL_ENTITIES,
      },
    },
    {
      name: 'external_lawyer_name_rv',
      label: 'Nombre abogado externo',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'external_lawyer_contact_rv',
      label: 'Contacto abogado externo',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'adviser',
      label: 'Asesor',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'adviser_contact',
      label: 'Contacto asesor',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'real_scripture_date',
      label: 'Fecha escritura real',
      type_field: 'inputDate',
      dependencies: [
        {
          on_field: 'property_address',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'property_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_id',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_phone',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_email',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'address_residence_notificacion',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
      ],
      fixed: true,
    },
    {
      name: 'sale_scripture_number',
      label: 'Número de escritura',
      type_field: 'input',
      fixed: true,
    },
  ],
  firstAnalystInformation: [
    {
      name: 'habi_scripture_sending',
      label: 'Envío escritura Habi',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'habi_scripture_signature',
      label: 'Firma escritura Habi',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'notary_collects_signature_habi',
      label: 'Notaría recoge escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'rent_scripture_sending_4',
      label: 'Envío escritura Renta 4',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'rent_scripture_signature_4',
      label: 'Firma escritura Renta 4',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'notary_collects_signature_4',
      label: 'Notaría recoge escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'dann_scripture_sending',
      label: 'Envío escritura Dann',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'dann_scripture_signature',
      label: 'Firma escritura Dann',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'notary_collects_signature_dann',
      label: 'Notaría recoge escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'financial_entity_scripture_sending',
      label: 'Envío escritura entidad financiera',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'financial_entity_scripture_signature',
      label: 'Firma escritura entidad financiera',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'notary_collects_signature_financial',
      label: 'Notaría recoge escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'registry_entry',
      label: 'Ingreso a Registro',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'beneficence_payment_rv',
      label: 'Pago de Beneficencia',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'registry_ticket',
      label: 'Boleta de registro',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'ctl_registered_property',
      label: 'Inmueble registrado en CTL',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'baddly_qualified_ctl_rv',
      label: 'Mal calificado CTL',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'last_followup',
      label: 'Último seguimiento',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'registry_entry_2',
      label: 'Ingreso a Registro',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'ctl_registered_property_2',
      label: 'Inmueble registrado en CTL',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'baddly_qualified_ctl_2',
      label: '2ndo mal calificado en CTL',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'last_followup_2',
      label: 'Último seguimiento',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'registry_entry_3',
      label: 'Ingreso a Registro',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'ctl_registered_property_3',
      label: 'Inmueble registrado en CTL',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  firstLeaderBuyersProceduresInformation: [
    {
      name: 'comments_rv',
      label: 'Comentarios',
      type_field: 'input',
      fixed: false,
    },
  ],
};
