import React, { useState, Fragment } from "react";
import { SideMenuWrapper, SideNavWrapper } from "./style";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import SearchButton from "../../components/shared/SearchButton/searchButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MENU_DICTIONARY_TREE } from "./MenuDictionary";
import { Hr } from '../../components/Styles/genericStyleComponent';
import { ButtonLifeCycle } from '../../components/ButtonLifeCycle/ButtonLifeCycle';
import { useSelector } from 'react-redux';

const SideMenu = () => {
  const [menuOptions] = useState(MENU_DICTIONARY_TREE);
  const nid = useSelector((state) => state.propertyData.nid);
  const userRoles = useSelector(state => state.loginData.data.role);

  const showOption = (functionRoles) => {
    let show = false;
    if (functionRoles) {
      userRoles.forEach((role) => {
        if (functionRoles.some((userRole) => userRole === role)) {
          show = true;
        }
      });
    }
    return show;
  };

  const getMenuSections = () => {
    return menuOptions.map(
      (menu, index) =>
        showOption(menu.roles) && (
          <div
            key={`${menu.menuName + '_' + index}`}
            className="container-sections"
          >
            {menu.menuName === '' && <Hr marginBottom="12" />}
            {menu.menuName && <h2 className="title">{menu.menuName}</h2>}
            <div className="sidenav">{getSections(menu)}</div>
          </div>
        )
    );
  };

  const getSections = (menu) => {
    return menu.sections.map(
      (section) =>
        showOption(section.roles) && (
          <Fragment key={section.name}>
            {section.subSections ? (
              <div className="subsections-container">
                {getSubSections(section)}
              </div>
            ) : (
              <NavLink
                to={section.link}
                activeClassName={
                  section.link !== '/home' ? 'selected' : 'no-available'
                }
                className={'section-container'}
                key={section.name}
              >
                <div className="square-selected"></div>
                <div className=" menu-option">
                  {section.icon}
                  <span className="section-name">{section.name}</span>
                </div>
              </NavLink>
            )}
          </Fragment>
        )
    );
  };

  const getSubSections = (section) => {
    return (
      <div className="accordion">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="section-with-subsection">
              {section.icon}
              {section.name}
            </div>
          </AccordionSummary>
          <AccordionDetails className="details-accordeon">
            {section.subSections.map(
              (subsection) =>
                showOption(subsection.roles) && (
                  <Fragment key={subsection.name}>
                    {subsection.link ? (
                      <NavLink
                        exact
                        to={subsection.link}
                        activeClassName="selected"
                        className="link-subsection"
                      >
                        <div className="square-selected"></div>
                        <span>{subsection.name}</span>
                      </NavLink>
                    ) : (
                      <div className="link-subsection">{subsection.name}</div>
                    )}
                  </Fragment>
                )
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };
  return (
    <SideMenuWrapper>
      <section className="nid-container">
        <SearchButton />
        {!!nid && <ButtonLifeCycle nid={nid} />}
      </section>
      <SideNavWrapper nid={!!nid}>
        {menuOptions && getMenuSections()}
      </SideNavWrapper>
    </SideMenuWrapper>
  );
};

export default withRouter(SideMenu);
