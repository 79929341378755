export const ACTIVITY_STATUS = {
  LOCK: 'lock',
  OPEN: 'open',
  DONE: 'done',
};

export const ACTIVITY_TYPE = 'activity';
export const LABEL = 'label';

export const BUSINESS_TYPE = {
  SELLERS: 1,
  BUYERS: 2,
};

export const DOCUMENT_TYPE = 'document';

export const DOCUMENT_TYPE_NAME = {
  PCV: 'PCV',
  SIGN_PCV: 'firma_PCV_ambas_partes',
  TITLE_STUDY: 'estudio_de_titulos',
  APPRAISAL: 'avaluo',
  DRAFT: 'minuta',
  PAY_INSTRUCTION: 'carga_instruccion_giro',
  PAY_INSTRUCTION_FIRM_ONE: 'firma_N1_instrucción_giro',
  PAY_INSTRUCTION_FIRM_TWO: 'firma_N2_instrucción_giro',
  THIRD_CREATION_REQUEST: 'solicitud_creacion_terceros',
  APPROVED_DRAFT: 'minuta_aprobada',
  DRAFT_OF_SALE: 'minuta_compra_venta',
  SIGNATURE_WRITING: 'firma_escritura',
  SIGNATURE_WRITING_DELIVERY: 'acta_entrega',
  PURCHASE_OPTION_EXERCISE_FORMAT_DANN: 'purchase_option_exercise_format_dann',
  PURCHASE_OPTION_EXERCISE_MINUTE_DANN: 'purchase_option_exercise_minute_dann',
  OPTION_DANN_FORMAT: 'formato_ejercicio_opcion_dann',
  DRAFT_OPTION_DANN_FORMAT: 'minuta_ejercicio_opcion_de_compra_dann',
  CI: 'ci',
  SIGN_CI: 'ci_firmada_ambas_partes',
  OTHER_YES_CI: 'otrosi_ci_buyers',
  PCV_BUYERS: 'pcv_buyers',
  SIGN_PCV_BUYERS: 'pcv_firmada_ambas_partes_buyers',
  OTHER_YES_PCV: 'otrosi_pcv_buyers',
  OFFER_FORMAT: 'formato_de_oferta',
  COMPLIANCE_PROOF: 'comprobante_de_cumplimiento',
  PROOF_COMPLIANCE: 'proof_compliance',
  MORTGAGE_DRAFT: 'minuta_hipoteca',
  SALE_DRAFT: 'minuta_venta',
  CHECKLIST_SENT_DOCUMENTS: 'checklist_documentos_enviados',
  NOTARY_DRAFT: 'minuta_notaria',
  LEGAL_DRAFT: 'minuta_legal',
  EXTERNAL_DRAFT: 'minuta_externo',
  BANK_DRAFT: 'minuta_banco',
  CC_CI_PCV: 'cc_ci_pcv',
  NOTARIAL_PRE_LIQUIDATION_EXPENSES: 'notarial_pre_liquidation_expenses',
  CHECK_REQUEST_FORMAT: 'check_request_format',
};

export const DOCUMENT_STATUS = {
  SUCCESS: 'success',
  REJECTED: 'rejected',
  REVIEW_DOCUMENTS: 'review_documents',
  REVIEW: 'review',
  EDITING: 'editing',
};

export const EVENT_STATUS = {
  SUCCESS: 'success',
  REJECT: 'reject',
  REVIEW: 'review',
};

export const DOCUMENT_WRAPPER_TYPE = {
  WHITE_SQUARE: 1,
  DOT_LINE: 2,
  TEXT: 3,
  ROW: 4,
};

export const PCV_STAGES = {
  UPLOAD_FILE: 'upload_file',
  PCV_UPLOADED: 'pcv_uploaded',
  LEGAL_SUCCESS: 'legal_success',
  LEGAL_REJECTED: 'legal_rejected',
  BUSINESS_OPS_SUCCESS: 'business_ops_success',
  BUSINESS_OPS_REJECTED: 'business_ops_rejected',
  OPS_SUCCESS: 'ops_success',
  OPS_REJECTED: 'ops_rejected',
  STAGE_SUCCESS: 'stage_success',
  COMMERCIAL_SUCCESS: 'commercial_success',
  COMMERCIAL_REJECTED: 'commercial_rejected',
  UPLOAD_CI: 'upload_ci',
  UPLOAD_SIGNED_CI: 'upload_signed_ci',
  UPLOAD_OTHER_YES_CI: 'upload_otrosi_ci',
  UPLOAD_BUYER_PCV: 'upload_buyer_pcv',
  UPLOAD_SIGNED_BUYER_PCV: 'upload_signed_buyer_pcv',
  UPLOAD_OTHER_YES_PCV: 'upload_otrosi_pcv',
};

export const PROCESS = {
  BUYERS_FOLDER: 'buyers_folder',
  REGISTRATION_TICKET: 'registration_ticket',
  MORTGAGE_REGISTRATION_TICKET: 'mortgage_registration_ticket',
  SELLER_AND_LEGAL: 'document_pcv_sales_and_legal',
  PRE_WRITING_SELLERS: 'document_pre_writing_sales_and_legal',
  PCV_SALES_AND_LEGAL: 'PCV_sales_and_legal',
  DATA_PROPERTY_SELLER: 'data_property_seller',
  DATA_PROPERTY_BUYER: 'data_property_buyer',
  DATA_PROPERTY_RECEPTION: 'data_property_reception',
  TITLE_STUDY: 'title_study',
  APPRAISAL: 'appraisal',
  BILL: 'bill',
  EXTERNAL_TITLE_STUDY: 'external_title_study',
  EXTERNAL_APPRAISAL: 'external_appraisal',
  EXTERNAL_BILL: 'external_bill',
  BONDING_COLPATRIA: 'bonding_colpatria',
  BONDING_DANN: 'bonding_leasing_DANN',
  CHECKLIST: 'checklist',
  PAY_INSTRUCTION_COLPATRIA: 'order_instruction_colpatria',
  SIGNATURE_AND_DELIVERY: 'signature_writing',
  PURCHASE_OPTION_LEASING_DANN: 'purchase_option_dann',
  PURCHASE_OPTION_PA_COLPATRIA: 'purchase_option_pa_colpatria',
  PURCHASE_OPTION_PA_DANN: 'purchase_option_pa_dann',
  DISBURSEMENT: 'disbursement',
  PCV_PURCHASE_AND_LEGAL: 'PCV_purchase_and_legal',
  CI_PURCHASE_AND_LEGAL: 'CI_purchase_and_legal',
  CI_PCV_DOCUMENTS: 'ci_pcv_documents',
  BUYER_RECEPTION: 'formato_recepcion_comprador',
  OTHER_BANKS_TITLE_STUDY: 'other_banks_title_study',
  OTHER_BANKS_TITLE_PRE_STUDY: 'other_banks_title_pre_study',
  APPRAISAL_APPOINTMENT: 'quote_appraisal',
  DEED_DELIVERY: 'deed_delivery',
  MORTGAGE: 'mortgage',
  MORTGAGE_CANCELLATION: 'mortgage_cancellation',
  MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS:
    'mortgage_cancellation_request_documents',
  DRAFT_APPROVAL_REQUEST: 'memorandum_aproval_request_bank',
  NOTARIAL_EXPENSES: 'notarial_expenses',
  PAYMENT_REQUEST: 'payment_requests',
  NOTARIAL_PAYMENT_DOCUMENTS: 'notarial_payments',
};

export const DUMMY_PROCESS = {
  PURCHASE_OPTION: 'purchase_option',
  BONDING: 'bonding',
};

export const DYNAMIC_FORM = {
  DETAILS_FROM_THE_SELLER: 'datos_del_vendedor',
};

export const PROCESS_EVENTS = {
  FIRST_REQUEST_BONDING: 'solicitud_de_vinculacion_primer_envio',
  FIRTS_FUNDING_APPROVE: 'aprobacion_fiduciara_primer_envio',
  SECOND_REQUEST_BONDING: 'solicitud_de_vinculacion_segundo_envio',
  SECOND_FUNDING_APPROVE: 'aprobacion_fiduciara_segundo_envio',
  PAY_INSTRUCTION_LOAD: 'revision_carga_instruccion_de_giro',
  PAY_INSTRUCTION_FIRM_ONE: 'revision_firma_uno_instruccion_de_giro',
  PAY_INSTRUCTION_FIRM_TWO: 'revision_firma_dos_instruccion_de_giro',
  PAY_INSTRUCTION_DOCUMENTS_COMPLETE:
    'documentos_completos_instruccion_de_giro',
  DANN_REQUEST_BONDING: 'solicitud_vinculacion_dann',
  DANN_BONDING_APPROVE: 'vinculacion_aprobada_dann',
  CAMPOS_COMERCIAL_COMPLETOS: 'campos_comercial_completos',
  APROBACION_LEGAL: 'aprobacion_legal',
  REQUEST_PURCHASE_OPTION: 'solicitud_aprobacion_opcion_compra',
  REQUEST_PURCHASE_OPTION_ONE: 'solicitud_aprobacion_opcion_compra_envio_uno',
  REQUEST_PURCHASE_OPTION_TWO: 'solicitud_aprobacion_opcion_compra_envio_dos',
  PURCHASE_OPTION_APPROVED: 'opcion_compra_aprobada',
  DATOS_COMPLETOS: 'datos_completos',
  DATOS_APROBADOS: 'datos_aprobados',
  NOTIFICAR_CLIENTE: 'notificar_cliente',
  REVIEW_REQUEST: 'solicitar_revision',
  LEGAL_APPROVE: 'aprobacion_legal',
  PCV_PROCEDURES_REQUEST: 'pcv_request',
  VALIDATION: 'validacion',
  LEGAL_REVIEW: 'revision_legal',
  OFFERING_FORMAT: 'formato_oferta',
  DOCUMENTS_READY: 'documentos_listos',
  APPROVED_DOCUMENTS: 'documentos_aprobados',
  APPRAISER_ASSIGNED: 'avaluador_asignado',
  SEND_INFORMATION: 'enviar_informacion',
  APPRAISAL_SUBMITTED: 'avaluo_enviado',
  TITLE_STUDY: 'title_study',
  SEND_BILLING: 'send_billing',
  APPROVE_BILLING: 'approved_billing',
  LOAD_BILLING: 'load_billing',
  LOAD_LEGAL_BILLING: 'load_legal_billing',
  PRE_STUDY_TITLE_REQUEST_FIRST: 'pre_study_title_request_first',
  PRE_STUDY_TITLE_REQUEST_SECOND: 'pre_study_title_request_second',
  PRE_STUDY_APROVED: 'pre_study_aproved',
  SIGNATURE_SCHEDULED: 'escrituracion_agendada',
  REQUEST_CANCELLATION_MORTGAGE: 'solicitar_cancelacion_hipoteca',
  CASH_MORTGAGE_PAYMENT: 'pago_hipoteca_efectivo',
  APPROVED_APPLICATION: 'solicitud_aprobada',
  REQUEST_APPROVED: 'request_approved',
  DRAFT_APPROVAL_REQUEST: 'solicitud_aprobacion_minuta_a_banco',
  DRAFT_APPROVAL_REQUEST_APPROVED:
    'solicitud_aprobacion_minuta_a_banco_aprobada',
  PAYMENT_NOTARIAL_EXPENSES: 'pago_gastos_notariales_efectivo',
  MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS:
  'mortgage_cancellation_request_documents',
  NOTARIAL_PAYMENT_DOCUMENTS: 'notarial_payments',
  REQUEST_PAYMENT: 'request_payment',
};

export const PROCESS_SECTIONS = {
  BONDING_COLPATRIA_FIRST: 'first_sending',
  BONDING_COLPATRIA_SECOND: 'second_sending',
  PRE_STUDY_TITLE_REQUEST_FIRST_SENDING:
    'pre_study_title_request_first_sending',
  PRE_STUDY_TITLE_REQUEST_SECOND_SENDING:
    'pre_study_title_request_second_sending',
};

export const ROLE = {
  LEGAL: 'legal',
  OPERATIONS: 'operaciones',
  BUSINESS_OPERATIONS: 'business_operations',
  COMMERCIAL: 'comercial',
  SUPPLIER_TITLE_STUDY: 'estudio_de_titulos',
  SUPPLIER_APPRAISAL: 'avaluo',
  SUPPLIER_DRAFT: 'minuta',
  ADMINISTRATOR: 'administrador',
  PROCEDURES_ANALYST_SELLERS: 'analista_tramites_vendedores',
  PROCEDURES_ANALYST_SELLERS_SR: 'analista_tramites_vendedores_senior',
  PROCEDURES_LEADER_SELLERS: 'lider_tramites_vendedores',
  PROCEDURES_ANALYST_COMMERCIAL: 'analista_tramites_comercial',
  PROCEDURES_ANALYST_BUYERS: 'analista_tramites_compradores',
  PROCEDURES_ASSISTANT: 'auxiliar_de_tramites',
  PROCEDURES_LEGAL_ASSISTANT: 'auxiliar_de_tramites_legal',
  COMMERCIAL_BUYERS: 'comercial_compradores',
  COMMERCIAL_SELLERS: 'comercial_vendedores',
  DATA: 'data',
  LEGAL_DIRECTOR: 'director_legal',
  PROCEDURES_LEADER_BUYERS: 'lider_tramites_compradores',
  NOTARY: 'notaria',
  REMODELING: 'remodelaciones',
  REPRESENTATIVE_LEGAL: 'representante_legal',
  SUPER_ADMIN: 'super_admin',
  CHECKLIST_DOCUMENTS: 'checklist_documents',
  LISTING_WEB: 'listing_web',
  OPERATIONS_DIRECTOR: 'director_operaciones',
  COMPLIANCE_OFFICER: 'oficial_cumplimiento',
  MORTGAGE_ANALYST_JUNIOR: 'analista_hipoteca_junior',
  MORTGAGE_ANALYST_SENIOR: 'analista_hipoteca_senior',
};

export const ALL_HABI_ROLES = [
  ROLE.LEGAL,
  ROLE.OPERATIONS,
  ROLE.BUSINESS_OPERATIONS,
  ROLE.COMMERCIAL,
  ROLE.ADMINISTRATOR,
  ROLE.PROCEDURES_ANALYST_SELLERS,
  ROLE.PROCEDURES_ANALYST_SELLERS_SR,
  ROLE.PROCEDURES_LEADER_SELLERS,
  ROLE.MORTGAGE_ANALYST,
  ROLE.PROCEDURES_ANALYST_COMMERCIAL,
  ROLE.PROCEDURES_ANALYST_BUYERS,
  ROLE.PROCEDURES_ASSISTANT,
  ROLE.PROCEDURES_LEGAL_ASSISTANT,
  ROLE.COMMERCIAL_BUYERS,
  ROLE.COMMERCIAL_SELLERS,
  ROLE.DATA,
  ROLE.LEGAL_DIRECTOR,
  ROLE.PROCEDURES_LEADER_BUYERS,
  ROLE.NOTARY,
  ROLE.REMODELING,
  ROLE.REPRESENTATIVE_LEGAL,
  ROLE.SUPER_ADMIN,
  ROLE.OPERATIONS_DIRECTOR,
  ROLE.COMPLIANCE_OFFICER,
  ROLE.MORTGAGE_ANALYST_JUNIOR,
  ROLE.MORTGAGE_ANALYST_SENIOR,
];

export const ALL_EXTERNAL_ROLES = [
  ROLE.SUPPLIER_TITLE_STUDY,
  ROLE.SUPPLIER_APPRAISAL,
  ROLE.SUPPLIER_DRAFT,
];

export const ALL_PROCEDURES_ROLES = [
  ROLE.PROCEDURES_ANALYST_SELLERS,
  ROLE.PROCEDURES_ANALYST_SELLERS_SR,
  ROLE.PROCEDURES_LEADER_SELLERS,
];

export const ALL_LEGAL_ROLES = [ROLE.LEGAL, ROLE.LEGAL_DIRECTOR];

export const SUB_ACTIVITY_TYPE = 'subactivity';

export const FIELD_TYPE = {
  INPUT: 'input',
  INPUT_NUMBER: 'inputNumber',
  INPUT_DECIMAL: 'inputDecimal',
  SELECT: 'select',
  INPUT_CURRENCY: 'inputCurrency',
  INPUT_DATE: 'inputDate',
  INPUT_DATE_TIME: 'inputDateTime',
  SIGNATURE_TYPE_NAME: 'signature_type_name',
  SIGNATURE_DATE: 'signature_date',
  SIGNATURE_DOCUMENT_DATE: 'signature_document_date',
  DELIVERY_DATE: 'delivery_date',
  DELIVERY_DOCUMENT_DATE: 'delivery_document_date',
  SUB_SECTION: 'subsection',
  RADIO_OPTION_BLOCK: 'radioOptionBlock',
  RADIO_OPTION_ROUNDED: 'radioOptionRounded',
  CHECKBOX_ROUNDED: 'checkboxRounded',
};

export const FIELD_NAME = {
  PROCESS_ANALYST: 'analista_tramites',
  PROCESS_ANALYST_JUNIOR: 'analista_tramites_junior',
  PROCESS_ANALYST_BUYERS: 'analista_tramites_buyers',
  SCHEDULE_DEED_TIMESTAMP: 'scheduled_deed_timestamp',
  REQUEST_TYPE: 'informacion_solicitud',
};

export const LOCKED_MESSAGE = {
  PCV_DOCUMENT:
    'Este documento se habilitará una vez que Legal revise la sección Documentos PCV y Recepción inmueble',
  PCV_OPTIONAL_DOCUMENTS:
    'Este documento se habilitará después de cargar la Firma PCV ambas partes',
  COMPLETE_DOCUMENTS:
    'Ésta carpeta se habilitará después de marcar los documentos completos',
  CI_PCV_DOCUMENT:
    'Este documento se habilitará una vez que Legal revise la sección Documentos CI / PCV y Recepción comprador',
};

export const STATUS_EXTERNAL_PROCESS = {
  IN_PROGRESS: 'in_progress',
  IN_REVIEW: 'in_review',
  HABI_CORRECTION: 'habi_correction',
  EXTERNAL_CORRECTION: 'external_correction',
  FINISHED: 'finished',
};

export const ROUTES = {
  BASE: '/',
  HOME: '/home',
  REGISTRATION_TICKET: '/boleta-registro',
  BUYERS_FOLDER: '/carpetas-compradores',
  DOCUMENTS_PCV: '/documentos-pcv',
  DOCUMENTS_PRE_DEED: '/documentos-pre-escritura',
  PROPERTY_SELLERS: '/inmuebles-vendedores',
  PROPERTY_BUYERS: '/inmuebles-compradores',
  DOCUMENT_REVIEW: '/revision-documento',
  PROMISE_OF_SALE: '/pcv',
  TITLE_STUDY: '/estudio-titulos',
  APPRAISAL: '/avaluo',
  DRAFT: '/minuta',
  PROPERTY_RECEPTION: '/recepcion-inmueble',
  SUPPLIER_TITLE_STUDY: '/proveedor-estudio-titulos',
  SUPPLIER_APPRAISAL: '/proveedor-avaluo',
  SUPPLIER_DRAFT: '/proveedor-minuta',
  SUPPLIER_DOCUMENTS: '/proveedor-documentos',
  DOCUMENTS_CHECKLIST: '/checklist-documentos',
  BONDING: '/vinculacion',
  PAY_INSTRUCTION: '/instruccion-de-giro',
  DISBURSEMENT: '/desembolso',
  SIGNATURE_AND_DELIVERY: '/firma-entrega',
  PURCHASE_OPTION: '/opcion-de-compra',
  LISTING_WEB: '/listing-web',
  DOCUMENTS_CI_PCV: '/documentos-ci-pcv',
  CI_PCV: '/ci-pcv',
  BUYER_RECEPTION: '/buyer-reception',
  HIGH_LEVEL_BUYERS: '/high-level-compradores',
  APPRAISAL_APPOINTMENT: '/cita-avaluo',
  NOT_FOUND: '/404',
  DOCUMENTS_PRE_STUDY: '/documentos-pre-estudio',
  TITLE_STUDY_AND_DRAFTS: '/estudio-titulos-minuta',
  SIGNATURE_AND_DELIVERY_BUYERS: '/firma-entrega-compradores',
  DRAFT_APPROVAL_REQUEST: '/solicitud_aprobacion_minuta',
  NOTARIAL_CHARGES: '/gastos_notariales',
  MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS:
    '/documentos_solicitud_cancelacion_hipoteca',
  MORTGAGE_CANCELLATION: '/cancelacion_hipoteca',
  NOTARIAL_EXPENSES: '/gastos_notariales',
  DASHBOARD: '/dashboard',
  MORTGAGE_REGISTRATION_TICKET: '/boleta_registro_hipoteca',
  PAYMENT_REQUEST: '/solicitudes_de_pago',
  NOTARIAL_PAYMENTS_DOCUMENTS: '/documentos_pagos_notariales',
};

export const ELEMENTS_PAGER = 10;
export const ELEMENTS_PAGER_HIGH_LEVEL = 25;

export const FILE_EXTENSIONS = {
  EXCEL: '.xls',
  EXCEL_NEW: '.xlsx',
  WORD: '.doc',
  WORD_NEW: '.docx',
  PDF: '.pdf',
};

export const FUNDING_TYPE = {
  HABI: 'habi',
  PA_COLPATRIA: 'pa_mas_colpatria',
  PA_DANN: 'pa_mas_dann',
  LEASING_DANN: 'leasing_dann',
};

export const DISBURSEMENT_CONFIG = {
  MONEY_PERCENTAGE: 'money-percentage',
  RADIO_BUTTON: 'radio-button',
  MONEY_DEPENDS: 'money-depends',
  MONEY_FREE: 'money-free',
  DISBURSEMENT_MOUNT_BY_DANN: 'amount_disbursed_dann',
  DISBURSEMENT_MOUNT_BY_COLPATRIA: 'amount_disbursed_colpatria',
  DISBURSEMENT_MOUNT_BY_HABI: 'amount_disbursed_habi',
  PERCENTAGE_ARRAS_HABI: 'arras_percent',
  MOUNT_ARRAS_HABI: 'arras_amount',
  MORTGAGE: 'mortgage_amount',
  DEPOSIT: 'deposit_amount',
  WITHHOLDING_TAX: 'withholding_source_amount',
  MANAGEMENT: 'admin_amount',
  PUBLIC_SERVICES: 'public_services_amount',
  PREDIALS: 'praedial_amount',
  ACCOUNT_RECEIVABLE_TO_DANN: 'account_receivable_dann',
  ACCOUNT_RECEIVABLE_FROM_PA_COLPATRIA: 'account_receivable_colpatria',
  DISBURSEMENT_TO_CLIENT_BY_DANN: 'disbursement_customer_dann',
  DISBURSEMENT_TO_CLIENT_BY_HABI: 'disbursement_customer_habi',
  DISBURSEMENT_ACCOUNTING: 'disbursement_accounting',
  MONTO_DESEMBOLSADO_POR_DANN: 'Monto desembolsado por Dann (85%)',
  MONTO_DESEMBOLSADO_POR_COLPATRIA: 'Monto desembolsado por Colpatria (15%)',
  MONTO_DESEMBOLSADO_POR_HABI: 'Monto desembolsado por Habi',
  PORCENTAJE_DE_ARRAS_HABI: 'Porcentaje de arras Habi',
  MONTO_ARRAS_HABI: 'Monto Arras Habi',
  HIPOTECA: 'Hipoteca',
  DEPOSITO: 'Depósito',
  RETENCIÓN_EN_LA_FUENTE: 'Retención en la fuente',
  ADMINISTRACION: 'Administración',
  SERVICIOS_PUBLICOS: 'Servicios públicos',
  PREDIALES: 'Prediales',
  CUENTA_POR_COBRAR_A_DANN: 'Cuenta por cobrar a Dann',
  CUENTA_POR_COBRAR_A_PA_COLPATRIA: 'Cuenta por cobrar a PA Colpatria',
  DESEMBOLSO_A_CLIENTE_POR_DANN: 'Desembolso a cliente por Dann',
  DESEMBOLSO_A_CLIENTE_POR_HABI: 'Desembolso a cliente por Habi',
  DESEMBOLSO_CONTABILIDAD: 'Desembolso contabilidad',
};

export const LABEL_CHECKBOX_BUTTON = {
  CAMPOS_COMERCIAL_COMPLETOS: 'Campos comercial completos',
  APROBACION_LEGAL: 'Aprobación Legal',
};

export const RESPONSE_ERRORS = {
  COMERCIAL_EMAIL_WAS_NOT_FOUND: 'Comercial email was not found',
};

export const BUSINESS_PUBLICATION = {
  ETIQUETA_DEL_INMUEBLE: 'Etiqueta del inmueble',
  CAMBIO_DE_ETIQUETA_DEL_INMUEBLE: 'Cambio de etiqueta del inmueble',
  FECHA_DE_BOLETA_DE_REGISTRO: 'Fecha esperada de boleta de registro',
  FECHA_DE_CTL: 'Fecha esperada de CTL',
};

export const BUYERS_FORM = {
  TAG_ONE: 'tag_one',
  TAG_TWO: 'tag_two',
  TAG_THREE: 'tag_three',
  TAG_CHANGE: 'tag_change',
  REGISTRATION_TICKET_DATE: 'registration_ticket_date',
  CTL_DATE: 'ctl_date',
  FECHAS: 'Fechas',
  CHOOSE_TAG_ERROR: 'Por favor, seleccione una etiqueta',
};

export const LISTING_WEB = {
  NUEVO: 'Nuevo',
  CAMBIO_DE_ETIQUETA: 'Cambio de etiqueta',
  VENDIDO: 'Vendido',
  INCOMPLETO: 'Incompleto',
  NO_DATA: '--------------------',
};

export const STATUS_LISTING_WEB_PROCESS = {
  REQUESTED: 'requested',
  PUBLISHED: 'published',
  SOLD_INCOMPLETE: 'sold_incomplete',
  SOLD: 'sold',
  INCOMPLETE: 'incomplete',
};

export const STATUS_PAYMENT_REQUEST = {
  REQUESTS: 'requests',
  APPROVED: 'approved_requests',
  REJECTED: 'rejected_requests',
};

export const HTTP_STATUS = {
  NOT_FOUND: 404,
};

export const API_ERROR_MESSAGE = {
  FUNDING_TYPE_NOT_FOUND: 'The funding type not found',
};

export const REQUEST_PCV = {
  PCV: 'pcv',
  CI_PCV: 'ci_and_pcv',
};

export const SECTION_WITHOUT_BUTTON = ['caracteristicas_de_negociacion'];

export const BUYER_RECEPTION_CONSTANTS = {
  NEGOTIATION_CHARACTERSTICS: 'caracteristicas_de_negociacion',
  OFFER_FORMAT: 'formato_de_oferta',
};

export const STEP_STATUS = {
  IN_PROGRESS: 'in_review',
  EMPTY: 'non_start',
  COMPLETED: 'success',
};

export const STEPS_LIFE_CYCLE = {
  PCV: 'pcv',
  ANALYST: 'analyst',
  APPRAISAL: 'appraisal_minute',
  FINANCING: 'financing',
  DEED_DELYVERY: 'deed_delivery',
  DISBURSEMENT: 'disbursement',
  REGISTRATION: 'registration_ticket',
  LISTING_WEB: 'listing_web',
};

export const DEPENDENCY_CONDITION = {
  NOT_EMPTY: 'notEmpty',
};

export const MORTGAGE_STATE = {
  CANCEL: 'cancelada',
  REMAINING_BALANCE: 'saldo_remanente',
};

export const MORTGAGE_CANCELLATION = {
  MORTGAGE_PAYMENT_SUPPORT: 'mortgage_payment_support',
  MORTGAGE_DEBT_CERTIFICATION: 'mortgage_debt_certification',
  MINOR_CASH_RECEIPT_SUPPORT: 'minor_cash_receipt_support',
  SUPPORT_PAYMENT_REMAINING_BALANCE: 'support_payment_remaining_balance',
  MORTGAGE_STATUS: 'mortgage_status',
};

export const NOTIFICATION_STATE = {
  NEW: 'new',
  IMPORTANT: 'important',
  FINISHED: 'finished'
};

export const PAYMENT_REQUESTS = {
  MORTGAGE_PAYMENT: 'mortgage_payment',
  NOTARY_PAYMENT: 'notary_payment',
};

export const TYPE_PCV = {
  PCV_LEASING: 'pcv_leasing'
};
