import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import { BUSINESS_TYPE, HTTP_STATUS, PROCESS } from '../../utils/constant';

export const getSignatureAndDeliveryBuyersData = (nid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const form = await getFormDataDependencies(
        nid,
        PROCESS.DEED_DELIVERY,
        BUSINESS_TYPE.BUYERS
      );
      resolve({
        form: form.data.data,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
