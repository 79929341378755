import styled from 'styled-components';
import { goldenYellow300, muliBoldFamily, nightBlue400, openSansBoldFamily, openSansRegular, scarpaGray100, scarpaGray300, skyBlue } from '../../../../StylesConstants';

export const NotificationsListWrapper = styled.div`

  table {
    width: 100%;
    font: 12px ${openSansRegular};
    
    th {
      height: 40px;
      text-align: left;
      border-bottom: 1px solid ${scarpaGray100};
      color: ${nightBlue400};
    }

    td {
      height: 50px;
      border-bottom: 1px solid ${scarpaGray100};
    }

  }

  .link {
    color ${skyBlue};
    font: 12px ${openSansBoldFamily};
    text-decoration: none;
    cursor: pointer;
  }

  .empty-notifications {
    font: 12px ${muliBoldFamily};
    padding: 32px;
  }

  .star {
    width: 56px;
    text-align: center;
    cursor: pointer;

    .important {
      color: ${goldenYellow300};
    }

    .new {
      color: ${scarpaGray300};
    }

  }

  .description {
    font: 12px ${openSansBoldFamily};
    cursor: pointer;
  }

`;
