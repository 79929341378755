import {
  BUSINESS_PUBLICATION,
  BUYERS_FORM,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  EVENT_STATUS,
  FIELD_TYPE,
  FILE_EXTENSIONS,
  FUNDING_TYPE,
  LISTING_WEB,
  MORTGAGE_CANCELLATION,
  PAYMENT_REQUESTS,
  PROCESS,
  STATUS_LISTING_WEB_PROCESS,
  STATUS_PAYMENT_REQUEST,
  STEPS_LIFE_CYCLE,
  STEP_STATUS,
} from './constant';

import IconFilePdf from '../static/assets/img/icons/icon-file-pdf.svg';
import IconFileXls from '../static/assets/img/icons/icon-file-xls.svg';
import IconFileDoc from '../static/assets/img/icons/icon-file-doc.svg';
import IconFilePdfEmpty from '../static/assets/img/icons/icon-file-pdf-empty.svg';
import IconFileXlsEmpty from '../static/assets/img/icons/icon-file-xls-empty.svg';
import IconFileDocEmpty from '../static/assets/img/icons/icon-file-doc-empty.svg';
import habi from '../pages/Disbursement/config/habi';
import dann from '../pages/Disbursement/config/dann';
import paColpatria from '../pages/Disbursement/config/paColpatria';

import IconFileAdd from '../static/assets/img/icons/icon-folder.svg';
import IconString from '../static/assets/img/icons/icon-add-user.svg';
import IconMoney from '../static/assets/img/icons/icon-file-generic.svg';
import IconFileGeneric from '../static/assets/img/icons/icon-account-balance.svg';
import IconAddUser from '../static/assets/img/icons/icon-string.svg';
import IconFolder from '../static/assets/img/icons/icon-money.svg';
import IconTicket from '../static/assets/img/icons/icon-file-add.svg';
import IconAccountBalance from '../static/assets/img/icons/icon-ticket.svg';
import IconCheck from '../static/assets/img/icons/icon-check-hint.svg';
import IconCheckWhiteBorder from '../static/assets/img/icons/icon-check-white-border.svg';
import IconCircleWhiteBorder from '../static/assets/img/icons/icon-circle-white-border.svg';
import {
  PUBLISHED_COLUMNS,
  REQUESTED_COLUMNS,
  SOLD_INCOMPLETE,
  REQUESTS_PAYMENT_REQUESTS,
  REQUESTS_PAYMENT_APPROVED,
  REQUESTS_PAYMENT_REJECTED,
} from '../components/GenericTable/constants/titlesColumns';

export const LABELS_BUTTON_CHECK = {
  [DOCUMENT_STATUS.REJECTED]: 'Rechazo',
  [DOCUMENT_STATUS.SUCCESS]: 'Aprobación',
  REVISION: 'Revisión',
};

export const FUNDING_TYPE_LABEL = {
  [FUNDING_TYPE.HABI]: 'Habi',
  [FUNDING_TYPE.PA_DANN]: 'Pa + Dann',
  [FUNDING_TYPE.LEASING_DANN]: 'Leasing Dann',
  [FUNDING_TYPE.PA_COLPATRIA]: 'Pa + Colpatria',
};

export const BONDING_PROCESS_BY_FUNDING_TYPE = {
  [FUNDING_TYPE.PA_COLPATRIA]: PROCESS.BONDING_COLPATRIA,
  [FUNDING_TYPE.PA_DANN]: PROCESS.BONDING_DANN,
  [FUNDING_TYPE.LEASING_DANN]: PROCESS.BONDING_DANN,
};

export const PURCHASE_OPTION_PROCESS_BY_FUNDING_TYPE = {
  [FUNDING_TYPE.LEASING_DANN]: PROCESS.PURCHASE_OPTION_LEASING_DANN,
  [FUNDING_TYPE.PA_DANN]: PROCESS.PURCHASE_OPTION_PA_DANN,
  [FUNDING_TYPE.PA_COLPATRIA]: PROCESS.PURCHASE_OPTION_PA_COLPATRIA,
};

export const ICON_BY_FILE_TYPE = {
  [FILE_EXTENSIONS.EXCEL]: IconFileXls,
  [FILE_EXTENSIONS.EXCEL_NEW]: IconFileXls,
  [FILE_EXTENSIONS.WORD]: IconFileDoc,
  [FILE_EXTENSIONS.WORD_NEW]: IconFileDoc,
  [FILE_EXTENSIONS.PDF]: IconFilePdf,
};

export const ICON_EMPTY_BY_FILE_TYPE = {
  [FILE_EXTENSIONS.EXCEL]: IconFileXlsEmpty,
  [FILE_EXTENSIONS.EXCEL_NEW]: IconFileXlsEmpty,
  [FILE_EXTENSIONS.WORD]: IconFileDocEmpty,
  [FILE_EXTENSIONS.WORD_NEW]: IconFileDocEmpty,
  [FILE_EXTENSIONS.PDF]: IconFilePdfEmpty,
};

export const FILE_TYPE_BY_DOCUMENT = {
  [DOCUMENT_TYPE_NAME.APPROVED_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.THIRD_CREATION_REQUEST]: FILE_EXTENSIONS.EXCEL,
  [DOCUMENT_TYPE_NAME.DRAFT_OF_SALE]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.PURCHASE_OPTION_EXERCISE_FORMAT_DANN]:
    FILE_EXTENSIONS.EXCEL,
  [DOCUMENT_TYPE_NAME.PURCHASE_OPTION_EXERCISE_MINUTE_DANN]:
    FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.DRAFT_OPTION_DANN_FORMAT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.MORTGAGE_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.SALE_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.CHECKLIST_SENT_DOCUMENTS]: FILE_EXTENSIONS.EXCEL,
  [DOCUMENT_TYPE_NAME.NOTARY_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.LEGAL_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.EXTERNAL_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.BANK_DRAFT]: FILE_EXTENSIONS.WORD,
  [DOCUMENT_TYPE_NAME.CHECK_REQUEST_FORMAT]: FILE_EXTENSIONS.EXCEL,
};

export const DISBURSEMENT_DYNAMIC_CONFIG = {
  [FUNDING_TYPE.HABI]: habi,
  [FUNDING_TYPE.LEASING_DANN]: dann,
  [FUNDING_TYPE.PA_COLPATRIA]: paColpatria,
};

export const DYNAMIC_LISTING_WEB = {
  [LISTING_WEB.NUEVO]: 'new-tag',
  [LISTING_WEB.CAMBIO_DE_ETIQUETA]: 'tag-change',
  [STATUS_LISTING_WEB_PROCESS.SOLD]: 'sold-tag',
  [STATUS_LISTING_WEB_PROCESS.INCOMPLETE]: 'incomplete-tag',
};

export const DYNAMIC_BUYERS_FORM = {
  [BUYERS_FORM.REGISTRATION_TICKET_DATE]:
    BUSINESS_PUBLICATION.FECHA_DE_BOLETA_DE_REGISTRO,
  [BUYERS_FORM.CTL_DATE]: BUSINESS_PUBLICATION.FECHA_DE_CTL,
};

export const DYNAMIC_FORM_STYLES = {
  [FIELD_TYPE.SUB_SECTION]: 'radio-buttons-full-width',
  [FIELD_TYPE.RADIO_OPTION_BLOCK]: 'radio-buttons-full-width-background',
  [FIELD_TYPE.CHECKBOX_ROUNDED]: 'checbox-rounded-container',
};

export const OPTIONS_VALIDATION = {
  [EVENT_STATUS.SUCCESS]: {
    label: 'Aprobado',
    value: EVENT_STATUS.SUCCESS,
  },
  [EVENT_STATUS.REJECT]: {
    label: 'Rechazado',
    value: EVENT_STATUS.REJECT,
  },
};

export const OPTIONS_VALIDATION_LIST = [
  {
    label: 'Aprobado',
    value: EVENT_STATUS.SUCCESS,
  },
  {
    label: 'Rechazado',
    value: EVENT_STATUS.REJECT,
  },
];

export const STEP_ICONS = {
  [STEP_STATUS.COMPLETED]: IconCheck,
  [STEPS_LIFE_CYCLE.PCV]: IconFolder,
  [STEPS_LIFE_CYCLE.ANALYST]: IconAddUser,
  [STEPS_LIFE_CYCLE.APPRAISAL]: IconFileGeneric,
  [STEPS_LIFE_CYCLE.FINANCING]: IconAccountBalance,
  [STEPS_LIFE_CYCLE.DEED_DELYVERY]: IconString,
  [STEPS_LIFE_CYCLE.DISBURSEMENT]: IconMoney,
  [STEPS_LIFE_CYCLE.REGISTRATION]: IconFileAdd,
  [STEPS_LIFE_CYCLE.LISTING_WEB]: IconTicket,
};

export const STEP_SECTION_ICONS = {
  [STEP_STATUS.COMPLETED]: IconCheckWhiteBorder,
  [STEP_STATUS.EMPTY]: IconCircleWhiteBorder,
};

export const TITLES_REGISTRATION_TICKET = {
  [FUNDING_TYPE.LEASING_DANN]: 'Leasing Dann',
  [FUNDING_TYPE.PA_DANN]: 'PA + Dann',
  [FUNDING_TYPE.PA_COLPATRIA]: 'PA + Colpatria',
  [FUNDING_TYPE.HABI]: 'Habi',
};

export const MORTGAGE_CANCELLATION_DOCUMENTS = {
  STATE: [
    MORTGAGE_CANCELLATION.MINOR_CASH_RECEIPT_SUPPORT,
    MORTGAGE_CANCELLATION.SUPPORT_PAYMENT_REMAINING_BALANCE,
  ],
  VOUCHERS: [
    MORTGAGE_CANCELLATION.MORTGAGE_PAYMENT_SUPPORT,
    MORTGAGE_CANCELLATION.MORTGAGE_DEBT_CERTIFICATION,
  ],
};

export const genericTableTitles = {
  // Listing Web:
  [STATUS_LISTING_WEB_PROCESS.REQUESTED]: REQUESTED_COLUMNS,
  [STATUS_LISTING_WEB_PROCESS.PUBLISHED]: PUBLISHED_COLUMNS,
  [STATUS_LISTING_WEB_PROCESS.SOLD]: SOLD_INCOMPLETE,
  // Payment Request:
  [STATUS_PAYMENT_REQUEST.REQUESTS]: REQUESTS_PAYMENT_REQUESTS,
  [STATUS_PAYMENT_REQUEST.APPROVED]: REQUESTS_PAYMENT_APPROVED,
  [STATUS_PAYMENT_REQUEST.REJECTED]: REQUESTS_PAYMENT_REJECTED,
};

export const PAYMENT_REQUESTS_DYNAMIC = {
  [PAYMENT_REQUESTS.MORTGAGE_PAYMENT]: [PAYMENT_REQUESTS.MORTGAGE_PAYMENT],
  [PAYMENT_REQUESTS.NOTARY_PAYMENT]: [PAYMENT_REQUESTS.NOTARY_PAYMENT],
};