import React from 'react';
import PropType from 'prop-types';
import { TabsHeaderWrapper } from './style';

const TabsHeader = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <TabsHeaderWrapper>
      {
        tabs.map(tab => (
          <div key={`tab${tab.value}`}>
            <div 
              className={`tab ${tab.value === currentTab? 'active': ''}`}
              onClick={() => setCurrentTab(tab.value)}
            >
              {tab.label}
            </div>
            {tab.value === currentTab && <div className="active-tab"/>}
          </div>
        ))
      }
    </TabsHeaderWrapper>
  )
};

TabsHeader.propTypes = {
  tabs: PropType.array.isRequired,
  currentTab: PropType.any.isRequired,
  setCurrentTab: PropType.any.isRequired
}

export default TabsHeader;
