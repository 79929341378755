import React, { useCallback, useEffect } from 'react';
import { DISBURSEMENT_CONFIG } from '../../../../../utils/constant';
import { FieldWrapper } from '../../DynamicFormDisbursement/Field/style';
import CurrencyInput from '@habi/habi-react-components/dist/CurrencyInput/currencyInput';
import RadioOption from '../../../../../components/RadioOption/radioOption';

export const Field = ({
  name,
  type,
  label,
  formikProps,
  dependentList,
  buttonsQuantity,
}) => {
  let { errors, values, touched, setFieldValue } = formikProps;

  const executedValues = useCallback(() => {
    for (const key in dependentList) {
      setFieldValue(key, dependentList[key](values));
    }
  }, [dependentList, setFieldValue, values]);

  useEffect(() => {
    executedValues();
  }, [values, executedValues]);

  const getFieldContent = () => {
    if (type === DISBURSEMENT_CONFIG.RADIO_BUTTON) {
      return getInputRadioOption();
    } else {
      return getInputCurrency();
    }
  };

  const isInputDisabled = () => {
    return (
      type === DISBURSEMENT_CONFIG.MONEY_DEPENDS ||
      type === DISBURSEMENT_CONFIG.MONEY_PERCENTAGE
    );
  };

  const getInputCurrency = () => (
    <CurrencyInput
      key={type === 'money-depends' ? values.arras_percent : name}
      id={name}
      name={name}
      staticLabel
      label={label}
      values={values}
      errors={errors}
      disabled={isInputDisabled()}
      touched={touched}
      className="input-filter"
      setFieldValue={setFieldValue}
      maxLength={16}
    />
  );

  const getInputRadioOption = () => {
    return (
      <>
        <div className="label-container">
          <label htmlFor={name} className="radio-button-label">
            {label}
          </label>
        </div>
        <div className="radio-buttons-container">
          {buttonsQuantity.map((quantity) => (
            <RadioOption
              key={`arras${quantity}`}
              name={name}
              label={`${quantity * 100} %`}
              value={quantity}
              selected={values[name] === +quantity}
              setValue={() => {
                setFieldValue(name, +quantity);
              }}
              round={true}
            />
          ))}
        </div>
      </>
    );
  };

  return <FieldWrapper>{getFieldContent()}</FieldWrapper>;
};
