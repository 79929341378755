import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSignatureAndDeliveryData, saveSignatureAndDeliveryData } from '../../apis/Financing/financingApi';
import { format } from 'date-fns';

import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import DateTimePicker from '../../components/shared/CustomDateTimePicker/DateTimePicker/dateTimePicker';
import GroupRadioOption from '../../components/GroupRadioOption/GroupRadioOption';
import { Container, DocumentsGrid, FormGrid, Hr, Label, Title } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { API_ERROR_MESSAGE, BUSINESS_TYPE, DOCUMENT_TYPE_NAME, FIELD_TYPE, PROCESS, ROUTES } from '../../utils/constant';
import { FUNDING_TYPE_LABEL } from '../../utils/dynamic-constant';
import { SignatureAndDeliveryWrapper } from './style';
import WarningDialog from '../../components/WarningDialog/warningDialog';
import PageMessage from '../../components/PageMessage/pageMessage';
import { Link } from 'react-router-dom';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const signaturesTypes = [
  {
    name: 'presencial',
    label: 'Presencial',
  },
  {
    name: 'domiciliario',
    label: 'Domiciliario',
  },
  {
    name: 'digital',
    label: 'Digital',
  },
];

const SignatureAndDelivery = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [dataChanged, setDataChanged] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tempField, setTempField] = useState({});

  const getSignatureData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getSignatureAndDeliveryData({
          nid,
        });
        setData(response.data);
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setData({});
        } else {
          console.log('error:::', error.response);
        }
        if (
          error.response.data.message !==
          API_ERROR_MESSAGE.FUNDING_TYPE_NOT_FOUND
        ) {
          dispatch(dataNotFound());
        }
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getSignatureData(nid) : dispatch(dataNotFound());
  }, [nid, getSignatureData, dispatch]);

  const save = ({ value, field }) => {
    if (dataChanged !== value) {
      setDataChanged(value);
      const dataToSave = { [field]: value };
      saveSignatureAndDeliveryData({
        nid,
        originUrl: window.location.href,
        data: dataToSave,
      }).then((res) => {
        setData({
          ...data,
          signature_writing_data: {
            ...data.signature_writing_data,
            ...dataToSave,
          },
        });
      });
    }
  };

  const saveFromWarning = () => {
    save(tempField);
    setShowConfirm(false);
    setTempField({});
  };

  const confirmField = ({ value, field }) => {
    setShowConfirm(true);
    setTempField({ value, field });
  };

  const getDateComponent = ({ label, field, confirm = false }) => {
    let onChange = confirm
      ? (value) => confirmField({ value, field })
      : (value) => save({ value, field });

    return (
      <div>
        <Label>{label}</Label>
        <DateTimePicker
          value={
            data.signature_writing_data && data.signature_writing_data[field]
              ? data.signature_writing_data[field]
              : null
          }
          format="dd-MM-yyyy h:mm a"
          onChange={onChange}
          disabled={false}
          placeHolder={'Seleccione'}
        />
      </div>
    );
  };

  return (
    <EmptyData>
      <LoadingData>
        <SignatureAndDeliveryWrapper>
          <Container>
            <LifeCycle miniLifeCycle />
            <Title>
              Escrituración
              <span className="funding-type">
                {FUNDING_TYPE_LABEL[data.funding_type] ?? ''}
              </span>
            </Title>
            {data.funding_type && (
              <>
                <GroupRadioOption
                  initialValue={
                    data?.signature_writing_data?.signature_type_name
                  }
                  handleChange={(signatureType) =>
                    save({
                      value: signatureType,
                      field: FIELD_TYPE.SIGNATURE_TYPE_NAME,
                    })
                  }
                  label="Tipo de firma"
                  options={signaturesTypes}
                  name="signature_type"
                />
                <FormGrid>
                  {getDateComponent({
                    label: 'Fecha y hora de escrituración agendada',
                    field: FIELD_TYPE.SIGNATURE_DATE,
                    confirm: true,
                  })}
                </FormGrid>
                <Hr />
                <FormGrid>
                  <div>
                    <Label>Carga comprobante escrituración</Label>
                    <DocumentsGrid>
                      <DocumentComponent
                        documentParam={data?.document_list?.find(
                          (doc) =>
                            doc.name === DOCUMENT_TYPE_NAME.SIGNATURE_WRITING
                        )}
                        businessTypeId={BUSINESS_TYPE.SELLERS}
                        processName={PROCESS.SIGNATURE_AND_DELIVERY}
                        handleUpload={() => {}}
                        handleDelete={() => {}}
                      />
                    </DocumentsGrid>
                  </div>
                  {getDateComponent({
                    label: 'Fecha y hora de escrituración efectiva',
                    field: FIELD_TYPE.SIGNATURE_DOCUMENT_DATE,
                  })}
                </FormGrid>
                <Hr />
                <Title>Entrega de inmueble</Title>
                <FormGrid>
                  {getDateComponent({
                    label: 'Fecha y hora de entrega agendada',
                    field: FIELD_TYPE.DELIVERY_DATE,
                    confirm: true,
                  })}
                </FormGrid>
                <Hr />
                <FormGrid>
                  <div>
                    <Label>Carga de comprobante de entrega</Label>
                    <DocumentsGrid>
                      <DocumentComponent
                        documentParam={data?.document_list?.find(
                          (doc) =>
                            doc.name ===
                            DOCUMENT_TYPE_NAME.SIGNATURE_WRITING_DELIVERY
                        )}
                        businessTypeId={BUSINESS_TYPE.SELLERS}
                        processName={PROCESS.SIGNATURE_AND_DELIVERY}
                        handleUpload={() => {}}
                        handleDelete={() => {}}
                      />
                    </DocumentsGrid>
                  </div>
                  {getDateComponent({
                    label: 'Fecha y hora de entrega efectiva',
                    field: FIELD_TYPE.DELIVERY_DOCUMENT_DATE,
                  })}
                </FormGrid>
              </>
            )}
          </Container>
        </SignatureAndDeliveryWrapper>
        {!data.funding_type && (
          <PageMessage
            title="Vinculación sin asignar"
            description={
              <>
                Para poder continuar, ve al formulario de recepción{' '}
                <Link to={ROUTES.PROPERTY_SELLERS}>Inmuebles vendedores</Link>{' '}
                <br />y selecciona el tipo de financiación que aplica para este
                negocio.
              </>
            }
          />
        )}
        <WarningDialog
          open={showConfirm}
          title="¿Estás seguro de querer notificar al cliente?"
          contentAlert={`Si confirmas la selección de ${
            tempField.value
              ? format(new Date(tempField.value), 'dd-MM-yyyy h:mm a')
              : ''
          }, se le enviará un correo con al cliente y a la notaría con fecha y hora seleccionada.`}
          cancelButtonText="No agendar"
          confirmButtonText="Aceptar y enviar"
          onCancel={() => setShowConfirm(false)}
          onConfirm={saveFromWarning}
        />
      </LoadingData>
    </EmptyData>
  );
};

export default SignatureAndDelivery;
