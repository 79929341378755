import React from 'react';
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

import { DocumentOptionsWrapper } from "./style";
import { deleteDocument } from '../../../../apis/Documents/documentsApis';
import { useHistory } from 'react-router';

const DocumentOptions = ({ url, label, documentId, agent, showDelete }) => {

  const history = useHistory();

  const deleteFile = async() => {
    try {
      await deleteDocument(documentId, agent);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DocumentOptionsWrapper>
      <a 
        className='btn'
        href={url}
        download={label}
        rel="noreferrer"
        target="_blank"
      >
        <GetAppIcon />
        Descargar documento
      </a>
      { showDelete &&
        <div 
          className='btn btn-delete' 
          onClick={deleteFile}
        >
          <DeleteIcon />
          Eliminar documento
        </div>
      }
    </DocumentOptionsWrapper>
  );
}
 
export default DocumentOptions;