import styled from 'styled-components';
import { electricPurple500, electricPurple700, greySoft, muliBoldFamily, nightBlue200, nightBlue500, openSansRegular, scarpaGray100, scarpaGray400, white } from '../../StylesConstants';

export const OptionalDocumentWrapper = styled.div`
  background-color: ${greySoft};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  justify-items: center;
  min-height: 204px;
  user-select: none; 
  
  .optional-grid {
    align-self: center;
    cursor: pointer;
    display: grid;
    justify-items: center;
    gap: 26px;
  }

  .title{
    font: 16px ${muliBoldFamily};
    letter-spacing: 0.03em;
    line-height: 24px;
    color: ${nightBlue200};
    text-align: center;
  }

  .add-option {
    color: ${nightBlue200};
    font: 11px ${openSansRegular};
    line-height: 18px;
    text-align: center;
  }
`;

export const ModalWrapper = styled.div`
  background-color: ${white};
  border-radius: 16px;
  display: grid;
  grid-template-rows: 50px 80px auto;
  outline:none;
  padding: 56px 26px 26px 88px;
  width: 344px;
  height: 346px;
  line-height: 28px;
  font: 15px ${openSansRegular};

  .close {
    align-items: center;
    background-color: ${scarpaGray100};
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 40px;
    margin-left: 318px;
    margin-top: -44px;
    position: absolute;
    width: 40px;
  }

  .text-container {
    color: ${scarpaGray400};
    line-height: 28px;
    margin-bottom: 20px;
    width: 282px;
  }

  .documents-container > div {
    width: 282px;
  }
  
  .document {
    padding: 10px 8px;
    color: ${nightBlue500};
    border-bottom: 1px solid ${scarpaGray100};
    cursor: pointer;
  }
  
  .document:hover {
    color: ${electricPurple700};
    border-bottom: 1px solid ${electricPurple500};
  }
  
  .documents-container {
    overflow: auto;
  }

  .documents-container::-webkit-scrollbar {
    width: 8px;
  }

  .documents-container::-webkit-scrollbar-track {
    background: ${white}; 
  }
  
  .documents-container::-webkit-scrollbar-thumb {
    background: ${electricPurple500};
    border-radius: 4px;
  }

  .documents-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;