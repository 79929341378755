import styled from "styled-components";
import {
  lightGrey,
  muliRegular,
  nightBlue500,
} from '../../../../StylesConstants';

export const PropertyReceptionFormWrapper = styled.div`
  width: 600px;
  margin: auto;
  .form-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 48px;
    margin-bottom: 32px;
  }
  .btn-field-complete {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 64px;
  }
  .static-buttons-full-width {
    background-color: ${lightGrey};
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    margin-bottom: 32px;
    border-radius: 12px;
    label {
      font-family: ${muliRegular};
      font-size: 16px;
      color: ${nightBlue500};
    }
    > div {
      display: flex;
      gap: 15px;
    }
    // .radio-buttons {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    //   grid-column-gap: 16px;
    // }
  }
`;