import React, { useRef } from 'react';
import PropTypes from 'prop-types'
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { createDocument, uploadFileToS3 } from "../../apis/Documents/documentsApis";
import { UploadFileWrapper } from './style';
import { ACTIVITY_STATUS, FILE_EXTENSIONS } from '../../utils/constant';
import { FILE_TYPE_BY_DOCUMENT } from '../../utils/dynamic-constant';

const UploadFile = ({ nid, businessTypeId, agent, document, setLoading, setDocument, handleUpload, variant }) => {

  const inputFile = useRef(null);

  const attachFile = () => {
    inputFile.current.click();
  };

  const fileChangedHandler = (event) => {
    try {
      let reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        getFileFormat(result);
        saveDocument(
          event.target.files[0],
          event.target.files[0].name.split('.').slice(-1)[0],
          event.target.files[0].type
        );
        event.target.value = null;
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getFileFormat = (file) => {
    const indexFormat = file.lastIndexOf(",") + 1;
    return file.slice(indexFormat);
  };

  const saveDocument = async (file, extension, type) => {
    try {
      setLoading(true);
      const documentDict = {
        nid: parseInt(nid),
        document_type_id: document.document_type_id,
        document_ext: extension,
        business_type_id: businessTypeId,
        agent,
        number_seller: document.number_seller
      };
      const response = await createDocument(documentDict);

      const documentCreate = response.data;

      await uploadFileToS3(documentCreate.presigned_url, file, type);

      if (documentCreate.document_id) {
        const documentCopy = {
          ...document,
          exist: true,
          url: documentCreate.document_url,
          id: documentCreate.document_id,
          update_date: documentCreate.update_date,
          user_creation: agent
        };
        setDocument(documentCopy);
        handleUpload(ACTIVITY_STATUS.DONE, documentCopy);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getAllowedFormats = () => {
    const fileType = FILE_TYPE_BY_DOCUMENT[document.name] ?? FILE_EXTENSIONS.PDF;

    switch (fileType) {
      case FILE_EXTENSIONS.EXCEL:
        return `${FILE_EXTENSIONS.EXCEL}, ${FILE_EXTENSIONS.EXCEL_NEW}`;
      case FILE_EXTENSIONS.WORD:
        return `${FILE_EXTENSIONS.WORD}, ${FILE_EXTENSIONS.WORD_NEW}`
      default:
        return FILE_EXTENSIONS.PDF;
    }
  };

  return (
    <UploadFileWrapper>
      { 
        variant === 'icon' &&
        <AttachFileIcon 
          id="iconUpload"
          onClick={() => attachFile()} 
        />
      }
      { 
        variant === 'link' &&
        <div 
          id="uploadLink"
          onClick={() => attachFile()} 
          className="link" 
        >
          <AttachFileIcon className="icon-link" />
          <span>
            Cargar Documento
          </span>
        </div>
      }
      <form>
        <input
          type="file"
          accept={ getAllowedFormats() }
          ref={inputFile}
          onChange={(e) => fileChangedHandler(e)}
          style={{ display: "none" }}
        />
      </form>
    </UploadFileWrapper>
  )
};

UploadFile.propTypes = {
  variant: PropTypes.oneOf(['icon', 'link'])
}

UploadFile.defaultProps = {  
  variant: 'icon'  
}

export default UploadFile;
