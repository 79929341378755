import React from 'react';

import { DocumentsListWrapper } from './style';
import { DocumentsGrid } from '../Styles/genericStyleComponent';
import IconFilePdf from '../../static/assets/img/icons/icon-file-pdf.svg';

const DocumentsList = ({ title = '', documents }) => {
  return (
    <DocumentsListWrapper>
      <div className='documents-title'>
        <span>
          {title}
        </span>
        <div>
          <hr />
        </div>
      </div>
      <DocumentsGrid>
        {
          documents.map(document => (
            <div className="document-container">
              <img src={IconFilePdf} alt="Icon document" className="icon-file" />
              <span>
                {document.label}
              </span>
            </div>
          ))
        }
      </DocumentsGrid>
      <hr /><br />
    </DocumentsListWrapper>
  )
};

export default DocumentsList;
