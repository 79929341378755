import styled from 'styled-components';
import {
  hintPerano800,
  montserratBold,
  montserratRegular,
  muliBoldFamily,
  nightBlue300,
  nightBlue400,
  nightBlue500,
  openSansBoldFamily,
  openSansRegular,
  scarpaGray100,
  scarpaGray400,
  skyBlue100,
  skyBlue600,
} from '../../StylesConstants';

export const Title = styled.h1`
  font-family: ${montserratBold};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: ${nightBlue500};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '32px')};
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : '0')};
  margin-block-start: 0;
`;

export const TitleAdd = styled.span`
  color: ${scarpaGray400};
  font: 10px ${openSansRegular};
  line-height: 24px;
  margin-left: 8px;
`;

export const SubTitle = styled.h2`
  font-family: ${muliBoldFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${nightBlue400};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '32px')};
`;

export const SubTitleMontserrat = styled.h2`
  font-family: ${montserratRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${hintPerano800};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '32px')};
`;

export const SubTitleOpenSans = styled.h2`
  font-family: ${openSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${nightBlue500};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '32px')};
`;

export const DocumentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 0px 0px 32px;
`;

export const DocumentsStepsGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 40px 470px;
  margin-bottom: 24px;
`;

export const DocumentsStepsDotLine = styled.div`
  border: 1px dashed ${scarpaGray100};
  position: absolute;
  height: 80px;
  margin-top: 45px;
  margin-left: 12px;
`;

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 0px 0px 32px;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 0px 0px 32px;
`;

export const ButtonGrid = styled.div`
  display: flex;
  gap: 24px;
`;

export const Container = styled.div`
  padding: 48px 32px;
`;

export const ContainerPage = styled.div`
  position: absolute;
  width: calc(100% - 256px);
  left: 256px;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const Label = styled.span`
  color: ${nightBlue500};
  font: 13px ${openSansBoldFamily};
  line-height: 18px;
  margin-bottom: 8px;
  display: block;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${nightBlue500};
  opacity: 0.8;
  z-index: 990;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const SearchContainer = styled.div`
  width: 290px;
  margin-bottom: 20px;
`;

export const FieldMessage = styled.span`
  font-size: 12px;
  margin-top: 10px;
  display: block;
  color: ${nightBlue300};
`;

export const Hr = styled.hr`
  height: 1px;
  border: 0;
  color: ${scarpaGray100};
  background-color: ${scarpaGray100};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '32px')};
  margin-top: ${(p) => (p.marginTop ? `${p.marginTop}px` : '0')};
`;

export const FormContainer = styled.div`
  width: 640px;
`;

export const GridForRightSection = styled.div`
  display: grid;
  grid-template-columns: auto 360px;
  height: 100%;
`;

export const TitleMultipleSection = styled.div`
  color: ${skyBlue600};
  background-color: ${skyBlue100};
  border-radius: 3px;
  font: 13px ${openSansRegular};
  line-height: 10px;
  margin-bottom: 32px;
  padding: 6px 8px;
  width: fit-content;
`;

export const DateFieldHour = styled.div`
  display: grid;
  min-width: 80px;

  span:nth-child(2n) {
    font-size: 10px;
    line-height: 10px;
    color: ${scarpaGray400};
    text-overflow: ellipsis;
    height: 10px;
    overflow: hidden;
  }
`;
export const SaveButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 272px 1fr;
  grid-column-gap: 48px;
  margin-bottom: 32px;
  button {
    grid-column: 2 / 3;
  }
`; 
