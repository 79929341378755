import React, { useState } from 'react';
import { tabSelector } from './components/TabSelector/tabSelector';
import { GenericTableWrapper } from './style';

const GenericTable = ({
  data,
  options,
  currentTab,
  defaultValue,
  titleColumns,
  handleUpdateButton,
  handleUpdateSelect,
}) => {
  const [header] = useState(titleColumns ?? []);
  return (
    <GenericTableWrapper>
      <table>
        <thead>
          <tr>
            {header.map((titleColumn) => (
              <th> {titleColumn} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((rowData, index) =>
            tabSelector[currentTab]({
              index,
              options,
              rowData,
              handleUpdateButton,
              handleUpdateSelect,
            })
          )}
        </tbody>
      </table>
    </GenericTableWrapper>
  );
};

export default GenericTable;
