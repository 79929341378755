import {
  getDocumentsProcess,
  getEventsProcess,
} from '../../apis/Process/processApis';
import {
  BUSINESS_TYPE,
  DOCUMENT_TYPE_NAME,
  HTTP_STATUS,
  PROCESS,
} from '../../utils/constant';

export const getDocumentsPreStudyData = (nid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const documentsCiPcv = await getDocumentsProcess({
        nid,
        businessType: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.CI_PCV_DOCUMENTS,
        sections: false,
      });
      const documentsPreStudy = await getDocumentsProcess({
        nid,
        businessType: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.OTHER_BANKS_TITLE_PRE_STUDY,
        sections: true,
      });
      const events = await getEventsProcess({
        nid,
        businessType: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.OTHER_BANKS_TITLE_PRE_STUDY,
      });

      documentsPreStudy.data.docs[0].docs.unshift(
        ...documentsCiPcv.data.docs.filter(
          (doc) => doc.name === DOCUMENT_TYPE_NAME.CC_CI_PCV
        )
      );

      resolve({
        documents: documentsPreStudy.data.docs,
        events: events.data.data.events,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          documents: [],
          events: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
