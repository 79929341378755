import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { callEventProcess } from '../../apis/Process/processApis';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import EmptyData from '../../components/EmptyData/emptyData';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import LoadingData from '../../components/LoadingData/loadingData';
import PageMessage from '../../components/PageMessage/pageMessage';
import RadioCheck from '../../components/RadioCheck/radioCheck';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import useDocuments from '../../hooks/useDocuments';
import {
  ACTIVITY_STATUS,
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  DOCUMENT_WRAPPER_TYPE,
  FUNDING_TYPE,
  PROCESS,
  PROCESS_EVENTS,
  ROUTES,
} from '../../utils/constant';
import { FUNDING_TYPE_LABEL } from '../../utils/dynamic-constant';
import { PayInstructionWrapper } from './style';

const PayInstruction = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const [fundingType, setFundingType] = useState('');
  const [events, setEvents] = useState([]);

  const { documents, documentUpdate } = useDocuments({
    businessType: BUSINESS_TYPE.SELLERS,
    processName: PROCESS.PAY_INSTRUCTION_COLPATRIA,
  });
  const { list_documents: listDocuments, additional_data: additionalData } =
    documents;

  useEffect(() => {
    if (additionalData) {
      setEvents(additionalData.events);
      setFundingType(additionalData.funding_type);
    }
  }, [additionalData]);

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: BUSINESS_TYPE.SELLERS,
      processName: PROCESS.PAY_INSTRUCTION_COLPATRIA,
      originUrl: window.location.href,
      eventName,
      status,
    });
    updateEvents(eventName, status);
  };

  const updateEvents = (eventName, status) => {
    if (events.some((event) => event.name === eventName)) {
      setEvents(
        events.map((event) =>
          event.name === eventName ? { name: event.name, status } : event
        )
      );
    } else {
      setEvents([...events, { name: eventName, status }]);
    }
  };

  const handleDocumentUpdate = (status, newDocument) => {
    if (newDocument.name === DOCUMENT_TYPE_NAME.PAY_INSTRUCTION) {
      callEvent(
        PROCESS_EVENTS.PAY_INSTRUCTION_LOAD,
        newDocument.exist ? DOCUMENT_STATUS.SUCCESS : DOCUMENT_STATUS.REVIEW
      );
    }
    if (newDocument.name === DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_ONE) {
      callEvent(
        PROCESS_EVENTS.PAY_INSTRUCTION_FIRM_ONE,
        newDocument.exist ? DOCUMENT_STATUS.SUCCESS : DOCUMENT_STATUS.REVIEW
      );
    }
    if (newDocument.name === DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_TWO) {
      callEvent(
        PROCESS_EVENTS.PAY_INSTRUCTION_FIRM_TWO,
        newDocument.exist ? DOCUMENT_STATUS.SUCCESS : DOCUMENT_STATUS.REVIEW
      );
    }

    documentUpdate(DOCUMENT_STATUS.SUCCESS, newDocument);
  };

  const getDocument = (documentName, locked) => {
    const document = listDocuments.find((doc) => doc.name === documentName);

    return (
      listDocuments.length > 0 && (
        <div
          className="document-grid"
          key={`document-container-${document.document_type_id}`}
        >
          <div>
            <RadioCheck
              status={
                document.exist ? ACTIVITY_STATUS.DONE : ACTIVITY_STATUS.OPEN
              }
            />
          </div>
          {getDocumentComponent({
            document,
            locked,
            lockedMessage: '',
            wrapperType: DOCUMENT_WRAPPER_TYPE.DOT_LINE,
          })}
        </div>
      )
    );
  };

  const getDocumentComponent = ({
    document,
    locked,
    lockedMessage,
    wrapperType,
  }) => {
    return (
      <DocumentComponent
        documentParam={document}
        businessTypeId={BUSINESS_TYPE.SELLERS}
        wrapperType={wrapperType}
        handleUpload={handleDocumentUpdate}
        handleDelete={handleDocumentUpdate}
        processName={PROCESS.PCV_SALES_AND_LEGAL}
        locked={locked}
        lockedMessage={lockedMessage}
        key={`document${document.document_type_id}`}
        showDelete={
          !getBoolEventStatus(PROCESS_EVENTS.PAY_INSTRUCTION_DOCUMENTS_COMPLETE)
        }
      />
    );
  };

  const getBoolEventStatus = (eventName) => {
    const event = events.find((event) => event.name === eventName);
    return event?.status === DOCUMENT_STATUS.SUCCESS;
  };

  const getOtherDocuments = () =>
    listDocuments.filter(
      (document) =>
        document.name !== DOCUMENT_TYPE_NAME.PAY_INSTRUCTION &&
        document.name !== DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_ONE &&
        document.name !== DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_TWO
    );

  return (
    <EmptyData>
      <LoadingData>
        <PayInstructionWrapper>
          <Container>
            <LifeCycle miniLifeCycle />
            <Title>
              Instrucción de giro
              <span className="funder">
                {FUNDING_TYPE_LABEL[fundingType] ?? ''}
              </span>
            </Title>
            {fundingType && fundingType === FUNDING_TYPE.PA_COLPATRIA && (
              <div>
                <div className="dot-line-documents" />
                {getDocument(DOCUMENT_TYPE_NAME.PAY_INSTRUCTION, false)}
                {getDocument(
                  DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_ONE,
                  !getBoolEventStatus(PROCESS_EVENTS.PAY_INSTRUCTION_LOAD)
                )}
                {getDocument(
                  DOCUMENT_TYPE_NAME.PAY_INSTRUCTION_FIRM_TWO,
                  !getBoolEventStatus(PROCESS_EVENTS.PAY_INSTRUCTION_FIRM_ONE)
                )}
                {getOtherDocuments().map((document) =>
                  getDocument(
                    document.name,
                    !getBoolEventStatus(PROCESS_EVENTS.PAY_INSTRUCTION_FIRM_TWO)
                  )
                )}
                <div className="event-button">
                  <ButtonCheck
                    label="Documentos completos"
                    status={
                      getBoolEventStatus(
                        PROCESS_EVENTS.PAY_INSTRUCTION_DOCUMENTS_COMPLETE
                      )
                        ? DOCUMENT_STATUS.SUCCESS
                        : null
                    }
                    handleChange={() =>
                      callEvent(
                        PROCESS_EVENTS.PAY_INSTRUCTION_DOCUMENTS_COMPLETE,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                    disabled={getOtherDocuments().some(
                      (document) => !document.exist
                    )}
                  />
                </div>
              </div>
            )}
          </Container>
          {!fundingType && (
            <PageMessage
              title="Vinculación sin asignar"
              description={
                <>
                  Para poder continuar, ve al formulario de recepción{' '}
                  <Link to={ROUTES.PROPERTY_SELLERS}>Inmuebles vendedores</Link>{' '}
                  <br />y selecciona el tipo de financiación que aplica para
                  este negocio.
                </>
              }
            />
          )}
          {fundingType && fundingType !== FUNDING_TYPE.PA_COLPATRIA && (
            <PageMessage
              title="Orden de giro no requerida"
              description={
                <>
                  La instrucción de giro no es requerida para el tipo de
                  financiación seleccionada
                </>
              }
            />
          )}
        </PayInstructionWrapper>
      </LoadingData>
    </EmptyData>
  );
};

export default PayInstruction;
