import React, { useCallback, useEffect, useState } from 'react';

// Components
import EmptyData from '../../components/EmptyData/emptyData';
import {
  ButtonGrid,
  Container,
  DocumentsGrid,
  GridForRightSection,
  Title,
} from '../../components/Styles/genericStyleComponent';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  EVENT_STATUS,
  PROCESS,
  PROCESS_EVENTS,
  ROLE,
  ROUTES,
} from '../../utils/constant';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import RightSection from '../../components/RightSection/rightSection';
import Comments from '../../components/Comments/comments';
import {
  callEventProcess,
  saveProcessComment,
} from '../../apis/Process/processApis';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../components/LoadingData/loadingData';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { updateDocuments } from '../../services/documentsService';
import { notarialPaymentsDocumentsData } from '../../services/data/notarialPaymentsDocumentsData';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { DocumentReviewWrapper } from '../DocumentReview/style';
import FormWithButton from '../../components/DynamicForm/FormWithButton/FormWithButton';
import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';

const businessType = BUSINESS_TYPE.SELLERS;
const processName = PROCESS.NOTARIAL_PAYMENT_DOCUMENTS;

const NotarialPaymentsDocuments = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const role = useSelector((state) => state.loginData.data.role[0]);

  const [comments, setComments] = useState([]);
  const [events, setEvents] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [allInteractions, setAllInteractions] = useState([]);
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const notarialPaymentData = await notarialPaymentsDocumentsData({
          nid: +nid,
          businessType,
          processName,
        });
        setEvents(notarialPaymentData.events);
        setDocuments(
          notarialPaymentData.documents.filter((doc) => doc.required || doc.url)
        );
        setOptionalDocuments(
          notarialPaymentData.documents.filter((doc) => !doc.required)
        );
        setComments(notarialPaymentData.comments);
        setAllInteractions(
          notarialPaymentData.error_comment_list.map((error) => ({
            value: error.id,
            label: error.label,
          }))
        );
        dispatch(dataFound());
      } catch (error) {
        console.error(error);
        dispatch(dataFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [getData, nid, dispatch]);

  const callEvent = async (eventName, status, otherUrl) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: businessType,
        processName,
        eventName,
        status,
        originUrl: otherUrl ?? window.location.href,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  const saveComment = async (comment) => {
    try {
      await saveProcessComment({
        nid,
        processName: PROCESS.NOTARIAL_PAYMENT_DOCUMENTS,
        agent,
        nameReviewerRole: role,
        comment,
        originUrl: window.location.href,
        noInteractions: true,
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role,
        },
        ...comments,
      ]);
    } catch (error) {
      console.error({ error });
    }
  };

  const addOptionalDocument = (document) => {
    if (
      documents.some(
        (doc) => doc.document_type_id !== document.document_type_id
      )
    ) {
      setDocuments((prev) => [...prev, document]);
    }
  };

  const updateDocument = (status, newDocument) => {
    setDocuments((docs) => updateDocuments(newDocument, docs));
    callEvent(PROCESS_EVENTS.REQUEST_PAYMENT, DOCUMENT_STATUS.REVIEW);
    callEvent(PROCESS_EVENTS.REQUEST_APPROVED, DOCUMENT_STATUS.REVIEW);
  };

  const deleteDocument = (status, newDocument) => {
    updateDocument(status, newDocument);
    callEvent(PROCESS_EVENTS.REQUEST_PAYMENT, DOCUMENT_STATUS.REVIEW);
    callEvent(PROCESS_EVENTS.REQUEST_APPROVED, DOCUMENT_STATUS.REVIEW);
  };

  const getDocumentComponent = (document, index) => {
    return (
      <DocumentComponent
        key={`document-${index}`}
        lastItemRow={(index + 1) % 5 === 0 ? true : false}
        documentParam={document}
        showReview={true}
        handleUpload={updateDocument}
        handleDelete={deleteDocument}
        reviewer={ROLE.MORTGAGE_ANALYST_SENIOR}
        businessTypeId={BUSINESS_TYPE.SELLERS}
        processName={PROCESS_EVENTS.NOTARIAL_PAYMENT_DOCUMENTS}
        showDelete={
          getEventStatus(events, PROCESS_EVENTS.APPROVED_APPLICATION) !==
          EVENT_STATUS.SUCCESS
        }
        allowReReview
      />
    );
  };

  const documentsLoaded = () => {
    return documents.filter((doc) => doc.required).some((doc) => !doc.url);
  };

  const saveData = async (values) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: processName,
      fields: values,
      business_type_id: businessType,
    };
    try {
      setLoadingSave(true);
      await updateFormData(data);
    } catch (error) {
      console.error({ error });
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <DocumentReviewWrapper>
      <EmptyData>
        <LoadingData>
          <GridForRightSection>
            <Container>
              <i className="arrow-back" onClick={history.goBack}>
                <ArrowBackIcon />
              </i>
              <Title>Documentos pagos notariales</Title>
              <DocumentsGrid>
                {documents.map((doc, i) => getDocumentComponent(doc, i))}
                <OptionalDocument
                  businessType={BUSINESS_TYPE.SELLERS}
                  processName={PROCESS.NOTARIAL_PAYMENT_DOCUMENTS}
                  handleAddDocument={addOptionalDocument}
                  optionalDocuments={optionalDocuments}
                />
              </DocumentsGrid>
              {!match.params.onlyRead && (
                <>
                  <FormWithButton
                    saveData={saveData}
                    loadingSave={loadingSave}
                    nid={nid}
                    businessType={businessType}
                    processName={processName}
                  />
                  <ButtonGrid>
                    <ButtonCheck
                      dataId={PROCESS_EVENTS.REQUEST_PAYMENT}
                      label="Solicitar pago de gastos notariales"
                      status={getEventStatus(
                        events,
                        PROCESS_EVENTS.REQUEST_PAYMENT
                      )}
                      handleChange={() =>
                        callEvent(
                          PROCESS_EVENTS.REQUEST_PAYMENT,
                          DOCUMENT_STATUS.SUCCESS,
                          `${window.location.protocol}//${window.location.host}${ROUTES.PAYMENT_REQUEST}`
                        )
                      }
                      disabled={documentsLoaded()}
                    />
                    <ButtonCheck
                      dataId={PROCESS_EVENTS.REQUEST_APPROVED}
                      label="Solicitud aprobada"
                      status={getEventStatus(
                        events,
                        PROCESS_EVENTS.REQUEST_APPROVED
                      )}
                      handleChange={() =>
                        callEvent(
                          PROCESS_EVENTS.REQUEST_APPROVED,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                      disabled={documentsLoaded()}
                    />
                  </ButtonGrid>
                </>
              )}
            </Container>
            {!match.params.onlyRead && (
              <RightSection>
                <Comments
                  allInteractions={allInteractions}
                  commentsHistory={comments}
                  handleSaveComment={saveComment}
                />
              </RightSection>
            )}
          </GridForRightSection>
        </LoadingData>
      </EmptyData>
    </DocumentReviewWrapper>
  );
};

export default NotarialPaymentsDocuments;
