import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import StageDocuments from './components/StageDocuments/StageDocuments';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';

import { Container, Hr, Title } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getDocumentsPreStudyData } from '../../services/data/documentsPreStudyData';
import { BUSINESS_TYPE, EVENT_STATUS, PROCESS, PROCESS_EVENTS, PROCESS_SECTIONS } from '../../utils/constant';
import { updateDocumentsInSections } from '../../services/documentsService';
import { callEventProcess } from '../../apis/Process/processApis';
import { getEventStatus, updateEvents } from '../../services/eventsService';

const processName = PROCESS.OTHER_BANKS_TITLE_PRE_STUDY;
const businessTypeId = BUSINESS_TYPE.BUYERS;

const DocumentsPreStudy = () => {

  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);

  const [documents, setDocuments] = useState([]);
  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await getDocumentsPreStudyData(nid);
      setDocuments(data.documents);
      setEvents(data.events);
      dispatch(dataFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  const documentUpdate = (status, newDocument) => {
    setDocuments((docs) => updateDocumentsInSections(newDocument, docs));

    if (!newDocument.exist || !newDocument.required) {
      if (documents[0].docs.some(document => document.name === newDocument.name)
        && getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST) === EVENT_STATUS.SUCCESS
      ) {
        callEvent(PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST, EVENT_STATUS.REVIEW);
      } else if (documents[1].docs.some(document => document.name === newDocument.name)
        && getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_SECOND) === EVENT_STATUS.SUCCESS
      ) {
        callEvent(PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_SECOND, EVENT_STATUS.REVIEW);
      }
    }
  }

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
    });
    setEvents(
      updateEvents(events, eventName, status)
    );
  };

  const addOptionalDocument = (document, sectionName) => {
    setDocuments(
      documents.map((section) =>
        section.name === sectionName &&
          !section.docs.some((doc) => doc.name === document.name)
          ? {
            ...section,
            docs: [...section.docs, document],
          }
          : section
      )
    );
  };

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <Title>
            Documentos Pre-estudio de títulos
          </Title>
          {
            documents
              .filter(section =>
                section.name === PROCESS_SECTIONS.PRE_STUDY_TITLE_REQUEST_FIRST_SENDING ||
                section.name === PROCESS_SECTIONS.PRE_STUDY_TITLE_REQUEST_SECOND_SENDING
              ).map(section =>
                <StageDocuments
                  key={section.name}
                  section={section}
                  events={events}
                  documentsReady={documents[0].docs.filter(doc => doc.exist)}
                  handleCallEvent={callEvent}
                  handleUpdateDocument={documentUpdate}
                  handleAddOptionalDocument={addOptionalDocument}
                />
              )
          }
          <Hr />
          <Title>
            Solicitud de estudio de títulos
          </Title>
          <ButtonCheck
            label="Documentos Pre-estudio aprobados"
            status={getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_APROVED)}
            handleChange={() => callEvent(PROCESS_EVENTS.PRE_STUDY_APROVED, EVENT_STATUS.SUCCESS)}
            disabled={
              getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_APROVED)
            }
          />
        </Container>
      </LoadingData>
    </EmptyData>
  )
}

export default DocumentsPreStudy;