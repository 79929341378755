import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../utils/constant";

export const RadioCheckWrapper = styled.div`

  cursor: ${ p => p.status === ACTIVITY_STATUS.LOCK? 'default': 'pointer' };
  width: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .radio {
    border: 2px solid #d8d7da;
    border-radius: 16px;
    height: 16px;
    width: 16px;
  }

  .icon-check{
    width: 20px;
    z-index: 1;
  }

`;