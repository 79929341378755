import styled from "styled-components";
import { muliBoldFamily, hintPerano700, openSansRegular } from "../../StylesConstants";

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;

  .not-found-container {
    text-align: center;

    .image-not-found{
        width: 174px;
        height: 174px;
        margin-bottom: 24px;
    }

    .title-not-found {
      font-family: ${muliBoldFamily};
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${hintPerano700};
      margin-bottom: 8px;
    }
    .description-not-found {
      font-family: ${openSansRegular};
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      text-align: center;
      color: ${hintPerano700};
    }
  }
`;
