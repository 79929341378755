import React from 'react';
import { DateFieldHour } from '../../../../components/Styles/genericStyleComponent';
import {
  PAYMENT_REQUESTS,
  PROCESS,
  ROLE,
  ROUTES,
} from '../../../../utils/constant';
import { PAYMENT_REQUESTS_DYNAMIC } from '../../../../utils/dynamic-constant';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SelectWithConfirmation from '../../../../components/shared/SelectWithConfirmation/selectWithConfirmation';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { updatePropertyData } from '../../../../redux/actions/propertyData';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Requests = ({
  index,
  rowData,
  handleUpdate,
  options,
  disabled,
  defaultValue,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.loginData.data.role);

  const getBadge = (label, type) => {
    return (
      <span className={`type ${PAYMENT_REQUESTS_DYNAMIC[type]}`}>{label}</span>
    );
  };

  const viewDocuments = () => {
    dispatch(updatePropertyData({ nid: rowData.nid }));
    history.push(
      rowData.event_name === PAYMENT_REQUESTS.MORTGAGE_PAYMENT
        ? `${ROUTES.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS}/true`
        : `${ROUTES.NOTARIAL_PAYMENTS_DOCUMENTS}/true`
    );
  };

  const disabledSelect = () => {
    return disabled || !role.includes(ROLE.MORTGAGE_ANALYST_SENIOR);
  };

  return (
    <tr id="row" key={`row-${index}`}>
      <td className="center-field">
        <div className="index">
          <span>{index + 1}</span>
        </div>
      </td>
      <td className="center-field">
        {getBadge(rowData?.payment_type, rowData?.event_name)}
      </td>
      <td className="center-field">
        <div className="badge-field">
          <span>{rowData?.nid}</span>
        </div>
      </td>
      <td className="center-field">
        <div className="badge-field">
          <span>{rowData?.property_name}</span>
        </div>
      </td>
      <td className="center-field">
        <DateFieldHour>
          <span>
            {moment(new Date(rowData?.update_date)).format('DD-MM-YYYY')}
          </span>
          <span>
            {moment(new Date(rowData?.update_date)).format('HH:mm_ss')}
          </span>
        </DateFieldHour>
      </td>
      <td className="center-field">
        <div className="view-docs-field">
          <VisibilityOutlinedIcon className="icon" />
          <span onClick={() => viewDocuments(rowData.payment_type)}>
            Ver docs.
          </span>
        </div>
      </td>
      <td className="center-field">
        <SelectWithConfirmation
          options={options}
          handleConfirm={(e) =>
            handleUpdate({
              nid: rowData.nid,
              processName: PROCESS.PAYMENT_REQUEST,
              eventName: rowData.event_name,
              status: e,
            })
          }
          disabled={disabledSelect()}
          defaultValue={defaultValue}
        />
      </td>
    </tr>
  );
};

export default Requests;
