import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChecklistDocuments } from '../../apis/Documents/documentsApis';
import { sendEmailNotification } from '../../apis/Notifications/notificationsApis';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import EmptyData from '../../components/EmptyData/emptyData';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS } from '../../utils/constant';
import Checklist from './components/Checklist/checklist';
import { DocumentsChecklistWrapper } from './style';

const DocumentsChecklist = () => {
  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);

  const [checklistDocuments, setChecklistDocuments] = useState([]);
  const [completeStatus, setCompleteStatus] = useState('');

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const { data } = await getChecklistDocuments({
          nid,
          processName: PROCESS.CHECKLIST,
          businessTypeId: BUSINESS_TYPE.SELLERS,
        });
        setCompleteStatus(data.complete_documents_status);
        setChecklistDocuments(data.data);
        dispatch(dataFound());
      } catch (error) {
        setChecklistDocuments([]);
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  const saveCompleteStatus = async () => {
    setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
    try {
      await sendEmailNotification({
        nid,
        processName: PROCESS.CHECKLIST,
        businessType: BUSINESS_TYPE.SELLERS,
        originUrl: window.location.href,
        status: DOCUMENT_STATUS.SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateDocument = (status, newDocument) => {
    if (!newDocument.exist) {
      setCompleteStatus(null);
    }

    const newChecklistDocuments = checklistDocuments.map((process) => {
      const newListDocuments = process.list_documents.map((document) => {
        return document.document_type_id === newDocument.document_type_id
          ? newDocument
          : document;
      });
      return { ...process, list_documents: newListDocuments };
    });

    setChecklistDocuments(newChecklistDocuments);
  };

  return (
    <EmptyData>
      <DocumentsChecklistWrapper>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>Checklist Documentos</Title>
          <div className="checklist-container">
            {checklistDocuments.map((process) => (
              <Checklist
                process={process}
                nid={nid}
                key={process.process_name}
                handleUpdateDocument={updateDocument}
              />
            ))}
          </div>
          <ButtonCheck
            label="Checklist completo"
            status={
              completeStatus === DOCUMENT_STATUS.SUCCESS ? completeStatus : null
            }
            handleChange={saveCompleteStatus}
          />
        </Container>
      </DocumentsChecklistWrapper>
    </EmptyData>
  );
};

export default DocumentsChecklist;
