import { getNotificationsApi } from '../../apis/Notifications/notificationsApis';

export const getNotificationsData = ({ recipient, state }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let notificationsData = await getNotificationsApi({ recipient, state });
      resolve(notificationsData.data);
    } catch (error) {
      console.error(error);
      reject({});
    }
  });
};
