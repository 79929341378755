import styled from 'styled-components';

export const BuyerReceptionWrapper = styled.div`
  width: 728px;
  margin: auto;
  .button-desist {
    display: flex;
    justify-content: space-between;
  }
`;
