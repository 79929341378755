import styled from 'styled-components';

export const HighLevelBuyersWrapper = styled.div`
  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: end;
  }
`;
