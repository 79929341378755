import React, { useState } from 'react'
import { ToggleButtonWrapper } from './style';

const ToggleButton = ({ dataId, initialState, iconOn, iconOff, onChange, ...props }) => {

  const [state, setState] = useState(true);

  const onChangeHandler = () => {
    setState(state => !state);
    onChange(state);
  }

  return (
    <ToggleButtonWrapper
      data-id={`btn-${dataId}`}
      onClick={onChangeHandler}
      className={state ? 'toggle-off' : 'toggle-on'}
    >
      {state ? iconOff : iconOn}
      {props.children}
    </ToggleButtonWrapper>
  )
}

export default ToggleButton;