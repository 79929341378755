import React, { useEffect, useState, useCallback } from 'react';

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IconSend from '../../../../static/assets/img/icons/icon-send.svg';
import { ButtonGrid, DocumentsGrid } from '../../../../components/Styles/genericStyleComponent';
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS, PROCESS_EVENTS, PROCESS_SECTIONS } from '../../../../utils/constant';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import OptionalDocument from '../../../../components/OptionalDocument/optionalDocument';
import ButtonCheck from '../../../../components/ButtonCheck/buttonCheck';
import { BondingStageColpatriaWrapper } from './style';
import DocumentsList from '../../../../components/DocumentsList/documentsList';

const BondingStageColpatria = ({ section, events, documentsReady, handleCallEvent, addOptionalDocument, handleUpdateDocument }) => {

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const getEventStatus = useCallback(
    (eventName) => {
      const event = events.find( event => event.name === eventName);
      return event?.status === DOCUMENT_STATUS.SUCCESS ? event.status : null;
    },
    [events],
  )

  useEffect(() => {
    const isSectionApproved = () => {
      return section.name === PROCESS_SECTIONS.BONDING_COLPATRIA_FIRST
        ? getEventStatus(PROCESS_EVENTS.FIRST_REQUEST_BONDING) === DOCUMENT_STATUS.SUCCESS
        : getEventStatus(PROCESS_EVENTS.SECOND_FUNDING_APPROVE) === DOCUMENT_STATUS.SUCCESS;
    };

    setAccordionExpanded(!isSectionApproved());
    setShowDelete(getEventStatus(PROCESS_EVENTS.SECOND_FUNDING_APPROVE) !== DOCUMENT_STATUS.SUCCESS);
  }, [events, getEventStatus, section.name])

  const showDocumentsReady = () => {
    return section.name === PROCESS_SECTIONS.BONDING_COLPATRIA_SECOND 
      && getEventStatus(PROCESS_EVENTS.FIRST_REQUEST_BONDING)
  }; 

  const handleAddDocument = (document) => {
    addOptionalDocument(document, section.name);
  };

  return (
    <BondingStageColpatriaWrapper>
      <Accordion 
        expanded={ accordionExpanded } 
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          className="accordion-title"
        >
          <div className="icon-send">
            <img src={IconSend} alt="Icon send" />
          </div>
          <span>
            { section.label }
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="detail-container">
            { 
              documentsReady && 
              documentsReady.length >0 &&
              showDocumentsReady() &&
              <DocumentsList 
                documents={documentsReady} 
                title="Documentos enviados"
              /> 
            }
            <DocumentsGrid>
              {section.list_documents.map((document, i) => (
                <DocumentComponent
                  key={`document_${i}`}
                  lastItemRow={(i + 1) % 5 === 0 ? true : false}
                  documentParam={document}
                  businessTypeId={BUSINESS_TYPE.SELLERS}
                  processName={PROCESS.BONDING_COLPATRIA}
                  handleUpload={handleUpdateDocument}
                  handleDelete={handleUpdateDocument}
                  showDelete={showDelete}
                />
              ))}
              <OptionalDocument 
                businessType={BUSINESS_TYPE.SELLERS}
                processName={PROCESS.BONDING_COLPATRIA} 
                sectionName={section.name}
                handleAddDocument={handleAddDocument}
              />
            </DocumentsGrid>
            <ButtonGrid>
              {
                section.name === PROCESS_SECTIONS.BONDING_COLPATRIA_FIRST
                ? <>
                    <ButtonCheck
                      label="Solicitud de vinculación"
                      status={ getEventStatus(PROCESS_EVENTS.FIRST_REQUEST_BONDING) }
                      handleChange={() => handleCallEvent(PROCESS_EVENTS.FIRST_REQUEST_BONDING, DOCUMENT_STATUS.SUCCESS)}
                      disabled={section.list_documents.some(document => !document.exist)}
                    />
                  </>
                : <>
                    <ButtonCheck
                      label="Solicitud de vinculación"
                      status={ getEventStatus(PROCESS_EVENTS.SECOND_REQUEST_BONDING) }
                      handleChange={() => handleCallEvent(PROCESS_EVENTS.SECOND_REQUEST_BONDING, DOCUMENT_STATUS.SUCCESS)}
                      disabled={!(section.list_documents.filter(document => document.exist).length > 0)}
                    />
                    <ButtonCheck
                      label="Aprobación fiduciara"
                      status={ getEventStatus(PROCESS_EVENTS.SECOND_FUNDING_APPROVE) }
                      disabled={ getEventStatus(PROCESS_EVENTS.SECOND_REQUEST_BONDING) !== DOCUMENT_STATUS.SUCCESS }
                      handleChange={() => handleCallEvent(PROCESS_EVENTS.SECOND_FUNDING_APPROVE, DOCUMENT_STATUS.SUCCESS)}
                    />
                  </>
              }
            </ButtonGrid>
          </div>
        </AccordionDetails>
      </Accordion>
    </BondingStageColpatriaWrapper>
  )
};

export default BondingStageColpatria;
