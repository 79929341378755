import {
  FINANCIAL_ENTITIES,
  NOTARY_SALE,
  PAYMENT_METHOD,
} from '../selectOptions';

export const legalSales = {
  basicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'hl_lawyer_1',
      label: 'Abogado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'Cindy Torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'Jessica Riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'Berenice Tarquino',
          },
          {
            label: 'Linda Jimenez',
            value: 'Linda Jimenez',
          },
          {
            label: 'Daniel Osorio',
            value: 'Daniel Osorio',
          },
          {
            label: 'Juan Mejia',
            value: 'Juan Mejia',
          },
          {
            label: 'Estefania Avila',
            value: 'Estefania Avila',
          },
        ],
      },
    },
    {
      name: 'aux_hl',
      label: 'Auxiliar',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Yuly Pancha',
            value: 'Yuly Pancha',
          },
          {
            label: 'Yenifer Villamil',
            value: 'Yenifer Villamil',
          },
          {
            label: 'Juan Pinilla',
            value: 'Juan Pinilla',
          },
        ],
      },
    },
  ],
  firstAnalystInformation: [
    {
      name: 'notaria_venta',
      label: 'Notaría venta',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: NOTARY_SALE,
      },
    },
    {
      name: 'comercial_vendedores',
      label: 'Comercial',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputCurrency',
      fixed: true,
    },
    {
      name: 'seedbed',
      label: 'Semilleros de propietarios',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
    },
    {
      name: 'severance_buyers',
      label: 'Cesantías',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
    },
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'payment_method',
      label: 'Forma de pago',
      type_field: 'select',
      multiple_values: {
        options: PAYMENT_METHOD,
      },
      fixed: false,
    },
    {
      name: 'financial_entity',
      label: 'Entidad financiera',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: FINANCIAL_ENTITIES,
      },
    },
    {
      name: 'timestamp_settle_letter_intent',
      label: 'Fecha y hora radicación solicitud carta de intención',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'timestamp_first_version_letter_intent',
      label: 'Fecha y hora primera versión carta de intención',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'timestamp_final_version_letter_intent',
      label: 'Fecha y versión final carta de intención',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'timestamp_signed_letter_intent',
      label: 'Fecha firma carta de intención',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'tentative_promise_date',
      label: 'Fecha tentativa promesa',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'intention_letter_observation',
      label: 'Observaciones carta de intención',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'timestamp_filing_request_promise',
      label: 'Fecha y hora radicación solicitud promesa',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'timestamp_first_response',
      label: 'Fecha y hora 1era respuesta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'interaction_1',
      label: 'Interacción 1',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Solicitud Adicional de Documentación Promesa (Simple)',
            value: 'Solicitud Adicional de Documentación Promesa (Simple)',
          },
          {
            label: 'Correcciones Promesa (Simple)',
            value: 'Correcciones Promesa (Simple)',
          },
          {
            label:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
            value:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
          },
          {
            label: 'Solicitud Cambios Comercial (Simple)',
            value: 'Solicitud Cambios Comercial (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Sucesiones (Compleja)',
            value: 'Sucesiones (Compleja)',
          },
          {
            label: 'Embargos (Compleja)',
            value: 'Embargos (Compleja)',
          },
          {
            label: 'Proyectos de utilidad pública. (Compleja)',
            value: 'Proyectos de utilidad pública. (Compleja)',
          },
          {
            label:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
            value:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
          },
          {
            label: 'Venta inmuebles menores de edad e interdictos (Compleja)',
            value: 'Venta inmuebles menores de edad e interdictos (Compleja)',
          },
          {
            label: 'Validar poderes (Compleja)',
            value: 'Validar poderes (Compleja)',
          },
          {
            label: 'Solicitud Cambios Comercial (Compleja)',
            value: 'Solicitud Cambios Comercial (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Envío Primera Versión (Compleja)',
            value: 'Envío Primera Versión (Compleja)',
          },
          {
            label: 'Envío Versión Final (Compleja)',
            value: 'Envío Versión Final (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_bill_lawyer_1',
      label: 'Abogado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: '_2_request_timestamp',
      label: 'Fecha y hora 2da solicitud',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: '_2_response_timestamp',
      label: 'Fecha y hora 2da respuesta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'interaction_2',
      label: 'Interacción 2',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Solicitud Adicional de Documentación Promesa (Simple)',
            value: 'Solicitud Adicional de Documentación Promesa (Simple)',
          },
          {
            label: 'Correcciones Promesa (Simple)',
            value: 'Correcciones Promesa (Simple)',
          },
          {
            label:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
            value:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
          },
          {
            label: 'Solicitud Cambios Comercial (Simple)',
            value: 'Solicitud Cambios Comercial (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Sucesiones (Compleja)',
            value: 'Sucesiones (Compleja)',
          },
          {
            label: 'Embargos (Compleja)',
            value: 'Embargos (Compleja)',
          },
          {
            label: 'Proyectos de utilidad pública. (Compleja)',
            value: 'Proyectos de utilidad pública. (Compleja)',
          },
          {
            label:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
            value:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
          },
          {
            label: 'Venta inmuebles menores de edad e interdictos (Compleja)',
            value: 'Venta inmuebles menores de edad e interdictos (Compleja)',
          },
          {
            label: 'Validar poderes (Compleja)',
            value: 'Validar poderes (Compleja)',
          },
          {
            label: 'Solicitud Cambios Comercial (Compleja)',
            value: 'Solicitud Cambios Comercial (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Envío Primera Versión (Compleja)',
            value: 'Envío Primera Versión (Compleja)',
          },
          {
            label: 'Envío Versión Final (Compleja)',
            value: 'Envío Versión Final (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_lawyer_2',
      label: 'Abogado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: '_3_request_timestamp',
      label: 'Fecha y hora 3ra solicitud',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: '_3_response_timestamp',
      label: 'Fecha y hora 3ra respuesta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'interaction_3',
      label: 'Interacción 3',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Solicitud Adicional de Documentación Promesa (Simple)',
            value: 'Solicitud Adicional de Documentación Promesa (Simple)',
          },
          {
            label: 'Correcciones Promesa (Simple)',
            value: 'Correcciones Promesa (Simple)',
          },
          {
            label:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
            value:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
          },
          {
            label: 'Solicitud Cambios Comercial (Simple)',
            value: 'Solicitud Cambios Comercial (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Sucesiones (Compleja)',
            value: 'Sucesiones (Compleja)',
          },
          {
            label: 'Embargos (Compleja)',
            value: 'Embargos (Compleja)',
          },
          {
            label: 'Proyectos de utilidad pública. (Compleja)',
            value: 'Proyectos de utilidad pública. (Compleja)',
          },
          {
            label:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
            value:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
          },
          {
            label: 'Venta inmuebles menores de edad e interdictos (Compleja)',
            value: 'Venta inmuebles menores de edad e interdictos (Compleja)',
          },
          {
            label: 'Validar poderes (Compleja)',
            value: 'Validar poderes (Compleja)',
          },
          {
            label: 'Solicitud Cambios Comercial (Compleja)',
            value: 'Solicitud Cambios Comercial (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Envío Primera Versión (Compleja)',
            value: 'Envío Primera Versión (Compleja)',
          },
          {
            label: 'Envío Versión Final (Compleja)',
            value: 'Envío Versión Final (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_lawyer_3',
      label: 'Abogado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: '_4_request_timestamp',
      label: 'Fecha y hora 4ra solicitud',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: '_4_response_timestamp',
      label: 'Fecha y hora 4ra respuesta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'interaction_4',
      label: 'Interacción 4',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Solicitud Adicional de Documentación Promesa (Simple)',
            value: 'Solicitud Adicional de Documentación Promesa (Simple)',
          },
          {
            label: 'Correcciones Promesa (Simple)',
            value: 'Correcciones Promesa (Simple)',
          },
          {
            label:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
            value:
              'Prohibición de Transferencia/Derecho de Preferencia (Simple)',
          },
          {
            label: 'Solicitud Cambios Comercial (Simple)',
            value: 'Solicitud Cambios Comercial (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Sucesiones (Compleja)',
            value: 'Sucesiones (Compleja)',
          },
          {
            label: 'Embargos (Compleja)',
            value: 'Embargos (Compleja)',
          },
          {
            label: 'Proyectos de utilidad pública. (Compleja)',
            value: 'Proyectos de utilidad pública. (Compleja)',
          },
          {
            label:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
            value:
              'Inconsistencia área por construcciones sin licencia (Compleja)',
          },
          {
            label: 'Venta inmuebles menores de edad e interdictos (Compleja)',
            value: 'Venta inmuebles menores de edad e interdictos (Compleja)',
          },
          {
            label: 'Validar poderes (Compleja)',
            value: 'Validar poderes (Compleja)',
          },
          {
            label: 'Solicitud Cambios Comercial (Compleja)',
            value: 'Solicitud Cambios Comercial (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Envío Primera Versión (Compleja)',
            value: 'Envío Primera Versión (Compleja)',
          },
          {
            label: 'Envío Versión Final (Compleja)',
            value: 'Envío Versión Final (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_lawyer_5',
      label: 'Abogado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: 'total_simple_interactions_lv',
      label: 'Total int. simple',
      type_field: 'inputNumber',
      fixed: false,
    },
    {
      name: 'total_complex_interactions_lv',
      label: 'Total int. complejas',
      type_field: 'inputNumber',
      fixed: false,
    },
    {
      name: 'first_version_timestamp',
      label: 'Fecha y hora primera versión',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'final_version_promise_timestamp',
      label: 'Fecha y hora versión final de promesa',
      type_field: 'inputDateTime',
      fixed: true,
    },
    {
      name: 'promise_signature_date',
      label: 'Fecha firma de promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'tentative_deed_date',
      label: 'Fecha tentativa escritura',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'corrections',
      label: 'Correcciones',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'promise_observations',
      label: 'Observaciones promesas',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'requirement_revision_bill_timestamp',
      label: 'Fecha y hora requerimiento o revisión de minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'first_response_bill',
      label: 'Fecha y hora 1era respuesta minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'bill_interaction_1',
      label: 'Interacción minuta 1',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cuadros de Valores (Simple)',
            value: 'Cuadros de Valores (Simple)',
          },
          {
            label: 'Solicitud Soportes (Simple)',
            value: 'Solicitud Soportes (Simple)',
          },
          {
            label: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
            value: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
          },
          {
            label: 'Corrección Minuta (Simple)',
            value: 'Corrección Minuta (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Cambio Esquema de Compra/Venta (Compleja)',
            value: 'Cambio Esquema de Compra/Venta (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Primera Aprobación Minuta (Compleja)',
            value: 'Primera Aprobación Minuta (Compleja)',
          },
          {
            label: 'Aprobación Final Minuta (Compleja)',
            value: 'Aprobación Final Minuta (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_bill_lawyer_1',
      label: 'Abogado minuta',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: 'second_bill_request_timestamp',
      label: 'Fecha y hora 2da solicitud minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'second_bill_response_timestamp',
      label: 'Fecha y hora 2da respuesta minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'bill_interaction_2',
      label: 'Interacción minuta 2',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cuadros de Valores (Simple)',
            value: 'Cuadros de Valores (Simple)',
          },
          {
            label: 'Solicitud Soportes (Simple)',
            value: 'Solicitud Soportes (Simple)',
          },
          {
            label: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
            value: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
          },
          {
            label: 'Corrección Minuta (Simple)',
            value: 'Corrección Minuta (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Cambio Esquema de Compra/Venta (Compleja)',
            value: 'Cambio Esquema de Compra/Venta (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Primera Aprobación Minuta (Compleja)',
            value: 'Primera Aprobación Minuta (Compleja)',
          },
          {
            label: 'Aprobación Final Minuta (Compleja)',
            value: 'Aprobación Final Minuta (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_bill_lawyer_2',
      label: 'Abogado minuta',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: 'third_bill_request_timestamp',
      label: 'Fecha y hora 3ra solicitud minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'third_bill_response_timestamp',
      label: 'Fecha y hora 3ra respuesta minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'bill_interaction_3',
      label: 'Interacción minuta 3',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cuadros de Valores (Simple)',
            value: 'Cuadros de Valores (Simple)',
          },
          {
            label: 'Solicitud Soportes (Simple)',
            value: 'Solicitud Soportes (Simple)',
          },
          {
            label: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
            value: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
          },
          {
            label: 'Corrección Minuta (Simple)',
            value: 'Corrección Minuta (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Cambio Esquema de Compra/Venta (Compleja)',
            value: 'Cambio Esquema de Compra/Venta (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Primera Aprobación Minuta (Compleja)',
            value: 'Primera Aprobación Minuta (Compleja)',
          },
          {
            label: 'Aprobación Final Minuta (Compleja)',
            value: 'Aprobación Final Minuta (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_bill_lawyer_3',
      label: 'Abogado minuta',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: 'fourth_bill_request_timestamp',
      label: 'Fecha y hora 4ta solicitud',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'fourth_bill_response_timestamp',
      label: 'Fecha y hora 4ta respuesta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'bill_interaction_4',
      label: 'Interacción minuta 4',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cuadros de Valores (Simple)',
            value: 'Cuadros de Valores (Simple)',
          },
          {
            label: 'Solicitud Soportes (Simple)',
            value: 'Solicitud Soportes (Simple)',
          },
          {
            label: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
            value: 'Cambios Minuta Banco/Notaria/Cliente (Simple)',
          },
          {
            label: 'Corrección Minuta (Simple)',
            value: 'Corrección Minuta (Simple)',
          },
          {
            label: 'Otra (Simple)',
            value: 'Otra (Simple)',
          },
          {
            label: 'Cambio Esquema de Compra/Venta (Compleja)',
            value: 'Cambio Esquema de Compra/Venta (Compleja)',
          },
          {
            label: 'Otra (Compleja)',
            value: 'Otra (Compleja)',
          },
          {
            label: 'Primera Aprobación Minuta (Compleja)',
            value: 'Primera Aprobación Minuta (Compleja)',
          },
          {
            label: 'Aprobación Final Minuta (Compleja)',
            value: 'Aprobación Final Minuta (Compleja)',
          },
        ],
      },
    },
    {
      name: 'hl_lawyer_6',
      label: 'Abogado minuta',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Cindy Torres',
            value: 'cindy torres',
          },
          {
            label: 'Jessica Riaño',
            value: 'jessica riaño',
          },
          {
            label: 'Berenice Tarquino',
            value: 'berenice tarquino',
          },
          {
            label: 'Linda Jiménez',
            value: 'linda jiménez',
          },
          {
            label: 'Daniel Osorio',
            value: 'daniel osorio',
          },
          {
            label: 'Juan Mejía',
            value: 'juan mejía',
          },
          {
            label: 'Estefanía Ávila',
            value: 'estefanía ávila',
          },
        ],
      },
    },
    {
      name: 'bill_simple_interaction_total',
      label: 'Total Int. Simples minuta',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'bill_complex_interaction_total',
      label: 'Total Int. compleja minuta',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'first_bill_approve_timestamp',
      label: 'Fecha y hora primera aprobación minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'final_bill_approve_timestamp',
      label: 'Fecha y hora aprobación final minuta',
      type_field: 'inputDateTime',
      fixed: false,
    },
    {
      name: 'bill_corrections',
      label: 'Correcciones minuta',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'bill_observations',
      label: 'Observaciones minuta',
      type_field: 'input',
      fixed: false,
    },
  ],
};
