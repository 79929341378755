import styled from "styled-components";
import { createTheme } from "@material-ui/core";
import { nightBlue200, openSansBoldFamily, openSansRegular, primary } from '../../../StylesConstants';

export const TabsWrapper = styled.div`
  .Mui-selected {
    color: ${primary} !important;
  }
`;

export const styledTabs = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: `${primary}`
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '13px',
        fontFamily: `${openSansRegular}`,
        "&:hover": {
          color: `${primary}`
        },
        "&$selected": {
          color: `${primary}`,
          font: `13px ${openSansBoldFamily}`,
          "&:hover": {
            color: `${primary}`,
          }
        },
      },
      textColorPrimary: {
        color: `${nightBlue200}`
      }
    }
  }
});