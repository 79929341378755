import styled from "styled-components";
import { bogotaBlue100, brightTurquoise100, brightTurquoise700, openSansBoldFamily, openSansRegular, scarpaGray300, skyBlue700 } from "../../../../StylesConstants";

export const ChecklistWrapper = styled.div`
  .process-title {
    display: flex;
    font: 12px ${ openSansBoldFamily };

    .stats {
      align-items: center;
      display: flex;
      gap: 6px;
      font: 10px ${ openSansRegular };
      color: ${ scarpaGray300 };

      span {
        border-radius: 3px;
        padding: 4px 8px;
      }

      .loaded {
        background-color: ${ bogotaBlue100 };
        color: ${ skyBlue700 };
      }

      .approved {
        background-color: ${ brightTurquoise100 };
        color: ${ brightTurquoise700 };
      }
    }
  }

  .documents-container {
    display: grid;
    width: 100%;;
  }

  .icon-document {
    padding: 0 !important;
  }

  .MuiPaper-root, .MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 16px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0px 24px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-content {
    justify-content: space-between;
    align-items: center;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;