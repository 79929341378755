import styled from "styled-components";
import {
  openSansRegular,
  electricPurple500,
  grey,
  nightBlue500,
  error,
  tomatoCoral000,
  light_grey,
  errorPrimary,
  white,
  brightTurquoise500,
  brightTurquoise000
} from "../../../StylesConstants";

export const SearchWrap = styled.div`
  padding: 0 24px;

  .form-search {
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    justify-items: center;
    border: 1px solid ${light_grey};
    border-radius: 8px;
    height: 32px;
    padding-left: 8px;
    overflow: hidden;
    margin: 1px;

    &:focus-within {
      border: 2px solid ${brightTurquoise500};
      border-radius: 8px;
      background: ${brightTurquoise000};
      margin: 0;

      .input-search {
        background: ${brightTurquoise000};
      }

      &.error {
        border: 1px solid ${brightTurquoise500};
        border-radius: 8px;
        background: ${brightTurquoise000};
        border-radius: 8px;
        .input-search {
          background: ${brightTurquoise000};
          color: ${nightBlue500};
        }
      }
    }

    &.error {
      background: ${tomatoCoral000};
      border: 1px solid ${errorPrimary};
      box-sizing: border-box;
      border-radius: 8px;
    }

    .input-search {
      border: none;
      outline: none;
      font-family: ${openSansRegular};
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: ${nightBlue500};
      width: 128px;

      &.error {
        background: ${tomatoCoral000};
        font-family: ${openSansRegular};
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: ${error};
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .button-container {
      align-items: center;
      background: ${electricPurple500};
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      .button-search {
        background: ${electricPurple500};
        border: none;
        color: white;
        width: 100%;
        height: 100%;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .MuiSvgIcon-root {
          color: white;
          width: 18px;
          height: 18px;
        }

        &.disabled {
          color: white;
          background-color: ${grey};
          cursor: not-allowed;
        }
      }

      .loading {
        position: relative;

        .buttonProgress {
          position: absolute;
          bottom: 5px;
          right: 10px;
        }
      }
    }
  }

  .MuiCircularProgress-svg {
    color: ${white}
  }
`;
