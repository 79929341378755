import React from 'react';
import PropTypes from 'prop-types';

import { RadioOptionWrapper } from './style';

const RadioOption = ({ name, label, value, selected, round, disabled, setValue }) => {
  
  const handleClick = () => {
    if (!disabled) {
      setValue(value);
    }
  };

  return (
    <RadioOptionWrapper selected={selected} disabled={disabled} round={round}>
      <label className="radio">
        <span className="radio-input" onClick={handleClick}>
          <input type="radio" name={name} disabled={disabled} />
          <span className="radio-control" />
        </span>
        <span className="radio-label">{label}</span>
      </label>
    </RadioOptionWrapper>
  );
}

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func
}

RadioOption.defaultProps = {
  selected: false,
  disabled: false
}
 
export default RadioOption;
