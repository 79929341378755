import styled from "styled-components";
import { greySoft, muliBoldFamily, scarpaGray100, scarpaGray600 } from "../../StylesConstants";

export const DocumentsListWrapper = styled.div`

  color: ${scarpaGray600};

  hr {
    height: 1px;
    border: 0;
    color: #666;
    background-color: ${scarpaGray100};
  }

  .document-container {
    background-color: ${greySoft};
    border: 1px solid ${scarpaGray100};
    border-radius: 8px;
    font: 10px ${muliBoldFamily};
    line-height: 15px;
    padding: 14px 16px;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
  }

  .icon-file {
    width: 30px
  }

  .documents-title {
    font: 12px ${muliBoldFamily};
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    div {
      width: 100%;
    }

    span {
      min-width: 150px;
    }
  }

`;