import styled from 'styled-components';
import { greySoft, hintPerano000 } from '../../../../StylesConstants';

export const HighLevelDetailWrapper = styled.div`

  .form-container {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .table-container {
    overflow-x: auto;
    width: calc(100vw - 421px);
    border-radius: 8px;
  }
  
  table {
    width: max-content !important;
    border-radius: 8px !important;
  }

  .section-container {
    background-color: ${hintPerano000} !important;
    vertical-align: top;
    padding: 0;
  }

  .hide-section-container {
    min-height: 64px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .show-section-container {
    min-height: 64px;
    justify-content: space-between;
    display: flex;
    tr {
      background-color: ${hintPerano000} !important;
    }
  }

  .data-container {
    padding: 0 12px 6px 12px !important;
    div {
      grid-template-columns: 1fr !important;
      span {
        margin-bottom: 0 !important;
      }
    }
  }
`;

export const SectionTitle = styled.td`
  background-color: ${p => p.color} !important;
  padding: 2px 8px !important;
  text-align: center;
`;

export const SectionClose = styled.div`
  background-color: ${greySoft} !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: -28px;
`;