import React from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { format } from 'date-fns';
import Loader from "@habi/habi-react-components/dist/Loader/loader";
import PropTypes from 'prop-types';

import { DOCUMENT_STATUS } from '../../../../utils/constant';
import { DocumentRowWrap } from './style';

const DocumentRow = ({ document, getReviewStatus, getActionIcon, getApprove, getOptional, loading }) => {

  const getStatusClass = () => {
    return document.status === DOCUMENT_STATUS.SUCCESS? 'status success': 'status';
  };

  const getLoadedClass = () => {
    let className = document.exist? 'loaded': 'default';
    return document.status === DOCUMENT_STATUS.SUCCESS? 'success': className;
  };

  return (
    <DocumentRowWrap>
      <div className={ getStatusClass() } >
        { getReviewStatus(true) }
      </div>
      <div className="detail">
        <div className="name">
          <div className={ getLoadedClass() }>
            <DescriptionOutlinedIcon />
          </div>
          <span>
            { document.label }
          </span>
        </div>
        {
          !loading &&
          <div className="options">
            { 
              !document.required && 
              <div className="opt">
                { getOptional() }
              </div>
            }
            <div className="opt">
              { 
                document.exist && 
                `Últ. mod. ${format(new Date(document.update_date), 'dd-MM-yyyy')}`
              }
            </div>
            <div className="opt">
              { document.exist? getApprove(): 'No hay documento' }
            </div>
            <div className="opt">
              { getActionIcon() }
            </div>
          </div>
        }
        { loading && <div className="loading"><Loader size='small' /></div> }
      </div>
    </DocumentRowWrap>
  )
};

DocumentRow.defaultProps = {
  loading: false
};

DocumentRow.propTypes = {
  document: PropTypes.object.isRequired,
  getReviewStatus: PropTypes.func.isRequired, 
  getActionIcon: PropTypes.func.isRequired, 
  getApprove: PropTypes.func.isRequired, 
  getOptional: PropTypes.func.isRequired, 
  loading: PropTypes.bool
}

export default DocumentRow;
