import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({
  component: Component,
  authorizedRoles,
  ...rest
}) {

  const { isAuthenticated, data } = useSelector(state => state.loginData);

  const isAuthorized = () => {
    let authorized = false;
    if( authorizedRoles ) {
      data.role.forEach(role => {
        if(authorizedRoles.some( authorizedRole =>  authorizedRole === role )) {
          authorized = true;
        }
      });
    } else {
      authorized = true;
    }
    return authorized;
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          isAuthorized() 
            ? <Component {...props} />
            : <Redirect to={`/home`} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}
