import React, { useState } from 'react';
import { format } from 'date-fns';

import { FIELD_TYPE } from '../../../../utils/constant';
import Button from '../../../../components/shared/button/button';
import HighLevelDetail from '../HighLevelDetail/HighLevelDetail';
import { getHighLevelBuyersDetailData } from '../../../../services/data/highLevelBuyers';

const HighLevelRow = ({ tab, dataRow, requestSaveDetailData }) => {

  const [detailData, setDetailData] = useState({});
  const [isDetaillVisible, setIsDetailVisible] = useState(false);

  const getDetailData = async (nid) => {
    setIsDetailVisible(true);
    const highLevelDetail = await getHighLevelBuyersDetailData({
      nid,
      tab: tab.value
    });
    setDetailData(highLevelDetail.data);
  }

  const buildDataForm = () => {
    let dataForm = [];
    if (tab && tab.sections) {
      dataForm = tab.sections.map(section => ({
        ...section,
        fields: section.fields.map(field => (
          { ...field, value: detailData[field.name] }
        ))
      }))
    }
    return { sections: dataForm };
  }

  const getFieldValue = (field, dataRow) => {
    if (field.type_field === FIELD_TYPE.INPUT_DATE) {
      try {
        return dataRow[field.name]
          ? format(new Date(dataRow[field.name]), 'dd-MM-yyyy')
          : '';
      } catch {
        return dataRow[field.name];
      }
    } else if (field.type_field === FIELD_TYPE.INPUT_DATE_TIME) {
      try {
        return dataRow[field.name]
          ? format(new Date(dataRow[field.name]), 'dd-MM-yyyy HH:mm')
          : '';
      } catch {
        return field.value;
      }
    } else if (field.type_field === FIELD_TYPE.SELECT && field.multiple_values) {
      const optionField = field.multiple_values.options.find(option => option.value === dataRow[field.name]);
      return optionField ? optionField.label : dataRow[field.name];
    } else {
      return dataRow[field.name] ? `${dataRow[field.name]}` : '';
    }
  }

  return (
    <>
      <tr className={dataRow.visible ? 'highlight header' : ''}>
        {
          tab.fields &&
          tab.fields.map(field =>
            <td>
              {getFieldValue(field, dataRow)}
            </td>
          )
        }
        <td className="action-column">
          {
            isDetaillVisible
              ? <Button
                size="mini"
                onClick={() => setIsDetailVisible(false)}
                typeClass="secondary"
              >
                Cerrar
              </Button>
              : <Button
                size="mini"
                onClick={() => getDetailData(dataRow.nid)}
                typeClass="secondary"
              >
                Editar
              </Button>
          }
        </td>
      </tr>
      {
        isDetaillVisible &&
        <tr className={dataRow.visible ? 'detail-row highlight data' : 'detail-row'}>
          <td colSpan="10">
            <HighLevelDetail
              data={buildDataForm()}
              nid={dataRow.nid}
              requestSaveDetailData={requestSaveDetailData}
              setIsDetailVisible={setIsDetailVisible}
            />
          </td>
        </tr>
      }
    </>
  )
}

export default HighLevelRow;
