import styled from "styled-components";
import { hintPerano000, montserratBold, primary, softPurple800, white } from '../../StylesConstants';

export const HeaderWrapper = styled.header`
  height: 52px;
  background-color: ${white};
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.03);
  align-items: center;
  justify-content: space-between;

  .logo {
    align-items: center;
    color: ${primary};
    display: grid;
    font: 12px ${montserratBold};
    grid-template-columns: 36px auto;
    grid-gap: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    margin-left: 24px;
  }

  .avatar {
    width: 32px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 16px;
    margin-right: 24px;
    img {
      width: 100%;
    }
  }

  .options-container {
    display:flex;
    gap: 16px;
  }

  .notification-button {
    background-color: ${hintPerano000};
    color: ${softPurple800};
    width: 32px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
  }
`;