import styled from "styled-components";
import { primary, scarpaGray400, tomatoCoral500 } from "../../../../StylesConstants";

export const RowDocumentControlWrapper = styled.tr`
  .sub-field {
    display: grid;
    min-width: 80px;

    span:nth-child(2n) {
      font-size: 10px;
      line-height: 10px;
      color: ${scarpaGray400};
      text-overflow: ellipsis;
      height: 10px;
      overflow: hidden;
    }
  }

  .view-docs-field {
    color: ${primary};
    display: grid;
    gap: 6px;
    cursor: pointer;
    height: 100%;
    grid-template-columns: 24px 52px;
    span:hover {
      text-decoration: underline;
    }
  }

  .center-field {
    vertical-align: middle;
  }

  .external-user-field {
    min-width: 180px;
  }

  .document-field {
    width: 140px;
    min-width: 140px;
  }

  .out-off-date{
    color: ${tomatoCoral500};
  }

  .out-off-date span:nth-child(2n)  {
      color: ${tomatoCoral500};
  }

  .icon-field {
    float: left;
    color: ${tomatoCoral500};
    margin-right: 4px;
    svg {
      width: 18px;
    }
  }
  
`;
