import axios from 'axios';
import API from '../Api';

export const getListDocumentsApi = (nid, businessType, processName, sections=false) => {
  return API.get(
    process.env.REACT_APP_GET_LIST_DOCUMENTS,
    {
      params: {
        nid,
        business_type: businessType,
        process_name: processName,
        sections
      }
    }
  );
};

export const getListDocumentsHistApi = ({ nid, businessType, processName }) => {
  return API.get(
    process.env.REACT_APP_GET_LIST_PROCEDURE_DOCUMENTS_HIST,
    {
      params: {
        nid: parseInt(nid),
        business_type: businessType,
        process_name: processName
      }
    }
  );
};

export const deleteDocument = async (documentId, agent) => {
  return API.post(
    process.env.REACT_APP_DELETE_DOCUMENT,
    {
      document_id: documentId,
      agent,
    }
  );
};

export const createDocument = async (document) => {
  return API.post(
    process.env.REACT_APP_CREATE_DOCUMENT,
    document
  );
};

export const uploadFileToS3 = async (presignedPostData, file) => {
  const fields = JSON.parse(presignedPostData.fields);
  const fileName = fields.key;

  return axios
    .put((`${presignedPostData.url}${fileName}`), file, {
      headers: {
        "Content-Type": "multipart/form-data",
        'x-amz-acl': 'public-read'
      },
    })
};

export const saveDocumentStatus = async (documentId, status, agent, role, comment, nid, originUrl, businessTypeId, processName) => {
  return API.post(
    process.env.REACT_APP_POST_DOCUMENT_STATUS,
    {
      agent,
      comment,
      business_type_id: businessTypeId,
      name_reviewer_role: role,
      nid: parseInt(nid),
      origin_url: originUrl,
      procedure_document_id: documentId,
      process_name: processName,
      status,
    }
  );
};

export const getDocumentsPcvApi = (nid, business_type) => {
  return API.get(
    process.env.REACT_APP_GET_PCV_SALES_AND_LEGAL,
    {
      params: {
        nid: nid,
        business_type: business_type
      }
    }
  );
}

export const getListOptionalDocumentsApi = ({ nid, businessType, processName, sectionName }) => {
  return API.get(
    process.env.REACT_APP_GET_OPTIONAL_DOCUMENTS_LIST,
    {
      params: {
        nid: nid? parseInt(nid): null,
        business_type: businessType,
        process_name: processName,
        section_name: sectionName
      }
    }
  );
};

export const getChecklistDocuments = ({ nid, processName, businessTypeId}) => {
  return API.get(
    process.env.REACT_APP_GET_CHECKLIST_DATA,
    { 
      params: { 
        nid: parseInt(nid),
        process_name: processName, 
        business_type_id: businessTypeId
      } 
    }
  );
}

export const getUrlTemplateDocument = async ({
  nid,
  businessTypeId,
  processName,
  type,
}) => {
  return API.post(process.env.REACT_APP_POST_TEMPLATE_DOCUMENT, {
    business_type_id: businessTypeId,
    nid: parseInt(nid),
    process_name: processName,
    document_name: type,
  });
};
