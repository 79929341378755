import styled from "styled-components";
import {
  nightBlue200,
  openSansBoldFamily,
  openSansRegular,
  primary,
  scarpaGray100
} from "../../../StylesConstants";

export const TabsHeaderWrapper = styled.div`
  
  display: flex;
  border-bottom: 1px solid ${scarpaGray100};
  
  .tab {
    color: ${nightBlue200};
    font: 12px ${openSansRegular};
    line-height: 16px;
    padding: 12px 36px;
    cursor: pointer;
  }

  .active {
    color: ${primary};
    font-family: ${openSansBoldFamily};
    cursor: default;
  }

  .active-tab {
    background-color: ${primary};
    border-radius: 4px 4px 0px 0px;
    height: 4px;
    width: 100%;
  }
`;
