import React from 'react';
import { HeaderHomeWrapper } from './style';
import IconDoubleHouse from '../../../../../../static/assets/img/icons/icon-double-house.svg';
import { useSelector } from 'react-redux';

export const HeaderHome = ({ propertyName }) => {
  const nid = useSelector((state) => state.propertyData.nid);

  return (
    <HeaderHomeWrapper>
      <img src={IconDoubleHouse} alt="double-house" className="double-house" />
      <div className="description">
        <span className="property">Inmueble:</span>
        <span className="propertyName">{propertyName}</span>
        <span className="nid">NID: {nid}</span>
      </div>
    </HeaderHomeWrapper>
  );
};
