import styled from 'styled-components';
import { createTheme } from "@material-ui/core";

import * as sc from '../../../../StylesConstants';

export const DateTimePickerWrapper = styled.div`
  display: flex;
  
  .MuiFormControl-root  {
    width: ${p => p.width};
  }
  .MuiInput-underline:before {
    border: 0 !important;
  }
  .MuiInput-underline:after {
    border: 0 !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${sc.nightBlue200};
    background: ${sc.greySoft}
  }
`;

export const StyledDateTimePicker = createTheme ({
  overrides: {
    MuiInputBase: {
      input:{
        background: `${sc.white}`,
        border: `1px solid ${sc.plainlightGray}`,
        borderRadius: '8px',
        color: `${sc.scarpaGray600}`,
        height: '40px',
        padding: '0 16px',
      }
    },
    MuiPopover: {
      paper: {
        borderRadius: '8px',
        marginTop: '16px'
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: `${sc.muliBoldFamily}`,
        color: `${sc.electricPurple700}`,
      },
      toolbarBtnSelected: {
        fontWeight: '900',
        color: `${sc.white}`,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: `${sc.primary}`,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: `${sc.primary}`,
      },
    },
    MuiPickersDay: {
      day: {
        color: `${sc.primary}`,
        fontFamily: `${sc.openSansRegular}`,
      },
      daySelected: {
        backgroundColor: `${sc.primary}`,
        '&:hover': {
          backgroundColor: `${sc.primary}`,
          color: `${sc.nightBlue000}`,
        },
      },
      current: {
        color: `${sc.primary}`,
      },
    },
    MuiFormHelperText: {
      root: {
        color: `${sc.tomatoCoral500}`,
        margin: "0",
        fontFamily: `${sc.openSansRegular}`,
        fontWeight: "600",
        '&$error': {
          color: `${sc.tomatoCoral500}`,
          margin: "0",
          fontFamily: `${sc.openSansRegular}`,
          fontWeight: "600",
        },
      },
    },
    MuiPickerDTTabs: {
      tabs:{
        backgroundColor: `${sc.primary}`,
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: `${sc.electricPurple700}`
      }
    },
    MuiPickersClockPointer: {
      noPoint: {
        backgroundColor: `${sc.primary}`
      },
      thumb: {
        border: `14px solid ${sc.primary}`
      },
      pointer: {
        backgroundColor: `${sc.primary}`
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: `${sc.primary}`
      }
    },
    MuiButton: {
      label: {
        color: `${sc.primary}`
      }
    }
  }
});