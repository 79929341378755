import React from 'react'
import { useFormik } from 'formik';

import { ButtonGrid, Label } from '../../../../components/Styles/genericStyleComponent';
import { HighLevelFiltersWrapper } from './style';

import Button from '../../../../components/shared/button/button';
import { HIGH_LEVEL_FILTERS } from '../../config/filters';

const initialValues = {
  nid: '',
  analyst: '',
  financial_entity: ''
};

const HighLevelFilters = ({ setFilters }) => {
  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      setFilters(values);
    },
    resetForm: () => {
      console.log('entras');
      setFilters({});
    }
  });

  return (
    <HighLevelFiltersWrapper>
      <form className="form" onSubmit={formik.handleSubmit}>
        <ButtonGrid>
          <div>
            <Label>Busca por NID</Label>
            <input
              id="nid"
              name="nid"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nid}
              placeholder="Busca por NID"
            />
          </div>
          <div>
            <Label>Analista</Label>
            <select
              id="analyst"
              name="analyst"
              onChange={formik.handleChange}
              value={formik.values.analyst}
              placeholder="Analista"
            >
              <option value="">Seleccione</option>
              {HIGH_LEVEL_FILTERS.ANALYST.map((analyst, index) => (
                <option value={analyst.value} key={`analyst${index}`}>
                  {analyst.label}
                </option>
              ))}
            </select>
          </div>
          {/* <div>
            <Label>
              Entidad financiera
            </Label>
            <select
              id="financial_entity"
              name="financial_entity"
              onChange={formik.handleChange}
              value={formik.values.financial_entity}
              placeholder="Entidad financiera"
            >
              <option value="">Seleccione</option>
              {HIGH_LEVEL_FILTERS.FINANCIAL_ENTITIES.map((entity, index) =>
                <option value={entity.value} key={`entity${index}`}>
                  {entity.label}
                </option>
              )}
            </select>
          </div> */}
          <Button size="small" type="submit">
            Aplicar filtros
          </Button>
          <Button typeClass="secondary" size="small" onClick={formik.resetForm}>
            Limpiar filtros
          </Button>
        </ButtonGrid>
      </form>
    </HighLevelFiltersWrapper>
  );
}

export default HighLevelFilters;