import React, { useEffect, useState } from 'react';
import AccordionDocumentsSection from '../../../../components/AccordionDocumentsSection/AccordionDocumentsSection';

import ButtonCheck from '../../../../components/ButtonCheck/buttonCheck';
import { ButtonGrid, SubTitle, TitleAdd } from '../../../../components/Styles/genericStyleComponent';
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS, PROCESS_EVENTS } from '../../../../utils/constant';

const PurchaseOptionLeasingDann = ({ sections, handleCallEvent, handleUpdateDocument, addOptionalDocument, getEventStatus }) => {

  const [buttonEvents, setButtonEvents] = useState([]);

  useEffect(() => {
    if(sections.length>0) {
      const buttonEventsTemp = [
        [{
          label: "Solicitar abrop. Opción de compra",
          event: PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_ONE,
          status: getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_ONE),
          disabled: sections[0].list_documents.some(document => !document.exist)
        }],
        [{
          label: "Solicitar abrop. Opción de compra",
          event: PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_TWO,
          status: getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_TWO),
          disabled: sections[1].list_documents.some(document => !document.exist)
        }],
        []
      ];
      setButtonEvents(buttonEventsTemp);
    }
  }, [sections, getEventStatus]);

  const allDocumentLoaded = () => {
    let documentLoaded = true;
    sections.forEach((section, index) => {
      section.list_documents.forEach(document => {
        if(index<3 && !document.exist) {
          documentLoaded = false;
        }
      });
    });
    return documentLoaded;
  };

  return (
    <div>
      { 
        sections && 
        sections.map((section, indexSection) =>
          <AccordionDocumentsSection 
            section={ section }
            buttonEvents={ buttonEvents[indexSection]?? [] }
            handleCallEvent={ handleCallEvent }
            handleUpdateDocument={ handleUpdateDocument }
            addOptionalDocument={ addOptionalDocument }
            businessTypeId={ BUSINESS_TYPE.BUYERS }
            processName={ PROCESS.PURCHASE_OPTION_LEASING_DANN }
            showDocumentOptional={ indexSection === 0 }
            showDelete={ getEventStatus(PROCESS_EVENTS.PURCHASE_OPTION_APPROVED) !== DOCUMENT_STATUS.SUCCESS}
            key={`section_${ section.id }`}
          />
      )} 
      <SubTitle>
        Solicitud de aprob. Opción de compra
        <TitleAdd>
          / Leasing Dann
        </TitleAdd>
      </SubTitle>
      <ButtonGrid>
        <ButtonCheck
          label="Opción de compra aprobada"
          status={ getEventStatus(PROCESS_EVENTS.PURCHASE_OPTION_APPROVED) }
          handleChange={() => handleCallEvent(PROCESS_EVENTS.PURCHASE_OPTION_APPROVED, DOCUMENT_STATUS.SUCCESS)}
          disabled={ !allDocumentLoaded() }
        />
      </ButtonGrid>
    </div>
  )
};

export default PurchaseOptionLeasingDann;
