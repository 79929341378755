import { getLifeCycleData } from '../../apis/LifeCycle/lifeCycleApi';

const defaultEmptyData = [
  {
    status: 'success',
    label: 'Not-Found',
    name: 'pcv',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'in_review',
    label: 'Not-Found',
    name: 'analyst',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'appraisal_minute',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'financing',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'deed_delivery',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'disbursement',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'registration_deposit',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
  {
    status: 'non_start',
    label: 'Not-Found',
    name: 'listing_web',
    itemsSection: [
      {
        status: 'success',
        label: 'Not-Found',
      },
    ],
  },
];

export const lifeCycleDataService = ({ nid, businessType }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const dataLifeCycle = await getLifeCycleData({
        nid,
        businessType,
      });

      resolve(dataLifeCycle.data.data);
    } catch (error) {
      console.error(error);
      reject(defaultEmptyData);
    }
  });
};
