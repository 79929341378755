import React from "react";
import FaceIcon from '@material-ui/icons/Face';
import CreateIcon from '@material-ui/icons/Create';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import BuildIcon from '@material-ui/icons/Build';
import FolderIcon from '@material-ui/icons/Folder';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { ALL_EXTERNAL_ROLES, ALL_HABI_ROLES, ROLE, ROUTES } from "../../utils/constant";

export const MENU_DICTIONARY_TREE = [
  {
    menuName: 'VENDEDORES',
    roles: [...ALL_EXTERNAL_ROLES, ...ALL_HABI_ROLES],
    sections: [
      {
        name: 'Comercial & Legal',
        icon: <FaceIcon />,
        roles: ALL_HABI_ROLES,
        subSections: [
          {
            name: 'Documentos PCV',
            link: '/documentos-pcv',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Recepción inmueble',
            link: '/recepcion-inmueble',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'PCV',
            link: '/pcv',
            roles: ALL_HABI_ROLES,
          },
        ],
      },
      {
        name: 'Inmuebles vendedores',
        icon: <CreateIcon />,
        link: '/inmuebles-vendedores',
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Documentos pre-escritura',
        icon: <CreateIcon />,
        link: '/documentos-pre-escritura',
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Solicitud proveedores',
        icon: <CreateIcon />,
        roles: [...ALL_EXTERNAL_ROLES, ...ALL_HABI_ROLES],
        subSections: [
          {
            name: 'Estudio de títulos',
            link: '/estudio-titulos',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Avalúo',
            link: '/avaluo',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Minuta',
            link: '/minuta',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Proveedor estudio de títulos',
            link: '/proveedor-estudio-titulos',
            roles: [...ALL_HABI_ROLES, ROLE.SUPPLIER_TITLE_STUDY],
          },
          {
            name: 'Proveedor avalúo',
            link: '/proveedor-avaluo',
            roles: [...ALL_HABI_ROLES, ROLE.SUPPLIER_APPRAISAL],
          },
          {
            name: 'Proveedor minuta',
            link: '/proveedor-minuta',
            roles: [...ALL_HABI_ROLES, ROLE.SUPPLIER_DRAFT],
          },
        ],
      },
      {
        name: 'Checklist Documentos',
        icon: <AssignmentTurnedInIcon />,
        link: ROUTES.DOCUMENTS_CHECKLIST,
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Financiación',
        icon: <AccountBalanceIcon />,
        roles: ALL_HABI_ROLES,
        subSections: [
          {
            name: 'Vinculación',
            link: ROUTES.BONDING,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Instrucción de giro',
            link: ROUTES.PAY_INSTRUCTION,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Desembolso',
            link: ROUTES.DISBURSEMENT,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Escrituración & Entrega',
            link: ROUTES.SIGNATURE_AND_DELIVERY,
            roles: ALL_HABI_ROLES,
          },
        ],
      },
      {
        name: 'Boleta de registro',
        icon: <ConfirmationNumberIcon />,
        link: '/boleta-registro',
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Hipoteca',
        icon: <AccountBalanceIcon />,
        roles: ALL_HABI_ROLES,
        subSections: [
          {
            name: 'Docs. cancelación hipoteca ',
            link: ROUTES.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Solicitudes de pago',
            link: '/solicitudes_de_pago',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Docs. pagos notariales',
            link: ROUTES.NOTARIAL_PAYMENTS_DOCUMENTS,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Cancelación hipoteca',
            link: ROUTES.MORTGAGE_CANCELLATION,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Solicitud aprobación minuta',
            link: ROUTES.DRAFT_APPROVAL_REQUEST,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Gastos notariales',
            link: ROUTES.NOTARIAL_EXPENSES,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Boleta de registro hipoteca',
            link: ROUTES.MORTGAGE_REGISTRATION_TICKET,
            roles: ALL_HABI_ROLES,
          },
        ],
      },
      {
        name: 'Remodelación',
        icon: <BuildIcon />,
        link: '/home',
        roles: ALL_HABI_ROLES,
      },
    ],
  },
  {
    menuName: '',
    roles: [...ALL_HABI_ROLES, ROLE.LISTING_WEB],
    sections: [
      {
        name: 'Publicación & Carpetas',
        icon: <FolderIcon />,
        roles: [...ALL_HABI_ROLES, ROLE.LISTING_WEB],
        subSections: [
          { name: 'Inmuebles compradores' },
          {
            name: 'Carpetas compradores',
            link: '/carpetas-compradores',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Listing Web',
            link: '/listing-web',
            roles: [...ALL_HABI_ROLES, ROLE.LISTING_WEB],
          },
        ],
      },
    ],
  },
  {
    menuName: 'COMPRADORES',
    roles: [...ALL_HABI_ROLES],
    sections: [
      {
        name: 'High level',
        icon: <CreateIcon />,
        link: ROUTES.HIGH_LEVEL_BUYERS,
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Comercial & Legal',
        icon: <FaceIcon />,
        roles: ALL_HABI_ROLES,
        subSections: [
          {
            name: 'Documentos CI / PCV',
            link: ROUTES.DOCUMENTS_CI_PCV,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Recepción comprador',
            link: ROUTES.BUYER_RECEPTION,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'CI / PCV',
            link: ROUTES.CI_PCV,
            roles: ALL_HABI_ROLES,
          },
        ],
      },
      {
        name: 'Inmuebles Compradores',
        icon: <CreateIcon />,
        link: ROUTES.PROPERTY_BUYERS,
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Legalización',
        icon: <FaceIcon />,
        roles: ALL_HABI_ROLES,
        subSections: [
          {
            name: 'Documentos Pre-estudio',
            link: ROUTES.DOCUMENTS_PRE_STUDY,
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Cita avalúo',
            link: '/cita-avaluo',
            roles: ALL_HABI_ROLES,
          },
          {
            name: 'Estudio de títulos',
            link: ROUTES.TITLE_STUDY_AND_DRAFTS,
            roles: ALL_HABI_ROLES,
          },
        ],
      },
      {
        name: 'Escrituración & Entrega',
        icon: <CreateIcon />,
        link: ROUTES.SIGNATURE_AND_DELIVERY_BUYERS,
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Opción de compra',
        icon: <ConfirmationNumberIcon />,
        link: ROUTES.PURCHASE_OPTION,
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Boleta de registro',
        icon: <ConfirmationNumberIcon />,
        link: '/home',
        roles: ALL_HABI_ROLES,
      },
      {
        name: 'Desembolso',
        icon: <AttachMoneyIcon />,
        link: '/home',
        roles: ALL_HABI_ROLES,
      },
    ],
  },
];
