import styled from "styled-components";
import { nightBlue100 } from "../../../../StylesConstants";

export const DocumentStepWrapper = styled.div`
  
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px dashed ${ nightBlue100 };
  border-radius: 16px;
  padding: 16px 20px;
  align-items: center;

  .name-document {
    margin: 0;
  }

  .date-document {
    margin: 0;
  }

  .no-document {
    margin: 0;
  }

  .loader {
    display: grid;
    place-self: center;
    grid-column: 1 / 4;
  }

  .lock {
    width: 22px;
    margin: 6px;
  }

  img {
    width: 38px;
  }
`;
