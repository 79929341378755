import { useEffect } from "react";

const useOutsideClick = (ref, callback, event) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener(`${event ?? 'click'}`, handleClick);

    return () => {
      document.removeEventListener(`${event ?? 'click'}`, handleClick);
    };
  });
};

export default useOutsideClick;