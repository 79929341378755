import React, { useCallback, useEffect, useState } from 'react';
import { ChoiceContractTypeWrapper } from './style';
import { Field, Formik } from 'formik';
import SelectComponent from '@habi/habi-react-components/dist/Select/select';
import { getContractTypeDataService } from '../../services/data/choiceContractTypeService';
import {
  getFormData,
  updateFormData,
} from '../../apis/DynamicForm/dynamicFormApis';
import { useSelector } from 'react-redux';

const ChoiceContractType = ({
  handleChange,
  disabled,
  nid,
  businessTypeId,
  processName,
  label,
}) => {
  const agent = useSelector((state) => state.loginData.data.email);
  const [type, setType] = useState('');
  const [selectData, setSelectData] = useState([]);
  const [field, setField] = useState({});
  const [initialValue, setInitialValue] = useState('');
  const [url, setUrl] = useState('');

  const getSelectData = useCallback(async () => {
    const { data } = await getFormData(nid, processName, businessTypeId);

    setSelectData(data.data.sections[0].fields[0].multiple_values.options);
    setField(data.data.sections[0].fields[0]);
    setInitialValue(data.data.sections[0].fields[0].value);
  }, [nid, businessTypeId, processName]);

  useEffect(() => {
    getSelectData();
  }, [getSelectData]);

  const getUrl = useCallback(async () => {
    if (!!type || !!initialValue) {
      const { data } = await getContractTypeDataService(
        nid,
        businessTypeId,
        processName,
        type || initialValue
      );
      setUrl(data.url);
    }
  }, [nid, businessTypeId, processName, type, initialValue]);

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  useEffect(() => {
    handleChange(url);
  }, [handleChange, url]);

  const setValueAndGetData = async (value) => {
    const fieldData = {
      type_fields_procedure_id: field.id,
      value,
      type_field: field.type_field,
      field_name: field.name,
    };
    const data = {
      nid: +nid,
      agent,
      process_name: processName,
      fields: [fieldData],
      business_type_id: businessTypeId,
    };
    try {
      await updateFormData(data);
      setType(value);
    } catch (error) {
      console.error(error);
    }
  };

  const getSelect = (props) => {
    let { values, setFieldValue, setFieldTouched } = props;

    const selected = { value: '', label: 'Seleccione' };
    let defaultSelect = selectData.find(
      (option) => option.value === initialValue
    );

    if (!defaultSelect) {
      defaultSelect = selected;
    }

    const newValues = [selected, ...selectData];

    return (
      <div key={initialValue}>
        <Field
          values={values}
          default={defaultSelect}
          options={newValues}
          component={SelectComponent}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name={'select'}
          id={'select-pcv-type'}
          isClearable={false}
          handleChange={setValueAndGetData}
          label={''}
          noBorder
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <ChoiceContractTypeWrapper>
      <Formik enableReinitialize initialValues={{ selected: initialValue }}>
        {(props) => (
          <>
            <div className="label">{label}</div>
            <div className="choice-grid-container" data-id="select">
              {getSelect(props)}
            </div>
          </>
        )}
      </Formik>
    </ChoiceContractTypeWrapper>
  );
};

export default ChoiceContractType;
