import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import WarningDialog from '../WarningDialog/warningDialog';

const RouteLeavingGuard = ({ when=true, handleConfirm, shouldBlockNavigation }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const history = useHistory();

  const closeModal = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    handleConfirm();
    setModalVisible(false);
    setConfirmedNavigation(true);
  }; 

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt 
        when={ when } 
        message={ handleBlockedNavigation } 
      />
      <WarningDialog 
        open={ modalVisible }
        title='Estás cambiando de sección sin guardar'
        contentAlert='Si continúas y cambias de sección sin guardar se perderá la información que ingresaste en esta sección/módulo.'
        cancelButtonText='Continuar sin guardar'
        confirmButtonText='Guardar y cambiar de sección/módulo'
        onCancel={ closeModal }
        onConfirm={ handleConfirmNavigationClick }
      />
    </>
  );
};
export default RouteLeavingGuard;
