import styled from 'styled-components';
import * as s from '../../../StylesConstants';

export const ButtonWrapper = styled.button`

  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: ${s.muliBoldFamily};
  gap: 8px;
  user-select: none;
  justify-content: center;
  outline: none;
  
  &.primary {
    border: 0;
    border-radius: 100px;
    letter-spacing: 0.04em;
    padding: 15px 24px;
    
    &.light {
      background-color: ${s.primary};
      color: ${s.white};
      
      :hover {
        background: ${s.electricPurple400};
      }
      
      :active {
        background: ${s.electricPurple700};
      }
      
      :disabled {
        background: ${s.scarpaGray100};
        color: ${s.scarpaGray300};
        cursor: default;
      }
    }

    &.dark {
      background: ${s.brightTurquoise500};
      color: ${s.scarpaGray800};
      
      :hover {
        background: ${s.brightTurquoise200};
        color: ${s.scarpaGray800};
      }
      
      :active {
        background: ${s.brightTurquoise600};;
        color: ${s.white};
      }
      
      :disabled {
        background: ${s.scarpaGray600};
        color: ${s.scarpaGray300};
        cursor: default;
      }
    }

  }

  &.secondary {
    border-radius: 100px;
    letter-spacing: 0.04em;
    padding: 15px 24px;

    &.light {
      background-color: ${s.white};
      border: 1px solid ${s.scarpaGray100};
      color: ${s.electricPurple500};

      :hover {
        background-color: ${s.electricPurple500};
        color: ${s.white};
      }

      :active {
        background: ${s.electricPurple500};
        color: ${s.white};
      }
      
      :disabled {
        border: 1px solid ${s.scarpaGray200};
        background: ${s.scarpaGray000};
        color: ${s.scarpaGray200};
        cursor: default;
      }
    }

    &.dark {
      background: transparent;
      border: 1px solid ${s.scarpaGray000};
      color: ${s.scarpaGray000};

      :hover {
        background: ${s.white};
        border: 1px solid ${s.white};
        color: ${s.scarpaGray600};
      }

      :active {
        background: ${s.scarpaGray800};
        border: 1px solid ${s.scarpaGray800};
        color: ${s.scarpaGray000};
      }
      
      :disabled {
        background: transparent;
        border: 1px solid ${s.scarpaGray500};
        color: ${s.scarpaGray500};
        cursor: default;
      }
    }
    
  }

  &.text {
    border: 0;
    border-radius: 0;
    font-weight: bold;
    letter-spacing: 0.03em;
    background: transparent;
    padding: 0 !important;
    
    &.light {
      border-bottom: 1px dashed ${s.brightTurquoise600};
      color: ${s.brightTurquoise600};
      
      :hover {
        border-bottom: 1px solid ${s.brightTurquoise700};
        color: ${s.brightTurquoise700};
      }

      :active {
        border-bottom: 1px solid ${s.brightTurquoise500};;
        color: ${s.brightTurquoise500};;
      }
      
      :disabled {
        border-bottom: 1px dashed ${s.scarpaGray200};
        color: ${s.scarpaGray200};
        cursor: default;
      }
    }

    &.dark {
      border-bottom: 1px dashed ${s.bogotaBlue300};
      color: ${s.bogotaBlue300};
      
      :hover {
        border-bottom: 1px solid ${s.bogotaBlue300};
      }

      :active {
        border-bottom: 1px solid ${s.bogotaBlue500};
        color: ${s.bogotaBlue500};
      }
      
      :disabled {
        border-bottom: 1px dashed ${s.scarpaGray500};
        color: ${s.scarpaGray500};
        cursor: default;
      }
    }
  }

  &.icon {
    padding: 0 !important;
  }

  &.mini {
    font-size: 10px;
    padding: 4px 8px;

    &.icon {
      width: 38px;
      height: 38px;
    }
  }

  &.small {
    font-size: 12px;
    padding: 10px 24px;

    &.icon {
      width: 38px;
      height: 38px;
    }
  }

  &.middle {
    font-size: 14px;
    padding: 13px 24px;

    &.icon {
      width: 48px;
      height: 48px;
    }
  }

  &.large {
    font-size: 16px;
    padding: 15px 24px;

    &.icon {
      width: 55px;
      height: 55px;
    }
  }

  &.full-width {
    width: 100%;
  }
`;