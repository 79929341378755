import {
  getDocumentsProcess,
  getEventsProcess,
} from '../../apis/Process/processApis';
import { BUSINESS_TYPE, HTTP_STATUS, PROCESS } from '../../utils/constant';

export const getTitleStudyAndDraftsData = (nid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const documents = await getDocumentsProcess({
        nid,
        businessType: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.OTHER_BANKS_TITLE_STUDY,
        sections: true,
      });
      const events = await getEventsProcess({
        nid,
        businessType: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.OTHER_BANKS_TITLE_STUDY,
      });
      resolve({
        documents: documents.data.docs,
        events: events.data.data.events,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          documents: [],
          events: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
