import { BUSINESS_TYPE } from '../../utils/constant';
import API from '../Api';

export const getFormBuyersFolderApi = ({nid}) => {
    return API.get(
      process.env.REACT_APP_GET_PUBLICATION_DATA,
      {
        params: {
          nid
        }
      }
    );
  };
  
export const getDataListingWebApi = ({status, page, page_elements}) => {
    return API.get(
      process.env.REACT_APP_GET_DATA_LISTING_WEB,
      {
        params: {
          status, 
          page, 
          page_elements
        }
      }
    );
  };

export const postBussinesPublication = async ({nid, business_type_id, origin_url, data}) => {
    return API.post(
      process.env.REACT_APP_POST_PUBLICATION_DATA,
      {
        nid,
        business_type_id,
        origin_url,
        ...data 
      }
    );
  };

export const postListingWeb = async ({nid, status, new_status}) => {
  return API.post(
    process.env.REACT_APP_POST_LISTING_WEB_DATA,
    {
      nid,
      status,
      new_status
    }
  );
};

export const getHighLevelApi = ({ page, pageElements, filters, tab }) => {
  return API.get(
    process.env.REACT_APP_GET_HIGH_LEVEL_DATA,
    {
      params: {
        page,
        page_elements: pageElements,
        nid: filters.nid === '' ? null : filters.nid,
        analyst: filters.analyst === '' ? null : filters.analyst,
        financial_entity: filters.financial_entity === '' ? null : filters.financial_entity,
        business_type_id: BUSINESS_TYPE.BUYERS,
        tab
      }
    }
  );
};

export const getHighLevelItemApi = ({ nid, tab }) => {
  return API.get(
    process.env.REACT_APP_GET_HIGH_LEVEL_ITEM,
    {
      params: {
        nid,
        tab,
        business_type_id: BUSINESS_TYPE.BUYERS
      }
    }
  );
};

export const putHighLevelItemApi = async (data) => {
  return API.put(
    process.env.REACT_APP_PUT_HIGH_LEVEL_DATA, data
  );
};
