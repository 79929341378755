import {
  getHighLevelApi,
  getHighLevelItemApi,
} from '../../apis/Buyers/buyersApis';
import { ELEMENTS_PAGER_HIGH_LEVEL, HTTP_STATUS } from '../../utils/constant';

export const getHighLevelBuyersData = ({ page, filters, tab }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getHighLevelApi({
        page,
        pageElements: ELEMENTS_PAGER_HIGH_LEVEL,
        filters,
        tab,
      });
      resolve(data.data);
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          data: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};

export const getHighLevelBuyersDetailData = ({ nid, tab }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getHighLevelItemApi({ nid, tab });
      resolve(data.data);
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          data: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
