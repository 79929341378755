import styled from 'styled-components';

export const AppraisalAppointmentWrapper = styled.div`
  .margin-bottom {
    margin-bottom: 36px;
  }
  .optional-document-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, max(180px));
    grid-column-gap: 32px;
    &.row-gap {
      grid-row-gap: 32px;
    }
  }
  .grid-buttons-container {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 282px 282px;
    &.check-buttons {
      grid-template-columns: 212px 250px;
      grid-column-gap: 32px;
    }
  }
`;
