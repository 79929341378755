import axios from 'axios';

const apikey = process.env.REACT_APP_ENDPOINT_HABI_PROCEDURE_API_KEY;
const rootUrl =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_HABI_API_PROCEDURE_BASE_PATH;

export default axios.create({
  baseURL: rootUrl,
  responseType: "json",
  headers: {
    "x-api-key": apikey,
    "Content-Type": "application/json",
  },
});