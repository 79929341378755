import {
  getDocumentsProcess,
  getEventsProcess,
  getHistoryComments,
} from '../../apis/Process/processApis';
import { HTTP_STATUS, PROCESS } from '../../utils/constant';

export const notarialPaymentsDocumentsData = ({
  nid,
  businessType,
  processName,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const events = await getEventsProcess({
        nid,
        businessTypeId: businessType,
        processName,
      });
      const comments = await getHistoryComments({
        nid,
        processName: PROCESS.NOTARIAL_PAYMENT_DOCUMENTS,
      });
      const documents = await getDocumentsProcess({
        nid,
        businessType,
        processName,
        sections: false,
      });
      resolve({
        documents: documents.data.docs,
        events: events.data.data.events,
        comments: comments.data.history,
        error_comment_list: comments.data.error_list,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: {},
          documents: [],
          events: [],
          comments: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
