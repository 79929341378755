import React, { useState } from 'react';
import {
  // FastField,
  Field as FormikField,
} from 'formik';
import TextInput from '@habi/habi-react-components/dist/TextInput/textInput';
import SelectComponent from '@habi/habi-react-components/dist/Select/select';
import CurrencyInput from '@habi/habi-react-components/dist/CurrencyInput/currencyInput';

import { DOCUMENT_STATUS, FIELD_TYPE } from '../../../utils/constant';
import CustomDatePicker from '../../shared/CustomDatepicker/CustomDatepicker';
import { FieldWrapper } from './style';
import FieldComment from '../FieldComment/fieldComment';
import RadioOption from '../../RadioOption/radioOption';
import ButtonCheck from '../../ButtonCheck/buttonCheck';
import CustomDateTimePicker from '../../shared/CustomDateTimePicker/CustomDateTimePicker';

const Field = ({ field, formikProps, disabled, handleSaveComment, hidden }) => {
  let { name, id, label, fixed, multiple_values, value, have_flag_review } =
    field;

  let {
    errors,
    touched,
    values,
    setFieldError,
    setFieldValue,
    handleChange,
    setFieldTouched,
  } = formikProps;

  const [haveBackdrop, setHaveBackdrop] = useState(false);
  const [comment, setComment] = useState(field.comment);

  const getInputNumber = () => (
    <TextInput
      name={name}
      errors={errors}
      touched={touched}
      id={id}
      label={label}
      type={'number'}
      className="input-filter"
      values={values}
      disabled={fixed || disabled}
      staticLabel
    />
  );

  const getInputDate = () => (
    <CustomDatePicker
      label={label}
      name={name}
      id={id}
      errors={errors}
      values={values}
      setFieldError={setFieldError}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      disabled={fixed || disabled}
    />
  );

  const getInputDateTime = () => (
    <CustomDateTimePicker
      name={name}
      label={label}
      id={id}
      errors={errors}
      values={values}
      setFieldError={setFieldError}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      disabled={fixed || disabled}
    />
  );

  const getSelect = () => {
    const selected = { value: '', label: 'Seleccione' };

    let defaultSelect = multiple_values.options.find(
      (option) => option.value === value
    );

    if (!defaultSelect) {
      defaultSelect = selected;
    }

    const newValues = [selected, ...multiple_values.options];
    return (
      <FormikField
        values={values}
        default={defaultSelect}
        options={newValues}
        component={SelectComponent}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        name={name}
        id={id}
        isClearable={false}
        handleChange={handleChange}
        label={label}
        noBorder
        disabled={fixed || disabled}
      />
    );
  };

  const getInput = () => (
    <TextInput
      id={id}
      name={name}
      label={label}
      className="input-filter"
      errors={errors}
      touched={touched}
      values={values}
      type={'text'}
      disabled={fixed || disabled}
      staticLabel
    />
  );

  const getInputCurrency = () => (
    <CurrencyInput
      id={id}
      name={name}
      label={label}
      className="input-filter"
      errors={errors}
      touched={touched}
      values={values}
      disabled={fixed || disabled}
      staticLabel
      setFieldValue={setFieldValue}
      maxLength={16}
    />
  );

  const getRadioOptionRounded = () => {
    const options = [
      { label: 'Si', value: 'si' },
      { label: 'No', value: 'no' },
    ];
    return (
      <div className={`radio-buttons-container ${field.type_field}`}>
        <label htmlFor={name} className="radio-button-label">
          {label}
        </label>
        {field.multiple_values && (
          <div className="radio-buttons">
            {options.map((option) => (
              <RadioOption
                key={name + option.value}
                name={name}
                label={option.label}
                value={option.value}
                selected={values[name] === option.value}
                setValue={() => {
                  setFieldValue(name, option.value);
                }}
                round={true}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const getFieldStatus = (value) => {
    if (!field.value) return '';
    const defaultValues = JSON.parse(JSON.stringify(field.value));
    const valueFound = defaultValues.includes(value);
    if (valueFound) {
      return DOCUMENT_STATUS.SUCCESS;
    } else {
      return '';
    }
  };

  const getCheckboxRounded = () => {
    return field.multiple_values.options.map((button) => (
      <div className="checkbox-rounded">
        <ButtonCheck
          label={button.label}
          status={getFieldStatus(button.value)}
          handleChange={() => {
            values[button.value] = button.value;
          }}
        />
      </div>
    ));
  };

  const getRadioOptionBlock = (options) => {
    return (
      <div className={`radio-buttons-container ${field.type_field}`}>
        <div className="radio-buttons">
          {options.map((option) => (
            <RadioOption
              key={name + option.value}
              name={name}
              label={option.label}
              value={option.value}
              selected={values[name] === option.value}
              setValue={() => {
                setFieldValue(name, option.value);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const showHideComment = (show) => {
    setHaveBackdrop(show);
  };

  const saveComment = (comment) => {
    setComment(comment);
    handleSaveComment(field.id, comment);
  };

  return (
    <FieldWrapper haveBackdrop={haveBackdrop}>
      {!hidden && (
        <div className={haveBackdrop ? 'over-backdrop' : ''}>
          {field.type_field === FIELD_TYPE.INPUT && (
            <FormikField name={name}>{() => getInput()}</FormikField>
          )}
          {(field.type_field === FIELD_TYPE.INPUT_NUMBER || field.type_field === FIELD_TYPE.INPUT_DECIMAL) &&
            <FormikField name={name}>{() => getInputNumber()}</FormikField>
          }
          {field.type_field === FIELD_TYPE.RADIO_OPTION_BLOCK &&
            getRadioOptionBlock(field.multiple_values.options)}
          {field.type_field === FIELD_TYPE.SUB_SECTION &&
            getRadioOptionRounded()}
          {field.type_field === FIELD_TYPE.RADIO_OPTION_ROUNDED &&
            getRadioOptionRounded()}
          {field.type_field === FIELD_TYPE.CHECKBOX_ROUNDED &&
            getCheckboxRounded()}
          {field.type_field === FIELD_TYPE.SELECT && !hidden && getSelect()}
          {field.type_field === FIELD_TYPE.INPUT_CURRENCY && getInputCurrency()}
          {field.type_field === FIELD_TYPE.INPUT_DATE && getInputDate()}
          {field.type_field === FIELD_TYPE.INPUT_DATE_TIME && getInputDateTime()}
        </div>
      )}
      {have_flag_review && (
        <FieldComment
          comment={comment}
          handleShowHide={showHideComment}
          handleSaveComment={saveComment}
        />
      )}
    </FieldWrapper>
  );
};

export default Field;
