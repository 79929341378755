import API from '../Api';

export const getFormData = (nid, processName, businessTypeId) => {
  return API.get(
    process.env.REACT_APP_GET_PROPERTY_FORM_DATA_PROCESS,
    {
      params: { 
        nid,
        process_name: processName,
        business_type_id: businessTypeId
      }
    }
  );
};

export const getFormDataDependencies = (nid, processName, businessTypeId) => {
  return API.get(process.env.REACT_APP_GET_FORM_DATA, {
    params: {
      nid,
      process_name: processName,
      business_type_id: businessTypeId,
    },
  });
};

export const updateFormData = (data) => {
  return API.put(
    process.env.REACT_APP_UPDATE_PROPERTY_SELLERS,
    data
  );
};

export const sendFlagComment = ({ nid, processName, originUrl, businessType, comment, fieldId, agent }) => {
  return API.post(
    process.env.REACT_APP_POST_FLAG_COMMENT,
    {
      nid: parseInt(nid),
      process_name: processName,
      origin_url: originUrl,
      business_type_id: businessType,
      comment,
      type_field_procedure_id: fieldId,
      agent
    }
  );
};
