import { format } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';

import { Title } from '../../components/Styles/genericStyleComponent';
import { CommentHistoryWrapper } from './style';
import { DOCUMENT_STATUS } from '../../utils/constant';

const CommentHistory = ({ history, title }) => {

  const getComment = (comment, index) => {
    return (
      <div className="dot-line" key={`comment${index}`}>
        <div className={`bullet ${comment.status === DOCUMENT_STATUS.REJECTED? 'error': 'success'}`} />
        <div className="role">
          {comment.role.toUpperCase().replace(/_/g, ' ')}
        </div>
        <div className="comment">
          { 
            comment.comment? comment.comment: 'Revisión'
          }
        </div>
        { 
          comment.url &&
          <div className="download-link">
            <a 
              className='btn'
              href={comment.url}
              rel="noreferrer"
              target="_blank"
            >
              Descargar documento
            </a>
          </div>
        }
        <div className="date">
          {format(new Date(comment.update_date), 'dd/MM/yyyy')}
        </div>
        <div className="hour">
          {format(new Date(comment.update_date), 'h:m a')}
        </div>
      </div>
    );
  }

  return (
    <CommentHistoryWrapper emptyHistory={history?.length === 0}>
      {title && <Title className="title">{title}</Title>}
      <div className="dot-line" />
      {history?.map((comment, index) => getComment(comment, index))}
    </CommentHistoryWrapper>
  );
}

CommentHistory.propTypes = {
  history: PropTypes.array.isRequired
}

export default CommentHistory
