import React from 'react';
import { SubTitleMontserrat } from '../../../../../../components/Styles/genericStyleComponent';
import { ResponsibleDetailWrapper } from './style';
import IconDoubleUserAdd from '../../../../../../static/assets/img/icons/icon-double-user-add.svg';

export const ResponsibleDetail = ({ assessor, analystSr, analystJr }) => {
  const getUserStyle = (user) => {
    return user ? '' : 'empty';
  };

  return (
    <>
      <SubTitleMontserrat>Responsables</SubTitleMontserrat>
      <ResponsibleDetailWrapper>
        <div className="icon-container">
          <img src={IconDoubleUserAdd} alt="user-icon" />
        </div>
        <div className="user-grid">
          <div className={`user-container ${getUserStyle(assessor)}`}>
            <div className="vertical-bar" />
            <div className="user-content">
              <span className="rol">Asesor comercial</span>
              <span className={`user ${getUserStyle(assessor)}`}>
                {assessor || 'Sin asignar'}
              </span>
            </div>
          </div>
          <div className={`user-container ${getUserStyle(analystSr)}`}>
            <div className="vertical-bar" />
            <div className="user-content">
              <span className="rol">Analista de trámites Sr.</span>
              <span className={`user ${getUserStyle(analystSr)}`}>
                {analystSr || 'Sin asignar'}
              </span>
            </div>
          </div>
          <div className={`user-container ${getUserStyle(analystJr)}`}>
            <div className="vertical-bar" />
            <div className="user-content">
              <span className="rol">Analista de trámites Jr.</span>
              <span className={`user ${getUserStyle(analystJr)}`}>
                {analystJr || 'Sin asignar'}
              </span>
            </div>
          </div>
        </div>
      </ResponsibleDetailWrapper>
    </>
  );
};
