import React from "react";
import { hydrate, render } from "react-dom";
import "./index.scss";
import App from "./App.jsx";
import WebFont from "webfontloader";
import "./static/assets/fonts/Archia-Regular.otf";
import 'react-notifications-component/dist/theme.css';
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

WebFont.load({
  custom: {
    families: ["Open Sans", "sans-serif", "Muli"],
  },
});

const pathInitial = window.location.pathname;
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <App pathInitial={pathInitial} />
    </Provider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
