import { getBusinessDataApi } from '../../apis/Common/commonApis';

export const genericDataService = ({ nid }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let genericData;
      genericData = await getBusinessDataApi(nid);
      resolve(genericData.data);
    } catch (error) {
      console.error(error);
      reject({});
    }
  });
};
