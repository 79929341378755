import styled from 'styled-components';
import { hintPerano100, openSansRegular, primary, scarpaGray200, scarpaGray500 } from '../../StylesConstants';

export const PagerWrapper = styled.div`
  
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 9px;
  color: ${scarpaGray500};
  font-family: ${openSansRegular};
  flex-wrap: wrap;

  .page {
    padding: 4px 8px;

    &.active {
      color: ${primary};
      background-color: ${hintPerano100};
      border-radius: 3px;
      cursor: default;
    }

    &:hover {
      color: ${primary};
      cursor: pointer;
    }
  }

  .page:hover {
    color: ${primary};
    cursor: pointer;
  }


  .control {
    font-size: 15px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    user-select: none;

    &.active {
      cursor: pointer;
    }

    &.active:hover {
      color: ${primary};
    }

    &.disabled {
      color: ${scarpaGray200};
      cursor: default;
    }
  }
`;