import React from 'react';
import { DownloadDocumentWrapper } from './style';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const DownloadDocument = ({ label, url }) => {
  return (
    <DownloadDocumentWrapper>
      <a href={url} target="_blank" rel="noreferrer" download>
        <i className="arrow-icon">
          <ArrowDownwardIcon />
        </i>
        <span className="label">{label}</span>
      </a>
    </DownloadDocumentWrapper>
  );
};

export default DownloadDocument;
