import styled from "styled-components";
import { informationPrimary, muliBoldFamily, nightBlue300, openSansRegular, scarpaGray200 } from "../../../../StylesConstants";
import { DOCUMENT_TYPE } from "../../../../utils/constant";

export const ActivityWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: 14px;
  align-items: ${ p => p.format === DOCUMENT_TYPE? 'center': null };
  min-height: 42px;
  align-items: center;

  .name {
    margin-left: 18px;
    font-family: ${ openSansRegular };
    font-size: 18px;
    flex: auto;
  }

  .owner {
    font-family: ${ muliBoldFamily };
    font-size: 16px;
    color: ${ scarpaGray200 };
    margin: 0 18px;
  }

  .description {
    font-size: 13px;
    color: ${ nightBlue300 };
    line-height: 24px;
  }

  .optional {
    color: ${ informationPrimary };
    font-size: 13px;
    line-height: 24px;
  }

  .document-container {
    width: 270px;
    height: 90px;
  }

  .date {
    width: 120px;
  }
`;