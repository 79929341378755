import styled from "styled-components";
import { white } from "../../StylesConstants";

export const RightSectionWrapper = styled.div`
  background-color: ${white};
  width: 360px;
  position: fixed;
  right: 0;
  height: 100%;
  overflow: auto;
`;