import { combineReducers } from "redux";

import loginData from "./reducers/loginData";
import propertyData from "./reducers/propertyData";
import registrationTicketData from "./reducers/registrationTicketData";
import mortgageRegistrationTicketData from './reducers/mortgageRegistrationTicketData';
import documentReviewData from './reducers/documentReviewData';
import configurationData from './reducers/configurationData';

export const rootReducer = combineReducers({
  loginData,
  propertyData,
  registrationTicketData,
  mortgageRegistrationTicketData,
  documentReviewData,
  configurationData,
});
