import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import {
  getDocumentsProcess,
  getEventsProcess,
  getHistoryComments,
} from '../../apis/Process/processApis';
import { BUSINESS_TYPE, HTTP_STATUS, PROCESS } from '../../utils/constant';

export const getDraftApprovalRequestData = (
  nid,
  processName,
  businessTypeId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Promise.all([
        getFormDataDependencies(nid, processName, BUSINESS_TYPE.SELLERS),
        getDocumentsProcess({
          nid,
          businessType: businessTypeId,
          processName,
          sections: false,
        }),
        getEventsProcess({ nid, businessTypeId, processName }),
        getHistoryComments({ nid, processName: PROCESS.MORTGAGE }),
      ]);
      resolve({
        form: result[0].data.data,
        documents: result[1].data.docs,
        events: result[2].data.data.events,
        comments: result[3].data.history ?? [],
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: {},
          documents: [],
          events: [],
          comments: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
