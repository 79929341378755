import React from 'react';
import { NotificationsListWrapper } from './style';

import PropTypes from 'prop-types';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

import { NOTIFICATION_STATE } from '../../../../utils/constant';
import Button from '../../../../components/shared/button/button';

const NotificationsList = ({ notifications, updateNotification, setCurrentNotification, loadLink }) => {

  const getStar = (notification) => {
    return notification.state === NOTIFICATION_STATE.IMPORTANT
      ? <div
        data-id={`btn-star-${notification.id}`}
        className='important'
        onClick={() => updateNotification(notification, NOTIFICATION_STATE.NEW)}
      >
        <StarIcon />
      </div>
      : <div
        data-id={`btn-star-${notification.id}`}
        className='new'
        onClick={() => updateNotification(notification, NOTIFICATION_STATE.IMPORTANT)}
      >
        <StarOutlineIcon />
      </div>
  }

  return (
    <NotificationsListWrapper>
      <table>
        <thead>
          {
            notifications.length > 0 &&
            <tr>
              <th className='star' />
              <th>Descripción</th>
              <th>Fecha</th>
              <th>Link portal</th>
              <th />
            </tr>
          }
        </thead>
        <tbody>
          {
            notifications.map(notification =>
              <tr key={`notification${notification.id}`}>
                <td className="star">
                  {getStar(notification)}
                </td>
                <td
                  className="description"
                  onClick={() => setCurrentNotification(notification)}
                >
                  {notification.description}
                </td>
                <td>{notification.date}</td>
                <td>
                  <span
                    onClick={() => loadLink(notification.link_url)}
                    className='link'
                  >
                    Link sección
                  </span>
                </td>
                <td>
                  <Button
                    dataId={`btn-end-notification-${notification.id}`}
                    size="mini"
                    onClick={() => updateNotification(notification, NOTIFICATION_STATE.FINISHED)}
                  >
                    Finalizar
                  </Button>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      {
        notifications.length === 0 &&
        <div className='empty-notifications'>
          <span>
            No tienes notificaciones aún
          </span>
        </div>
      }
    </NotificationsListWrapper >
  )
}

NotificationsList.propTypes = {
  notifications: PropTypes.array,
  updateNotification: PropTypes.func
}

export default NotificationsList;