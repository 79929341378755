import styled from 'styled-components';

export const IconWrap = styled.div`
  align-self: center;
  width: 36px;

  img {
    cursor: pointer;
  }
`;
