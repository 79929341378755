import React from 'react';

import NotFound from '../../pages/404/notFound';
import { useSelector } from 'react-redux';

const EmptyData = (props, { message }) => {
  const noData = useSelector((state) => state.propertyData.noData);

  return <>
    {
      noData ? <NotFound message={message} /> : props.children
    }
  </>;
};

export default EmptyData;
