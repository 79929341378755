import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import Button from '../../../../components/shared/button/button';
import { HighLevelDetailWrapper, SectionClose, SectionTitle } from './style';
import useDynamicForm from '../../../../hooks/useDynamicForm';
import FieldRow from '../../../../components/DynamicForm/FieldRow/FieldRow';
import { DEPENDENCY_CONDITION, FIELD_TYPE } from '../../../../utils/constant';
import { useSelector } from 'react-redux';

const HighLevelDetail = ({
  nid,
  data,
  requestSaveDetailData,
  setIsDetailVisible,
}) => {
  const { role } = useSelector((state) => state.loginData.data);

  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave,
    ,
    changeVisibleSection,
  ] = useDynamicForm(data);

  const getFieldValue = (field) => {
    if (field.type_field === FIELD_TYPE.INPUT_DATE) {
      try {
        return field.value ? format(new Date(field.value), 'dd-MM-yyyy') : '';
      } catch {
        return field.value;
      }
    } else if (field.type_field === FIELD_TYPE.INPUT_DATE_TIME) {
      try {
        return field.value
          ? format(new Date(field.value), 'dd-MM-yyyy HH:mm')
          : '';
      } catch {
        return field.value;
      }
    } else if (field.type_field === FIELD_TYPE.SELECT) {
      const optionField = field.multiple_values.options.find(
        (option) => option.value === field.value
      );
      return optionField ? optionField.label : field.value;
    } else {
      return field.value;
    }
  };

  const isAllowEditRole = (authorizedRole) => {
    let isAllow = false;
    role.forEach((userRole) => {
      if (
        authorizedRole &&
        authorizedRole.some((authRole) => authRole === userRole) &&
        !isAllow
      ) {
        isAllow = true;
      }
    });
    return isAllow;
  };

  const isAllowEditDependencies = (field, props) => {
    let isAllow = true;
    if (field && field.dependencies) {
      field.dependencies.forEach((dependency) => {
        if (
          isAllow &&
          dependency.condition === DEPENDENCY_CONDITION.NOT_EMPTY &&
          !props.values[dependency.on_field]
        ) {
          isAllow = false;
        }
      });
    }
    return isAllow;
  };

  const getField = (section, field, props) => {
    if (
      !field.fixed &&
      isAllowEditRole(section.role_edit) &&
      isAllowEditDependencies(field, props)
    ) {
      return <FieldRow field={field} formikProps={props} />;
    } else {
      return getFieldValue(field);
    }
  };

  const saveDetail = (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      let data = [];
      finalValues.forEach((field) => {
        data[field.field_name] = field.value;
      });

      requestSaveDetailData({ nid, data });
      setIsDetailVisible(false);
    }
  };

  return (
    <HighLevelDetailWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={saveDetail}
      >
        {(props) => (
          <Form className="form-container">
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    {sections.map((section, index) => (
                      <SectionTitle
                        color={section.color}
                        key={`sectionTitle${index}`}
                      >
                        {section.label}
                      </SectionTitle>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {sections.map((section, index) => (
                      <td className="section-container" key={`section${index}`}>
                        {!section.visible && (
                          <div className="hide-section-container">
                            <Button
                              size="mini"
                              onClick={() => changeVisibleSection(index, true)}
                              typeClass="secondary"
                              type="button"
                            >
                              Ver
                            </Button>
                          </div>
                        )}
                        {section.visible && (
                          <div className="show-section-container">
                            <table>
                              <thead>
                                <tr>
                                  {section.fields.map((field, indexField) => (
                                    <th key={`titleField${indexField}`}>
                                      {field.label}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {section.fields.map((field, indexField) => (
                                    <td
                                      key={`field${indexField}`}
                                      className="data-container"
                                    >
                                      {getField(section, field, props)}
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            </table>
                            <SectionClose
                              color={section.color}
                              onClick={() => changeVisibleSection(index, false)}
                            >
                              <ChevronLeftIcon />
                            </SectionClose>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <Button size="mini" type="submit">
              Guardar
            </Button>
          </Form>
        )}
      </Formik>
    </HighLevelDetailWrapper>
  );
};

export default HighLevelDetail;
