import React from 'react';
import PropTypes from 'prop-types';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

import { NotificationDetailWrapper } from './style';
import { NOTIFICATION_STATE } from '../../../../utils/constant';

const NotificationDetail = ({ notification, setCurrentNotification, updateNotification, loadLink }) => {

  const getStar = () => {
    return notification.state === NOTIFICATION_STATE.IMPORTANT
      ? <div
        data-id={`btn-star-${notification.id}`}
        className='star important'
        onClick={() => updateNotification(notification, NOTIFICATION_STATE.NEW)}
      >
        <StarIcon />
      </div>
      : <div
        data-id={`btn-star-${notification.id}`}
        className='star new'
        onClick={() => updateNotification(notification, NOTIFICATION_STATE.IMPORTANT)}
      >
        <StarOutlineIcon />
      </div>
  }

  return (
    <NotificationDetailWrapper>
      <div className='header-actions'>
        <KeyboardBackspaceIcon
          className='btn-back'
          data-id='btn-back'
          onClick={() => setCurrentNotification(null)}
        />
        {getStar()}
      </div>
      <h3>
        {notification.description}
      </h3>
      <p dangerouslySetInnerHTML={{ __html: notification.body }} />
      <div className='footer-info'>
        <span
          onClick={() => loadLink(notification.link_url)}
          className='link'
        >
          Link sección
        </span>
        <span className='date'>
          {notification.date}
        </span>
      </div>
    </NotificationDetailWrapper>
  )
}

NotificationDetail.propTypes = {
  notification: PropTypes.object.isRequired,
  updateNotification: PropTypes.func.isRequired,
  setCurrentNotification: PropTypes.func.isRequired
}

export default NotificationDetail;
