import React from 'react';
import RowDocumentControl from './components/RowDocumentControl/rowDocumentControl';

import { SupplierDocumentControlWrapper } from './style';

const SupplierDocumentControl = ({ data, externalUsers, handleUpdate, processName, handleUploadDocument, handleDeleteDocument, currentTab }) => {
  return (
    <SupplierDocumentControlWrapper>
      <table>
        <thead>
          <tr>
            <th> NID </th>
            <th> Folio de matrícula </th>
            <th> Fecha de solicitud </th>
            <th> Fecha de recep. esperada </th>
            <th> Persona encargada </th>
            <th> Documentos habi </th>
            <th> Fecha de entrega </th>
            <th> Carga de documento </th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((control, index) => (
              <RowDocumentControl
                key={`control${ index }`}
                control={ control }
                externalUsers={ externalUsers }
                handleUpdate={ handleUpdate }
                processName={ processName }
                handleUploadDocument={ handleUploadDocument }
                handleDeleteDocument={ handleDeleteDocument }
                currentTab={ currentTab }
              />
            ))
          }
        </tbody>
      </table>
    </SupplierDocumentControlWrapper>
  )
}

export default SupplierDocumentControl
