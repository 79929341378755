import styled from "styled-components";

export const BuyersFormWrapper = styled.div`
  .form-container {
    display: grid;
    grid-template-columns: 240px 240px;
    grid-column-gap: 40px;
    grid-row-gap: 32px;
    margin-bottom: 32px;
  }
`;
    
