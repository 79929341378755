import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getDataListingWebApi, postListingWeb } from '../../apis/Buyers/buyersApis';
import EmptyData from '../../components/EmptyData/emptyData'
import GenericTable from '../../components/GenericTable/GenericTable';
import TabsHeader from '../../components/shared/TabsHeader/tabsHeader';
import { Container, Title } from '../../components/Styles/genericStyleComponent';
import { hideLoading, resetPropertyData, showLoading } from '../../redux/actions/propertyData';
import {
  ELEMENTS_PAGER,
  STATUS_LISTING_WEB_PROCESS,
} from '../../utils/constant';
import Pager from '../../components/Pager/pager';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import { genericTableTitles } from '../../utils/dynamic-constant';
import { LISTING_WEB } from '../../components/GenericTable/constants/tabs';

const ListingWeb = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);
  const [currentTab, setCurrentTab] = useState(
    STATUS_LISTING_WEB_PROCESS.REQUESTED
  );
  const dispatch = useDispatch();

  const getData = useCallback(
    async ({ page }) => {
      setActivePage(page ? page : 1);
      try {
        dispatch(showLoading());
        let { data } = await getDataListingWebApi({
          status: currentTab,
          page,
          page_elements: ELEMENTS_PAGER,
        });
        setData(data.data);
        setNumberPages(data.pages);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(hideLoading());
      }
    },
    [currentTab, dispatch]
  );

  useEffect(() => {
    dispatch(resetPropertyData());
    getData({ page: 1 });
  }, [getData, dispatch]);

  const handleUpdateSelect = async (value, nid) => {
    dispatch(showLoading());
    try {
      await postListingWeb({
        nid,
        status: currentTab,
        new_status: value,
      });
      setData(data.filter((control) => control.nid !== nid));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleUpdateButton = async (nid) => {
    dispatch(showLoading());
    try {
      await postListingWeb({
        nid,
        status: currentTab,
      });
      setData(data.filter((control) => control.nid !== nid));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <EmptyData>
      <Container>
        <LifeCycle miniLifeCycle />
        <Title>Listing Web</Title>
        <TabsHeader
          tabs={LISTING_WEB}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <GenericTable
          data={data}
          titleColumns={genericTableTitles[currentTab]}
          currentTab={currentTab}
          handleUpdateButton={handleUpdateButton}
          handleUpdateSelect={handleUpdateSelect}
        />
        {data.length > 0 && (
          <Pager
            numPages={numberPages}
            activePage={activePage}
            onChange={getData}
          />
        )}
      </Container>
    </EmptyData>
  );
};

export default ListingWeb;