import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import { ActivityWrapper } from "./style";
import RadioCheck from "../../../../components/RadioCheck/radioCheck";
import { 
  setRegistrationTicket, 
  updateActivityDate, 
  updateSubActivityDate 
} from "../../../../redux/actions/registrationTicket";
import { setNewActivitiesOnChange } from "../../../../apis/RegistrationTicket/registrationTicketService";
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import { 
  ACTIVITY_STATUS, 
  BUSINESS_TYPE, 
  DOCUMENT_WRAPPER_TYPE, 
  DOCUMENT_TYPE, 
  SUB_ACTIVITY_TYPE 
} from '../../../../utils/constant';
import DatePicker from '../../../../components/shared/CustomDatepicker/datePicker/DatePicker';

const Activity = ({
  saveRegistrationTicket,
  id,
  name,
  label,
  status,
  owner,
  description,
  optional,
  type,
  format,
  url,
  stageId,
  parentId,
  updateDate,
  date=null,
  subActivity=false,
  ...props
}) => {

  const { 
    propertyData, 
    loginData, 
    registrationTicketData 
  } = useSelector( state => state );
  const dispatch = useDispatch();

  const getOwner = (owner) => {
    return owner && <div className="owner">{owner}</div>;
  };

  const getOptional = () => {
    return optional && <div className="optional">Opcional</div>;
  };

  const getActivity = () => {
    if( format === DOCUMENT_TYPE) {
      let document = {}
      if(props.document ){
        document = props.document
      } else {
        document = { 
          exist: props.exist, 
          label, 
          url, 
          id : props.id_document,
          document_type_id: props.document_type_id,
          update_date: updateDate
        }
      }
      return (
        <div className="document-container">
          <DocumentComponent 
            documentParam={document}
            propertyData={propertyData}
            userData={loginData.data}
            handleUpload={updateRegistrationTicket}
            wrapperType={DOCUMENT_WRAPPER_TYPE.DOT_LINE}
            businessTypeId={BUSINESS_TYPE.SELLERS}
          />
        </div>
      ); 
    } else {
      return (
        <>
          {label}
          {getOptional()}
          <div className="description">
            {description}
          </div>
        </>
      )
    }
  };

  const updateRegistrationTicket = (status, document) => {
    if (status === ACTIVITY_STATUS.DONE) {
      const dataChanged = {
        type,
        id,
        stageId,
        activityId: type === SUB_ACTIVITY_TYPE ? parentId : null,
        document
      };
      
      const newStorage = setNewActivitiesOnChange(
        registrationTicketData,
        dataChanged
      );
     dispatch(setRegistrationTicket(newStorage));

      if(document){
        saveRegistrationTicket();
      }

    }
  };

  const changeDate = (date) => {
    subActivity
      ? dispatch(updateSubActivityDate(id, date))
      : dispatch(updateActivityDate(id, date));
  }

  return (
    <ActivityWrapper type={format}>
      <RadioCheck 
        status={status}
        setValue={updateRegistrationTicket} 
      />
      <div className="name">
        {getActivity()}
      </div>
      {getOwner(owner)}
      <div className="date">
        <DatePicker
          name={`dateActivity${id}`}
          value={date? moment(date).format(): date} 
          format="yyyy-MM-dd"
          disabled={status !== ACTIVITY_STATUS.DONE}
          onChange={changeDate}
        />
      </div>
    </ActivityWrapper>
  );
};

export default (Activity);
