import React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router";

import { updateNotificationApi } from '../../../../apis/Notifications/notificationsApis';
import TabsHeader from '../../../../components/shared/TabsHeader/tabsHeader';
import { Title } from '../../../../components/Styles/genericStyleComponent';
import { hideLoading, showLoading, updatePropertyData } from '../../../../redux/actions/propertyData';
import { getNotificationsData } from '../../../../services/data/notificationsData';
import { NOTIFICATION_STATE } from '../../../../utils/constant';
import NotificationDetail from '../NotificationDetail/NotificationDetail';
import NotificationsList from '../NotificationsList/NotificationsList';
import { NotificationsWrapper } from './style';

const tabs = [
  { label: 'Nuevas', value: NOTIFICATION_STATE.NEW },
  { label: 'Destacadas', value: NOTIFICATION_STATE.IMPORTANT },
  { label: 'Finalizadas', value: NOTIFICATION_STATE.FINISHED }
];

const Notifications = () => {

  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.loginData.data);
  const history = useHistory();

  const [notifications, setNotifications] = useState([]);
  const [currentTab, setCurrentTab] = useState(NOTIFICATION_STATE.NEW);
  const [currentNotification, setCurrentNotification] = useState(null);

  const getNotifications = useCallback(
    async () => {
      try {
        dispatch(showLoading());
        const { data } = await getNotificationsData({
          recipient: email,
          state: currentTab
        });
        setNotifications(data.notifications);
      } catch (error) {
        setNotifications([]);
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch, email, currentTab]
  );

  const removeNotification = async (id) => {
    setNotifications(currentNotifications =>
      currentNotifications.filter(notification =>
        notification.id !== id
      )
    );
  }

  const updateNotification = async (notification, newState) => {
    const newNotification = { ...notification, state: newState };
    try {
      await updateNotificationApi(newNotification);
      removeNotification(notification.id);
      if (currentNotification) {
        setCurrentNotification(newNotification);
      }
    } catch (error) { }
  }

  const loadLink = (pathLink) => {
    const pathSplit = pathLink.split('/');
    const nid = parseInt(pathSplit[pathSplit.length - 1]);
    const route = pathSplit[pathSplit.length - 2]
    dispatch(updatePropertyData({ nid }));
    history.push(route);
  }

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <NotificationsWrapper>
      {
        !currentNotification &&
        <>
          <Title marginLeft="32" marginBottom="20">
            Notificaciones
          </Title>
          <TabsHeader
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <NotificationsList
            notifications={notifications}
            updateNotification={updateNotification}
            setCurrentNotification={setCurrentNotification}
            loadLink={loadLink}
          />
        </>
      }
      {
        currentNotification &&
        <NotificationDetail
          notification={currentNotification}
          setCurrentNotification={setCurrentNotification}
          updateNotification={updateNotification}
          loadLink={loadLink}
        />
      }
    </NotificationsWrapper>
  )
}

export default Notifications;
