import React, { useEffect, useState } from 'react';
import { StageWrapper } from "./style";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import iconLocked from "../../../../static/assets/img/icons/icon-locked.svg";
import iconUnlocked from "../../../../static/assets/img/icons/icon-unlocked.svg";
import iconCheck from "../../../../static/assets/img/icons/icon-check.svg";
import Activity from "../Activity/activity";
import { 
  ACTIVITY_STATUS, 
  ACTIVITY_TYPE, 
  SUB_ACTIVITY_TYPE 
} from '../../../../utils/constant';

const Stage = ({ stage, stageId, isActivity, saveRegistrationTicket }) => {

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(stage.status === ACTIVITY_STATUS.OPEN);
  }, [stage.status]);

  const getIcon = ( state ) => {
    let icon = iconLocked;
    switch (state) {
      case ACTIVITY_STATUS.OPEN:
        icon = iconUnlocked;
        break;
      case ACTIVITY_STATUS.DONE:
        icon = iconCheck;
        break;
      default:
        icon = iconLocked; 
    }
    return <img src={ icon } alt={ `icon${ state }` } className="icon-state" />
  }

  const getActivity = ( activity ) => {
    if (activity.subactivities_list && activity.subactivities_list.length > 0) {
      return <Stage 
        stage={ activity } 
        stageId={ stage.id } 
        isActivity={ true }
        key={ `activity${ activity.id }` } 
      />
    } else {
      return <div key={ `activityBox${ activity.id }` }> 
        <Activity 
          id={ activity.id }
          name={ activity.name } 
          label={ activity.label } 
          status={ activity.status } 
          owner={ activity.owner } 
          description={ activity.description_text }
          optional={ activity.optional }
          type={ ACTIVITY_TYPE }
          format={ activity.type }
          url={ activity.url }
          stageId={ stageId }
          id_document={ activity.id_document }
          document_type_id={ activity.document_type_id }
          exist={activity.exist}
          key={ `activity${ activity.id }` }
          document={activity.document}
          saveRegistrationTicket={saveRegistrationTicket}
          updateDate={activity.update_date}
          date={activity.date}
        />
        { activity.subactivities_list && 
          activity.subactivities_list.length > 0 &&
          activity.subactivities_list.map( 
            subActivity => getSubActivity( subActivity ) 
          )
        }
      </div>
    }
  } 

  const getSubActivity = ( subActivity ) => {
    return <div className="sub-activities-container" key={ `subActivityBox${ subActivity.id }` } >
      <Activity 
        id={ subActivity.id }
        name={ subActivity.name } 
        label={ subActivity.label } 
        status={ subActivity.status } 
        owner={ subActivity.owner } 
        description={ subActivity.description_text }
        optional={ subActivity.optional }
        type={ SUB_ACTIVITY_TYPE }
        stageId={ stageId }
        parentId={ stage.id }
        date={subActivity.date}
        subActivity
        key={ `subActivity${ subActivity.id }` } />
    </div>
  }

  const updateExpand = () => {
    setExpanded( !expanded );
  };

  return <StageWrapper state={ stage.status } isActivity={ isActivity }>
    <Accordion expanded={ expanded }>
      <AccordionSummary
        expandIcon={ <ExpandMoreIcon /> }
        className="name-stage"
        onClick={ updateExpand }
      >
        { getIcon( stage.status ) }
        { stage.label }
      </AccordionSummary>
      <AccordionDetails>
        <div className="dot-line" >
          <div className="activities-container">
            { 
              stage.activities_list && 
              stage.activities_list.map( activity => getActivity( activity )) 
            }
            { 
              stage.subactivities_list && 
              stage.subactivities_list.map( activity => getSubActivity( activity )) 
            }
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  </StageWrapper>;
}
 
export default Stage;