import React, { useEffect, useState } from "react";
import SendIcon from '@material-ui/icons/Send';

import { TextCommentWrapper } from "./style";

const TextComment = ({ value, disabled, handleSubmit, allowEmpty = false }) => {

  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value])

  const handleChange = (event) => {
    setText(event.target.value);
  }

  const send = () => {
    if (allowEmpty || (text && text !== '')) {
      handleSubmit(text);
    }
  }

  return (
    <TextCommentWrapper disabled={disabled}>
      <textarea
        rows="5"
        onChange={handleChange}
        value={text}
        disabled={disabled}
      />
      <div className="btn-send" onClick={send}>
        <SendIcon /> Enviar
      </div>
    </TextCommentWrapper>
  );
}

export default TextComment;