import React from 'react'
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ChecklistWrapper } from './style';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import { BUSINESS_TYPE, DOCUMENT_STATUS, DOCUMENT_WRAPPER_TYPE, ROLE } from '../../../../utils/constant';

const Checklist = ({ process, nid, handleUpdateDocument }) => {
  return (
    <ChecklistWrapper>
      <Accordion>
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          className="process-title"
        >
          <span>
            {process.label}
          </span>
          <div className="stats">
            <span>
              Documentos:
            </span>
            <span className="loaded">
              Cargados: 
              { process.list_documents.filter(doc => doc.exist).length }/{ process.list_documents.length }
            </span>
            <span className="approved">
              Aprobados: 
              { process.list_documents.filter(doc => doc.status===DOCUMENT_STATUS.SUCCESS).length }/{ process.list_documents.length }
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="documents-container">
            {
              process.list_documents.map(document => (
                <DocumentComponent
                  documentParam={ document }
                  businessTypeId={ BUSINESS_TYPE.SELLERS }
                  processName={ process.process_name }
                  wrapperType={ DOCUMENT_WRAPPER_TYPE.ROW }
                  reviewer={ ROLE.CHECKLIST_DOCUMENTS }
                  nid={ nid }
                  handleUpload={handleUpdateDocument}
                  handleDelete={handleUpdateDocument}
                  handleApprove={handleUpdateDocument}
                  lastItemRow
                />
              ))
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </ChecklistWrapper>
  )
}

export default Checklist;
