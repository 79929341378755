import { ANALYST, PAYMENT_METHOD } from '../selectOptions';

export const bankPromiseBgta = {
  firstBasicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'purchase_financing',
      label: 'Financiación compra',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_phone',
      label: 'Numero(s) de Teléfono',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_email',
      label: 'Correo cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'scripture_deal_date',
      label: 'Fecha escritura pactada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'otrosi_scripture_deal_date',
      label: 'Fecha escritura pactada otrosí',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: ANALYST,
      },
    },
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'payment_method',
      label: 'Forma de pago',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: PAYMENT_METHOD,
      },
    },
  ],
  firstAnalystInformation: [
    {
      name: 'last_client_communication_date_pbb',
      label: 'Fecha última comunicación cliente',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'fiduciary_bonding_sending',
      label: 'Envío vinculación fiduciaria',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'aproved_bonding',
      label: 'Vinculación aprobada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'dann_pa_colpatria_docs_radication',
      label: 'Radicación Docs en Dann/PA Colpatria',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'dann_colpatria_docs_aproval',
      label: 'Aprobación Docs Dann/Colpatria',
      type_field: 'input',
      fixed: true,
    },
  ],
  secondBasicInformation: [
    {
      name: 'convention_pbb',
      label: 'Convencionado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 1,
          },
          {
            label: 'No',
            value: 0,
          },
        ],
      },
    },
  ],
  secondAnalystInformation: [
    {
      name: 'et_avaluo_payment_date_pbb',
      label: 'Fecha pago Avalúo & ET',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'bank_document_signature_date_pbb',
      label: 'Fecha firma de docs en banco',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'avaluo_request_date_pbb',
      label: 'Fecha solicitud avalúo',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'avaluo_fulfilled_date_pbb',
      label: 'Fecha realización avalúo',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'avaluo_aproved_receipt_pbb',
      label: 'Recibo avalúo aprobado',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'et_docs_radication_date_pbb',
      label: 'Fecha radicación docs ET',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'et_aproval_date_more_pbb',
      label: 'Fecha aprobación ET, entrega minuta & legalización crédito',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_request_pbb',
      label: 'Solicitud minuta',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_receipt_pbb',
      label: 'Recepción minuta',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_legal_aproval_habi_pbb',
      label: 'Aprobación minuta legal (Habi)',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'fidu_dann_colpatria_memorandum_aproval_pbb',
      label: 'Aprobación minuta Fidu/Dann/Colpatria',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  thirdBasicInformation: [
    {
      name: 'real_scripture_date',
      label: 'Fecha escritura real',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'sale_scripture_number',
      label: 'Número de escritura',
      type_field: 'input',
      fixed: true,
    },
  ],
  thirdAnalystInformation: [
    {
      name: 'good_standing_scripture_pbb',
      label: 'Paz y salvo escrituración',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Sí',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
    },
    {
      name: 'arras_date',
      label: 'Fecha arras',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'arras_value',
      label: 'Valor arras',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'real_first_payment_date_pbb',
      label: 'Fecha real primer pago',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'first_payment_value_pbb',
      label: 'Valor primer pago',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_date_pbb',
      label: 'Fecha pago contra escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_value',
      label: 'Valor pago contra escritura',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'pending_value_to_be_disbursed_bank_pbb',
      label: 'Valor pendiente desembolso banco',
      type_field: 'inputCurrency',
      fixed: false,
    },
  ],
  fourthBasicInformation: [
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputNumber',
      fixed: true,
    },
  ],
  fourthAnalystInformation: [
    {
      name: 'selling_price_difference',
      label: 'Diferencia precio de venta - valor pagos',
      type_field: 'inputCurrency',
      fixed: true,
    },
  ],
};
