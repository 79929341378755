import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../shared/button/button';
import Field from '../Field/field';
import { SectionWrapper } from './style';
import { DYNAMIC_FORM_STYLES } from '../../../utils/dynamic-constant';
import { SECTION_WITHOUT_BUTTON } from '../../../utils/constant';
import { Hr } from '../../Styles/genericStyleComponent';

const Section = ({
  section,
  formikProps,
  handleSaveComment,
  addMultipleSection,
  labelButton,
  requestDocumentField,
}) => {
  const isDisabled = ({ field, isMultiple, multipleSection }) => {
    if (field.parent && !isMultiple) {
      const parentField = section.fields.find(({ id }) => id === field.parent);
      return `${formikProps.values[parentField.name]}` ===
        `${field.value_parent}`
        ? false
        : true;
    }
    if (field.parent && isMultiple) {
      const parentField = section.fields[multipleSection].fields.find(
        ({ id }) => id === field.parent
      );
      return `${formikProps.values[parentField.name]}` ===
        `${field.value_parent}`
        ? false
        : true;
    }
    return field.disabled;
  };

  const numberOfName = (string) => {
    const splitList = string.split('_');
    return +splitList[splitList.length - 1];
  };

  const actionDependencies = ({ field, action, multiple_part }) => {
    if (!field.dependencies || !field.dependencies.length) return false;
    let listFields = {};

    if (section.is_multiple === 1) {
      field.dependencies.forEach((dependField) => {
        let listFieldsTarget = [];
        section.fields.forEach(
          (subSection) =>
          (listFieldsTarget = [
            ...listFieldsTarget,
            ...subSection.fields.filter(
              (fieldSection) =>
                fieldSection.id === dependField.on_field_id &&
                dependField.action === action &&
                numberOfName(fieldSection.name) === multiple_part
            ),
          ])
        );
        if (listFieldsTarget.length > 0) {
          listFields = {
            ...listFields,
            [listFieldsTarget[0].id]: listFieldsTarget[0].name,
          };
        }
      });
    } else {
      section.fields.forEach((fieldSection) => {
        field.dependencies.forEach((dependField) => {
          if (
            fieldSection.id === dependField.on_field_id &&
            dependField.action === action
          ) {
            listFields = {
              ...listFields,
              [fieldSection.id]: fieldSection.name,
            };
          }
          if (
            requestDocumentField &&
            requestDocumentField.id === dependField.on_field_id &&
            dependField.action === action
          ) {
            listFields = {
              ...listFields,
              [requestDocumentField.id]: requestDocumentField.name,
            };
          }
        });
      });
    }

    let checkDependencies = field.dependencies
      .some(dependence => {
        if (dependence.default_state === 0) {
          return dependence.action === action
            && formikProps.values[listFields[dependence.on_field_id]] === dependence.on_field_value
        } else {
          return dependence.action === action
            && formikProps.values[listFields[dependence.on_field_id]] !== dependence.on_field_value
        }
      });

    const haveFieldsUndefined = field.dependencies.some(dependence =>
      formikProps.values[listFields[dependence.on_field_id]] === undefined
      && dependence.action === action
    );

    return haveFieldsUndefined ? true : checkDependencies;
  };

  const getTitle = (title) => {
    return <h2 className="title">{title}</h2>;
  };

  const getNoMultipleSections = () => {
    let sections = [];
    section.fields.forEach((field, index) => {
      if (
        !actionDependencies({
          field,
          action: 'hidden',
        })
      ) {
        sections = [
          ...sections,
          <div
            key={field.name + index}
            className={DYNAMIC_FORM_STYLES[field.type_field] ?? ''}
          >
            <Field
              field={field}
              formikProps={formikProps}
              disabled={
                field.dependencies && field.dependencies.length > 0
                  ? actionDependencies({
                    field,
                    action: 'disabled',
                  })
                  : isDisabled({ field, isMultiple: false })
              }
              handleSaveComment={handleSaveComment}
              hidden={
                field.dependencies &&
                actionDependencies({
                  field,
                  action: 'hidden',
                })
              }
            />
          </div>,
        ];
      }
    });
    return sections;
  };

  return (
    <SectionWrapper key={section.name + section.id}>
      {section.is_multiple !== 1 && (
        <div>
          {getTitle(section.label)}
          <div className="section-fields">{getNoMultipleSections()}</div>
        </div>
      )}
      {section.is_multiple === 1 && (
        <div>
          {getTitle(section.label)}
          {section.fields.map(({ multiple_part, fields }, index) => (
            <div
              className="multiple-section"
              key={`${section.name}_${multiple_part}_${index}_${section.id}`}
            >
              <div className="title-multiple-section">
                {`${labelButton ?? 'vendedor'} nº ${multiple_part}`}
              </div>
              <div className="section-fields">
                {fields.map((field) => (
                  <div
                    key={`${field.name}_${multiple_part}_${index}_${field.id}`}
                  >
                    <Field
                      field={field}
                      formikProps={formikProps}
                      disabled={
                        field.dependencies && field.dependencies.length > 0
                          ? actionDependencies({
                            field,
                            action: 'disabled',
                            multiple_part,
                          })
                          : isDisabled({
                            field,
                            multipleSection: index,
                            isMultiple: true,
                          })
                      }
                      hidden={
                        field.dependencies &&
                        actionDependencies({
                          field,
                          action: 'hidden',
                          multiple_part,
                        })
                      }
                      handleSaveComment={handleSaveComment}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {!SECTION_WITHOUT_BUTTON.includes(section.name) && (
            <div className="add-multiple-section">
              <Button
                size="small"
                onClick={() =>
                  addMultipleSection(section.id, formikProps.values)
                }
                type="button"
              >
                <AddIcon />
                {`Añadir otro ${labelButton ?? 'vendedor'}`}
              </Button>
            </div>
          )}
        </div>
      )}
      <Hr marginTop='40' />
    </SectionWrapper>
  );
};

Section.defaultProps = {
  addMultipleSection: () => { },
};

export default Section;
