import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Alert from "@habi/habi-react-components/dist/Alert/alert";
import NotificationsIcon from '@material-ui/icons/Notifications';

import Button from '../shared/button/button';
import { WarningDialogWrapper } from './style'

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  margin: 0,
  border: 0,
  outline: 0,
};

const WarningDialog = ({ 
  open,
  title,
  contentAlert,
  confirmationText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm
}) => {
  return (
    <Modal 
      open={open} 
      style={modalStyle} 
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Fade in={open}>
        <WarningDialogWrapper>
          <span className="modal-title">
            { title }
          </span>
          <Alert
            title="¡Atención!"
            description={ contentAlert }
            type="warning"
          >
            <NotificationsIcon />
          </Alert>
          <span className='confirmation-text'>
            { confirmationText }
          </span>
          <div className="btn-container">
            <Button typeClass="secondary" onClick={ onCancel }>
              { cancelButtonText }
            </Button>
            <Button onClick={ onConfirm }>
              { confirmButtonText }
            </Button>
          </div>
        </WarningDialogWrapper>
      </Fade>
    </Modal>
  );
};

WarningDialog.defaultProps = {
  open: false,
  confirmationText: '¿Estás seguro que quieres continuar?',
};

WarningDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  contentAlert: PropTypes.string.isRequired,
  confirmationText: PropTypes.string,
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default WarningDialog;
