import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendFlagComment,
  updateFormData,
} from '../../apis/DynamicForm/dynamicFormApis';
import { callEventProcess } from '../../apis/Process/processApis';
import { ButtonDesist } from '../../components/ButtonDesist/ButtonDesist';

import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import { Container } from '../../components/Styles/genericStyleComponent';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { getBuyerReceptionData } from '../../services/data/buyerReception';
import { updateEvents } from '../../services/eventsService';
import {
  ALL_LEGAL_ROLES,
  BUSINESS_TYPE,
  BUYER_RECEPTION_CONSTANTS,
  PROCESS,
  ROLE,
} from '../../utils/constant';
import { ReceptionForm } from './ReceptionForm/ReceptionForm';
import { BuyerReceptionWrapper } from './style';

const businessType = BUSINESS_TYPE.BUYERS;
const processName = PROCESS.BUYER_RECEPTION;

const BuyerReception = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const roles = useSelector((state) => state.loginData.data.role);
  const dispatch = useDispatch();

  const [receptionData, setReceptionData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [complianceList, setComplianceList] = useState([]);

  const getListCompliance = useCallback((receptionDataSections) => {
    let list = [];
    let sectionMultiple = receptionDataSections.find(
      (section) => section.is_multiple === 1
    );
    for (let i = 1; i <= sectionMultiple.number_times; i++) {
      list = [...list, i];
    }
    setComplianceList(list);
  }, []);

  const getData = useCallback(
    async (reload) => {
      try {
        if (!reload) {
          dispatch(showLoading());
        } else {
          setLoadingSave(true);
        }
        const receptionData = await getBuyerReceptionData(
          nid,
          businessType,
          processName
        );
        setReceptionData(receptionData);
        setDocuments(receptionData.documents);
        setEvents(receptionData.events);
        dispatch(dataFound());
        getListCompliance(receptionData.sections);
      } catch {
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
        setLoadingSave(false);
      }
    },
    [nid, dispatch, getListCompliance]
  );

  const callEvent = async (eventName, status) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: businessType,
        processName,
        eventName,
        status,
        originUrl: window.location.href,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  const saveBuyerReceptionForm = async (values) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: processName,
      fields: values,
      business_type_id: businessType,
    };
    try {
      setLoadingSave(true);
      await updateFormData(data);
    } catch (error) {
      console.error({ error });
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  const addNegotiationCharacteristics = (multiplePart) => {
    const multipleSections = receptionData.sections.find(
      (section) =>
        section.name === BUYER_RECEPTION_CONSTANTS.NEGOTIATION_CHARACTERSTICS
    );
    const newSection = {
      multiple_part: multiplePart,
      fields: multipleSections.fields[0].fields.map((field) => ({
        ...field,
        value: null,
        name: `${field.name}_${multiplePart}`,
      })),
    };
    multipleSections.fields.push(newSection);
    setReceptionData({
      sections: receptionData.sections.map((section) =>
        section.id === BUYER_RECEPTION_CONSTANTS.NEGOTIATION_CHARACTERSTICS
          ? multipleSections
          : section
      ),
    });
  };

  const addMultipleSection = (sectionId) => {
    let multipleSections = receptionData.sections.find(
      (section) => section.id === sectionId
    );
    const multiplePart = multipleSections.fields.length + 1;
    const newSection = {
      multiple_part: multiplePart,
      fields: multipleSections.fields[0].fields.map((field) => ({
        ...field,
        value: null,
        name: `${field.name}_${multiplePart}`,
      })),
    };
    addNegotiationCharacteristics(multiplePart);
    multipleSections.fields.push(newSection);

    setReceptionData({
      sections: receptionData.sections.map((section) =>
        section.id === sectionId ? multipleSections : section
      ),
    });
  };

  const saveComment = async (fieldId, comment) => {
    sendFlagComment({
      nid,
      processName: PROCESS.BUYER_RECEPTION,
      originUrl: window.location.href,
      businessType: BUSINESS_TYPE.BUYERS,
      comment,
      fieldId,
      agent,
    });
  };

  const getAllowRoleDesist = () => {
    const AllowedRoles = [
      ...ALL_LEGAL_ROLES,
      ROLE.COMMERCIAL_BUYERS,
      ROLE.PROCEDURES_LEADER_BUYERS,
    ];
    return roles.some((rol) => AllowedRoles.includes(rol));
  };

  return (
    <EmptyData>
      <LoadingData>
        <BuyerReceptionWrapper>
          <Container>
            <div className="button-desist">
              <div></div>
              {getAllowRoleDesist() && <ButtonDesist nid={nid} />}
            </div>
            {Object.keys(receptionData).length > 0 && (
              <ReceptionForm
                data={receptionData}
                documents={documents}
                events={events}
                businessType={businessType}
                processName={processName}
                callEvent={callEvent}
                saveData={saveBuyerReceptionForm}
                loadingSave={loadingSave}
                addMultipleSection={addMultipleSection}
                setDocuments={setDocuments}
                handleSaveComment={saveComment}
                complianceList={complianceList}
                getData={getData}
              />
            )}
          </Container>
        </BuyerReceptionWrapper>
      </LoadingData>
    </EmptyData>
  );
};

export default BuyerReception;
