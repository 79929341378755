import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { 
  DatePicker as DatePickerMaterial, 
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { DatePickerWrapper, StyledDatePicker } from './style';

const DatePicker = ({ 
  value, 
  onChange, 
  disabled, 
  width='100%', 
  name, 
  format, 
  helperText, 
  error, 
  placeHolder='' 
}) => {

  return (
    <DatePickerWrapper width={width}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <ThemeProvider theme={StyledDatePicker}>
          <DatePickerMaterial
            autoOk 
            name={name}
            value={value} 
            format={format}
            helperText={helperText}
            onChange={onChange}
            error={error}
            variant='inline'
            disabled={disabled}
            emptyLabel={placeHolder}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </DatePickerWrapper>
  )
}

export default DatePicker
