import styled from 'styled-components';
import { nightBlue200, white } from '../../../../StylesConstants';

export const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 42px;

  .over-backdrop {
    z-index: 1000;
    span,
    label {
      color: ${white} !important;
    }
  }

  .select__menu {
    max-width: 100% !important;
    left: 0 !important;
  }

  .select__dropdown-indicator {
    position: inherit !important;
  }

  input:disabled {
    color: ${nightBlue200} !important;
  }

  .form-control.is-invalid {
    border: none;
    background: none;
  }
`;
