import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@habi/habi-react-components/dist/Loader/loader';

import { DocumentTextWrapper } from './style';

const DocumentText = ({ getActionIcon, getNameDocument, loading }) => {
  return (
    <DocumentTextWrapper id="document-text">
      { loading 
        ? <Loader size="small" />
        : <div className="document-container">
            <div>
              { getNameDocument() }
            </div>
            { getActionIcon()  }
          </div>
      }
    </DocumentTextWrapper>
  )
};

DocumentText.defaultProps = {
  loading: false
};

DocumentText.propTypes = {
  getActionIcon: PropTypes.func.isRequired, 
  getNameDocument: PropTypes.func.isRequired, 
  loading: PropTypes.bool
}

export default DocumentText;