import React, { useState } from 'react';

import CommentHistory from '../CommentHistory/commentHistory';
import MultiSelect from '../shared/MultiSelect/multiSelect';
import { Container, Title } from '../Styles/genericStyleComponent';
import TextComment from '../TextComment/textComment';

const Comments = ({ allInteractions, handleSaveComment, commentsHistory }) => {

  const [interactionsSelected, setInteractionsSelected] = useState([]);
  const [comment, setComment] = useState('');
  const [commentDisabled, setCommentDisabled] = useState(!!allInteractions);

  const saveComment = async (comment) => {
    try {
      setCommentDisabled(true);
      setComment(comment);
      await handleSaveComment(comment, interactionsSelected);
      setInteractionsSelected([]);
      setComment('');
    } catch (error) {
      console.log('ERROR:', error);
    } finally {
      setCommentDisabled(false);
    }
  };

  const changeInteractions = (interactions) => {
    setInteractionsSelected(interactions);
    setCommentDisabled(interactions.length !== 0? false: true);
  };

  return (
    <Container>
      {
        allInteractions &&
        <>
          <Title>
            Interacciones
          </Title>
          <MultiSelect
            value={interactionsSelected}
            options={allInteractions}
            initOptions={[]}
            onChange={changeInteractions}
            withBorder
          />
        </>
      }
      <Title>
        Comentarios
      </Title>
      <TextComment
        handleSubmit={saveComment}
        value={comment}
        disabled={commentDisabled}
      />
      <CommentHistory history={commentsHistory} />
    </Container>
  )
};

export default Comments;
