import styled from "styled-components";
import { muliBoldFamily, nightBlue500 } from "../../../../StylesConstants";

export const FormWrapper = styled.div`
  .form-container {
    text-align: left;

    .section {
      .title {
        font-family: ${muliBoldFamily};
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: ${nightBlue500};
        margin: 0px 0px 40px;
      }
      .section-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 48px;
        grid-row-gap: 32px;
      }
      .line-separator {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #ccc;
        margin: 24px 0px 40px;
        padding: 0;
      }
    }
    .form-button-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 48px;
    }
  }
`;
