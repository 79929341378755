export const updatePropertyData = payload => ({
  type: 'UPDATE_PROPERTY_DATA',
  payload
});
export const updateGenericData = (payload) => ({
  type: 'UPDATE_GENERIC_DATA',
  payload,
});
export const resetPropertyData = () => ({
  type: 'RESET_PROPERTY_DATA'
});
export const showLoading = () => ({
  type: 'SHOW_LOADING'
});
export const hideLoading = () => ({
  type: 'HIDE_LOADING'
});
export const dataFound = () => ({
  type: 'DATA_FOUND'
});
export const dataNotFound = () => ({
  type: 'DATA_NOT_FOUND'
});