import React, { useEffect, useState } from "react";
import { Field } from "formik";

import DateTimePicker from "./DateTimePicker/dateTimePicker";
import { Label } from "../../Styles/genericStyleComponent";

const FORMAT_DATE = 'yyyy-MM-dd HH:mm';

const DateTimePickerField = ({
  field,
  values,
  errors,
  disabled,
  label,
  setFieldError,
  setFieldValue
}) => {

  const [currentError, setCurrentError] = useState('');

  useEffect(() => {
    if (errors) {
      setCurrentError(errors[field.name]);
    }
  }, [errors, field.name]);

  return (
    <div>
      <Label>
        {label}
      </Label>
      <DateTimePicker
        name={field.name}
        value={values[field.name] ? values[field.name] : null}
        format={FORMAT_DATE}
        helperText={currentError}
        error={Boolean(currentError)}
        onError={error => {
          if (error !== currentError) {
            setFieldError(field.name, error);
          }
        }}
        onChange={date => { setFieldValue(field.name, date, false) }}
        disabled={disabled}
        placeHolder='Selecciona una fecha y hora'
      />
    </div>
  );
}

const CustomDateTimePicker = props => {
  return (
    <Field
      name={props.name}
      label={props.label}
      values={props.values}
      component={DateTimePickerField}
      errors={props.errors}
      setFieldError={props.setFieldError}
      setFieldValue={props.setFieldValue}
      handleChange={props.handleChange}
      setFieldTouched={props.setFieldTouched}
      disabled={props.disabled}
    />
  );
};

export default CustomDateTimePicker;
