import API from '../Api';
import { ELEMENTS_PAGER } from '../../utils/constant';

export const getExternalProcessData = ({ status, processName, agent, page, filter }) => {
  return API.get(
    process.env.REACT_APP_GET_EXTERNAL_PROCESS_DATA,
    {
      params: { 
        process_name: processName,
        status,
        agent,
        page,
        page_elements: ELEMENTS_PAGER,
        filter
      }
    }
  );
};

export const putExternalProcessData = ({ agent, nid, processName, externalUser, deliveryDate, originUrl }) => {
  return API.put(
    process.env.REACT_APP_PUT_EXTERNAL_PROCESS_DATA,
    {
      agent,
      nid: parseInt(nid),
      process_name: processName,
      external_user: externalUser,
      delivery_date: deliveryDate,
      origin_url: originUrl
    }
  );
};
