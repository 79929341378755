import React, { useState } from 'react';

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IconSend from '../../static/assets/img/icons/icon-send.svg';
import { ButtonGrid, DocumentsGrid, Hr } from '../Styles/genericStyleComponent';
import { DOCUMENT_STATUS } from '../../utils/constant';
import DocumentComponent from '../DocumentComponent/documentComponent';
import { AccordionDocumentsSectionWrapper } from './style';
import ButtonCheck from '../ButtonCheck/buttonCheck';
import OptionalDocument from '../OptionalDocument/optionalDocument';

const AccordionDocumentsSection = ({ section, buttonEvents, handleUpdateDocument, addOptionalDocument, handleCallEvent, businessTypeId, processName, showDocumentOptional, showDelete }) => {

  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const handleAddDocument = (document) => {
    addOptionalDocument(document, section.name);
  };

  return (
    <AccordionDocumentsSectionWrapper>
      <Accordion 
        expanded={ accordionExpanded } 
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          className="accordion-title"
        >
          <div className="group-title">
            <div className="icon-send">
              <img src={IconSend} alt="Icon send" />
            </div>
            <span>
              { section.label }
            </span>
          </div>
          <div className="stats">
            <span>
              Documentos:
            </span>
            <span className="loaded">
              Cargados: 
              { section.list_documents.filter(doc => doc.exist).length }/{ section.list_documents.length }
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="detail-container">
            <DocumentsGrid>
              {section.list_documents.map((document, i) => (
                <DocumentComponent
                  key={`document_${i}`}
                  lastItemRow={(i + 1) % 5 === 0 ? true : false}
                  documentParam={document}
                  businessTypeId={businessTypeId}
                  processName={processName}
                  handleUpload={handleUpdateDocument}
                  handleDelete={handleUpdateDocument}
                  showDelete={showDelete}
                />
              ))}
              {
                showDocumentOptional &&
                <OptionalDocument 
                  businessType={businessTypeId}
                  processName={processName} 
                  sectionName={section.name}
                  handleAddDocument={handleAddDocument}
                />
              }
            </DocumentsGrid>
            <ButtonGrid>
              {
                buttonEvents.map(buttonEvent => 
                  <ButtonCheck
                    label={ buttonEvent.label }
                    status={ buttonEvent.status }
                    handleChange={() => handleCallEvent(buttonEvent.event, DOCUMENT_STATUS.SUCCESS)}
                    disabled={ buttonEvent.disabled }
                  />
                )
              }
            </ButtonGrid>
          </div>
        </AccordionDetails>
      </Accordion>
      <br /><Hr />
    </AccordionDocumentsSectionWrapper>
  )
};

export default AccordionDocumentsSection;
