import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@habi/habi-react-components/dist/Loader/loader';

import { DocumentStepWrapper } from './style';

const DocumentStep = ({ getActionIcon, getDocumentIcon, getNameDocument, loading }) => {
  return (
    <DocumentStepWrapper id="document-step">
      { getDocumentIcon() }
        <div>
          { getNameDocument() }
        </div>
      { loading? <Loader size="small" /> : getActionIcon() }
    </DocumentStepWrapper>
  )
};

DocumentStep.defaultProps = {
  loading: false
};

DocumentStep.propTypes = {
  getActionIcon: PropTypes.func.isRequired, 
  getDocumentIcon: PropTypes.func.isRequired, 
  getNameDocument: PropTypes.func.isRequired, 
  loading: PropTypes.bool
}

export default DocumentStep;
