export const desistedSales = {
  firstAnalystInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'input',
      fixed: true
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true
    },
    {
      name: 'reason_withdrawal',
      label: 'Razón de desistimiento',
      type_field: 'input',
      fixed: false
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'input',
      fixed: true
    },
  ],
  firstLegalInformation: [
    {
      name: 'commercial_executive',
      label: 'Comercial Buyers',
      type_field: 'input',
      fixed: true
    },
    {
      name: 'intention_chart_date',
      label: 'Fecha carta de intención',
      type_field: 'inputDate',
      fixed: true
    },
    {
      name: 'promise_signature_date',
      label: 'Fecha firma promesa',
      type_field: 'inputDate',
      fixed: true
    },
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputCurrency',
      fixed: true
    },
  ],
  secondAnalystInformation: [
    {
      name: 'transaction_contract',
      label: 'Contrato de transacción',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      }
    },
    {
      name: 'unilateral_communication',
      label: 'Comunicación unilateral',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      }
    },
  ],
  secondLegalInformation: [
    {
      name: 'withdrawal_date',
      label: 'Fecha elaboración desistimiento',
      type_field: 'inputDate',
      fixed: false
    },
    {
      name: 'singned_withdrawal_date',
      label: 'Fecha firma cliente desistimiento',
      type_field: 'inputDate',
      fixed: false
    },
    {
      name: 'paid_deposit',
      label: 'Se pagaron arras',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      }
    },
    {
      name: 'arras_value',
      label: 'Valor arras',
      type_field: 'inputCurrency',
      fixed: false
    },
    {
      name: 'habi_paid_penalty_clause',
      label: 'Habi pagó cláusula penal',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Sí',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      }
    },
    {
      name: 'penalty_clause_value',
      label: 'Valor cláusula penal',
      type_field: 'inputCurrency',
      fixed: false
    },
    {
      name: 'client_paid_penalty_clause',
      label: 'Cliente pagó cláusula penal',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Sí',
            value: '1',
          },
          {
            label: 'No',
            value: '0',
          },
        ],
      }
    },
    {
      name: 'penalty_clause_value_client',
      label: 'Valor cláusula penal cliente',
      type_field: 'inputCurrency',
      fixed: false
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true
    },
    {
      name: 'client_phone',
      label: 'Numero(s) de Teléfono',
      type_field: 'input',
      fixed: true
    },
    {
      name: 'client_id_dv',
      label: 'Identificación cliente',
      type_field: 'inputNumber',
      fixed: false
    },
    {
      name: 'business_value',
      label: 'Valor del negocio',
      type_field: 'inputCurrency',
      fixed: true
    },
    {
      name: 'commission_payment_date',
      label: 'Fecha pago de comisión',
      type_field: 'inputDate',
      fixed: false
    },
    {
      name: 'commission_value',
      label: 'Valor comisión',
      type_field: 'inputCurrency',
      fixed: false
    },
    {
      name: 'commission_reversal_date',
      label: 'Fecha reverso comisión',
      type_field: 'inputDate',
      fixed: false
    },
    {
      name: 'observations_hl',
      label: 'Observaciones',
      type_field: 'input',
      fixed: false
    },
  ]
};
