import React from 'react';
import { Title } from '../../../../components/Styles/genericStyleComponent';
import { NewsWrapper } from './style';

const News = () => {
  return (
    <NewsWrapper>
      <Title marginBottom="20">
        Novedades
      </Title>
      <p>
        Aquí podrás ver las nuevas funcionalidades que desarrollemos para tu rol en el portal.
      </p>
      <div className="btn-next">
        Próximamente
      </div>

    </NewsWrapper>
  )
}

export default News;
