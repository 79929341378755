import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import {
  getDocumentsProcess,
  getEventsProcess,
  getHistoryComments,
} from '../../apis/Process/processApis';
import { HTTP_STATUS, PROCESS } from '../../utils/constant';

export const mortgageCancellationData = (nid, businessType, processName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const documents = await getDocumentsProcess({
        nid,
        businessType,
        processName,
        sections: false,
      });
      const comments = await getHistoryComments({
        nid,
        processName: PROCESS.MORTGAGE,
      });
      const events = await getEventsProcess({
        nid,
        businessTypeId: businessType,
        processName,
      });
      const { data } = await getFormDataDependencies(
        nid,
        processName,
        businessType
      );
      resolve({
        form: data.data,
        documents: documents.data.docs,
        events: events.data.data.events,
        comments: comments.data.history,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: {},
          documents: [],
          events: [],
          comments: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
