import styled from 'styled-components';

export const UserExternalFormWrapper = styled.div`

  width: 100%;

  form {
    display: grid;
    gap: 16px;
  }


`;
