import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import DocumentComponent from "../../components/DocumentComponent/documentComponent";
import {
  DocumentsGrid,
  Title,
  Container,
} from "../../components/Styles/genericStyleComponent";
import {
  ALL_EXTERNAL_ROLES,
  BUSINESS_TYPE,
  DOCUMENT_TYPE_NAME,
  PROCESS
} from "../../utils/constant";
import EmptyData from "../../components/EmptyData/emptyData";
import useDocuments from "../../hooks/useDocuments";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DocumentReviewWrapper } from "../DocumentReview/style";

const SupplierDocuments = () => {

  const {processName} = useSelector( state => state.documentReviewData );
  const roles = useSelector(state => state.loginData.data.role);
  const history = useHistory();

  const {
    documents, 
    documentUpdate, 
  } = useDocuments({
    businessType: BUSINESS_TYPE.SELLERS,
    processName
  });
  const { list_documents: listDocuments } = documents;

  const getMainDocumentSection = () => {
    switch (processName) {
      case PROCESS.TITLE_STUDY:
        return DOCUMENT_TYPE_NAME.TITLE_STUDY
      case PROCESS.APPRAISAL:
        return DOCUMENT_TYPE_NAME.APPRAISAL
      case PROCESS.BILL:
        return DOCUMENT_TYPE_NAME.BILL
      default:
        return
    }
  }

  return (
    <DocumentReviewWrapper>
      <EmptyData>
        <Container>
          <i className="arrow-back" onClick={history.goBack}>
            <ArrowBackIcon />
          </i>
          <Title>Documentos Habi</Title>
          <DocumentsGrid>
            { listDocuments
              .filter(document => document.name !== getMainDocumentSection() )
              .map((document, i) => (
                <DocumentComponent
                  key={`document-${i}`}
                  lastItemRow={(i + 1) % 5 === 0 ? true : false}
                  documentParam={document}
                  businessTypeId={BUSINESS_TYPE.SELLERS}
                  handleUpload={documentUpdate}
                  handleDelete={documentUpdate}
                  processName={processName}
                  showReview
                  showDelete={false}
                  reviewer={ ALL_EXTERNAL_ROLES.some(role => role === roles[0])? roles[0]: null  }
                />
              ))
            }
          </DocumentsGrid>
        </Container>
      </EmptyData>
    </DocumentReviewWrapper>
  );
};

export default SupplierDocuments;
