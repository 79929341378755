import { DEPENDENCY_CONDITION } from '../../../../utils/constant';

export const promiseCounted = {
  firstBasicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'purchase_financing',
      label: 'Financiación compra',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_phone',
      label: 'Numero(s) de Teléfono',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_email',
      label: 'Correo cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'promise_request_date',
      label: 'Fecha solicitud promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'promise_signature_date',
      label: 'Fecha firma promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'scripture_deal_date',
      label: 'Fecha escritura pactada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'latest_otrosi_date',
      label: 'Fecha último otrosí',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'latest_otrosi_reason',
      label: 'Razón último otrosí',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'otrosi_scripture_deal_date',
      label: 'Fecha escritura pactada otrosí',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: true,
    },
  ],
  firstAnalystInformation: [
    {
      name: 'last_client_communication_date_pc',
      label: 'Fecha última comunicación cliente',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'fiduciary_bonding_sending',
      label: 'Envío vinculación fiduciaria',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'aproved_bonding',
      label: 'Vinculación aprobada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'dann_pa_colpatria_docs_radication',
      label: 'Radicación Docs en Dann/PA Colpatria',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'dann_colpatria_docs_aproval',
      label: 'Aprobación Docs Dann/Colpatria',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'memorandum_request_pc',
      label: 'Solicitud minuta',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_receipt_pc',
      label: 'Recepción minuta',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_radication',
      label: 'Radicación minuta',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'legal_memorandum_approval',
      label: 'Aprobación minuta legal (Habi)',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'memorandum_fidu_dann_colpat_aproval_pc',
      label: 'Apro. Minuta Fidu/Dann/Colpatria',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  secondBasicInformation: [
    {
      name: 'real_scripture_date',
      label: 'Fecha escritura real',
      type_field: 'inputDate',
      fixed: true,
      dependencies: [
        {
          on_field: 'property_address',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'property_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_id',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_phone',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'address_residence_notification',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
      ],
    },
    {
      name: 'sale_scripture_number',
      label: 'Número de escritura',
      type_field: 'input',
      fixed: true,
    },
  ],
  secondAnalystInformation: [
    {
      name: 'good_standing_scripture_pc',
      label: 'Paz y salvo escrituración',
      type_field: 'select',
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
      fixed: false,
    },
    {
      name: 'arras_date',
      label: 'Fecha arras',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'arras_value',
      label: 'Valor arras',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'real_first_payment_date_pc',
      label: 'Fecha real primer pago',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'first_payment_value_pc',
      label: 'Valor primer pago',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_date_pc',
      label: 'Fecha pago contra escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_value_pc',
      label: 'Valor pago contra escritura',
      type_field: 'inputCurrency',
      fixed: false,
    },
  ],
  thirdBasicInformation: [
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputCurrency',
      fixed: true,
    },
  ],
  thirdAnalystInformation: [
    {
      name: 'selling_price_difference',
      label: 'Diferencia precio de venta - valor pagos',
      type_field: 'inputCurrency',
      fixed: true,
    },
  ],
};
