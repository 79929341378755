import React from "react";
import { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LogIn from "../pages/Login/login";
import AuthenticatedRoute from "../utils/Auth/authenticatedRoute";
import Home from "../pages/Home/home";
import ListingWeb from "../pages/ListingWeb/ListingWeb";
import NotFound from "../pages/404/notFound";
import PropertySellers from "../pages/PropertySellers/propertySellers";
import RegistrationTicket from "../pages/RegistrationTicket/registrationTicket";
import DocumentsPreDeed from "../pages/DocumentsPreDeed/documentsPreDeed";
import DocumentsPromiseOfSale from "../pages/DocumentsPromiseOfSale/documentsPromiseOfSale";
import DocumentReview from "../pages/DocumentReview/documentReview";
import BuyersFolder from "../pages/BuyersFolder/buyersFolder";
import PromiseOfSale from "../pages/PromiseOfSale/promiseOfSale";
import Appraisal from "../pages/Appraisal/appraisal";
import TitleStudy from "../pages/TitleStudy/titleStudy";
import Draft from "../pages/Draft/draft";
import PropertyReception from "../pages/PropertyReception/propertyReception";
import SupplierTitleStudy from "../pages/SupplierTitleStudy/supplierTitleStudy";
import SupplierAppraisal from "../pages/SupplierAppraisal/supplierAppraisal";
import SupplierDraft from "../pages/SupplierDraft/supplierDraft";
import { ALL_EXTERNAL_ROLES, ALL_HABI_ROLES, ROLE, ROUTES } from "./constant";
import SupplierDocuments from '../pages/SupplierDocuments/supplierDocuments';
import DocumentsChecklist from "../pages/DocumentsChecklist/documentsChecklist";
import Bonding from "../pages/Bonding/bonding";
import PayInstruction from '../pages/PayInstruction/PayInstruction';
import PropertyBuyers from "../pages/PropertyBuyers/propertyBuyers";
import Disbursement from "../pages/Disbursement/Disbursement";
import SignatureAndDelivery from '../pages/SignatureAndDelivery/SignatureAndDelivery';
import PurchaseOption from '../pages/PurchaseOption/PurchaseOption';
import DocumentsPCV from '../pages/DocumentsPCV/DocumentsPCV';
import CiPcv from '../pages/CiPcv/CiPcv';
import BuyerReception from '../pages/BuyerReception/BuyerReception';
import HighLevelBuyers from '../pages/HighLevelBuyers/HighLevelBuyers';
import TitleStudyAndDrafts from '../pages/TitleStudyAndDrafts/TitleStudyAndDrafts';
import DocumentsPreStudy from '../pages/DocumentsPreStudy/DocumentsPreStudy';
import AppraisalAppointment from '../pages/AppraisalAppointment/AppraisalAppointment';
import SignatureAndDeliveryBuyers from '../pages/SignatureAndDeliveryBuyers/SignatureAndDeliveryBuyers';
import DraftApprovalRequest from '../pages/DraftApprovalRequest/DraftApprovalRequest';
import MortgageCancellationDocuments from '../pages/MortgageCancelationDocuments/MortgageCancellationDocuments';
import MortgageCancellation from '../pages/MortgageCancellation/MortgageCancellation';
import NotarialExpenses from '../pages/NotarialExpenses/NotarialExpenses';
import Layout from '../layout/Layout';
import Dashboard from '../pages/Dashboard/Dashboard';
import MortgageRegistrationTicket from '../pages/MortgageRegistrationTicket/MortgageRegistrationTicket';
import PaymentRequest from '../pages/PaymentRequest/PaymentRequest';
import NotarialPaymentsDocuments from '../pages/NotarialPaymentsDocuments/NotarialPaymentsDocuments';

const Routes = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<div />}>
          <Layout>
            <Switch>
              <Route exact path={ROUTES.BASE}>
                <LogIn />
              </Route>
              <AuthenticatedRoute path={ROUTES.HOME} component={Home} />
              <AuthenticatedRoute
                path={ROUTES.REGISTRATION_TICKET}
                component={RegistrationTicket}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.BUYERS_FOLDER}
                component={BuyersFolder}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENTS_PCV}
                component={DocumentsPromiseOfSale}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.DOCUMENTS_PCV}/:nid`}
                component={DocumentsPromiseOfSale}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENTS_PRE_DEED}
                component={DocumentsPreDeed}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.DOCUMENTS_PRE_DEED}/:nid`}
                component={DocumentsPreDeed}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PROPERTY_SELLERS}
                component={PropertySellers}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PROPERTY_BUYERS}
                component={PropertyBuyers}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENT_REVIEW}
                component={DocumentReview}
                authorizedRoles={[...ALL_EXTERNAL_ROLES, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={ROUTES.PROMISE_OF_SALE}
                component={PromiseOfSale}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.PROMISE_OF_SALE}/:nid`}
                component={PromiseOfSale}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.TITLE_STUDY}
                component={TitleStudy}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.APPRAISAL}
                component={Appraisal}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DRAFT}
                component={Draft}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PROPERTY_RECEPTION}
                component={PropertyReception}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.PROPERTY_RECEPTION}/:nid`}
                component={PropertyReception}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.SUPPLIER_TITLE_STUDY}
                component={SupplierTitleStudy}
                authorizedRoles={[ROLE.SUPPLIER_TITLE_STUDY, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={`${ROUTES.SUPPLIER_TITLE_STUDY}/:nid`}
                component={SupplierDocuments}
                authorizedRoles={[...ALL_EXTERNAL_ROLES, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={ROUTES.SUPPLIER_APPRAISAL}
                component={SupplierAppraisal}
                authorizedRoles={[ROLE.SUPPLIER_APPRAISAL, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={`${ROUTES.SUPPLIER_APPRAISAL}/:nid`}
                component={SupplierAppraisal}
                authorizedRoles={[ROLE.SUPPLIER_APPRAISAL, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={ROUTES.SUPPLIER_DRAFT}
                component={SupplierDraft}
                authorizedRoles={[ROLE.SUPPLIER_DRAFT, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={`${ROUTES.SUPPLIER_DRAFT}/:nid`}
                component={SupplierDraft}
                authorizedRoles={[ROLE.SUPPLIER_DRAFT, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={ROUTES.SUPPLIER_DOCUMENTS}
                component={SupplierDocuments}
                authorizedRoles={[...ALL_EXTERNAL_ROLES, ...ALL_HABI_ROLES]}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENTS_CHECKLIST}
                component={DocumentsChecklist}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.BONDING}
                component={Bonding}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PAY_INSTRUCTION}
                component={PayInstruction}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.SIGNATURE_AND_DELIVERY}
                component={SignatureAndDelivery}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PURCHASE_OPTION}
                component={PurchaseOption}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENTS_CI_PCV}
                component={DocumentsPCV}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DISBURSEMENT}
                component={Disbursement}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.BUYER_RECEPTION}
                component={BuyerReception}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.LISTING_WEB}
                component={ListingWeb}
                authorizedRoles={[...ALL_HABI_ROLES, ROLE.LISTING_WEB]}
              />
              <AuthenticatedRoute
                path={ROUTES.CI_PCV}
                component={CiPcv}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.HIGH_LEVEL_BUYERS}
                component={HighLevelBuyers}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DOCUMENTS_PRE_STUDY}
                component={DocumentsPreStudy}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.TITLE_STUDY_AND_DRAFTS}
                component={TitleStudyAndDrafts}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.APPRAISAL_APPOINTMENT}
                component={AppraisalAppointment}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.SIGNATURE_AND_DELIVERY_BUYERS}
                component={SignatureAndDeliveryBuyers}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DRAFT_APPROVAL_REQUEST}
                component={DraftApprovalRequest}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.NOTARIAL_EXPENSES}
                component={NotarialExpenses}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                exact
                path={ROUTES.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS}
                component={MortgageCancellationDocuments}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS}/:onlyRead`}
                component={MortgageCancellationDocuments}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.MORTGAGE_CANCELLATION}
                component={MortgageCancellation}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.DASHBOARD}
                component={Dashboard}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.MORTGAGE_REGISTRATION_TICKET}
                component={MortgageRegistrationTicket}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.PAYMENT_REQUEST}
                component={PaymentRequest}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                exact
                path={ROUTES.NOTARIAL_PAYMENTS_DOCUMENTS}
                component={NotarialPaymentsDocuments}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={`${ROUTES.NOTARIAL_PAYMENTS_DOCUMENTS}/:onlyRead`}
                component={NotarialPaymentsDocuments}
                authorizedRoles={ALL_HABI_ROLES}
              />
              <AuthenticatedRoute
                path={ROUTES.NOT_FOUND}
                component={NotFound}
              />
              <Route>
                <LogIn />
              </Route>
            </Switch>
          </Layout>
        </Suspense>
      </Router>
    </>
  );
};

export default Routes;
