import { hintPerano000 } from '@habi/habi-react-components/dist/StylesConstants';
import styled from "styled-components";
import { openSansRegular, scarpaGray100, scarpaGray400 } from '../../StylesConstants';

export const PayInstructionWrapper = styled.div`

  .funder {
    color: ${ scarpaGray400 };
    font: 10px ${ openSansRegular };
    line-height: 24px;
    margin-left: 8px;
  }

  .document-grid {
    align-items: center;
    display: grid;
    grid-template-columns: 40px 490px;
    margin-bottom: 24px;
  }

  .event-button {
    margin: 0 0 48px 40px;
  }

  .dot-line-documents {
    border: 1px dashed ${scarpaGray100};
    position: absolute;
    height: 500px;
    top: 170px;
    margin-left: 12px;
  }

  .document-grid .radio {
    background-color: ${hintPerano000} !important;
    z-index: 1;
  }

`;
