import React from 'react';

import useRedirectToModule from '../../hooks/useRedirectToModule';
import { HomeLifeCycle } from './components/HomeLifeCycle/HomeLifeCycle';
import { useSelector } from 'react-redux';
import Dashboard from '../Dashboard/Dashboard';

const Home = () => {
  useRedirectToModule();
  const nid = useSelector((state) => state.propertyData.nid);

  return nid ? <HomeLifeCycle /> : <Dashboard />;
};

export default Home;
