import { ACTIVITY_STATUS } from '../../utils/constant';

export const setNewFundingType = (registrationTicketData, newFundingType) => {
  const copy_registrationTicketData = { ...registrationTicketData };
  copy_registrationTicketData.alreadySelected = true;
  copy_registrationTicketData.value = newFundingType;
  copy_registrationTicketData.change = true;
  return copy_registrationTicketData;
};

export const openFirstStages = (registrationTicketData) => {
  const copy_registrationTicketData = { ...registrationTicketData };
  copy_registrationTicketData.list_funding_types.map((funding) => {
    funding.list_stages.map((stage) => {
      if (stage.parents === null && stage.status !== ACTIVITY_STATUS.DONE) {
        stage.status = 'open';
        stage.change = true;
        openEnabledWithParent(stage.activities_list);
      }
      return stage;
    });
    return funding;
  });
  return copy_registrationTicketData;
};

export const setNewActivitiesOnChange = (
  registrationTicketData,
  dataChanged
) => {
  const copy_registrationTicketData = { ...registrationTicketData };

  const listFundingTypes = copy_registrationTicketData.list_funding_types.find(
    (funding) => funding.name === copy_registrationTicketData.value
  );

  const stage = listFundingTypes.list_stages.find(
    (stage) => stage.id === dataChanged.stageId
  );

  if (!copy_registrationTicketData.already_disabled_funding) {
    disabledNoSelectedFundingTypes(
      copy_registrationTicketData.list_funding_types,
      copy_registrationTicketData.value
    );
    copy_registrationTicketData.already_disabled_funding = true;
  }

  if (dataChanged.type === 'subactivity') {
    const activity = stage.activities_list.find(
      (activity) => activity.id === dataChanged.activityId
    );
    changeStatus(activity.subactivities_list, 'done', dataChanged.id);
    changeSiblingToOpen(
      activity.subactivities_list,
      dataChanged.id,
      dataChanged.type
    );
    checkUpdateStatusParentNode(
      stage.activities_list,
      activity.subactivities_list,
      activity.id
    );
  } else {
    const activity = stage.activities_list.find(
      (activity) => activity.id === dataChanged.id
    );
    if (activity.type !== 'document') {
      changeStatus(stage.activities_list, 'done', dataChanged.id);
      changeSiblingToOpen(
        stage.activities_list,
        dataChanged.id,
        dataChanged.type
      );
    } else if (activity.type === 'document' && dataChanged.document) {
      activity.document = dataChanged.document;
      changeStatus(stage.activities_list, 'done', dataChanged.id);
      changeSiblingToOpen(
        stage.activities_list,
        dataChanged.id,
        dataChanged.type
      );
    }
    checkUpdateStatusParentNode(
      listFundingTypes.list_stages,
      stage.activities_list,
      stage.id,
      'stage'
    );
  }

  return copy_registrationTicketData;
};

const disabledNoSelectedFundingTypes = (fundingTypesList, selectedFunding) => {
  fundingTypesList.map((funding) => {
    if (selectedFunding === funding.name) {
      funding.disabled = false;
    } else {
      funding.disabled = true;
    }
    return funding;
  });
};

const checkUpdateStatusParentNode = (listParents, listChildren, id, type) => {
  if (listChildren) {
    let notDoneObject = listChildren.filter(
      (child) => child.status !== 'done' && !child.optional
    );
    if (notDoneObject && notDoneObject.length === 0) {
      changeStatus(listParents, 'done', id);
      changeSiblingToOpen(listParents, id, type);
    }
  }
};

const changeSiblingToOpen = (listObject, id_current, type) => {
  const listFindResults = listObject.filter(
    (object) =>
      object.status === 'lock' &&
      object.parents !== null &&
      object.parents.includes(id_current) &&
      checkSiblingsComplete(object.parents, listObject)
  );

  listFindResults.map((sibling) => {
    if (sibling.type !== 'document') {
      sibling.status = 'open';
      sibling.change = true;

      const listChildren =
        type === 'activity'
          ? sibling.subactivities_list
          : sibling.activities_list;

      if (listChildren && listChildren.length > 0) {
        openEnabledWithParent(listChildren);
      }
    }
    return sibling;
  });
  return listFindResults;
};

const openEnabledWithParent = (listChildren) => {
  listChildren.map((child) => {
    if (child.enable_with_parent && child.status !== ACTIVITY_STATUS.DONE) {
      changeStatus(listChildren, 'open', child.id);
    }
    return child;
  });
};

const checkSiblingsComplete = (parents, listObject) => {
  const resultParentsOK = parents.filter((sibling) => {
    const resultParent = listObject.find(
      (object) => object.id === sibling && object.status === 'done'
    );
    if (resultParent) {
      return true;
    }
    return false;
  });

  if (resultParentsOK.length === parents.length) return true;
  return false;
};

// Change current activity o subactivity status to done
const changeStatus = (listObject, status, id) => {
  let object = listObject.find((object) => object.id === id);
  object.status = status;
  object.change = true;
};
