import styled from "styled-components";
import { openSansRegular } from "../../../../StylesConstants";

export const DocumentTextWrapper = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;  

  .document-container {
    display: grid;
    grid-template-columns: ${p => p.loading? 'auto': 'auto 24px' };
    padding: 0;
    align-items: center;
    justify-content: ${p => p.loading? 'center': '' };
  }

  .name-document {
    font: 12px ${openSansRegular};
    line-height: 24px;
    margin: 0;
    text-align: left;
  }

  .date-document {
    font: 10px ${openSansRegular};
    line-height: 10px;
    margin: 0;
    text-align: left;
  }

  .no-document {
    font: 10px ${openSansRegular};
    line-height: 10px;
    margin: 0;
    text-align: left;
  }

  .icon-document {
    line-height: 10px;
  }

  .action_container {
    top: 36px !important;
    right: -138px !important;
  }

  .small {
    display: flex;
  }
`;
