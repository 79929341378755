import axios from "axios";

export const getRolCognito = (data) => {
  const apikey = process.env.REACT_APP_HABI_CARONTE_API_KEY;
  const rootUrl =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_HABI_CARONTE_BASE_PATH;
  const endpointUrl =
    process.env.REACT_APP_ENDPOINT_HABI_API_GET_COGNITO_USER_ROLE;
  const url = rootUrl + endpointUrl;

  return axios({
    url: url,
    method: "GET",
    params: {
      user_id: data.userId
    },
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json"
    }
  });
};

export const loginExternalUser = ({ email, password }) => {
  const apikey = process.env.REACT_APP_ENDPOINT_HABI_PROCEDURE_API_KEY;
  const rootUrl =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_HABI_API_PROCEDURE_BASE_PATH;
  const endpointUrl = process.env.REACT_APP_POST_LOGIN_EXTERNAL_USERS;
  const url = rootUrl + endpointUrl;

  return axios({
    url: url,
    method: "POST",
    data: {email, password},
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json"
    }
  });
};
