import styled from 'styled-components';
export const PropertyReceptionWrapper = styled.div`
  display: ${(p) => (p.showComment ? 'grid' : 'inherit')};
  grid-template-columns: auto 360px;
  height: 100%;
  .title-container {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
`;
