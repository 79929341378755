import React from 'react';
import News from './components/News/News';
import Notifications from './components/Notifications/Notifications';
import { DashboardWrapper } from './style';

const Dashboard = () => {
  return (
    <DashboardWrapper>
      <Notifications />
      <News />
    </DashboardWrapper>
  )
}

export default Dashboard;
