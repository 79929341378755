import { DISBURSEMENT_CONFIG } from "../../../utils/constant";
import { constantConfig } from "./constantConfig";

const habi = [
  {
    hasHorizontalLine: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.MONTO_DESEMBOLSADO_POR_HABI + ' (100%)',
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_MOUNT_BY_HABI,
        type: DISBURSEMENT_CONFIG.MONEY_PERCENTAGE,
        value: ({ mount }) => mount,
        percentage: 100,
      },
    ],
  },
  ...constantConfig,
  {
    hasHorizontalLine: true,
    dependentValues: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.DESEMBOLSO_A_CLIENTE_POR_HABI,
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_TO_CLIENT_BY_HABI,
        type: DISBURSEMENT_CONFIG.MONEY_DEPENDS,
        value: ({
          mortgage_amount,
          deposit_amount,
          amount_disbursed_habi,
          arras_amount,
          withholding_source_amount,
          admin_amount,
          public_services_amount,
          praedial_amount,
        }) =>
          parseFloat(
            +amount_disbursed_habi -
              +arras_amount -
              +mortgage_amount -
              +deposit_amount -
              +withholding_source_amount -
              +admin_amount -
              +public_services_amount -
              +praedial_amount
          ).toFixed(2),
      },
    ],
  },
];

export default habi;