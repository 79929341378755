export const initialState = {
  loginData: {
    isAuthenticated: false,
    data: {},
    initialPath: null,
  },
  propertyData: {
    nid: null,
    loadingSearch: false,
    noData: false,
  },
  configurationData: {
    showLifeCycle: false,
  },
  registrationTicketData: {},
  mortgageRegistrationTicketData: {},
  documentReviewData: {},
};
