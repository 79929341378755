import React, { useState } from 'react'
import { putHighLevelItemApi } from '../../../../apis/Buyers/buyersApis';
import WarningDialog from '../../../../components/WarningDialog/warningDialog';

import HighLevelRow from '../HighLevelRow/HighLevelRow';
import { HighLevelTableWrapper } from './style';

const HighLevelTable = ({ tab, highLevelData }) => {

  const [showConfirm, setShowConfirm] = useState(false);
  const [dataDetailForSave, setDataDetailForSave] = useState({});

  const requestSaveDetailData = ({ nid, data }) => {
    setDataDetailForSave({ nid, ...data });
    setShowConfirm(true);
  }

  const saveDetailData = async () => {
    await putHighLevelItemApi(dataDetailForSave);
    setDataDetailForSave({});
    setShowConfirm(false);
  }

  return (
    <HighLevelTableWrapper>
      {
        <table className="high-level">
          <thead>
            <tr>
              {tab.fields && tab.fields.map((field) => <th>{field.label}</th>)}
              <th className="action-column" />
            </tr>
          </thead>
          <tbody>
            {highLevelData &&
              highLevelData.map((dataRow, index) => (
                <HighLevelRow
                  key={`dataRow${index}`}
                  tab={tab}
                  dataRow={dataRow}
                  requestSaveDetailData={requestSaveDetailData}
                />
              ))}
          </tbody>
        </table>
      }
      <WarningDialog
        open={showConfirm}
        title="¿Estás seguro de cambiar estos datos?"
        contentAlert="Si confirmas el guardado de los campos, la información quedará registrada en el histórico de este inmueble."
        confirmationText="¿Estás seguro que quieres continuar?"
        cancelButtonText="Cancelar"
        confirmButtonText="Aceptar y guardar"
        onCancel={() => setShowConfirm(false)}
        onConfirm={saveDetailData}
      />
    </HighLevelTableWrapper>
  );
}

export default HighLevelTable;