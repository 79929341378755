import { getBusinessDataApi } from "../../apis/Common/commonApis";
import { getDocumentsProcess, getEventsProcess, getReviewsProcess } from '../../apis/Process/processApis';
import { HTTP_STATUS, PROCESS, REQUEST_PCV } from "../../utils/constant";

export const getCiPcvData = ({ nid, businessType, processName }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let reviewsCI = {data:{data:[]}};
      let reviewsPCV = {data:{data:[]}};
      let documentsCI = {data:{docs:[]}};
      let documentsPCV = {data:{docs:[]}};
      let events = {data:{data:{events:[]}}};

      const business = await getBusinessDataApi(nid);

      if(business.data.pcv_ci_option) {
        documentsPCV = await getDocumentsProcess({
          nid,
          businessType,
          processName: PROCESS.PCV_PURCHASE_AND_LEGAL, 
          sections: false
        });
        reviewsPCV = await getReviewsProcess({
          nid,
          businessType,
          processName: PROCESS.PCV_PURCHASE_AND_LEGAL
        });
      }
      
      if(business.data.pcv_ci_option === REQUEST_PCV.CI_PCV) {
        events = await getEventsProcess({
          nid,
          businessTypeId: businessType,
          processName: PROCESS.CI_PURCHASE_AND_LEGAL
        });
        documentsCI = await getDocumentsProcess({
          nid,
          businessType,
          processName: PROCESS.CI_PURCHASE_AND_LEGAL, 
          sections: false
        });
        reviewsCI = await getReviewsProcess({
          nid,
          businessType,
          processName: PROCESS.CI_PURCHASE_AND_LEGAL
        });
      }

      const documents = [
        ...documentsPCV.data.docs, 
        ...documentsCI.data.docs
      ]

      resolve({
        documents: documents.filter(doc => doc.required || doc.exist),
        optionalDocuments: documents.filter(doc => !doc.required),
        reviews: {
          pcv: reviewsPCV.data.data,
          ci: reviewsCI.data.data
        },
        events: events.data.data.events,
        business: business.data
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          documents: [],
          events: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
