import React, { useCallback } from 'react';
import ButtonCheck from '../../../../components/ButtonCheck/buttonCheck';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import { ButtonGrid, DocumentsGrid, Hr, SubTitle } from '../../../../components/Styles/genericStyleComponent';
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS, PROCESS_EVENTS } from '../../../../utils/constant';

import BondingStageDann from '../BondingStageDann/bondingStageDann';
import { BondingDannWrapper } from './style';

const BondingDann = ({ sections, events, handleCallEvent, handleUpdateDocument }) => {

  const getEventStatus = useCallback(
    (eventName) => {
      const event = events.find( event => event.name === eventName);
      return event?.status === DOCUMENT_STATUS.SUCCESS ? event.status : null;
    },
    [events],
  );

  const allDocumentLoaded = () => {
    let documentLoaded = true;
    sections.forEach((section, index) => {
      section.list_documents.forEach(document => {
        if(index<3 && !document.exist) {
          documentLoaded = false;
        }
      });
    });
    return documentLoaded;
  };

  return (
    <BondingDannWrapper>
      { sections && 
        sections.map((section, indexSection) =>
          indexSection<3 && 
          <BondingStageDann 
            section={ section }
            events={ events }
            handleUpdateDocument={ handleUpdateDocument }
            key={`section_${ section.id }`}
          />
      )}
      <Hr />
      <SubTitle>
        Solicitud de vinculación Leasing Dann
      </SubTitle>
      <ButtonGrid>
        <ButtonCheck
          label="Solicitar vinculación"
          status={ getEventStatus(PROCESS_EVENTS.DANN_REQUEST_BONDING) }
          handleChange={() => handleCallEvent(PROCESS_EVENTS.DANN_REQUEST_BONDING, DOCUMENT_STATUS.SUCCESS)}
          disabled={ !allDocumentLoaded() }
        />
        <ButtonCheck
          label="Vinculación aprobada"
          status={ getEventStatus(PROCESS_EVENTS.DANN_BONDING_APPROVE) }
          handleChange={() => handleCallEvent(PROCESS_EVENTS.DANN_BONDING_APPROVE, DOCUMENT_STATUS.SUCCESS)}
          disabled={ !getEventStatus(PROCESS_EVENTS.DANN_REQUEST_BONDING) }
        />
      </ButtonGrid>
      <Hr marginTop='32'/>
      <SubTitle>
        Carta de aprobación de crédito
      </SubTitle>
      <DocumentsGrid>
        { sections[3] && sections[3].list_documents.map(document => 
          <DocumentComponent
            documentParam={document}
            businessTypeId={BUSINESS_TYPE.SELLERS}
            processName={PROCESS.BONDING_DANN}
            handleUpload={handleUpdateDocument}
            handleDelete={handleUpdateDocument}
            key={`document_${ document.name }`}
          />
        )}
      </DocumentsGrid>
    </BondingDannWrapper>
    

  )
};

export default BondingDann;
