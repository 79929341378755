import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button/button';
import {
  LISTING_WEB,
  STATUS_LISTING_WEB_PROCESS,
} from '../../../../utils/constant';
import { DYNAMIC_LISTING_WEB } from '../../../../utils/dynamic-constant';
import { RowGenericTableWrapper } from './style';

const RowSoldIncomplete = ({ index, rowData, handleUpdate }) => {
  const [typeBadge, setTypeBadge] = useState(LISTING_WEB.VENDIDO);

  useEffect(() => {
    if (rowData.status === STATUS_LISTING_WEB_PROCESS.INCOMPLETE) {
      setTypeBadge(LISTING_WEB.INCOMPLETO);
    }
  }, [rowData.status]);

  const getBadgeConfig = () => {
    let badgeConfig = {
      label: typeBadge,
      class: DYNAMIC_LISTING_WEB[rowData.status],
    };
    return badgeConfig;
  };

  const getBadge = () => {
    const BadgeConfig = getBadgeConfig();
    if (BadgeConfig) {
      return (
        <span className={`type ${BadgeConfig.class}`}>{BadgeConfig.label}</span>
      );
    }
  };

  const getChangeTag = (label, date) => {
    if (label) {
      return (
        <div className="sub-field">
          <span>{label}</span>
          <span>{moment(new Date(date)).format('DD-MM-YYYY')}</span>
        </div>
      );
    } else {
      return LISTING_WEB.NO_DATA;
    }
  };

  return (
    <RowGenericTableWrapper id="row">
      <td>
        <div className="index">
          <span>{index + 1}</span>
        </div>
      </td>
      <td>
        <div className="badge-field">
          {getBadge()}
          <span>{rowData.nid}</span>
        </div>
      </td>
      <td>
        <span>{rowData.property_name}</span>
      </td>
      <td>
        <div className="sub-field">
          <span>{rowData.tag_one_label}</span>
          <span>
            {moment(new Date(rowData.tag_one_date)).format('DD-MM-YYYY')}
          </span>
        </div>
      </td>
      <td>{getChangeTag(rowData.tag_two_label, rowData.tag_two_date)}</td>
      <td>{getChangeTag(rowData.tag_three_label, rowData.tag_three_date)}</td>
      <td>
        <span>
          <Button
            size="small"
            typeClass="secondary"
            fullWidth
            onClick={() => handleUpdate(rowData.nid)}
          >
            {`Publicar`}
          </Button>
        </span>
      </td>
    </RowGenericTableWrapper>
  );
};

export default RowSoldIncomplete;
