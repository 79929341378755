import React from "react";
import { withRouter } from "react-router";
import PiioElement from "../../components/Piio-images/piioElement";
import imageNotFound from "../../static/assets/404/illustration-disconnected.png";
import { Wrapper } from "./style";

const NotFound = ({ message }) => {
  return (
    <Wrapper>
      <div className="not-found-container">
        <PiioElement
          tag="img"
          className="image-not-found"
          src={imageNotFound}
        />
        {
          message &&
          <h2 className="title-not-found">{message}</h2>
        }
        {
          !message &&
          <>
            <h2 className="title-not-found">No hay resultados para este NID</h2>
            <h3 className="description-not-found">
              {" "}
              Verifica que esté bien el número o intenta con otro NID
            </h3>
          </>
        }
      </div>
    </Wrapper>
  );
};

export default withRouter(NotFound);
