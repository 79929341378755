import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';

// Components
import CurrencyInput from '@habi/habi-react-components/dist/CurrencyInput/currencyInput';
import RadioButtonV2 from '@habi/habi-react-components/dist/RadioButtonV2/RadioButtonV2';
import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';

import {
  ButtonGrid,
  Container,
  DocumentsGrid,
  Hr,
  Label,
  Title,
} from '../../components/Styles/genericStyleComponent';
// Styled
import { MortgageCancellationWrapper } from './style';
// Form
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  MORTGAGE_CANCELLATION,
  MORTGAGE_STATE,
  PROCESS,
  PROCESS_EVENTS,
  ROLE,
} from '../../utils/constant';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import Button from '../../components/shared/button/button';
import {
  callEventProcess,
  saveProcessComment,
} from '../../apis/Process/processApis';
import { useDispatch, useSelector } from 'react-redux';
import RightSection from '../../components/RightSection/rightSection';
import Comments from '../../components/Comments/comments';
import { mortgageCancellationData } from '../../services/data/mortgageCancellationData';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import useDynamicForm from '../../hooks/useDynamicForm';
import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import { updateDocuments } from '../../services/documentsService';
import { MORTGAGE_CANCELLATION_DOCUMENTS } from '../../utils/dynamic-constant';

const businessType = BUSINESS_TYPE.SELLERS;
const processName = PROCESS.MORTGAGE_CANCELLATION;

const MortgageCancellation = () => {
  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const role = useSelector((state) => state.loginData.data.role[0]);

  const [comments, setComments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [mortgageStatus, setMortgageStatus] = useState('');
  const [disabledButtonCheck, setDisabledButtonCheck] = useState(true);

  const [, initialValues, validationSchema, cleanDataToSave] =
    useDynamicForm(form);

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const mortgageData = await mortgageCancellationData(
          nid,
          businessType,
          processName
        );
        setDocuments(mortgageData.documents);
        setEvents(mortgageData.events);
        setForm(mortgageData.form);
        setComments(mortgageData.comments);
        dispatch(dataFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [getData, dispatch, nid]);

  const saveComment = async (comment) => {
    try {
      await saveProcessComment({
        nid,
        processName: PROCESS.MORTGAGE,
        agent,
        nameReviewerRole: role,
        comment,
        originUrl: window.location.href,
        noInteractions: true,
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role,
        },
        ...comments,
      ]);
    } catch (error) {
      console.error({ error });
    }
  };

  const callEvent = async (eventName, status) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: businessType,
        processName,
        eventName,
        status,
        originUrl: window.location.href,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  const updateDocument = (status, newDocument) => {
    setDocuments((docs) => updateDocuments(newDocument, docs));
  };

  const save = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      try {
        setLoading(true);
        await updateFormData({
          nid: typeof string ? parseInt(nid) : nid,
          agent,
          process_name: processName,
          fields: finalValues,
          business_type_id: BUSINESS_TYPE.SELLERS,
        });
      } catch (error) {
        console.error('ERROR:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const changeStatus = (value, setFieldValue, values) => {
    setFieldValue(MORTGAGE_CANCELLATION.MORTGAGE_STATUS, value);
    values[MORTGAGE_CANCELLATION.MORTGAGE_STATUS] = value;
    setMortgageStatus(value);
    save(values);
  };

  useEffect(() => {
    let documentsFilter = documents;
    if (mortgageStatus === MORTGAGE_STATE.CANCEL) {
      documentsFilter = documentsFilter.filter((doc) => {
        return (
          doc.name !== MORTGAGE_CANCELLATION.MINOR_CASH_RECEIPT_SUPPORT &&
          doc.name !== MORTGAGE_CANCELLATION.SUPPORT_PAYMENT_REMAINING_BALANCE
        );
      });
    }
    setDisabledButtonCheck(!documentsFilter.every((doc) => doc.url));
  }, [documents, mortgageStatus]);

  return (
    <EmptyData>
      <LoadingData>
        <MortgageCancellationWrapper>
          <Container>
            <Title>Cancelación de hipoteca</Title>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={(values) => {
                save(values);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <>
                  <div className="mount-field">
                    <CurrencyInput
                      id={'amount_paid_mortgage'}
                      name={'amount_paid_mortgage'}
                      label={'Monto pagado en hipoteca'}
                      className="input-filter"
                      errors={errors}
                      touched={touched}
                      values={values}
                      staticLabel
                      setFieldValue={setFieldValue}
                      maxLength={16}
                    />
                  </div>
                  <Hr />
                  <Label marginBottom={17}>
                    Comprobantes de aporte a hipoteca
                  </Label>
                  <DocumentsGrid>
                    {documents
                      .filter((document) =>
                        MORTGAGE_CANCELLATION_DOCUMENTS.VOUCHERS.includes(
                          document.name
                        )
                      )
                      .map((document, i) => (
                        <DocumentComponent
                          key={`document-${i}`}
                          businessTypeId={businessType}
                          processName={processName}
                          documentParam={document}
                          handleUpload={updateDocument}
                          handleDelete={updateDocument}
                          showReview={true}
                          reviewer={ROLE.LEGAL}
                          eventReview={PROCESS_EVENTS.LEGAL_REVIEW}
                        />
                      ))}
                  </DocumentsGrid>
                  <Hr />
                  <div className="mortgage-state-container">
                    <Label marginBottom={17}>Estado de hipoteca</Label>
                    {Object.keys(form).length && (
                      <RadioButtonV2
                        options={
                          form?.sections[0]?.fields[1]?.multiple_values
                            ?.options ?? []
                        }
                        handleChange={(value) => {
                          changeStatus(value, setFieldValue, values);
                        }}
                        initialValue={form?.sections[0]?.fields[1].value}
                        dataId={'checkbox'}
                      />
                    )}
                  </div>
                  {values[MORTGAGE_CANCELLATION.MORTGAGE_STATUS] ===
                    MORTGAGE_STATE.REMAINING_BALANCE && (
                    <DocumentsGrid>
                      {documents
                        .filter((document) =>
                          MORTGAGE_CANCELLATION_DOCUMENTS.STATE.includes(
                            document.name
                          )
                        )
                        .map((document, i) => (
                          <DocumentComponent
                            key={`document-${i}`}
                            businessTypeId={businessType}
                            processName={processName}
                            documentParam={document}
                            handleUpload={updateDocument}
                            handleDelete={updateDocument}
                            showReview={true}
                            reviewer={ROLE.LEGAL}
                            eventReview={PROCESS_EVENTS.LEGAL_REVIEW}
                          />
                        ))}
                    </DocumentsGrid>
                  )}
                  <Hr />
                  <ButtonGrid>
                    <Button onClick={() => save(values)} loading={loading}>
                      Guardar
                    </Button>
                    <ButtonCheck
                      dataId={PROCESS_EVENTS.CASH_MORTGAGE_PAYMENT}
                      label="Pago de hipoteca efectivo"
                      status={getEventStatus(
                        events,
                        PROCESS_EVENTS.CASH_MORTGAGE_PAYMENT
                      )}
                      handleChange={() =>
                        callEvent(
                          PROCESS_EVENTS.CASH_MORTGAGE_PAYMENT,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                      disabled={disabledButtonCheck}
                    />
                  </ButtonGrid>
                </>
              )}
            </Formik>
          </Container>
          <RightSection>
            <Comments
              commentsHistory={comments}
              handleSaveComment={saveComment}
            />
          </RightSection>
        </MortgageCancellationWrapper>
      </LoadingData>
    </EmptyData>
  );
};

export default MortgageCancellation;
