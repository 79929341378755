import React, { useState } from 'react';
import { format, compareAsc } from "date-fns";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useDispatch, useSelector } from "react-redux";

import DocumentComponent from '../../../DocumentComponent/documentComponent';
import { ALL_LEGAL_ROLES, ALL_PROCEDURES_ROLES, BUSINESS_TYPE, DOCUMENT_WRAPPER_TYPE, PROCESS, ROUTES } from '../../../../utils/constant';
import SelectWithConfirmation from '../../../shared/SelectWithConfirmation/selectWithConfirmation';
import { RowDocumentControlWrapper } from './style';
import { useHistory } from 'react-router';
import { setDocumentReview } from '../../../../redux/actions/documentReview';
import { updatePropertyData } from '../../../../redux/actions/propertyData';
import DateTimePicker from '../../../shared/CustomDateTimePicker/DateTimePicker/dateTimePicker';
import InfoIcon from '@material-ui/icons/Info';

const RowDocumentControl = ({ control, externalUsers, handleUpdate, processName, handleUploadDocument, handleDeleteDocument, currentTab }) => {

  const roles = useSelector(state => state.loginData.data.role);
  const [externalUser, setExternalUser] = useState(control.external_agent_id);
  const [deliveryDate, setDeliveryDate] = useState(control.delivery_date);
  const history = useHistory();
  const dispatch = useDispatch();

  const getExternalUser = () => {
    if(externalUser) {
      const externalUserObj = externalUsers.find(person => person.value === externalUser);
      return externalUserObj? externalUserObj.label: '';
    } else {
      return (
        <SelectWithConfirmation 
          options={ externalUsers }
          handleConfirm={ asignExternalUser }
        />
      )
    }
  };

  const asignExternalUser = (user) => {
    setExternalUser(user);
    handleUpdate({
      nid: control.nid, 
      externalUser: user, 
      deliveryDate, 
    });
  };

  const getOriginalProcessName = () => {
    switch (processName) {
      case PROCESS.EXTERNAL_TITLE_STUDY:
        return PROCESS.TITLE_STUDY;
      case PROCESS.EXTERNAL_BILL:
        return PROCESS.BILL;
      case PROCESS.EXTERNAL_APPRAISAL:
        return PROCESS.APPRAISAL;
      default:
        return
    }
  };

  const viewDocuments = () => {
    dispatch(setDocumentReview({ 
      processName: getOriginalProcessName(),
      currentTab 
    }));
    dispatch(updatePropertyData({ nid: control.nid }));
    history.push(ROUTES.SUPPLIER_DOCUMENTS);
  };

  const saveDeliveryDate = (newDeliveryDate) => {
    setDeliveryDate(newDeliveryDate);
    handleUpdate({
      nid: control.nid, 
      deliveryDate: newDeliveryDate 
    });
  };

  const isOutOffDate = () => {
    const referenceDate = control.document.exist? new Date(control.document.update_date): new Date();
    return compareAsc(new Date(deliveryDate), referenceDate)<0;
  };

  const getDateDelivery = () => {
    if(deliveryDate) {
      return (
        <div>
          { 
            isOutOffDate() && 
            <div className="icon-field">
              <InfoIcon /> 
            </div>
          }
          <div className={`sub-field ${isOutOffDate()? 'out-off-date': '' }`}>
            <span>
              { format(new Date(deliveryDate), 'dd/MM/yyyy') } 
            </span>
            <span> 
              { format(new Date(deliveryDate), 'HH:mm') } 
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <DateTimePicker
          name={`date${control.nid}`}
          value={null} 
          format="yyyy-MM-dd"
          onChange={saveDeliveryDate}
          disabled={!externalUser}
        />
      )
    }
  };

  return (
    <RowDocumentControlWrapper id="row">
      <td> 
        <div className="sub-field">
          <span> 
            { control.nid } 
          </span>
          <span>
            { control.property_name }
          </span>
        </div>
      </td>
      <td>
        <span> 
          { control.invoice_number } 
        </span>
      </td>
      <td>
        <div className="sub-field">
          <span> 
            { format(new Date(control.request_date), 'dd/MM/yyyy') } 
          </span>
          <span> 
            { format(new Date(control.request_date), 'HH:mm') } 
          </span>
        </div>
      </td>
      <td> 
        <div className="sub-field">
          <span> 
            { format(new Date(`${ control.confirmation_date} 05:00:00`), 'dd/MM/yyyy') } 
          </span>
        </div>
      </td>
      <td className="external-user-field"> 
        <span> 
          { getExternalUser() }
        </span>
      </td>
      <td className="center-field">
        <div className="view-docs-field">
          <VisibilityOutlinedIcon className="icon" />
          <span onClick={ viewDocuments }>Ver docs.</span>
        </div>
      </td>
      <td>
        { getDateDelivery() }
      </td>
      <td className="center-field document-field"> 
        <DocumentComponent
          documentParam={ control.document }
          businessTypeId={ BUSINESS_TYPE.SELLERS }
          reviewer={ [...ALL_PROCEDURES_ROLES, ...ALL_LEGAL_ROLES].some(role => role === roles[0])? roles[0]: null  }
          processName={ processName }
          wrapperType={ DOCUMENT_WRAPPER_TYPE.TEXT }
          lastItemRow
          showReview
          nid={ control.nid }
          handleUpload={ () => handleUploadDocument(control.nid) }
          handleDelete={ () => handleDeleteDocument(control.nid) }
          allowReReview
        />
      </td>
    </RowDocumentControlWrapper>
  )
};

export default RowDocumentControl;
