import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DocumentComponent from "../../components/DocumentComponent/documentComponent";
import { getListDocumentsHistApi, getListOptionalDocumentsApi } from "../../apis/Documents/documentsApis";
import { dataFound, dataNotFound, hideLoading, showLoading } from "../../redux/actions/propertyData";
import {
  ButtonGrid,
  Container,
  DocumentsGrid,
  Title,
} from "../../components/Styles/genericStyleComponent";
import ButtonCheck from "../../components/ButtonCheck/buttonCheck";
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  PROCESS,
  ROLE
} from "../../utils/constant";
import { sendEmailNotification } from "../../apis/Notifications/notificationsApis";
import OptionalDocument from "../../components/OptionalDocument/optionalDocument";
import EmptyData from "../../components/EmptyData/emptyData";
import MultiSelect from "../../components/shared/MultiSelect/multiSelect";
import { DocumentsPromiseOfSaleWrapper } from "./style";
import Comments from "../../components/Comments/comments";
import RightSection from "../../components/RightSection/rightSection";
import { saveProcessComment } from '../../apis/Process/processApis';
import Button from '../../components/shared/button/button';
import { LABELS_BUTTON_CHECK } from "../../utils/dynamic-constant";
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const DocumentsPromiseOfSale = () => {
  const { propertyData, loginData } = useSelector((state) => state);
  const [listDocuments, setListDocuments] = useState([]);
  const [legalStatus, setLegalStatus] = useState('');
  const [completeStatus, setCompleteStatus] = useState('');
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [allInteractions, setAllInteractions] = useState([]);
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [optionalDocumentsRequested, setOptionalDocumentsRequested] = useState(
    []
  );
  const dispatch = useDispatch();
  const documentsRef = useRef([]);

  const getListDocument = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getListDocumentsHistApi({
          nid,
          businessType: BUSINESS_TYPE.SELLERS,
          processName: PROCESS.SELLER_AND_LEGAL,
        });
        const { list_documents, error_list, history, review } = response.data;
        updateStatus(review);
        setListDocuments(list_documents);
        setAllInteractions(
          error_list.map((error) => ({ value: error.id, label: error.label }))
        );
        setCommentsHistory(history);
        documentsRef.current = list_documents;
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setListDocuments([]);
        } else {
          console.log('error:::', error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  const getOptionalDocuments = useCallback(async () => {
    const response = await getListOptionalDocumentsApi({
      nid: propertyData.nid,
      businessType: BUSINESS_TYPE.SELLERS,
      processName: PROCESS.SELLER_AND_LEGAL,
    });
    setOptionalDocuments(response.data.list_optional_documents);
  }, [propertyData.nid]);

  useEffect(() => {
    if (propertyData.nid) {
      getListDocument(propertyData.nid);
      getOptionalDocuments();
    } else {
      setListDocuments([]);
      dispatch(dataNotFound());
    }
  }, [propertyData.nid, getListDocument, getOptionalDocuments, dispatch]);

  useEffect(() => {
    const getLegalStatus = () => {
      if (
        listDocuments.filter(
          (document) => document.status === DOCUMENT_STATUS.REJECTED
        ).length > 0
      ) {
        setLegalStatus(DOCUMENT_STATUS.REJECTED);
      } else if (
        !listDocuments.some(
          (document) => document.status !== DOCUMENT_STATUS.SUCCESS
        )
      ) {
        setLegalStatus(DOCUMENT_STATUS.SUCCESS);
      } else {
        setLegalStatus('');
      }
    };

    if (listDocuments.length > 0) {
      getLegalStatus();
    }
  }, [listDocuments]);

  const handleChangeFile = (status, newDocument) => {
    documentsRef.current = documentsRef.current.map((document) => {
      return document.document_type_id === newDocument.document_type_id
        ? newDocument
        : document;
    });
    setListDocuments([...documentsRef.current]);

    updateStatus(DOCUMENT_STATUS.EDITING);
  };

  const sendNotification = async () => {
    setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
    try {
      await sendEmailNotification({
        nid: propertyData.nid,
        processName: PROCESS.SELLER_AND_LEGAL,
        originUrl: window.location.href,
        businessType: BUSINESS_TYPE.SELLERS,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addOptionalDocument = (document) => {
    if (
      !documentsRef.current.some(
        (doc) => doc.document_type_id === document.document_type_id
      )
    ) {
      documentsRef.current.push(document);
      setListDocuments([...documentsRef.current]);
    }
  };

  const updateStatus = (status) => {
    switch (status) {
      case DOCUMENT_STATUS.REVIEW_DOCUMENTS:
        setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
        setLegalStatus(null);
        break;
      case DOCUMENT_STATUS.EDITING:
        setCompleteStatus(null);
        setLegalStatus(null);
        break;
      case DOCUMENT_STATUS.SUCCESS:
        setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
        setLegalStatus(DOCUMENT_STATUS.SUCCESS);
        break;
      default:
        setCompleteStatus(null);
        setLegalStatus(null);
    }
  };

  const isCompleteDisabled = () => {
    return listDocuments.some((doc) => doc.required && !doc.exist);
  };

  const getOptionsOptionalDocuments = () => {
    return optionalDocuments.map((doc) => ({
      label: doc.label,
      value: doc.document_type_id,
    }));
  };

  const saveComment = async (comment, interactionsList) => {
    const errorList = interactionsList.map((interaction) => ({
      id: interaction.value,
      label: interaction.label,
    }));

    try {
      await saveProcessComment({
        nid: propertyData.nid,
        processName: PROCESS.SELLER_AND_LEGAL,
        agent: loginData.data.email,
        nameReviewerRole: loginData.data.role[0],
        comment,
        errorList,
        originUrl: window.location.href,
      });
      setCommentsHistory([
        {
          comment,
          update_date: new Date(),
          role: loginData.data.role[0],
        },
        ...commentsHistory,
      ]);
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  const documentsRequest = () => {
    sendEmailNotification({
      nid: propertyData.nid,
      processName: PROCESS.SELLER_AND_LEGAL,
      agent: loginData.data.email,
      originUrl: window.location.href,
      businessType: BUSINESS_TYPE.SELLERS,
      optionalDocumentsList: optionalDocumentsRequested.map((doc) => doc.label),
    });
    setOptionalDocumentsRequested([]);
  };

  return (
    <EmptyData>
      <DocumentsPromiseOfSaleWrapper>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>Documentos PCV</Title>
          <DocumentsGrid>
            {listDocuments.map((document, i) => (
              <DocumentComponent
                key={`document-${i}`}
                lastItemRow={(i + 1) % 5 === 0 ? true : false}
                documentParam={document}
                showReview={true}
                businessTypeId={BUSINESS_TYPE.SELLERS}
                reviewer={ROLE.LEGAL}
                handleUpload={handleChangeFile}
                handleDelete={handleChangeFile}
                processName={PROCESS.SELLER_AND_LEGAL}
              />
            ))}
            <OptionalDocument
              businessType={BUSINESS_TYPE.SELLERS}
              processName={PROCESS.SELLER_AND_LEGAL}
              handleAddDocument={addOptionalDocument}
              optionalDocuments={optionalDocuments}
            />
          </DocumentsGrid>
          <div className="select-grid">
            <MultiSelect
              options={getOptionsOptionalDocuments()}
              initOptions={[]}
              label="Documentos opcionales faltantes"
              value={optionalDocumentsRequested}
              onChange={setOptionalDocumentsRequested}
            />
            <Button onClick={documentsRequest}>Solicitar</Button>
          </div>
          <ButtonGrid>
            <ButtonCheck
              label="Documentos comercial completos"
              status={completeStatus}
              disabled={isCompleteDisabled()}
              handleChange={sendNotification}
              key="completeStatus"
            />
            <ButtonCheck
              label={`${
                LABELS_BUTTON_CHECK[legalStatus] ?? LABELS_BUTTON_CHECK.REVISION
              } legal`}
              status={legalStatus}
              disabled={true}
              key="legalStatus"
            />
          </ButtonGrid>
        </Container>
        <RightSection>
          <Comments
            allInteractions={allInteractions}
            commentsHistory={commentsHistory}
            handleSaveComment={saveComment}
          />
        </RightSection>
      </DocumentsPromiseOfSaleWrapper>
    </EmptyData>
  );
};

export default DocumentsPromiseOfSale;
