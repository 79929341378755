import styled from 'styled-components';
import { muliBoldFamily } from '../../StylesConstants';

export const ChoiceContractTypeWrapper = styled.div`
  width: 240px;
  margin-bottom: 42px;
  .label {
    font-family: ${muliBoldFamily};
    margin-bottom: 32px;
  }
  .choice-grid-container {
    height: 42px;
  }
  .select__dropdown-indicator {
    position: inherit !important;
  }
  .select__menu {
    max-width: 100% !important;
    left: 0 !important;
    cursor: pointer;
  }
  .select__control {
    cursor: pointer;
  }
`;
