import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { BuyersFormWrapper } from './style';
import Button from '../../../../components/shared/button/button';
import { BUSINESS_PUBLICATION, BUYERS_FORM } from '../../../../utils/constant';
// import SelectComponent from "@habi/habi-react-components/dist/Select/select";
import CustomDatePicker from '../../../../components/shared/CustomDatepicker/CustomDatepicker';
import { addHours, format } from 'date-fns';
import { emptyData } from './utils/emptyData';
import { tagOptions } from './utils/tagOptios';
import WarningDialog from '../../../../components/WarningDialog/warningDialog';
import { CustomSelect } from '../../../../components/Select/CustomSelect';
import { DYNAMIC_BUYERS_FORM } from '../../../../utils/dynamic-constant';
import * as Yup from 'yup';

const BuyersForm = ({
  data,
  handleSubmit,
  showConfirm,
  showConfirmModal,
  closeConfirm,
}) => {
  const [keyFirstTagField, setKeyFirstTagField] = useState('');
  const [tagFieldDisabled, setTagFieldDisabled] = useState(false);
  const [tagOptionsState] = useState(tagOptions);
  const [dataFile, setDataFile] = useState({});
  const [labelConfirm, setLabelConfirm] = useState('');
  const [labelsDateConfirm, setLabelDateConfirm] = useState([]);
  const [cleanData, setCleanData] = useState({});

  useEffect(() => {
    if (data) {
      setDataFile(data);
    } else {
      setDataFile(emptyData);
    }
  }, [data]);

  const { ctl_date, registration_ticket_date, tag_one, tag_two, tag_three } =
    dataFile;

  let valueFirstTagField = dataFile[`${keyFirstTagField}_status`];

  const getInitialValues = () => {
    const initialValues = {
      [keyFirstTagField]: valueFirstTagField,
      [keyFirstTagField === 'tag_change' ? 'temporal_tag' : 'tag_change']: '',
      registration_ticket_date: registration_ticket_date
        ? addHours(new Date(registration_ticket_date), 5)
        : null,
      ctl_date: ctl_date ? addHours(new Date(ctl_date), 5) : null,
    };
    return initialValues;
  };

  const getSelect = (name, label, disabled, props) => {
    const selected = { value: '', label: 'Seleccione' };

    const newValues = [selected, ...tagOptionsState];

    const defaultValue = tagOptionsState.find(
      (tag) => tag.value === valueFirstTagField
    );

    return (
      <>
        <CustomSelect
          name={name}
          label={label}
          disabled={disabled}
          options={newValues}
          defaultValue={defaultValue}
          formikError={props.errors[keyFirstTagField]}
          labelError={BUYERS_FORM.CHOOSE_TAG_ERROR}
        />
      </>
    );
  };

  const getTimePicker = (formikProps, name, label) => {
    let { errors, values, setFieldError, setFieldValue, handleChange } =
      formikProps;

    return (
      <CustomDatePicker
        label={label}
        name={name}
        id={name}
        errors={errors}
        values={values}
        setFieldError={setFieldError}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
      />
    );
  };

  const formatIfDate = (value) => {
    let valuefield;
    if (typeof value === 'object' && value instanceof Date) {
      valuefield = format(new Date(value), 'yyyy-MM-dd');
    } else {
      valuefield = value;
    }
    return valuefield;
  };

  const validateIfDateChange = (valueForm, valueResponse) => {
    return formatIfDate(valueForm) !== valueResponse;
  };

  const clearData = (values) => {
    let dataForm = {};
    Object.keys(values).forEach((value) => {
      if (values[value] !== '') {
        switch (value) {
          case BUYERS_FORM.TAG_CHANGE:
            if (values[value] !== dataFile[value]) {
              dataForm = { ...dataForm, [value]: formatIfDate(values[value]) };
            }
            break;
          case BUYERS_FORM.CTL_DATE:
            if (validateIfDateChange(values[value], dataFile[value])) {
              dataForm = { ...dataForm, [value]: formatIfDate(values[value]) };
            }
            break;
          case BUYERS_FORM.REGISTRATION_TICKET_DATE:
            if (validateIfDateChange(values[value], dataFile[value])) {
              dataForm = { ...dataForm, [value]: formatIfDate(values[value]) };
            }
            break;
          default:
            break;
        }
      }
    });
    const datesKeys = Object.keys(dataForm).filter(
      (key) =>
        key === BUYERS_FORM.REGISTRATION_TICKET_DATE ||
        key === BUYERS_FORM.CTL_DATE
    );
    if (datesKeys) {
      const labelsValues = datesKeys.map((key) => DYNAMIC_BUYERS_FORM[key]);

      setLabelDateConfirm(labelsValues);
    }
    setCleanData(dataForm);
    showConfirmModal();
  };

  const getDateLabel = () => {
    let dateLabels = '';
    if (labelsDateConfirm) {
      labelsDateConfirm.forEach((label, index) => {
        if (index === labelsDateConfirm.length - 1) {
          dateLabels += label;
        } else {
          dateLabels += `${label} y `;
        }
      });
    }
    return `Además se te notificará la ${dateLabels} seleccionada/s.`;
  };

  const saveBuyerFolderData = () => {
    if (Object.keys(cleanData).length > 0) {
      handleSubmit(cleanData);
    }
  };

  const chooseValueFirstKey = useCallback(() => {
    setLabelConfirm(BUSINESS_PUBLICATION.CAMBIO_DE_ETIQUETA_DEL_INMUEBLE);
    if (tag_three) {
      setTagFieldDisabled(true);
      return setKeyFirstTagField(BUYERS_FORM.TAG_THREE);
    } else if (tag_two) {
      setTagFieldDisabled(true);
      return setKeyFirstTagField(BUYERS_FORM.TAG_TWO);
    } else if (tag_one) {
      setTagFieldDisabled(true);
      return setKeyFirstTagField(BUYERS_FORM.TAG_ONE);
    } else {
      setTagFieldDisabled(false);
      setLabelConfirm(BUSINESS_PUBLICATION.ETIQUETA_DEL_INMUEBLE);
      return setKeyFirstTagField(BUYERS_FORM.TAG_CHANGE);
    }
  }, [tag_one, tag_two, tag_three]);

  useEffect(() => {
    chooseValueFirstKey();
  }, [dataFile, chooseValueFirstKey]);

  const getYupSchema = () => {
    if (!tagFieldDisabled) {
      return {
        [keyFirstTagField]: Yup.string().required(BUYERS_FORM.CHOOSE_TAG_ERROR),
      };
    } else {
      return {};
    }
  };

  return (
    <BuyersFormWrapper>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={Yup.object(getYupSchema())}
        onSubmit={(values) => {
          clearData(values);
        }}
      >
        {(props) => (
          <>
            <Form>
              <div className="form-container">
                {getSelect(
                  keyFirstTagField,
                  BUSINESS_PUBLICATION.ETIQUETA_DEL_INMUEBLE,
                  tagFieldDisabled,
                  props
                )}
                {tagFieldDisabled &&
                  getSelect(
                    'tag_change',
                    BUSINESS_PUBLICATION.CAMBIO_DE_ETIQUETA_DEL_INMUEBLE,
                    false,
                    props
                  )}
              </div>
              <div className="form-container">
                {getTimePicker(
                  props,
                  'registration_ticket_date',
                  BUSINESS_PUBLICATION.FECHA_DE_BOLETA_DE_REGISTRO
                )}
                {getTimePicker(
                  props,
                  'ctl_date',
                  BUSINESS_PUBLICATION.FECHA_DE_CTL
                )}
                <Button type="submit">{`Guardar cambios`}</Button>
              </div>
              <WarningDialog
                open={showConfirm}
                title={`¿Estás seguro de querer notificar al equipo de remodelaciones?`}
                contentAlert={`Si confirmas la selección de "${labelConfirm}", se le notificará vía 
                  correo electrónico al equipo de remodelaciones. ${labelsDateConfirm.length > 0 ? getDateLabel() : ''
                  }`}
                confirmationText="¿Estás seguro que quieres continuar?"
                cancelButtonText="No enviar"
                confirmButtonText="Aceptar y enviar"
                onCancel={closeConfirm}
                onConfirm={saveBuyerFolderData}
              />
            </Form>
          </>
        )}
      </Formik>
    </BuyersFormWrapper>
  );
};

export default BuyersForm;
