import React from 'react';
import Select from 'react-select'
import { Label } from '../../Styles/genericStyleComponent';

import { getReactSelectStyles } from './style';

const MultiSelect = ({ options, value, label, onChange, withBorder = false }) => {
  return (
    <div>
      {
        label &&
        <Label>
          {label}
        </Label>
      }
      <Select
        value={value}
        isMulti
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        styles={getReactSelectStyles(withBorder)}
        placeholder='Seleccione'
        noOptionsMessage={() => "No hay mas opciones"}
        onChange={onChange}
      />
    </div>
  )
}

export default MultiSelect;
