export const emptyData = {
    ctl_date : null,
    registration_ticket_date : null,
    tag_change : null,
    tag_change_status : null,
    tag_one : null,
    tag_one_status : null,
    tag_two : null,
    tag_two_status : null,
    tag_three : null,
    tag_three_status : null
}