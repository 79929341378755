function registrationTicketData(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_REGISTRATION_TICKET': {
      return { ...state, ...action.payload };
    }
    case 'RESET_REGISTRATION_TICKET': {
      return {};
    }
    case 'UPDATE_ACTIVITY_DATE':{
      const listFundingTypes = state.list_funding_types.map( fundingType => {
        fundingType.list_stages.map( stage => {
          stage.activities_list.map( activity => {
            if(activity.id === action.payload.idActivity) {
              activity.date = action.payload.date;
              activity.change = true;
            }
            return activity
          })
          return stage;
        })
        return fundingType;
      });
      
      return { 
        ...state,  
        list_funding_types: listFundingTypes
      };
    }
    case 'UPDATE_SUB_ACTIVITY_DATE':{
      const listFundingTypes = state.list_funding_types.map( fundingType => {
        fundingType.list_stages.map( stage => {
          stage.activities_list.map( activity => {
            activity.subactivities_list &&
            activity.subactivities_list.map( subActivity =>{
              if(subActivity.id === action.payload.idSubActivity) {
                subActivity.date = action.payload.date;
                subActivity.change = true;
              }
              return subActivity
            })
            return activity
          })
          return stage;
        })
        return fundingType;
      });
      
      return { 
        ...state,  
        list_funding_types: listFundingTypes
      };
    }
    case 'CLEAN_CHANGES': {
      const listFundingTypes = state.list_funding_types.map( fundingType => {
        fundingType.list_stages.map( stage => {
          stage.activities_list.map( activity => {
            activity.subactivities_list &&
            activity.subactivities_list.map( subActivity =>{
              subActivity.change = false;
              return subActivity
            })
            activity.change = false;
            return activity
          })
          return stage;
        })
        return fundingType;
      });
      
      return { 
        ...state,  
        list_funding_types: listFundingTypes
      };
    }
    default:
      return state;
  }
}

export default registrationTicketData;