import React from "react";

import { useSelector } from "react-redux";
import { ContainerPage } from '../components/Styles/genericStyleComponent';
import { LayoutWrapper } from "./style";
import Header from './Header/Header';
import SideMenu from './SideMenu/SideMenu';

const Layout = (props) => {

  const isAuthenticated = useSelector(state => state.loginData.isAuthenticated);

  return (
    <>
      {isAuthenticated
        ? <>
          <Header />
          <LayoutWrapper>
            <SideMenu />
            <ContainerPage>
              {props.children}
            </ContainerPage>
          </LayoutWrapper>
        </>
        : <ContainerPage>
          {props.children}
        </ContainerPage>
      }
    </>
  );
};

export default Layout;
