import React from 'react';
import Stage from '../Stage/stage';
import { RegistrationTicketTreeWrapper } from './style';

const RegistrationTicketTree = ({ fundingType, saveRegistrationTicket }) => {

  return <RegistrationTicketTreeWrapper> 
    {
      fundingType &&
      fundingType.list_stages &&
      fundingType.list_stages.map( stage => 
        <Stage 
          saveRegistrationTicket={saveRegistrationTicket}
          stage={ stage }
          stageId={ stage.id }
          key={ `stage${ stage.id }` } />
      )
    }
  </RegistrationTicketTreeWrapper>;
}
 
export default RegistrationTicketTree;