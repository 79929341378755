import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import useDynamicForm from '../../../hooks/useDynamicForm';
import { FormWrapper } from './style';
import {
  ALL_LEGAL_ROLES,
  BUYER_RECEPTION_CONSTANTS,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  FIELD_NAME,
  PROCESS_EVENTS,
  ROLE,
} from '../../../utils/constant';
import { OPTIONS_VALIDATION } from '../../../utils/dynamic-constant';

import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import DocumentComponent from '../../../components/DocumentComponent/documentComponent';
import Section from '../../../components/DynamicForm/Section/section';
import Button from '../../../components/shared/button/button';
import ButtonCheck from '../../../components/ButtonCheck/buttonCheck';
import RadioOption from '../../../components/RadioOption/radioOption';
import {
  getButtonSelected,
  getEventStatus,
} from '../../../services/eventsService';
import { useSelector } from 'react-redux';
import { updateDocuments } from '../../../services/documentsService';

export const ReceptionForm = ({
  data,
  saveData,
  loadingSave,
  documents,
  events,
  businessType,
  processName,
  callEvent,
  addMultipleSection,
  setDocuments,
  handleSaveComment,
  complianceList,
  getData,
}) => {
  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave,
    haveChangeData,
  ] = useDynamicForm(data);

  const [disabledValidation, setDisabledValidation] = useState(false);
  const [buttonValidationCustomer, setButtonValidationCustomer] = useState({});
  const [requestReviewDisabled, setRequestReviewDisabled] = useState(false);
  const userRoles = useSelector((state) => state.loginData.data.role);

  const saveReceptionData = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      await saveData(finalValues);
      getData(true);
    }
  };

  const getValidateAndSubmitCallback = (validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        console.error(errors);
      }
    });
  };

  const validateAndSubmit = async (validateForm, submitForm) => {
    await getValidateAndSubmitCallback(validateForm);
    submitForm();
  };

  const changeClientValidation = (clientValidation, complianceId) => {
    callEvent(
      `${PROCESS_EVENTS.VALIDATION}__${complianceId}`,
      clientValidation
    );
    setButtonValidationCustomer((prevState) => {
      return { ...prevState, [complianceId]: clientValidation };
    });
  };

  const disabledCustomerValidation = useCallback(
    (status, newDocument) => {
      if (documents && newDocument) {
        setDocuments((docs) => updateDocuments(newDocument, docs));
      }
      const bitFormatDoc = documents.find(
        (doc) => doc.name === BUYER_RECEPTION_CONSTANTS.OFFER_FORMAT
      );
      const isDocumentUploaded = bitFormatDoc?.exist;
      const isOfficer = userRoles.includes(ROLE.COMPLIANCE_OFFICER);
      if (isDocumentUploaded && isOfficer) {
        setDisabledValidation(false);
      } else {
        setDisabledValidation(true);
      }
    },
    [documents, setDocuments, userRoles]
  );

  useEffect(() => {
    disabledCustomerValidation();
  }, [documents, disabledCustomerValidation]);

  const getValidationCustomer = useCallback(() => {
    let option;
    let optionValidationConfig = {};
    complianceList.forEach((number) => {
      option =
        OPTIONS_VALIDATION[
          getButtonSelected(events, `${PROCESS_EVENTS.VALIDATION}__${number}`)
        ];
      optionValidationConfig = {
        ...optionValidationConfig,
        [number]: option?.value ?? null,
      };
    });
    if (Object.keys(buttonValidationCustomer).length < 1) {
      setButtonValidationCustomer(optionValidationConfig);
    }
  }, [events, complianceList, buttonValidationCustomer]);

  useEffect(() => {
    getValidationCustomer();
  }, [getValidationCustomer]);

  const getClientValidation = (complianceId) => {
    return (
      <>
        {documents && (
          <div className={`static-buttons-full-width`}>
            <label htmlFor={'clientValidation'} className="radio-button-label">
              Validación de cliente
            </label>
            <div className="radio-buttons">
              {Object.values(OPTIONS_VALIDATION).map((option) => (
                <RadioOption
                  key={`clientValidation${option.value}`}
                  name="clientValidation"
                  label={option.label}
                  value={option.value}
                  selected={
                    buttonValidationCustomer[complianceId] === option.value
                  }
                  setValue={(e) => changeClientValidation(e, complianceId)}
                  round={true}
                  disabled={disabledByDocument(complianceId)}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const getInitialValues = () => {
    const staticInitialValues = {
      clientValidation: '',
    };
    return { ...initialValues, ...staticInitialValues };
  };

  const addMultipleSectionWithCurrentFields = (sectionId, values) => {
    haveChangeData(values);
    addMultipleSection(sectionId);
  };

  const disabledLegalReview = () => {
    let isDisabled = true;
    ALL_LEGAL_ROLES.forEach((rol) => {
      if (userRoles.includes(rol)) {
        isDisabled = false;
      }
    });
    return isDisabled;
  };

  const disabledRequestReview = useCallback(
    (status, newDocument) => {
      if (documents && newDocument) {
        setDocuments((docs) => updateDocuments(newDocument, docs));
      }
      const documentTarget = documents.find(
        (doc) => doc.name === BUYER_RECEPTION_CONSTANTS.OFFER_FORMAT
      );
      const isDocumentUploaded = documentTarget?.exist;
      const isCommercial = userRoles.includes(ROLE.COMMERCIAL_BUYERS);
      const haveRequestType = sections[0]?.fields.find(
        (field) => field.name === FIELD_NAME.REQUEST_TYPE
      )?.value;

      if (isDocumentUploaded && isCommercial && haveRequestType) {
        setRequestReviewDisabled(false);
      } else {
        setRequestReviewDisabled(true);
      }
    },
    [documents, setDocuments, userRoles, sections]
  );

  useEffect(() => {
    disabledRequestReview();
  }, [documents, disabledRequestReview]);

  const disabledByDocument = (complianceId) => {
    const documentTarget = documents.find(
      (doc) => doc.number_seller === complianceId
    );
    const isDocumentUploaded = documentTarget?.exist;
    const isOfficer = userRoles.includes(ROLE.COMPLIANCE_OFFICER);
    let localDisabled = false;
    if (!isOfficer) {
      localDisabled = true;
    } else if (isOfficer && !isDocumentUploaded) {
      localDisabled = true;
    } else if (isOfficer && isDocumentUploaded) {
      localDisabled = false;
    }
    const isDisabled = disabledValidation || localDisabled;
    return isDisabled;
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues()}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          saveReceptionData(values);
        }}
      >
        {(props) => (
          <>
            <Form className="form-container">
              {sections.map((section) => (
                <Section
                  section={section}
                  formikProps={props}
                  key={`section${section.id}`}
                  addMultipleSection={addMultipleSectionWithCurrentFields}
                  labelButton={'Comprador'}
                  requestDocumentField={sections[0].fields[0]}
                  handleSaveComment={handleSaveComment}
                />
              ))}
              <h2 className="title">Carga formato de oferta</h2>
              <div className="document-container">
                <DocumentComponent
                  documentParam={documents.find(
                    (doc) => doc.name === DOCUMENT_TYPE_NAME.OFFER_FORMAT
                  )}
                  showReview={userRoles.includes(ROLE.COMPLIANCE_OFFICER)}
                  reviewer={ROLE.COMPLIANCE_OFFICER}
                  businessTypeId={businessType}
                  processName={processName}
                  handleUpload={disabledCustomerValidation}
                  handleDelete={disabledCustomerValidation}
                  eventReview={PROCESS_EVENTS.OFFERING_FORMAT}
                />
              </div>
              <hr className="line-separator" />
              {sections.length > 0 && (
                <div className="form-button-container">
                  <Button
                    type="submit"
                    loading={loadingSave}
                    onClick={() =>
                      getValidateAndSubmitCallback(props.validateForm)
                    }
                  >
                    Guardar
                  </Button>
                </div>
              )}
              <hr className="line-separator" />
              <ButtonCheck
                label={'Solicitar revisión'}
                status={getEventStatus(events, PROCESS_EVENTS.REVIEW_REQUEST)}
                handleChange={() =>
                  callEvent(
                    PROCESS_EVENTS.REVIEW_REQUEST,
                    DOCUMENT_STATUS.SUCCESS
                  )
                }
                disabled={requestReviewDisabled}
              />
              <hr className="line-separator" />
              <>
                <div>
                  <h2 className="title">Oficial de cumplimiento</h2>
                  {complianceList.map((complianceId) => (
                    <div key={`complianceOfficer${complianceId}`}>
                      {getClientValidation(complianceId)}
                      {userRoles.includes(ROLE.COMPLIANCE_OFFICER) && (
                        <div className="document-container">
                          <DocumentComponent
                            documentParam={documents.find(
                              (doc) =>
                                doc.number_seller === complianceId &&
                                doc.name === DOCUMENT_TYPE_NAME.COMPLIANCE_PROOF
                            )}
                            businessTypeId={businessType}
                            processName={processName}
                            handleUpload={disabledCustomerValidation}
                            handleDelete={disabledCustomerValidation}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <hr className="line-separator" />
              </>
              <ButtonCheck
                label={'Revisión legal'}
                status={getEventStatus(events, PROCESS_EVENTS.LEGAL_REVIEW)}
                handleChange={() =>
                  callEvent(
                    PROCESS_EVENTS.LEGAL_REVIEW,
                    DOCUMENT_STATUS.SUCCESS
                  )
                }
                disabled={disabledLegalReview()}
              />
            </Form>
            <RouteLeavingGuard
              shouldBlockNavigation={() => haveChangeData(props.values)}
              handleConfirm={() =>
                validateAndSubmit(props.validateForm, props.submitForm)
              }
            />
          </>
        )}
      </Formik>
    </FormWrapper>
  );
};
