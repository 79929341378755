import styled from "styled-components";
import { nightBlue200, openSansRegular, plainlightGray, primary, white } from '../../../StylesConstants';

export const InputActionWrapper = styled.div`

  border: 1px solid ${plainlightGray};
  display: grid;
  grid-template-columns: auto 40px;
  border-radius: 8px;
  overflow: hidden;

  input {
    font: 13px ${openSansRegular};
    padding: 8px 16px;
    border: 0;
    outline: none;
  }

  input::placeholder {
    color: ${nightBlue200};
  }

  .action {
    cursor: pointer;
    background-color: ${primary};
    color: ${white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action svg {
    width: 18px;
  }

`;