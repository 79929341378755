export function createValidationData(fields) {
  let initialValuesObj = {};
  let validationArrayJson = [];

  for (let index = 0; index < fields.length; index++) {
    const field = fields[index];

    if (field.type_field === "inputDate" && field.value === "") {
      initialValuesObj = Object.assign(
        {},
        {
          [field.name]: null
        },
        initialValuesObj
      );
    } else {
      initialValuesObj = Object.assign(
        {},
        {
          [field.name]: field.value,
        },
        initialValuesObj
      );
    }

    const validation = getValidationJsonField(field);
    if (validation) {
      validationArrayJson.push(validation);
    }
  }

  const validationSchema = {
    initialValues: initialValuesObj,
    validationYupJson: {
      validationArrayJson,
    },
  };

  return validationSchema;
}

export function getValidationJsonField(field) {
  if (field.required) {
    return {
      id: field.name,
      validationType: field.type_field === "inputNumber" ? "number" : "string",
      validations: [
        {
          type: field.required ? "required" : null,
          params: ["Campo requerido"],
        },
        {
          type:
            field.typeInput === "inputNumber" && field.validations.required
              ? "test"
              : null,
          params: [
            "test-min-number-required",
            `El campo es requerido con un valor mayor a 0`,
            function (value) {
              if (
                value === undefined ||
                value === 0 ||
                value === "" ||
                value === "$0"
              ) {
                return false;
              }
              return true;
            },
          ],
        },
      ],
    };
  }
}
