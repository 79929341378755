import { Form, Formik } from 'formik';
import React from 'react';
import useDynamicForm from '../../../hooks/useDynamicForm';
import { FormWrapper } from '../../../pages/BuyerReception/ReceptionForm/style';
import Button from '../../shared/button/button';
import { SaveButtonContainer } from '../../Styles/genericStyleComponent';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { formWithButtonData } from '../../../services/data/formWithButtonData';
import { useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dataNotFound } from '../../../redux/actions/propertyData';
import Section from '../Section/section';

const FormWithButton = ({
  saveData,
  loadingSave,
  nid,
  businessType,
  processName,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState();
  const [sections, initialValues, validationSchema, cleanDataToSave, ,] =
    useDynamicForm(form);

  const getFormData = useCallback(async () => {
    try {
      const formData = await formWithButtonData(nid, businessType, processName);
      setForm(formData.form);
    } catch (error) {
      console.error(error);
    }
  }, [nid, businessType, processName]);

  useEffect(() => {
    nid ? getFormData() : dispatch(dataNotFound());
  }, [nid, getFormData, dispatch]);

  const saveForm = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      await saveData(finalValues);
    }
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          saveForm(values);
        }}
      >
        {(props) => (
          <Form>
            {sections.map((section) => (
              <Section
                section={section}
                formikProps={props}
                key={`section${section.id}`}
              />
            ))}
            {sections.length > 0 && (
              <SaveButtonContainer>
                <Button
                  dataId={'button-save'}
                  type="submit"
                  loading={loadingSave}
                >
                  Guardar
                </Button>
              </SaveButtonContainer>
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default FormWithButton;
