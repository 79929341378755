import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Button from '../../../../components/shared/button/button';
import { FormWrapper } from "./style";
import useDynamicForm from "../../../../hooks/useDynamicForm";
import Section from "../../../../components/DynamicForm/Section/section";
import RouteLeavingGuard from "../../../../components/RouteLeavingGuard/RouteLeavingGuard";

const PropertyForm = ({ data, saveData, loading }) => {

  const [
    sections, 
    initialValues, 
    validationSchema, 
    cleanDataToSave,
    haveChangeData
  ] = useDynamicForm(data);

  const savePropertyData = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      saveData(finalValues);
    }
  };

  const getValidateAndSubmitCallback = (validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        console.log('ERRORS:', errors);
      }
    });
  };

  const validateAndSubmit = async (validateForm, submitForm) => {
    await getValidateAndSubmitCallback(validateForm);
    submitForm();
  }

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          savePropertyData(values);
        }}
      >
        {(props) => (
          <>
            <Form className="form-container">
              { sections.map( section => 
                <Section
                  section={section}
                  formikProps={props}
                  key={`section${ section.id }`}
                />
              )}

              {sections.length > 0 && (
                <div className="form-button-container">
                  <Button typeClass="secondary" type="reset">
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    loading={loading}
                    onClick={() =>
                      getValidateAndSubmitCallback(props.validateForm)
                    }
                  >
                    Guardar cambios
                  </Button>
                </div>
              )}
            </Form>
            <RouteLeavingGuard 
              shouldBlockNavigation={ () => haveChangeData(props.values) }
              handleConfirm={ () => validateAndSubmit(props.validateForm, props.submitForm) }
            />
          </>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default PropertyForm;
