import React, { useEffect, useState } from 'react';

import RadioOption from "../RadioOption/radioOption";
import { FormGrid, Label } from '../Styles/genericStyleComponent';
import { GroupRadioOptionWrapper } from './style';

const GroupRadioOption = ({ initialValue, handleChange, label, options, name }) => {

  const [optionSelected, setOptionSelected] = useState( initialValue );

  useEffect(() => {
    setOptionSelected( initialValue );
  }, [initialValue]);

  const changeOption = (newOption) => {
    setOptionSelected(newOption);
    handleChange(newOption)
  }

  return (
    <GroupRadioOptionWrapper>
      <Label>
        {label}
      </Label>
      <FormGrid>
        {
          options.map( (option, index) => 
            <RadioOption 
              name={ name }
              label={ option.label } 
              value={ option.name } 
              selected={ optionSelected === option.name }
              setValue={ changeOption } 
              key={ `option-${ name }${ index }` } 
            />
          )
        }
      </FormGrid>
    </GroupRadioOptionWrapper>
  )
}
 
export default GroupRadioOption;
