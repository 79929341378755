import { getBusinessDataApi } from '../../apis/Common/commonApis';
import { getDocumentsProcess, getEventsProcess, getHistoryComments } from '../../apis/Process/processApis';
import { HTTP_STATUS } from "../../utils/constant";

export const getPcvData = ({ nid, businessType, processName }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const business = await getBusinessDataApi(nid);
      const documents = await getDocumentsProcess({
        nid,
        businessType,
        processName, 
        sections: false
      });
      const events = await getEventsProcess({
          nid,
          businessTypeId: businessType,
          processName
      });
      const comments = await getHistoryComments({
          nid,
          processName
      });
      resolve({
        business: business.data,
        documents: documents.data.docs.filter(doc => doc.required || doc.exist),
        optionalDocuments: documents.data.docs.filter(doc => !doc.required),
        events: events.data.data.events,
        comments: comments.data.history ?? [],
        interactions: comments.data.error_list.map((error) => ({
          value: error.id,
          label: error.label,
        })),
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          business: {},
          documents: [],
          optionalDocuments: [],
          events: [],
          comments: [],
          interactions: []
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
