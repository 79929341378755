import styled from "styled-components";
import { primaryGray } from "../../StylesConstants";

export const DisbursementWrapper = styled.div`
  .save-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-bottom: 48px;
  }

  .save-button-container > button {
    grid-column: 2 / 3;
  }

  .options-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 40px;
    margin-bottom: 48px;

    .align-center {
      display: flex;
      align-items: center;
    }
  }

  .line-separator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${primaryGray};
    margin: 24px 0px 40px;
    padding: 0;
  }
`;
