import { useCallback, useEffect, useState } from 'react';
import { DYNAMIC_FORM, FIELD_TYPE } from '../utils/constant';

import { createValidationData } from '../components/shared/forms/validationForms';
import { createYupSchema } from '../components/shared/forms/yupSchemaCreator';

const useDynamicForm = (data) => {
  const [sections, setSections] = useState([]);
  const [initialFields, setInitialFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [changedFields, setChangedFields] = useState([]);
  const [complianceList, setComplianceList] = useState([]);

  const getListCompliance = useCallback(() => {
    let list = [];
    let sectionMultiple = sections.find(
      (section) => section.name === DYNAMIC_FORM.DETAILS_FROM_THE_SELLER
    );
    if (sectionMultiple && sectionMultiple.number_times) {
      for (let i = 1; i <= sectionMultiple.number_times; i++) {
        list = [...list, i];
      }
    }
    setComplianceList(list);
  }, [sections]);

  useEffect(() => {
    if (data && data.sections) {
      const fields = getFieldsFromSections(data.sections);
      if (changedFields.length === 0) {
        createInitialValues(fields);
      }
      setSections(data.sections);
      setInitialFields(fields);
      getListCompliance();
    }
  }, [data, changedFields, getListCompliance]);

  const getFieldsFromSections = (sections) => {
    let fields = [];
    for (const section of sections) {
      if (section.is_multiple === 1) {
        for (const sectionMultiple of section.fields) {
          fields = [
            ...fields,
            ...sectionMultiple.fields.map((field) => ({
              ...field,
              is_multiple: true,
              field_number_time: sectionMultiple.multiple_part,
              section_id: section.id,
            })),
          ];
        }
      } else {
        fields = [...fields, ...section.fields];
      }
    }
    return fields;
  };

  const createInitialValues = (fields) => {
    const schemaValidation = createValidationData(fields);
    setInitialValues(schemaValidation.initialValues);
    setValidationSchema(
      schemaValidation.validationYupJson.validationArrayJson.reduce(
        createYupSchema,
        {}
      )
    );
  };

  const setNewInitialFields = (values) => {
    setInitialFields(
      initialFields.map((field) => {
        if (
          !field.fixed &&
          values[field.name] !== '' &&
          values[field.name] !== null &&
          values[field.name] !== field.value
        ) {
          field.value = values[field.name];
        }
        return field;
      })
    );
  };

  const fieldsChanged = (values) => {
    let fields = [];
    for (const field of initialFields) {
      if (
        !field.fixed &&
        values[field.name] !== '' &&
        values[field.name] !== null &&
        values[field.name] !== undefined &&
        values[field.name] !== field.value
      ) {
        let fieldMultiple = field.is_multiple
          ? {
              is_multiple: field.is_multiple,
              field_number_time: field.field_number_time,
              section_id: field.section_id,
            }
          : {};

        if (
          field.type_field === FIELD_TYPE.INPUT_CURRENCY &&
          values[field.name] !== parseFloat(field.value)
        ) {
          fields.push({
            ...fieldMultiple,
            type_fields_procedure_id: field.id,
            value: values[field.name],
            type_field: field.type_field,
            field_name: field.name,
          });
        } else if (field.type_field !== FIELD_TYPE.INPUT_CURRENCY) {
          fields.push({
            ...fieldMultiple,
            type_fields_procedure_id: field.id,
            value: values[field.name],
            type_field: field.type_field,
            field_name: field.name,
          });
        }
      }
    }
    const newChangedFields = [...changedFields, ...fields];
    setChangedFields(newChangedFields);
    return newChangedFields;
  };

  const cleanDataToSave = (values) => {
    const fieldsToSave = fieldsChanged(values);
    setNewInitialFields(values);
    setChangedFields([]);
    return fieldsToSave;
  };

  const haveChangeData = (values) => {
    return fieldsChanged(values).length > 0;
  };

  const changeVisibleSection = (indexSection, visible) => {
    setSections((sectionsOld) => {
      const newSections = [...sectionsOld];
      newSections[indexSection].visible = visible;
      return newSections;
    });
  };

  return [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave,
    haveChangeData,
    changeVisibleSection,
    complianceList,
  ];
};

export default useDynamicForm;
