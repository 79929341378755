import Loader from '@habi/habi-react-components/dist/Loader/loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { lifeCycleDataService } from '../../services/data/lifeCycleData';
import { BUSINESS_TYPE } from '../../utils/constant';
import { SubTitleMontserrat } from '../Styles/genericStyleComponent';
import Step from './components/Step';
import { LifeCycleWrapper } from './style';

export const LifeCycle = ({ title, miniLifeCycle = false }) => {
  const nid = useSelector((state) => state.propertyData.nid);
  const showLifeCycle = useSelector(state => state.configurationData.showLifeCycle);

  const [stepMenu, setStepMenu] = useState('');
  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    const data = await lifeCycleDataService({
      nid,
      businessType: BUSINESS_TYPE.SELLERS,
    });
    setData(data);
  }, [nid]);

  useEffect(() => {
    if (nid) {
      getData();
    }
  }, [nid, getData]);

  const showStepMenu = (stepName) => {
    setStepMenu(stepName);
  };

  const hideStepMenu = () => {
    setStepMenu('');
  };

  return (
    <>
      {
        (showLifeCycle || !miniLifeCycle) &&
        <>
          {title && <SubTitleMontserrat>{title}</SubTitleMontserrat>}
          <LifeCycleWrapper
            miniLifeCycle={miniLifeCycle}
            isLoading={data.length === 0}
          >
            {data.length === 0 && (
              <div className="loader-container">
                <Loader />
              </div>
            )}
            <div className="steps-container">
              {!!data.length &&
                data.map((step, index) => (
                  <Step
                    key={`${step.name}_${index}`}
                    index={index}
                    step={step}
                    lengthOfList={data.length}
                    handleClick={showStepMenu}
                    stepMenuSelected={stepMenu}
                    hideStepMenu={hideStepMenu}
                    nextStepStatus={data[index + 1] ? data[index + 1].status : null}
                    miniLifeCycle={miniLifeCycle}
                  />
                ))}
            </div>
            <div className="labels-container">
              {!!data.length &&
                data.map((step, index) => (
                  <span key={step.label + index} className="label">
                    {step.label}
                  </span>
                ))}
            </div>
          </LifeCycleWrapper>
        </>
      }
    </>
  );
};
