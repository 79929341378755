import React, { useRef } from 'react';
import { StepWrapper } from './style';
import {
  STEP_ICONS,
  STEP_SECTION_ICONS,
} from '../../../utils/dynamic-constant';
import { STEP_STATUS } from '../../../utils/constant';
import useOutsideClick from '../../../hooks/useOutsideClick';

const Step = ({
  index,
  step,
  lengthOfList,
  handleClick,
  stepMenuSelected,
  hideStepMenu,
  miniLifeCycle,
  nextStepStatus,
}) => {
  let menuRef = useRef();

  useOutsideClick(menuRef, hideStepMenu, 'mousedown');

  const barClass = `bar ${step.status}`;

  const getClassVerticalBar = (indexSection) => {
    const nextSectionExist = step.items_section[indexSection + 1];
    if (nextSectionExist) {
      return `vertical-bar ${step.items_section[indexSection + 1].status}`;
    }
  };

  const getBarRightClass = () => {
    const isLast = index === lengthOfList - 1;
    const isInProgress = step.status === STEP_STATUS.IN_PROGRESS;
    let customClassName = `${barClass} `;
    if (
      !isLast &&
      isInProgress &&
      (nextStepStatus === STEP_STATUS.COMPLETED ||
        nextStepStatus === STEP_STATUS.IN_PROGRESS)
    ) {
      customClassName += `${
        isInProgress ? `right-${STEP_STATUS.IN_PROGRESS}` : ' '
      }`;
    } else if (!isLast) {
      customClassName += `${isInProgress ? 'right ' : ' '}`;
    } else {
      customClassName = 'no-display';
    }
    return customClassName;
  };

  return (
    <StepWrapper
      miniLifeCycle={miniLifeCycle}
      isLast={index === lengthOfList - 1}
    >
      <div className={index ? barClass : 'no-display'} />
      <div
        className={`icon-container ${step.status}`}
        onClick={() => handleClick(step.name)}
      >
        <img
          src={STEP_ICONS[step.status] ?? STEP_ICONS[step.name]}
          alt={step.label}
          className={`icon-step`}
        />
        <div className={`floating-circle ${step.status}`} />
        {stepMenuSelected === step.name && (
          <div ref={menuRef} className="menu-container">
            <div className="menu-title">{step.label}</div>
            <ul>
              {step.items_section.map((section, indexSection) => (
                <li key={`${section.label}_${index}`}>
                  <div className="icon-section">
                    <img
                      src={STEP_SECTION_ICONS[section.status]}
                      alt={section.label}
                    />
                    {<div className={getClassVerticalBar(indexSection)} />}
                  </div>
                  <span className={'section-label'}>{section.label}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={getBarRightClass()} />
    </StepWrapper>
  );
};

export default Step;
