import { getUrlTemplateDocument } from '../../apis/Documents/documentsApis';
import { HTTP_STATUS } from '../../utils/constant';

export const getContractTypeDataService = (
  nid,
  businessTypeId,
  processName,
  type
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await getUrlTemplateDocument({
        nid,
        businessTypeId,
        processName,
        type,
      });
      resolve(data);
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          data: {
            id: '',
            url: '',
          },
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
