import API from '../Api';

 
export const getDisbursementData = ({nid}) => {
    return API.get(
      process.env.REACT_APP_GET_DISBURSEMENT_DATA,
      {
        params: {
          nid
        }
      }
    );
  };


export const postDisbursementData = async ({nid, disbursement_type, valuesForm}) => {
    return API.post(
      process.env.REACT_APP_POST_DISBURSEMENT_DATA,
      {
        nid,
        disbursement_type,
        ...valuesForm
      }
    );
  };