import React from 'react'
import { RightSectionWrapper } from './style'

const RightSection = ({ children }) => {
  return (
    <RightSectionWrapper>
      { children }
    </RightSectionWrapper>
  )
}

export default RightSection
