import React from 'react';
import { Field as FormikField } from 'formik';
import SelectComponent from '@habi/habi-react-components/dist/Select/select';

import { FieldWrapper } from './style';
import { FIELD_TYPE } from '../../../../utils/constant';
import { Label } from '../../../../components/Styles/genericStyleComponent';
import DateTimePicker from '../../../../components/shared/CustomDateTimePicker/DateTimePicker/dateTimePicker';

const FORMAT_DATE = 'yyyy-MM-dd HH:mm';

const Field = ({ field, formikProps, disabled, hidden, saveData }) => {
  let { name, id, label, fixed, multiple_values, value } = field;

  let { errors, values, setFieldError, setFieldValue, setFieldTouched } =
    formikProps;

  const getInputDate = () => (
    <div>
      <Label>{label}</Label>
      <DateTimePicker
        name={field.name}
        value={values[field.name] ? values[field.name] : null}
        format={FORMAT_DATE}
        helperText={errors[field.name]}
        error={Boolean(errors[field.name])}
        onError={(error) => {
          if (error !== errors[field.name]) {
            setFieldError(field.name, error);
          }
        }}
        onChange={(date) => {
          setFieldValue(field.name, date, false);
          saveData(date, field);
        }}
        disabled={disabled}
        placeHolder="Selecciona una fecha y hora"
      />
    </div>
  );

  const getSelect = () => {
    const selected = { value: '', label: 'Seleccione' };
    const numberPhone = field.name === 'appraisal_phone_number';
    let defaultSelect = multiple_values.options.find((option) => {
      if (numberPhone) {
        return option.value === values['assigned_appraiser'];
      } else {
        return option.value === value;
      }
    });

    if (!defaultSelect) {
      defaultSelect = selected;
    }

    const newValues = [selected, ...multiple_values.options];
    return (
      <div key={numberPhone ? values[field.name] : ''}>
        <FormikField
          values={values}
          default={defaultSelect}
          options={newValues}
          component={SelectComponent}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name={name}
          id={id}
          isClearable={false}
          handleChange={(e) => {
            setFieldValue('appraisal_phone_number', e);
            saveData(e, field, values);
          }}
          label={label}
          noBorder
          disabled={fixed || disabled}
        />
      </div>
    );
  };

  return (
    <FieldWrapper>
      {!hidden && (
        <div>
          {field.type_field === FIELD_TYPE.SELECT && !hidden && getSelect()}
          {field.type_field === FIELD_TYPE.INPUT_DATE && getInputDate()}
        </div>
      )}
    </FieldWrapper>
  );
};

export default Field;
