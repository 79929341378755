import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import GenericTable from '../../components/GenericTable/GenericTable';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import Pager from '../../components/Pager/pager';
import TabsHeader from '../../components/shared/TabsHeader/tabsHeader';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import { hideLoading, showLoading } from '../../redux/actions/propertyData';
import { paymentRequestData } from '../../services/data/paymentRequestData';
import {
  BUSINESS_TYPE,
  ELEMENTS_PAGER,
  EVENT_STATUS,
} from '../../utils/constant';
import { PAYMENT_REQUEST } from '../../components/GenericTable/constants/tabs';
import { genericTableTitles } from '../../utils/dynamic-constant';
import { PaymentRequestWrapper } from './style';
import { useEvents } from '../../hooks/useEvents';
import { useSelector } from 'react-redux';

const OPTIONS_SELECT = [
  {
    label: 'Aprobado',
    value: EVENT_STATUS.SUCCESS,
  },
  {
    label: 'Rechazado',
    value: EVENT_STATUS.REJECT,
  },
];

const PaymentRequest = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);
  const [currentTab, setCurrentTab] = useState('requests');
  const [, , callEvent] = useEvents();

  const dispatch = useDispatch();
  const agent = useSelector((state) => state.loginData.data.email);

  const getData = useCallback(
    async ({ page }) => {
      setActivePage(page ? page : 1);
      try {
        dispatch(showLoading());
        let { paymentRequest } = await paymentRequestData({
          tab: currentTab,
          page,
          elements: ELEMENTS_PAGER,
        });
        setData(paymentRequest.data.payments);
        setNumberPages(paymentRequest.pages);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(hideLoading());
      }
    },
    [currentTab, dispatch]
  );

  useEffect(() => {
    getData({ page: 1 });
  }, [getData, dispatch]);

  useEffect(() => {
    setData([]);
    getData({ page: 1 });
  }, [currentTab, getData]);

  const handleUpdateButton = ({ nid, processName, eventName, status }) => {
    callEvent({
      nid,
      businessTypeId: BUSINESS_TYPE.SELLERS,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
      email: agent,
    });
  };

  return (
    <PaymentRequestWrapper>
      <Container>
        <LifeCycle miniLifeCycle />
        <Title>Solicitudes de pago</Title>
        <TabsHeader
          tabs={PAYMENT_REQUEST}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <GenericTable
          data={data}
          currentTab={currentTab}
          options={OPTIONS_SELECT}
          handleUpdateButton={handleUpdateButton}
          titleColumns={genericTableTitles[currentTab]}
        />
        {data.length > 0 && (
          <Pager
            numPages={numberPages}
            activePage={activePage}
            onChange={getData}
          />
        )}
      </Container>
    </PaymentRequestWrapper>
  );
};

export default PaymentRequest;
