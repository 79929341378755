import styled from 'styled-components';
import { hintPerano200, muliBoldFamily, openSansBoldFamily, openSansRegular, primary, scarpaGray400 } from '../../StylesConstants';

export const PageMessageWrap = styled.div`

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
      color: ${hintPerano200};
      font: 36px ${muliBoldFamily};
      line-height: 48px;
      margin-bottom: 16px;
    }

  .message {
    font-size: 12px;
    line-height: 24px;
    color: ${scarpaGray400};
    font: 12px ${openSansRegular};
    text-align: center;
  }

  a {
    color: ${primary};
    font: 12px ${openSansBoldFamily};
  }

`;