import styled from "styled-components";
import { secondaryGray } from "../../StylesConstants";

export const BuyersFolderWrapper = styled.div`
    .line-separator {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid ${secondaryGray};
        margin: 24px 0px 40px;
        padding: 0;
    }
`;