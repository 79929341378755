import styled from "styled-components";
import { primary } from "../../StylesConstants";

export const UploadFileWrapper = styled.div`

  .link {
    color: ${ primary };
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 130px;
  }

  .link span {
    text-decoration: underline;
  }

  .icon-link {
    width: 20px;
  }
`;