import styled from 'styled-components';
import { greySoft, hintPerano500, openSansBoldFamily, openSansRegular, scarpaGray100, scarpaGray600, white } from '../../../../StylesConstants';

export const HighLevelTableWrapper = styled.div`
  margin-bottom: 16px;

  .action-column {
    width: 60px;
  }

  .detail-row {
    background-color: ${white} !important;
    > td {
      padding: 8px !important;
    }
  }

  .highlight {
    border-left: 1px solid ${hintPerano500};
    border-right: 1px solid ${hintPerano500};
    &.header {
      border-top: 1px solid ${hintPerano500};
    }
    &.data {
      border-bottom: 1px solid ${hintPerano500};
    }
  }

  table {
    background-color: ${white};
    width: 100%;
    border-collapse: collapse;
    thead {
      font: 12px ${openSansBoldFamily};
      line-height: 14px;
      color: ${scarpaGray600};
      th {
        padding: 8px 12px;
        text-align: start;
      }
      th:not(:last-child) {
        border-right: 1px solid ${scarpaGray100};
      }
    }
    tbody {
      td {
        padding: 5px 12px;
        font: 12px ${openSansRegular};
        line-height: 24px;
      }
      td:not(:last-child) {
        border-right: 1px solid ${scarpaGray100};
      }
      tr:nth-child(odd) {
        background-color: ${greySoft};
      }
    }
  }

  .rs-table-column-group-header-content {
    padding: 0;
  }
  .rs-table-header-row-wrapper {
    font: 12px ${openSansBoldFamily};
    color: ${scarpaGray600};
  }
  .rs-table-body-row-wrapper {
    font: 12px ${openSansRegular};
    color: ${scarpaGray600};
  }
  .rs-table-row-header .rs-table-cell {
    background: #ffffff;
  }
`;

