import React from 'react'

import Loader from "@habi/habi-react-components/dist/Loader/loader";
import { LoadingDataWrap } from './style';
import { useSelector } from 'react-redux';

const LoadingData = (props) => {

  const loading = useSelector( state => state.propertyData.loadingSearch );

  return (
    <>
      { loading
          ? <LoadingDataWrap>
              <Loader />
            </LoadingDataWrap>
          : props.children}
    </>
  )
}

export default LoadingData;
