import { bankPromiseBgta } from './tabs/bankPromiseBgta';
import { desistedSales } from './tabs/desistedSales';
import { honorBoxPromise } from './tabs/honorBoxPromise';
import { letterOfIntent } from './tabs/letterOfIntent';
import { otherBanksPromise } from './tabs/otherBanksPromise';
import { salesRecord } from './tabs/salesRecord';
import { promiseCounted } from './tabs/promiseCounted';
import { sales } from './tabs/sales';
import { legalSales } from './tabs/legalSales';
import { ROLE, ALL_LEGAL_ROLES } from '../../../utils/constant';
import {
  FINANCIAL_ENTITIES,
  NOTARY_SALE,
  PURCHASE_FINANCING,
} from './selectOptions';

const colors = {
  light_blue: '#E0F6FF',
  light_purple: '#E7E5FF',
  light_pink: '#FFDBDB',
  light_blue_strong: '#C7F2EA',
};

const colorSection = {
  BASIC: colors.light_blue,
  ANALYST: colors.light_purple,
  LEGAL: colors.light_pink,
  LEADER: colors.light_blue_strong,
};

export const HIGH_LEVEL_TABS = [
  {
    value: 'high_level_ventas',
    label: 'High Level Ventas',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'input',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: [
            {
              label: 'Habi',
              value: 'habi',
            },
            {
              label: 'PA + Dann',
              value: 'pa_mas_dann',
            },
            {
              label: 'PA + Colpatria',
              value: 'pa_mas_colpatria',
            },
            {
              label: 'Leasing Dann',
              value: 'leasing_dann',
            },
          ],
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'input',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'input',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: sales.basicInformation,
      },
      {
        label: 'INFO. ANALISTAS',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: sales.firstAnalystInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.firstLegalInformation,
      },
      {
        label: 'INFO. LIDER TRAMITES BUYER',
        visible: false,
        color: colorSection.LEADER,
        role_edit: [ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.firstLeaderBuyersProceduresInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.secondLegalInformation,
      },
      {
        label: 'INFO. ANALISTAS',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: sales.secondAnalystInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.thirdLegalInformation,
      },
      {
        label: 'INFO. ANALISTAS',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: sales.thirdAnalystInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.fourthLegalInformation,
      },
      {
        label: 'INFO. ANALISTAS',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: sales.fourthAnalystInformation,
      },
      {
        label: 'INFO. LIDER TRÁMITES BUYERS',
        visible: false,
        color: colorSection.LEADER,
        role_edit: [ROLE.PROCEDURES_LEADER_BUYERS],
        fields: sales.secondLeaderBuyersProceduresInformation,
      },
    ],
  },
  {
    value: 'carta_intencion',
    label: 'Carta de Intención',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: PURCHASE_FINANCING,
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: letterOfIntent.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: letterOfIntent.firstAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: letterOfIntent.secondBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: letterOfIntent.secondAnalystInformation,
      },
    ],
  },
  {
    value: 'promesa_contado',
    label: 'Promesa Contado',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: PURCHASE_FINANCING,
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.firstAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.secondBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.secondAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.thirdBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: promiseCounted.thirdAnalystInformation,
      },
    ],
  },
  {
    value: 'promesa_banco_bogota',
    label: 'Promesa Bco. Bgta',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: PURCHASE_FINANCING,
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.firstAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.secondBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.secondAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.thirdBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.thirdAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.fourthBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: bankPromiseBgta.fourthAnalystInformation,
      },
    ],
  },
  {
    value: 'promesa_caja_honor',
    label: 'Promesa Caja Honor',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: PURCHASE_FINANCING,
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.firstAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.secondBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.secondAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.thirdBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.thirdAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.fourthBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.fourthAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.fifthBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.fifthAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.sixthBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: honorBoxPromise.sixthAnalystInformation,
      },
    ],
  },
  {
    value: 'promesa_otros_bancos',
    label: 'Promesa Otros Bancos',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'purchase_financing',
        label: 'Financiación compra',
        type_field: 'select',
        multiple_values: {
          options: PURCHASE_FINANCING,
        },
      },
      {
        name: 'client_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'real_scripture_date',
        label: 'Fecha escritura real',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.firstAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.secondBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.secondAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.thirdBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.thirdAnalystInformation,
      },
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.fourthBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: otherBanksPromise.fourthAnalystInformation,
      },
    ],
  },
  {
    value: 'registro_ventas',
    label: 'Registro Ventas',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'city',
        label: 'Ciudad',
        type_field: 'input',
      },
      {
        name: 'procedure_analyst',
        label: 'Analista de trámites',
        type_field: 'select',
      },
      {
        name: 'notaria_venta',
        label: 'Notaría venta',
        type_field: 'select',
        fixed: true,
        multiple_values: {
          options: NOTARY_SALE,
        },
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'registry_ticket',
        label: 'Boleta de registro',
        type_field: 'date',
      },
      {
        name: 'ctl_registered_property',
        label: 'Inmueble registrado en CTL',
        type_field: 'date',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: salesRecord.firstBasicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: salesRecord.firstAnalystInformation,
      },
      {
        label: 'INFO. LIDER DE TRÁMITES COMPRADORES',
        visible: false,
        color: colorSection.LEADER,
        role_edit: [ROLE.PROCEDURES_LEADER_BUYERS],
        fields: salesRecord.firstLeaderBuyersProceduresInformation,
      },
    ],
  },
  {
    value: 'legal_ventas',
    label: 'Legal Ventas',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'notaria_venta',
        label: 'Notaría venta',
        type_field: 'select',
        fixed: true,
        multiple_values: {
          options: NOTARY_SALE,
        },
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'payment_method',
        label: 'Forma de pago',
        type_field: 'select',
      },
      {
        name: 'financial_entity',
        label: 'Entidad financiera',
        type_field: 'select',
        multiple_values: {
          options: FINANCIAL_ENTITIES,
        },
      },
      {
        name: 'tentative_promise_date',
        label: 'Fecha tentativa promesa',
        type_field: 'inputDate',
      },
    ],
    sections: [
      {
        label: 'INFO. BÁSICA',
        visible: false,
        color: colorSection.BASIC,
        role_edit: [...ALL_LEGAL_ROLES, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: legalSales.basicInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: legalSales.firstAnalystInformation,
      },
    ],
  },
  {
    value: 'desistidos_ventas',
    label: 'Desistidos Ventas',
    fields: [
      {
        name: 'nid',
        label: 'NID',
        type_field: 'inputNumber',
      },
      {
        name: 'property_name',
        label: 'Nombre',
        type_field: 'input',
      },
      {
        name: 'reason_withdrawal',
        label: 'Razón de desistimiento',
        type_field: 'input',
      },
      {
        name: 'commercial_executive',
        label: 'Comercial buyers',
        type_field: 'select',
      },
      {
        name: 'client_nameclient_name',
        label: 'Nombre del cliente',
        type_field: 'input',
      },
      {
        name: 'promise_date',
        label: 'Fecha firma promesa',
        type_field: 'inputDate',
      },
      {
        name: 'singned_withdrawal_date',
        label: 'Fecha firma cliente desistimiento',
        type_field: 'inputDate',
      },
      {
        name: 'penalty_clause_value',
        label: 'Valor cláusula penal',
        type_field: 'inputCurrency',
      },
    ],
    sections: [
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: desistedSales.firstAnalystInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: desistedSales.firstLegalInformation,
      },
      {
        label: 'INFO. ANALISTA',
        visible: false,
        color: colorSection.ANALYST,
        role_edit: [
          ROLE.PROCEDURES_ANALYST_BUYERS,
          ROLE.PROCEDURES_LEADER_BUYERS,
        ],
        fields: desistedSales.secondAnalystInformation,
      },
      {
        label: 'INFO. LEGAL',
        visible: false,
        color: colorSection.LEGAL,
        role_edit: [ROLE.LEGAL, ROLE.PROCEDURES_LEADER_BUYERS],
        fields: desistedSales.secondLegalInformation,
      },
    ],
  },
];
