import styled from 'styled-components';
import { electricPurple500, openSansRegular, white } from '../../StylesConstants';

export const DocumentViewerWrapper = styled.div`
  
  button {
    background-color: transparent;
    border: 0;
    outline: none;
    margin: 0;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.3;
  }
  
  .options {
    align-items: center;
    background-color: ${electricPurple500};
    border-radius: 0 0 16px 16px;
    color: ${white};
    display: grid;
    font: 16px ${openSansRegular};
    justify-items: center;
    grid-template-columns: 32px 110px 32px 1fr 40px 40px;
    padding: 8px;
    position: absolute;
    width: ${p => p.width-16}px;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${p => p.height}px;
  }

  .page-indicator {
    font-size: 14px;
    user-select: none; 
  }

  .viewer {
    background-color: ${white};
    border-radius: 16px 16px 0 0;
    height: ${p => p.height}px;
    overflow: auto;
    width: ${p => p.width}px;
  }

  .MuiSvgIcon-root {
    color: ${white};
  }

  button {
    display: flex;
  }
`;