import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 256px 1fr;
  margin-top: 52px;
  height: calc(100% - 52px);
  position: absolute;
  width: 100%;
`;
