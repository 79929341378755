import { getDataPaymentRequestApi } from '../../apis/PaymentRequest/registrationTicketApis';
import { HTTP_STATUS } from '../../utils/constant';

export const paymentRequestData = ({ tab, page, elements }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getDataPaymentRequestApi({
        tab,
        page,
        elements,
      });
      resolve({
        paymentRequest: data.data,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          paymentRequestData: {},
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
