import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import RegistrationTicketTree from './components/RegistrationTicketTree/registrationTicketTree';
import { RegistrationTicketWrapper } from './style';
import { saveRegistrationTicketData } from '../../apis/RegistrationTicket/registrationTicketApis';
import {
  setNewFundingType,
  openFirstStages,
} from '../../apis/RegistrationTicket/registrationTicketService';
import {
  cleanChanges,
  resetRegistrationTicket,
  setRegistrationTicket,
} from '../../redux/actions/registrationTicket';
import { dataFound, dataNotFound } from '../../redux/actions/propertyData';
import {
  Container,
  SubTitleOpenSans,
  Title,
  Hr,
} from '../../components/Styles/genericStyleComponent';
import Button from '../../components/shared/button/button';
import RouteLeavingGuard from '../../components/RouteLeavingGuard/RouteLeavingGuard';
import EmptyData from '../../components/EmptyData/emptyData';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import { BUSINESS_TYPE, PROCESS } from '../../utils/constant';
import { getRegistrationTicketData as registrationTicketDataService } from '../../apis/RegistrationTicket/registrationTicketApis';
import { TITLES_REGISTRATION_TICKET } from '../../utils/dynamic-constant';

const RegistrationTicket = ({
  setRegistrationTicketStorage,
  registrationTicketData,
}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state);
  const nid = useSelector((state) => state.propertyData.nid);

  const [fundingTypeSelected, setFundingTypeSelected] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRegistrationTicketData = async () => {
      try {
        const response = await registrationTicketDataService({
          nid,
          process_name: PROCESS.REGISTRATION_TICKET,
          business_type_id: BUSINESS_TYPE.SELLERS,
        });
        let data = response.data.registration_ticket;
        data = openFirstStages(data);
        setInitialData(data);
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No hay datos');
        } else {
          console.log('error:::', error.response);
        }
        dispatch(dataNotFound());
      }
    };

    const setInitialData = (data) => {
      setRegistrationTicketStorage(data);

      setFundingTypeSelected(data.value);
    };

    dispatch(resetRegistrationTicket());

    nid ? getRegistrationTicketData(nid) : dispatch(dataNotFound());
  }, [nid, dispatch, setRegistrationTicketStorage]);

  const getActivitiesWithChange = (stages) => {
    const stagesFiltered = stages
      .map((stage) => {
        const activities = stage.activities_list
          .map((activity) => {
            let subactivities = [];
            if (activity.subactivities_list) {
              subactivities = activity.subactivities_list
                .filter((subactivity) => subactivity.change)
                .map((subactivity) => {
                  return {
                    id: subactivity.id,
                    status: subactivity.status,
                    date: subactivity.date,
                  };
                });
            }
            if (subactivities.length > 0 || activity.change) {
              return {
                id: activity.id,
                status: activity.status,
                date: activity.date,
                subactivities,
              };
            }
            return null;
          })
          .filter((activity) => activity);
        return {
          id: stage.id,
          status: stage.status,
          activities,
        };
      })
      .filter((stage) => stage.activities.length > 0);

    return stagesFiltered;
  };

  const getFundingTypeSelected = (registrationTicketData) => {
    return registrationTicketData.list_funding_types.find(
      (fundingType) => fundingType.name === registrationTicketData.value
    );
  };

  const saveRegistrationTicket = async () => {
    const fundingType = getFundingTypeSelected(registrationTicketData);

    if (fundingType) {
      setLoading(true);
      const activitiesWithChanges = getActivitiesWithChange(
        fundingType.list_stages
      );

      if (activitiesWithChanges.length > 0) {
        const registrationTicketToSave = {
          funding_type: fundingType.id,
          agent: loginData.data.email,
          nid: parseInt(nid),
          stages: activitiesWithChanges,
        };

        try {
          await saveRegistrationTicketData(registrationTicketToSave);
          dispatch(cleanChanges());
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
      setLoading(false);
    }
  };

  const haveChangeData = () => {
    let haveChange = false;
    if (registrationTicketData.list_funding_types) {
      const fundingType = getFundingTypeSelected(registrationTicketData);
      if (
        fundingType &&
        getActivitiesWithChange(fundingType.list_stages).length > 0
      ) {
        haveChange = true;
      }
    }
    return haveChange;
  };

  const changeFundingType = useCallback(() => {
    setFundingTypeSelected(fundingTypeSelected);
    let newStorage = setNewFundingType(
      registrationTicketData,
      fundingTypeSelected
    );
    setRegistrationTicketStorage(newStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingTypeSelected]);

  useEffect(() => {
    changeFundingType();
  }, [changeFundingType]);

  return (
    <EmptyData>
      <RegistrationTicketWrapper>
        <Container>
          <div className="registration-ticket-container">
            <LifeCycle miniLifeCycle />
            <h2 className="title-container">Boleta de Registro</h2>
            <SubTitleOpenSans>Tipo de financiación:</SubTitleOpenSans>
            <Title>
              {TITLES_REGISTRATION_TICKET[registrationTicketData.value]}
            </Title>
            <Hr />
            {registrationTicketData.list_funding_types && (
              <RegistrationTicketTree
                saveRegistrationTicket={saveRegistrationTicket}
                fundingType={registrationTicketData.list_funding_types.find(
                  (item) => item.name === fundingTypeSelected
                )}
              />
            )}
          </div>
          <div className="button-save">
            <Button
              type="submit"
              loading={loading}
              onClick={saveRegistrationTicket}
            >
              Guardar cambios
            </Button>
          </div>
        </Container>
        <RouteLeavingGuard
          shouldBlockNavigation={haveChangeData}
          handleConfirm={saveRegistrationTicket}
        />
      </RegistrationTicketWrapper>
    </EmptyData>
  );
};

const mapStateToProps = (state) => {
  return {
    registrationTicketData: state.registrationTicketData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRegistrationTicketStorage: (loading) =>
    dispatch(setRegistrationTicket(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationTicket);
