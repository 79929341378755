import React from "react";
import { IconWrap } from "./style";
import logo from "../../static/assets/logo/habi.svg";
import { withRouter } from "react-router";
import PiioElement from "../Piio-images/piioElement";

const Logo = (props) => {
  const goToIndex = () => {
    props.history.push("/home");
  };

  return (
    <IconWrap>
      <PiioElement
        tag="img"
        src={logo}
        onClick={() => goToIndex()}
        alt="logo habi"
      />
    </IconWrap>
  );
};
export default withRouter(Logo);
