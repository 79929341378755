import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  EVENT_STATUS,
  PROCESS,
  PROCESS_EVENTS,
  ROLE,
} from '../../utils/constant';
import {
  ButtonGrid,
  Container,
  DocumentsGrid,
  Title,
} from '../../components/Styles/genericStyleComponent';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { getPcvData } from '../../services/data/documentsPcvData';
import { getEventStatus } from '../../services/eventsService';
import { sendEmailNotification } from '../../apis/Notifications/notificationsApis';
import {
  callEventProcess,
  saveProcessComment,
} from '../../apis/Process/processApis';
import { DocumentsPCVWrapper } from './style';

import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import MultiSelect from '../../components/shared/MultiSelect/multiSelect';
import Button from '../../components/shared/button/button';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import RightSection from '../../components/RightSection/rightSection';
import Comments from '../../components/Comments/comments';
import { updateDocuments } from '../../services/documentsService';

const processName = PROCESS.CI_PCV_DOCUMENTS;
const businessType = BUSINESS_TYPE.BUYERS;

const DocumentsPCV = () => {
  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);
  const { loginData } = useSelector((state) => state);

  const [business, setBusiness] = useState({});
  const [documents, setDocuments] = useState([]);
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [events, setEvents] = useState([]);
  const [comments, setComments] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [optionalDocumentsRequested, setOptionalDocumentsRequested] = useState(
    []
  );

  const mapEvents = (tempEvents) => {
    setEvents([
      {
        label: 'Solicitar revisión',
        name: PROCESS_EVENTS.REVIEW_REQUEST,
        status: getEventStatus(tempEvents, PROCESS_EVENTS.REVIEW_REQUEST),
      },
      {
        label: 'Revisión legal',
        name: PROCESS_EVENTS.LEGAL_REVIEW,
        status: getEventStatus(tempEvents, PROCESS_EVENTS.LEGAL_REVIEW),
      },
    ]);
  };

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await getPcvData({ nid, businessType, processName });
      setBusiness(data.business);
      setDocuments(data.documents);
      setOptionalDocuments(data.optionalDocuments);
      setComments(data.comments);
      setInteractions(data.interactions);
      mapEvents(data.events);
      dispatch(dataFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  const addOptionalDocument = (document) => {
    setDocuments((docs) => {
      return docs.some(
        (doc) => doc.document_type_id === document.document_type_id
      )
        ? docs
        : [...docs, document];
    });
  };

  const getOptionsOptionalDocuments = () => {
    return optionalDocuments.map((doc) => ({
      label: doc.label,
      value: doc.document_type_id,
    }));
  };

  const documentsRequest = () => {
    sendEmailNotification({
      nid,
      businessType,
      processName,
      agent: loginData.data.email,
      originUrl: window.location.href,
      optionalDocumentsList: optionalDocumentsRequested.map((doc) => doc.label),
    });
    setOptionalDocumentsRequested([]);
  };

  const saveComment = async (comment, interactionsList) => {
    const errorList = interactionsList.map((interaction) => ({
      id: interaction.value,
      label: interaction.label,
    }));

    try {
      await saveProcessComment({
        nid,
        processName,
        agent: loginData.data.email,
        nameReviewerRole: loginData.data.role[0],
        comment,
        errorList,
        originUrl: window.location.href,
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role: loginData.data.role[0],
        },
        ...comments,
      ]);
    } catch (error) {
      console.error({ error });
    }
  };

  const isEventDisabled = (eventName, status) => {
    switch (eventName) {
      case PROCESS_EVENTS.REVIEW_REQUEST:
        return documents.some((doc) => doc.required && !doc.exist);

      case PROCESS_EVENTS.LEGAL_REVIEW:
        return (
          status === EVENT_STATUS.SUCCESS ||
          status === EVENT_STATUS.REJECT ||
          !business.buyer_reception_format_legal_review ||
          documents.some(
            (doc) => doc.required && doc.status !== DOCUMENT_STATUS.SUCCESS
          ) ||
          getEventStatus(events, PROCESS_EVENTS.REVIEW_REQUEST) !==
            EVENT_STATUS.SUCCESS
        );

      default:
        return true;
    }
  };

  const updateEvents = (events, eventName, status) => {
    return events.map((event) => {
      return event.name === eventName
        ? {
            name: eventName,
            status: status === EVENT_STATUS.SUCCESS ? status : null,
            label: event.label,
          }
        : event;
    });
  };

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: businessType,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
    });
    setEvents((evts) => updateEvents(evts, eventName, status));
  };

  const documentUpdate = (status, newDocument) => {
    setDocuments((docs) => updateDocuments(newDocument, docs));

    if (!newDocument.exist) {
      callEvent(PROCESS_EVENTS.REVIEW_REQUEST, EVENT_STATUS.REVIEW);
      callEvent(PROCESS_EVENTS.LEGAL_REVIEW, EVENT_STATUS.REVIEW);
    } else if (
      getEventStatus(events, PROCESS_EVENTS.REVIEW_REQUEST) ===
      EVENT_STATUS.SUCCESS
    ) {
      callEvent(PROCESS_EVENTS.REVIEW_REQUEST, EVENT_STATUS.REVIEW);
      callEvent(PROCESS_EVENTS.LEGAL_REVIEW, EVENT_STATUS.REVIEW);
    }
  };

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  return (
    <EmptyData>
      <LoadingData>
        <DocumentsPCVWrapper>
          <Container>
            <Title>Documentos CI / PCV</Title>
            <DocumentsGrid>
              {documents.map((document, i) => (
                <DocumentComponent
                  key={`document-${i}`}
                  businessTypeId={businessType}
                  processName={processName}
                  documentParam={document}
                  showReview={true}
                  reviewer={ROLE.LEGAL}
                  handleUpload={documentUpdate}
                  handleDelete={documentUpdate}
                  eventReview={PROCESS_EVENTS.LEGAL_REVIEW}
                />
              ))}
              <OptionalDocument
                businessTypeId={businessType}
                processName={processName}
                handleAddDocument={addOptionalDocument}
                optionalDocuments={optionalDocuments}
              />
            </DocumentsGrid>
            <div className="select-grid">
              <MultiSelect
                options={getOptionsOptionalDocuments()}
                initOptions={[]}
                label="Documentos opcionales faltantes"
                value={optionalDocumentsRequested}
                onChange={setOptionalDocumentsRequested}
              />
              <Button onClick={documentsRequest}>Solicitar</Button>
            </div>
            <ButtonGrid>
              {events.map((event) => (
                <ButtonCheck
                  label={event.label}
                  status={event.status}
                  handleChange={() =>
                    callEvent(event.name, DOCUMENT_STATUS.SUCCESS)
                  }
                  disabled={isEventDisabled(event.name, event.status)}
                />
              ))}
            </ButtonGrid>
          </Container>
          <RightSection>
            <Comments
              allInteractions={interactions}
              commentsHistory={comments}
              handleSaveComment={saveComment}
            />
          </RightSection>
        </DocumentsPCVWrapper>
      </LoadingData>
    </EmptyData>
  );
};

export default DocumentsPCV;
