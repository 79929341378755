import styled from "styled-components";
import {  
  scarpaGray400, 
  openSansRegular,
  brightTurquoise700,
  brightTurquoise100,
  goldenYellow700,
  goldenYellow100
} from "../../../../StylesConstants";

export const RowGenericTableWrapper = styled.tr`
  .index {
    color: ${scarpaGray400}
  }

  .badge-field {
    min-width: 80px;
  }

  .sub-field {
    display: grid;
    min-width: 80px;

    span:nth-child(2n) {
      font-size: 10px;
      line-height: 10px;
      color: ${scarpaGray400};
      text-overflow: ellipsis;
      height: 10px;
      overflow: hidden;
    }
  }

  .type {
    border-radius: 3px;
    font: 10px ${openSansRegular};
    padding: 2px 4px;
    line-height: 10px;
    width: fit-content;
    position: absolute;
    margin-top: -13px;

    &.new-tag {
      color: ${brightTurquoise700};
      background-color: ${brightTurquoise100};
    }
    &.tag-change {
      color: ${goldenYellow700};
      background-color: ${goldenYellow100};
    }
  }
`;