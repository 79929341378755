import styled from 'styled-components';
import * as s from '../../StylesConstants';

export const ToggleButtonWrapper = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: ${s.openSansBoldFamily};
  gap: 12px;
  user-select: none;
  justify-content: center;
  outline: none;
  height: 32px;
  border: 0;
  border-radius: 20px;
  padding: 0 12px;

  &.toggle-on {
    background-color: ${s.electricPurple500};
    color: ${s.white};
  }

  &.toggle-off {
    background-color: ${s.hintPerano000};
    color: ${s.softPurple800};
  }
`;
