import React, { useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { PropertyReceptionFormWrapper } from './style';
import useDynamicForm from '../../../../hooks/useDynamicForm';
import Button from '../../../../components/shared/button/button';
import Section from '../../../../components/DynamicForm/Section/section';
import ButtonCheck from '../../../../components/ButtonCheck/buttonCheck';
import { findEventStatus } from '../../../../utils/Scripts/findEventStatus';
import RouteLeavingGuard from '../../../../components/RouteLeavingGuard/RouteLeavingGuard';
import {
  ALL_LEGAL_ROLES,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  LABEL_CHECKBOX_BUTTON,
  PROCESS_EVENTS,
  ROLE,
} from '../../../../utils/constant';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import {
  ButtonGrid,
  DocumentsGrid,
  Hr,
  SubTitle,
  TitleMultipleSection,
} from '../../../../components/Styles/genericStyleComponent';
import {
  OPTIONS_VALIDATION,
  OPTIONS_VALIDATION_LIST,
} from '../../../../utils/dynamic-constant';
import RadioButtonV2 from '@habi/habi-react-components/dist/RadioButtonV2/RadioButtonV2';
import { getButtonSelected } from '../../../../services/eventsService';

const PropertyReceptionForm = ({
  data,
  roles,
  events,
  getData,
  documents,
  processName,
  handleSubmit,
  businessType,
  submitLoading,
  handleComplete,
  handleSaveComment,
  addMultipleSection,
  changeClientValidation,
  buttonValidationCustomer,
  disabledCustomerValidation,
  setButtonValidationCustomer,
  haveComplianceOfficerDocument,
}) => {
  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave,
    haveChangeData,
    ,
    complianceList = [],
  ] = useDynamicForm(data);

  const getEventStatus = useCallback(
    (eventName) => {
      return findEventStatus(eventName, events);
    },
    [events]
  );

  const savePropertyData = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      await handleSubmit(finalValues);
      getData(true);
    }
  };

  const getValidateAndSubmitCallback = (validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        console.log('ERRORS:', errors);
      }
    });
  };

  const validateAndSubmit = async (validateForm, submitForm) => {
    await getValidateAndSubmitCallback(validateForm);
    submitForm();
  };

  const addMultipleSectionWithCurrentFields = (sectionId, values) => {
    haveChangeData(values);
    addMultipleSection(sectionId);
  };

  const haveALegalRole = () => {
    let haveLegalRole = false;
    roles.forEach((role) => {
      ALL_LEGAL_ROLES.forEach((legalRole) => {
        if (role === legalRole) {
          haveLegalRole = true;
        }
      });
    });
    return haveLegalRole;
  };

  const changeValidationCustomer = useCallback(
    (optionValidationConfig) => {
      setButtonValidationCustomer(optionValidationConfig);
    },
    [setButtonValidationCustomer]
  );

  const getValidationCustomer = useCallback(() => {
    let option;
    let optionValidationConfig = {};
    complianceList.forEach((number) => {
      option =
        OPTIONS_VALIDATION[
          getButtonSelected(events, `${PROCESS_EVENTS.VALIDATION}__${number}`)
        ];
      optionValidationConfig = {
        ...optionValidationConfig,
        [number]: option?.value ?? null,
      };
    });
    if (Object.keys(buttonValidationCustomer).length < 1) {
      changeValidationCustomer(optionValidationConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, complianceList]);

  useEffect(() => {
    getValidationCustomer();
  }, [getValidationCustomer]);

  const getClientValidation = (complianceId) => {
    return (
      <div key={buttonValidationCustomer[complianceId]}>
        {documents && (
          <div className={`static-buttons-full-width`}>
            <label className="radio-button-label">Validación de cliente</label>
            <RadioButtonV2
              options={OPTIONS_VALIDATION_LIST}
              handleChange={(value) => {
                changeClientValidation(value, complianceId);
              }}
              initialValue={buttonValidationCustomer[complianceId]}
              disabled={haveComplianceOfficerDocument(complianceId)}
              round={true}
              dataId={'checkbox'}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <PropertyReceptionFormWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => savePropertyData(values)}
      >
        {(props) => (
          <>
            <Form className="form-container">
              {sections.map((section) => (
                <Section
                  section={section}
                  formikProps={props}
                  key={`section${section.id}`}
                  handleSaveComment={handleSaveComment}
                  addMultipleSection={addMultipleSectionWithCurrentFields}
                />
              ))}
              {sections.length > 0 && (
                <>
                  <div className="btn-field-complete">
                    <ButtonCheck
                      label={LABEL_CHECKBOX_BUTTON.CAMPOS_COMERCIAL_COMPLETOS}
                      status={getEventStatus(
                        PROCESS_EVENTS.CAMPOS_COMERCIAL_COMPLETOS
                      )}
                      handleChange={() =>
                        handleComplete(
                          PROCESS_EVENTS.CAMPOS_COMERCIAL_COMPLETOS,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                    />
                  </div>
                  <div className="form-button-container">
                    <Button typeClass="secondary" type="reset">
                      Cancelar
                    </Button>
                    <Button
                      onClick={() =>
                        getValidateAndSubmitCallback(props.validateForm)
                      }
                      type="submit"
                      loading={submitLoading}
                    >
                      Guardar cambios
                    </Button>
                  </div>
                  <Hr />
                  <div>
                    <SubTitle>Oficial de cumplimiento</SubTitle>
                    {complianceList.map((complianceId, index) => (
                      <div key={`complianceOfficer${complianceId}${index}`}>
                        <TitleMultipleSection>
                          {`vendedor n°${index + 1}`}
                        </TitleMultipleSection>
                        {roles.includes(ROLE.COMPLIANCE_OFFICER) && (
                          <DocumentsGrid>
                            <DocumentComponent
                              documentParam={documents.find(
                                (doc) =>
                                  doc.number_seller === complianceId &&
                                  doc.name ===
                                    DOCUMENT_TYPE_NAME.PROOF_COMPLIANCE
                              )}
                              businessTypeId={businessType}
                              processName={processName}
                              handleUpload={disabledCustomerValidation}
                              handleDelete={disabledCustomerValidation}
                            />
                          </DocumentsGrid>
                        )}
                        {getClientValidation(complianceId)}
                      </div>
                    ))}
                  </div>
                  <Hr />
                  <ButtonGrid>
                    <ButtonCheck
                      label={'Solicitud de revisión'}
                      status={getEventStatus(PROCESS_EVENTS.REVIEW_REQUEST)}
                      handleChange={() =>
                        handleComplete(
                          PROCESS_EVENTS.REVIEW_REQUEST,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                      disabled={!roles.includes(ROLE.COMPLIANCE_OFFICER)}
                    />
                    <ButtonCheck
                      label={'Revisión legal'}
                      status={getEventStatus(PROCESS_EVENTS.LEGAL_REVIEW)}
                      handleChange={() =>
                        handleComplete(
                          PROCESS_EVENTS.LEGAL_REVIEW,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                      disabled={!haveALegalRole()}
                    />
                  </ButtonGrid>
                </>
              )}
            </Form>
            <RouteLeavingGuard
              shouldBlockNavigation={() => haveChangeData(props.values)}
              handleConfirm={() =>
                validateAndSubmit(props.validateForm, props.submitForm)
              }
            />
          </>
        )}
      </Formik>
    </PropertyReceptionFormWrapper>
  );
};

export default PropertyReceptionForm;
