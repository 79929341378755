import styled from "styled-components";
import { brightTurquoise600, errorPrimary, muliBoldFamily, nightBlue100, nightBlue200, openSansBoldFamily, openSansRegular, scarpaGray200, skyBlue500, successPrimary } from "../../StylesConstants";

export const CommentHistoryWrapper = styled.div`
  font-family: ${openSansRegular};
  line-height: 20px;
  overflow: auto;
  height: 100%;

  a {
    color: ${brightTurquoise600};
    font: 14px ${muliBoldFamily};
  }

  .title {
    margin-bottom: 0
  }

  .dot-line {
    border-left: 1px dashed ${scarpaGray200};
    margin-left: 6px;
    padding: ${p => p.emptyHistory? '0': '0 0 24px 14px'} ;
  }

  .role {
    background-color: ${skyBlue500};
    border-radius: 4px;
    font-family: ${openSansBoldFamily};
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 4px;
    padding: 4px 8px;
    text-transform: capitalize;
    display: inline-flex;
  }

  .comment {
    margin-bottom: 6px;
    font-size: 13px;
  }

  .date {
    font-size: 13px;
    line-height: 20px;
    color: ${nightBlue200};
  }

  .hour {
    font-size: 11px;
    color: ${nightBlue100};
  }

  .bullet {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${errorPrimary};
    position: absolute;
    margin-left: -20px;
    margin-top: 32px;
  }

  .error {
    background-color: ${errorPrimary};
  }

  .success {
    background-color: ${successPrimary};
  }

  .download-link{
    margin-bottom: 14px;
  }
`;