import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBondingDataApi } from '../../apis/Financing/financingApi';
import { callEventProcess } from '../../apis/Process/processApis';
import EmptyData from '../../components/EmptyData/emptyData';
import GroupRadioOption from '../../components/GroupRadioOption/GroupRadioOption';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import LoadingData from '../../components/LoadingData/loadingData';
import PageMessage from '../../components/PageMessage/pageMessage';
import {
  Container,
  Title,
  TitleAdd,
} from '../../components/Styles/genericStyleComponent';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  DUMMY_PROCESS,
  FUNDING_TYPE,
  PROCESS_EVENTS,
  ROUTES,
} from '../../utils/constant';
import {
  BONDING_PROCESS_BY_FUNDING_TYPE,
  FUNDING_TYPE_LABEL,
} from '../../utils/dynamic-constant';
import BondingColpatria from './components/BondingColpatria/bondingColpatria';
import BondingDann from './components/BondingDann/bondingDann';

const Bonding = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const dispatch = useDispatch();

  const [sections, setSections] = useState([]);
  const [events, setEvents] = useState([]);
  const [fundingType, setFundingType] = useState(null);
  const [doubleBonding, setDoubleBonding] = useState(false);

  const fundingTypes = [
    {
      label: FUNDING_TYPE_LABEL[FUNDING_TYPE.PA_COLPATRIA],
      name: FUNDING_TYPE.PA_COLPATRIA,
    },
    {
      label: FUNDING_TYPE_LABEL[FUNDING_TYPE.LEASING_DANN],
      name: FUNDING_TYPE.LEASING_DANN,
    },
  ];

  const getBondingData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getBondingDataApi({
          nid,
          businessTypeId: BUSINESS_TYPE.SELLERS,
          dummyProcess: DUMMY_PROCESS.BONDING,
          fundingType,
        });
        const { data } = response.data;
        setSections(data.sections);
        setEvents(data.events);
        if (!fundingType && data.funding_type !== null) {
          setFundingType(data.funding_type);
        }
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setSections([]);
          setEvents([]);
        } else {
          console.log('error:::', error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch, fundingType]
  );

  useEffect(() => {
    nid ? getBondingData(nid) : dispatch(dataNotFound());
  }, [nid, getBondingData, dispatch]);

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: BUSINESS_TYPE.SELLERS,
      processName: BONDING_PROCESS_BY_FUNDING_TYPE[fundingType],
      eventName,
      status,
      originUrl: window.location.href,
    });
    updateEvents(eventName, status);
  };

  const updateEvents = (eventName, status) => {
    if (events.some((event) => event.name === eventName)) {
      setEvents(
        events.map((event) =>
          event.name === eventName ? { name: event.name, status } : event
        )
      );
    } else {
      setEvents([...events, { name: eventName, status }]);
    }
  };

  const addOptionalDocument = (document, sectionName) => {
    setSections(
      sections.map((section) =>
        section.name === sectionName &&
        !section.list_documents.some((doc) => doc.name === document.name)
          ? {
              ...section,
              list_documents: [...section.list_documents, document],
            }
          : section
      )
    );
  };

  const updateDocument = (status, newDocument) => {
    const newSections = sections.map((section) => {
      const newListDocuments = section.list_documents.map((document) => {
        return document.label === newDocument.label ? newDocument : document;
      });
      return { ...section, list_documents: newListDocuments };
    });
    setSections(newSections);

    if (fundingType === FUNDING_TYPE.PA_COLPATRIA) {
      sections.forEach((section, indexSection) => {
        section.list_documents.forEach((document) => {
          if (document.label === newDocument.label && !newDocument.exist) {
            indexSection === 0
              ? callEvent(
                  PROCESS_EVENTS.FIRST_REQUEST_BONDING,
                  DOCUMENT_STATUS.REVIEW
                )
              : callEvent(
                  PROCESS_EVENTS.SECOND_REQUEST_BONDING,
                  DOCUMENT_STATUS.REVIEW
                );
          }
        });
      });
    }

    if (fundingType === FUNDING_TYPE.LEASING_DANN) {
      sections.forEach((section) => {
        section.list_documents.forEach((document) => {
          if (document.label === newDocument.label && !newDocument.exist) {
            callEvent(
              PROCESS_EVENTS.DANN_REQUEST_BONDING,
              DOCUMENT_STATUS.REVIEW
            );
          }
        });
      });
    }
  };

  const fundingTypeChange = (funding_type) => {
    if (funding_type !== fundingType) {
      setFundingType(funding_type);
      setDoubleBonding(true);
    }
  };

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>
            Vinculación
            <TitleAdd>{FUNDING_TYPE_LABEL[fundingType] ?? ''}</TitleAdd>
          </Title>
          {(doubleBonding || fundingType === FUNDING_TYPE.PA_COLPATRIA) && (
            <GroupRadioOption
              initialValue={fundingType}
              handleChange={fundingTypeChange}
              label="Tipo de financiación"
              options={fundingTypes}
              name="funding_type"
            />
          )}
          {fundingType && fundingType === FUNDING_TYPE.PA_COLPATRIA && (
            <BondingColpatria
              sections={sections}
              events={events}
              handleCallEvent={callEvent}
              addOptionalDocument={addOptionalDocument}
              handleUpdateDocument={updateDocument}
            />
          )}
          {fundingType && fundingType === FUNDING_TYPE.LEASING_DANN && (
            <BondingDann
              sections={sections}
              events={events}
              handleCallEvent={callEvent}
              addOptionalDocument={addOptionalDocument}
              handleUpdateDocument={updateDocument}
            />
          )}
        </Container>
        {!fundingType && (
          <PageMessage
            title="Vinculación sin asignar"
            description={
              <>
                Para poder continuar, ve al formulario de recepción{' '}
                <Link to={ROUTES.PROPERTY_SELLERS}>Inmuebles vendedores</Link>{' '}
                <br />y selecciona el tipo de financiación que aplica para este
                negocio.
              </>
            }
          />
        )}
        {fundingType &&
          fundingType !== FUNDING_TYPE.PA_COLPATRIA &&
          fundingType !== FUNDING_TYPE.LEASING_DANN && (
            <PageMessage
              title="Vinculación no soportada"
              description={
                <>
                  Actualmente la aplicación no soporta la vinculación con{' '}
                  {FUNDING_TYPE_LABEL[fundingType] ?? ''}
                </>
              }
            />
          )}
      </LoadingData>
    </EmptyData>
  );
};

export default Bonding;
