import React, { useState } from 'react'
import Select from 'react-select';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import { reactSelectStyles, SelectWithConfirmationWrap } from './style'

const SelectWithConfirmation = ({
  options,
  handleConfirm,
  disabled,
  defaultValue,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);

  const changeOption = (optionSelected) => {
    setShowConfirm(true);
    setOptionSelected(optionSelected.value);
  };

  const confirm = () => {
    setShowConfirm(false);
    handleConfirm(optionSelected);
  };

  const cancel = () => {
    setShowConfirm(false);
    setOptionSelected(null);
  };

  return (
    <SelectWithConfirmationWrap showConfirm={showConfirm}>
      <Select
        options={options}
        styles={reactSelectStyles}
        placeholder="Seleccione"
        onChange={(option) => changeOption(option)}
        dataTest="select"
        isDisabled={disabled}
        defaultValue={defaultValue}
      />
      {showConfirm && (
        <div className={'confirm-container'}>
          <div className="icon confirm" onClick={confirm}>
            <DoneIcon />
          </div>
          <div className="icon cancel" onClick={cancel}>
            <CloseIcon />
          </div>
        </div>
      )}
    </SelectWithConfirmationWrap>
  );
};

export default SelectWithConfirmation
