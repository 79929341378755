import React, { useEffect, useState } from 'react'
import { InputActionWrapper } from './style'

const InputAction = ({ placeholder, iconAction, handleAction, value }) => {
  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, [value])

  const handleChange = (event) => {
    setInput(event.target.value);
  }

  return (
    <InputActionWrapper>
      <input 
        type="text" 
        placeholder={placeholder} 
        value={input}
        onChange={handleChange}
      />
      <div className="action" onClick={() => handleAction(input)}>
        {iconAction}
      </div>
    </InputActionWrapper>
  )
}

export default InputAction
