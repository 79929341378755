import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Logo from "../../components/Logo/logo";
import VisibilityHidden from "../../static/assets/img/icons/icon-visibility-hidden.svg";
import ToggleButton from '../../components/ToggleButton/ToggleButton';
import { toggleVisibilityLifeCycle } from '../../redux/actions/configuration';
import { HeaderWrapper } from './style';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../utils/constant';
import Button from '../../components/shared/button/button';

const Header = () => {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.loginData);
  const showLifeCycle = useSelector(
    (state) => state.configurationData.showLifeCycle
  );
  const nid = useSelector((state) => state.propertyData.nid);

  const redirectToAnotherProject = () => {
    let windowRef = window.open('https://d32hsky5c1miu8.cloudfront.net/');
    console.log('primer window.name', windowRef.name);
    windowRef.name = JSON.stringify(loginData);
    console.log('segundo window.name', windowRef.name);
  };

  return (
    <HeaderWrapper>
      <div className="logo">
        <Logo />
        <span>
          Portal de <br /> inmuebles
        </span>
      </div>
      <section className="options-container">
        {nid && (
          <ToggleButton
            dataId="lifecycle-toggle"
            initialState={false}
            onChange={() => dispatch(toggleVisibilityLifeCycle())}
            iconOn={<VisibilityIcon />}
            iconOff={<img src={VisibilityHidden} alt="Icono ocultar" />}
          >
            LifeCycle {showLifeCycle}
          </ToggleButton>
        )}
        <NavLink to={ROUTES.DASHBOARD}>
          <div className="notification-button">
            <NotificationsIcon />
          </div>
        </NavLink>
        <div className="avatar">
          <img src={loginData.data.imageProfile} alt="avatar" />
        </div>
      </section>
    </HeaderWrapper>
  );
};

export default Header;
