import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DocumentComponent from "../../components/DocumentComponent/documentComponent";
import { getListDocumentsApi } from "../../apis/Documents/documentsApis";
import { updatePropertyData } from "../../redux/actions/propertyData";
import {
  DocumentsGrid,
  Title,
  ButtonGrid,
  Container,
} from "../../components/Styles/genericStyleComponent";
import ButtonCheck from "../../components/ButtonCheck/buttonCheck";
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  PROCESS,
  ROLE,
} from "../../utils/constant";
import { sendEmailNotification } from "../../apis/Notifications/notificationsApis";
import OptionalDocument from "../../components/OptionalDocument/optionalDocument";
import EmptyData from "../../components/EmptyData/emptyData";
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const DocumentsPreDeed = ({ location, ...props }) => {
  const [emptyData, setEmptyData] = useState(false);

  const { propertyData } = useSelector((state) => state);
  const [listDocuments, setListDocuments] = useState([]);
  const [analystStatus, setAnalystStatus] = useState('');
  const [completeStatus, setCompleteStatus] = useState('');
  const dispatch = useDispatch();
  const documentsRef = useRef([]);

  const getListDocument = useCallback(
    async (nid) => {
      try {
        dispatch(updatePropertyData({ loadingSearch: true }));
        const response = await getListDocumentsApi(
          nid,
          BUSINESS_TYPE.SELLERS,
          PROCESS.PRE_WRITING_SELLERS
        );
        const { list_documents, review } = response.data;
        updateStatus(review);
        setListDocuments(list_documents);
        documentsRef.current = list_documents;
        setEmptyData(false);
        dispatch(updatePropertyData({ noData: false }));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setListDocuments([]);
          setEmptyData(true);
        } else {
          console.log('error:::', error.response);
        }
        dispatch(updatePropertyData({ noData: true }));
      } finally {
        dispatch(updatePropertyData({ loadingSearch: false }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (propertyData.nid) {
      getListDocument(propertyData.nid);
    } else {
      setListDocuments([]);
      setEmptyData(true);
    }
  }, [propertyData.nid, getListDocument]);

  useEffect(() => {
    if (location && location.state && location.state.nid) {
      const nid = location.state.nid;
      dispatch(updatePropertyData({ nid: nid }));
      getListDocument(nid);
    }
  }, [location, dispatch, getListDocument]);

  useEffect(() => {
    const getAnalystStatus = () => {
      const documentsRequired = listDocuments.filter((doc) => doc.required);

      if (
        listDocuments.filter(
          (document) => document.status === DOCUMENT_STATUS.REJECTED
        ).length > 0
      ) {
        setAnalystStatus(DOCUMENT_STATUS.REJECTED);
      } else if (
        listDocuments.filter(
          (document) =>
            document.status === DOCUMENT_STATUS.SUCCESS && document.required
        ).length === documentsRequired.length
      ) {
        setAnalystStatus(DOCUMENT_STATUS.SUCCESS);
      } else {
        setAnalystStatus('');
      }
    };

    if (listDocuments.length > 0) {
      getAnalystStatus();
    }
  }, [listDocuments]);

  const handleChangeFile = (status, newDocument) => {
    documentsRef.current = documentsRef.current.map((document) => {
      return document.document_type_id === newDocument.document_type_id
        ? newDocument
        : document;
    });
    setListDocuments([...documentsRef.current]);

    if (
      completeStatus &&
      newDocument.required &&
      (!newDocument.exist || newDocument.status === DOCUMENT_STATUS.REJECTED)
    ) {
      updateStatus(DOCUMENT_STATUS.EDITING);
    }
  };

  const sendNotification = async () => {
    try {
      await sendEmailNotification({
        nid: propertyData.nid,
        processName: PROCESS.PRE_WRITING_SELLERS,
        originUrl: window.location.href,
        businessType: BUSINESS_TYPE.SELLERS,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addOptionalDocument = (document) => {
    if (
      !documentsRef.current.some(
        (doc) => doc.document_type_id === document.document_type_id
      )
    ) {
      documentsRef.current.push(document);
      setListDocuments([...documentsRef.current]);
    }
  };

  const updateStatus = (status) => {
    switch (status) {
      case DOCUMENT_STATUS.REVIEW_DOCUMENTS:
        setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
        setAnalystStatus(null);
        break;
      case DOCUMENT_STATUS.EDITING:
        setCompleteStatus(null);
        setAnalystStatus(null);
        break;
      case DOCUMENT_STATUS.SUCCESS:
        setCompleteStatus(DOCUMENT_STATUS.SUCCESS);
        setAnalystStatus(DOCUMENT_STATUS.SUCCESS);
        break;
      default:
        setCompleteStatus(null);
        setAnalystStatus(null);
    }
  };

  const isCompleteDisabled = () => {
    return listDocuments.some((doc) => doc.required && !doc.exist);
  };

  return (
    <EmptyData empty={emptyData}>
      <Container>
        <LifeCycle miniLifeCycle />
        <Title>Documentos pre-escritura</Title>
        <DocumentsGrid>
          {listDocuments.map((document, i) => (
            <DocumentComponent
              key={`document-${i}`}
              lastItemRow={(i + 1) % 5 === 0 ? true : false}
              documentParam={document}
              showReview={true}
              businessTypeId={BUSINESS_TYPE.SELLERS}
              reviewer={ROLE.OPERATIONS}
              handleUpload={handleChangeFile}
              handleDelete={handleChangeFile}
              processName={PROCESS.PRE_WRITING_SELLERS}
            />
          ))}
          <OptionalDocument
            businessType={BUSINESS_TYPE.SELLERS}
            processName={PROCESS.PRE_WRITING_SELLERS}
            handleAddDocument={addOptionalDocument}
          />
        </DocumentsGrid>
        <ButtonGrid>
          <ButtonCheck
            label="Documentos comercial completos"
            status={completeStatus}
            disabled={isCompleteDisabled()}
            handleChange={sendNotification}
          />
          <ButtonCheck
            label="Revisión analista trámites"
            status={analystStatus}
            disabled={true}
          />
        </ButtonGrid>
      </Container>
    </EmptyData>
  );
};

export default DocumentsPreDeed;
