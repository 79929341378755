export const addUniqueDocument = (optionalDocument, allDocuments) => {
  if (
    !allDocuments.current.some(
      (doc) => doc.document_type_id === optionalDocument.document_type_id
    )
  ) {
    return [...allDocuments, optionalDocument];
  }
  return allDocuments;
};

/**
 * @description Update state of documents with a new document.
 * @param {Object} newDocument new document update.
 * @param {Array} documents list of documents (state of documents).
 * @returns new List of documents.
 */
export const updateDocuments = (newDocument, documents) => {
  return documents.map((document) => {
    const defaultComparative = document.label === newDocument.label;
    return defaultComparative ? newDocument : document;
  });
};

export const updateDocumentsInSections = (newDocument, documentsSections) => {
  return documentsSections.map((section) => {
    const docs = section.docs.map((document) => {
      const defaultComparative = document.label === newDocument.label;
      return defaultComparative ? newDocument : document;
    });
    return { ...section, docs };
  });
};