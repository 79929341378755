import React, { useCallback, useEffect, useState } from 'react';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from "lodash";

import {
  BUSINESS_TYPE,
  DOCUMENT_WRAPPER_TYPE,
  PROCESS,
  ROLE,
  DOCUMENT_STATUS,
  ACTIVITY_STATUS,
  DOCUMENT_TYPE_NAME,
  PCV_STAGES,
  LOCKED_MESSAGE,
  TYPE_PCV
} from '../../utils/constant';
import {
  Container,
  DocumentsGrid,
  Title,
} from '../../components/Styles/genericStyleComponent';
import RadioCheck from '../../components/RadioCheck/radioCheck';
import { PromiseOfSaleWrapper } from './style';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
  updatePropertyData,
} from '../../redux/actions/propertyData';
import { getDocumentsPcvApi } from '../../apis/Documents/documentsApis';
import { setDocumentReview } from '../../redux/actions/documentReview';
import EmptyData from '../../components/EmptyData/emptyData';
import { sendPcvEmailNotification } from '../../apis/Notifications/notificationsApis';
import RightSection from '../../components/RightSection/rightSection';
import Comments from '../../components/Comments/comments';
import { saveProcessComment } from '../../apis/Process/processApis';
import { LABELS_BUTTON_CHECK } from '../../utils/dynamic-constant';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import DownloadDocument from '../../components/DownloadDocument/DownloadDocument';
import { getContractTypeDataService } from '../../services/data/choiceContractTypeService';

const PromiseOfSale = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);
  const { email, role } = useSelector((state) => state.loginData.data);

  const [reviews, setReviews] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [allInteractions, setAllInteractions] = useState([]);
  const [allowAddDocument, setAllowAddDocument] = useState(true);
  const [urlTemplate, setUrlTemplate] = useState('');

  const getPcvData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const { data } = await getDocumentsPcvApi(nid, BUSINESS_TYPE.SELLERS);
        setDocuments(data.documents);
        setCommentsHistory(data.history);
        setAllInteractions(
          data.error_list.map((error) => ({
            value: error.id,
            label: error.label,
          }))
        );
        setReviews(data.reviews);
        setAllowAddDocument(
          (prevState) => data.allow_add_document ?? prevState
        );
        const { data: dataPcvTemplate } = await getContractTypeDataService(
          nid,
          BUSINESS_TYPE.SELLERS,
          PROCESS.PCV_SALES_AND_LEGAL,
          TYPE_PCV.PCV_LEASING
        );
        setUrlTemplate(dataPcvTemplate.url);
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setDocuments([]);
          setReviews([]);
        } else {
          console.log('error:::', error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getPcvData(nid) : dispatch(dataNotFound());
  }, [nid, getPcvData, dispatch]);

  useEffect(() => {
    if (location && location.state && location.state.nid) {
      const nid = location.state.nid;
      dispatch(updatePropertyData({ nid: nid }));
      getPcvData(nid);
    }
  }, [location, dispatch, getPcvData]);

  const sendNotification = async (stage, comment) => {
    try {
      await sendPcvEmailNotification(
        nid,
        BUSINESS_TYPE.SELLERS,
        stage,
        window.location.href,
        comment
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFile = (status, newDocument) => {
    setDocuments(
      documents.map((document) => {
        return document.document_type_id === newDocument.document_type_id
          ? newDocument
          : document;
      })
    );

    if (newDocument.name === DOCUMENT_TYPE_NAME.PCV) {
      setReviews(reviews.map((rev) => ({ ...rev, status: null })));
    }

    if (newDocument.name === DOCUMENT_TYPE_NAME.PCV && newDocument.exist) {
      sendNotification(PCV_STAGES.UPLOAD_FILE, '');
    }

    if (newDocument.name === DOCUMENT_TYPE_NAME.SIGN_PCV && newDocument.exist) {
      sendNotification(PCV_STAGES.PCV_UPLOADED, '');
    }
  };

  const reviewDocument = (review) => {
    const documentPcv = documents.find(
      (document) => document.name === DOCUMENT_TYPE_NAME.PCV
    );
    if (!isReviewDisabled()) {
      dispatch(
        setDocumentReview({
          document: { ...documentPcv, status: review.status },
          reviewer: review.role,
          businessTypeId: BUSINESS_TYPE.SELLERS,
          processName: PROCESS.PCV_SALES_AND_LEGAL,
          originUrl: window.location.href,
          nid,
        })
      );
      history.push('/revision-documento');
    }
  };

  const isDocumentLocked = (documentName) => {
    switch (documentName) {
      case DOCUMENT_TYPE_NAME.PCV:
        return !allowAddDocument;
      case DOCUMENT_TYPE_NAME.SIGN_PCV:
        return reviews.some(
          (rev) =>
            rev.role === ROLE.COMMERCIAL &&
            rev.status === DOCUMENT_STATUS.SUCCESS
        )
          ? false
          : true;
      default:
        return !isSignPcvUpload();
    }
  };

  const getDocument = (documentName) => {
    const document = documents.find((doc) => doc.name === documentName);

    return (
      documents.length > 0 && (
        <div className="document-grid">
          <div>
            <RadioCheck
              status={
                document.exist ? ACTIVITY_STATUS.DONE : ACTIVITY_STATUS.OPEN
              }
            />
          </div>
          {getDocumentComponent({
            document,
            locked: isDocumentLocked(document.name),
            lockedMessage: LOCKED_MESSAGE.PCV_DOCUMENT,
            wrapperType: DOCUMENT_WRAPPER_TYPE.DOT_LINE,
          })}
        </div>
      )
    );
  };

  const getDocumentComponent = ({
    document,
    locked,
    lockedMessage,
    wrapperType,
  }) => {
    return (
      <DocumentComponent
        documentParam={document}
        businessTypeId={BUSINESS_TYPE.SELLERS}
        wrapperType={wrapperType}
        handleUpload={handleChangeFile}
        handleDelete={handleChangeFile}
        processName={PROCESS.PCV_SALES_AND_LEGAL}
        locked={locked}
        lockedMessage={lockedMessage}
        key={`document${document.document_type_id}`}
      />
    );
  };

  const isReviewDisabled = () => {
    const documentPcv = documents.find(
      (doc) => doc.name === DOCUMENT_TYPE_NAME.PCV
    );
    return documentPcv.exist ? false : true;
  };

  const isSignPcvUpload = () => {
    return documents.some(
      (doc) => doc.name === DOCUMENT_TYPE_NAME.SIGN_PCV && doc.exist
    );
  };

  const getLabelRole = (role, status) => {
    const label = _.join(
      role.split('_').map((word) => _.upperFirst(word)),
      [' ']
    );
    return `${
      LABELS_BUTTON_CHECK[status] ?? LABELS_BUTTON_CHECK.REVISION
    } ${label}`;
  };

  const getReview = (review = { role: ROLE.COMMERCIAL }) => {
    return (
      <div
        onClick={() => reviewDocument(review)}
        className={isReviewDisabled() ? 'btn-disabled' : 'btn'}
      >
        <ButtonCheck
          label={getLabelRole(review.role, review.status)}
          status={review.status}
        />
      </div>
    );
  };

  const getReviews = () => {
    return (
      reviews.length > 0 && (
        <div className="document-grid">
          <div />
          <div className="btn-grid">
            {getReview(
              reviews.find((review) => review.role === ROLE.COMMERCIAL)
            )}
          </div>
        </div>
      )
    );
  };

  const isLegalCheckDone = () => {
    return false;
  };

  const saveComment = async (comment, interactionsList) => {
    const errorList = interactionsList.map((interaction) => ({
      id: interaction.value,
      label: interaction.label,
    }));

    try {
      await saveProcessComment({
        nid: nid,
        processName: PROCESS.PCV_SALES_AND_LEGAL,
        agent: email,
        nameReviewerRole: role[0],
        comment,
        errorList,
        originUrl: window.location.href,
      });
      setCommentsHistory([
        {
          comment,
          update_date: new Date(),
          role: role[0],
        },
        ...commentsHistory,
      ]);
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  return (
    <EmptyData>
      <PromiseOfSaleWrapper isLegalCheckDone={isLegalCheckDone}>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title> PCV </Title>
          <div className="document-grid">
            <div>
              <RadioCheck
                status={
                  document.exist
                    ? ACTIVITY_STATUS.DONE
                    : ACTIVITY_STATUS.OPEN
                }
              />
            </div>
            <DownloadDocument
              label={'Descargar Plantilla PCV'}
              url={urlTemplate}
            />
          </div>
          <div className="dot-line-download" />
          {getDocument(DOCUMENT_TYPE_NAME.PCV)}
          {getReviews()}
          {getDocument(DOCUMENT_TYPE_NAME.SIGN_PCV)}
          <DocumentsGrid>
            {documents.map((doc) =>
              doc.name !== DOCUMENT_TYPE_NAME.PCV &&
                doc.name !== DOCUMENT_TYPE_NAME.SIGN_PCV
                ? getDocumentComponent({
                  document: doc,
                  locked: isDocumentLocked(doc.name),
                  wrapperType: DOCUMENT_WRAPPER_TYPE.WHITE_SQUARE,
                })
                : ''
            )}
          </DocumentsGrid>
          {documents.length > 0 && <div className="dot-line-documents" />}
        </Container>
        <RightSection>
          <Comments
            allInteractions={allInteractions}
            commentsHistory={commentsHistory}
            handleSaveComment={saveComment}
          />
        </RightSection>
      </PromiseOfSaleWrapper>
    </EmptyData>
  );
};
 
export default PromiseOfSale;