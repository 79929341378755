import styled from "styled-components";
import { scarpaGray200, purple, hintPerano100, openSansRegular } from "../../StylesConstants";

export const RadioOptionWrapper = styled.div`
  background-color: ${ p => p.selected? hintPerano100: null };
  height:14px;    
  border: ${ p => p.selected? 
    `2px solid ${ purple }`: 
    `1px solid ${ scarpaGray200 }`
  };
  border-radius: ${ p => p.round? 
    `70px`: 
    `8px`
  };
  padding: ${ p => p.round? 
    `12px 15px`: 
    `16px 20px`
  };

  .radio {
    cursor: ${ p => !p.disabled? 'pointer': null };
    display: grid;
    font-size: 15px;
    grid-template-columns: min-content auto;
    grid-gap: 18px;
  }

  .radio-input {
    color: ${ p => p.selected? purple: scarpaGray200 };
    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    } 
  }

  .radio-control {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid currentColor;
    background: ${ p => p.selected? 'radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%)': '' }
  }

  .radio-input {
    display: flex;
  }

  .radio-label {
    color: ${ p => p.disabled? scarpaGray200: null };
    line-height: 1;
    font-family: ${ openSansRegular };
  }
`;
