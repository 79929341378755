import styled from "styled-components";
import { nightBlue200, openSansRegular, plainlightGray, scarpaGray600, white } from "../../../StylesConstants";

export const FieldRowWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 42px;

  select {
    border: 1px solid ${plainlightGray};
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    width: 100%;
    padding: 4px 6px;
    color: ${scarpaGray600};
    font: 12px ${openSansRegular};
  }

  input {
    font: 12px ${openSansRegular} !important;
    height: fit-content !important;
    padding: 4px 6px !important;
    line-height: normal !important;
  }

  input:focus {
    background-color: ${white} !important;
    border: 1px solid ${plainlightGray} !important;
  }

  .over-backdrop {
    z-index: 1000;
    span,
    label {
      color: ${white} !important;
    }
  }

  .select__menu {
    max-width: 100% !important;
    left: 0 !important;
  }

  .select__dropdown-indicator {
    position: inherit !important;
  }

  input:disabled {
    color: ${nightBlue200} !important;
  }

  .form-control.is-invalid {
    border: none;
    background: none;
  }

  .form-control {
    height: fit-content !important;
    padding: 0 !important;
  }

  .input-field {
    padding-top: 0 !important;
  }
  
`;