import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import { HTTP_STATUS } from '../../utils/constant';

export const formWithButtonData = (nid, businessType, processName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const form = await getFormDataDependencies(
        nid,
        processName,
        businessType
      );
      resolve({
        form: form.data.data,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          form: {},
          documents: [],
          events: [],
          comments: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
