import { nightBlue400 } from '@habi/habi-react-components/dist/StylesConstants';
import styled from 'styled-components';
import {
  brightTurquoise600,
  muliBoldFamily,
  white,
} from '../../StylesConstants';

export const DownloadDocumentWrapper = styled.div`
  a {
    text-decoration: none;
    display: flex;
    width: 100%;
    border-radius: 16px;
    padding: 16px 20px;
    align-items: center;
    background-color: ${white};
    height: 43px;
  }

  .arrow-icon {
    margin-right: 24px;
    svg {
      path {
        color: ${brightTurquoise600};
      }
    }
  }

  .label {
    font-family: ${muliBoldFamily};
    color: ${nightBlue400};
  }
`;
