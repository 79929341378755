import React, { useState } from 'react';
import { Page, Document } from 'react-pdf';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Loader from "@habi/habi-react-components/dist/Loader/loader";

import { DocumentViewerWrapper } from './style';

const DocumentViewer = ({ file, width, height, initScale }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(initScale);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(true);
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const nextPage = () => {
    const newPage = pageNumber+1;
    if(newPage === numPages) {
      setDisabledNext(true);
    }
    if(newPage <= numPages) {
      setPageNumber(newPage);
      setDisabledPrev(false);
    }
  }

  const prevPage = () => {
    const newPage = pageNumber-1;
    if(newPage === 1) {
      setDisabledPrev(true);
    }
    if(newPage > 0) {
      setPageNumber(newPage);
      setDisabledNext(false);
    }
  }

  const zoomIn = () => {
    const newScale = (parseFloat(scale)+0.2).toFixed(2);
    if(newScale >= 1.3) {
      setZoomInDisabled(true);
    }
    if(newScale <= 1.3) {
      setScale(newScale);
      setZoomOutDisabled(false);
    }
  }

  const zoomOut = () => {
    const newScale = (parseFloat(scale)-0.2).toFixed(2);
    if(newScale <= 0.5) {
      setZoomOutDisabled(true);
    }
    if(newScale >= 0.5) {
      setScale(newScale);
      setZoomInDisabled(false);
    }
  }

  const getLoader = () => {
    return (
      <div className="loading">
        <Loader size="large" />
      </div>
    );
  };

  return (
    <DocumentViewerWrapper width={width} height={height}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess} 
        width={width}
        className="viewer"
        loading={getLoader}
      >
        <Page 
          pageNumber={pageNumber} 
          size="LETTER" 
          scale={scale} 
          loading={getLoader}
        />
      </Document>
      <div className="options">
        <button onClick={prevPage} disabled={disabledPrev}>
          <ChevronLeftIcon />
        </button>
        <div className="page-indicator">
          Página {pageNumber} de {numPages}
        </div>
        <button onClick={nextPage} disabled={disabledNext}>
          <ChevronRightIcon />
        </button>
        <div />
        <button onClick={zoomOut} disabled={zoomOutDisabled}>
          <ZoomOutIcon />
        </button>
        <button onClick={zoomIn} disabled={zoomInDisabled}>
          <ZoomInIcon />
        </button>
      </div>
    </DocumentViewerWrapper>
  );
}

export default DocumentViewer;

