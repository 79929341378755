import React, { useState } from 'react';
import { DropDownSectionWrapper } from './style';
import SendIcon from '@material-ui/icons/Send';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const DropDownSection = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSection = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <DropDownSectionWrapper>
      <div className="dropDownButton">
        <i className="sendIconContainer">
          <SendIcon />
        </i>
        <span className="description">Solicitud documentos Perito</span>
        <i className="ArrowIconContainer" onClick={openSection}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </i>
      </div>
      {!!children && isOpen && children}
    </DropDownSectionWrapper>
  );
};
