import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE, ROUTES } from '../utils/constant';

const useRedirectToModule = (data) => {

  const nid = useSelector(state => state.propertyData.nid);
  const userRoles = useSelector(state => state.loginData.data.role);
  const history = useHistory();

  useEffect(() => {
    switch(userRoles[0]) {
      case ROLE.SUPPLIER_TITLE_STUDY:
        history.push(ROUTES.SUPPLIER_TITLE_STUDY);
        break;
      case ROLE.SUPPLIER_APPRAISAL:
        history.push(ROUTES.SUPPLIER_APPRAISAL);
        break;
      case ROLE.SUPPLIER_DRAFT:
        history.push(ROUTES.SUPPLIER_DRAFT);
        break;
      default:
        break;
    }
  }, [nid, userRoles, history]);
  
  return;
};

export default useRedirectToModule;
