import styled from 'styled-components';
import { openSansBoldFamily, scarpaGray600 } from '../../../../../StylesConstants';

export const FieldWrapper = styled.div`
    .label-container {
        margin-bottom: 8px;
    }

    .radio-button-label {
        color: ${scarpaGray600};
        font-family: ${openSansBoldFamily};
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
    }

    .radio-buttons-container {
        display: flex;
        justify-content: space-around;
    }
`;