import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';

import { DocumentReviewWrapper } from './style';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import DocumentViewer from '../../components/DocumentViewer/documentViewer';
import TextComment from '../../components/TextComment/textComment';
import DocumentInfo from './components/DocumentInfo/documentInfo';
import DocumentOptions from './components/DocumentOptions/documentOptions';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import {
  DOCUMENT_STATUS,
  EVENT_STATUS,
  PCV_STAGES,
  PROCESS,
  ROLE,
} from '../../utils/constant';
import { setDocumentStatus } from '../../redux/actions/documentReview';
import { saveDocumentStatus } from '../../apis/Documents/documentsApis';
import { sendPcvEmailNotification } from '../../apis/Notifications/notificationsApis';
import WarningDialog from '../../components/WarningDialog/warningDialog';
import { LABELS_BUTTON_CHECK } from '../../utils/dynamic-constant';
import { callEventProcess } from '../../apis/Process/processApis';

const DocumentReview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { documentReviewData, loginData, propertyData } = useSelector(
    (state) => state
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const [comment, setComment] = useState('');

  const {
    document,
    reviewer,
    businessTypeId,
    processName,
    originUrl,
    nid,
    showDelete,
    allowReReview,
    eventReview,
  } = documentReviewData;

  const showConfirmModal = (com) => {
    setShowConfirm(true);
    setComment(com);
  };

  const getPcvStage = (status) => {
    if (processName === PROCESS.PCV_SALES_AND_LEGAL) {
      if (status === DOCUMENT_STATUS.SUCCESS) {
        switch (reviewer) {
          case ROLE.LEGAL:
            return PCV_STAGES.LEGAL_SUCCESS;
          case ROLE.BUSINESS_OPERATIONS:
            return PCV_STAGES.BUSINESS_OPS_SUCCESS;
          case ROLE.OPERATIONS:
            return PCV_STAGES.OPS_SUCCESS;
          case ROLE.COMMERCIAL:
            return PCV_STAGES.COMMERCIAL_SUCCESS;
          default:
            return '';
        }
      } else {
        switch (reviewer) {
          case ROLE.LEGAL:
            return PCV_STAGES.LEGAL_REJECTED;
          case ROLE.BUSINESS_OPERATIONS:
            return PCV_STAGES.BUSINESS_OPS_REJECTED;
          case ROLE.OPERATIONS:
            return PCV_STAGES.OPS_REJECTED;
          case ROLE.COMMERCIAL:
            return PCV_STAGES.COMMERCIAL_REJECTED;
          default:
            return '';
        }
      }
    } else if (processName === PROCESS.PCV_PURCHASE_AND_LEGAL
      || processName === PROCESS.CI_PURCHASE_AND_LEGAL
    ) {
      return status === DOCUMENT_STATUS.SUCCESS
        ? PCV_STAGES.COMMERCIAL_SUCCESS
        : PCV_STAGES.COMMERCIAL_REJECTED;
    }
  };

  const sendNotification = async (status) => {
    if (processName === PROCESS.PCV_SALES_AND_LEGAL
      || processName === PROCESS.PCV_PURCHASE_AND_LEGAL
      || processName === PROCESS.CI_PURCHASE_AND_LEGAL
    ) {
      try {
        await sendPcvEmailNotification(
          propertyData.nid,
          businessTypeId,
          getPcvStage(status),
          originUrl,
          comment
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveComment = async () => {
    setShowConfirm(false);
    dispatch(setDocumentStatus(DOCUMENT_STATUS.REJECTED));
    try {
      await saveDocumentStatus(
        document.id,
        DOCUMENT_STATUS.REJECTED,
        loginData.data.email,
        reviewer,
        comment,
        nid,
        originUrl,
        businessTypeId,
        processName
      );
      sendNotification(DOCUMENT_STATUS.REJECTED);
      if (eventReview) {
        callEventProcess({
          nid,
          businessTypeId,
          processName,
          eventName: eventReview,
          status: EVENT_STATUS.REJECT,
          originUrl,
          comment,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const approveDocument = async () => {
    dispatch(setDocumentStatus(DOCUMENT_STATUS.SUCCESS));
    try {
      await saveDocumentStatus(
        document.id,
        DOCUMENT_STATUS.SUCCESS,
        loginData.data.email,
        reviewer,
        comment,
        nid,
        originUrl,
        businessTypeId,
        processName
      );
      sendNotification(DOCUMENT_STATUS.SUCCESS);
    } catch (error) {
      console.log(error);
    }
  };

  const getLabelRole = () => {
    const label = _.join(
      reviewer.split('_').map((word) => _.upperFirst(word)),
      [' ']
    );
    return `${
      LABELS_BUTTON_CHECK[document.status] ?? LABELS_BUTTON_CHECK.REVISION
    } ${label}`;
  };

  const isDisabledReject = () => {
    if (reviewer && !document.status) {
      return false;
    } else if (allowReReview) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DocumentReviewWrapper>
      <Container>
        <i className="arrow-back" onClick={history.goBack}>
          <ArrowBackIcon />
        </i>
        <div className="document-review-grid">
          <div>
            <DocumentInfo
              label={document.label}
              updateDate={document.update_date}
              userCreation={document.user_creation}
            />
            <div className="options-container">
              {reviewer && (
                <ButtonCheck
                  label={getLabelRole()}
                  status={document.status}
                  handleChange={approveDocument}
                />
              )}
              <DocumentOptions
                label={document.label}
                url={document.url}
                documentId={document.id}
                agent={loginData.data.email}
                showDelete={showDelete}
              />
            </div>
          </div>
          <DocumentViewer
            file={document.url}
            width={430}
            height={560}
            initScale={0.7}
          />
          <div>
            <Title marginBottom={16}>Comentarios</Title>
            <TextComment
              handleSubmit={showConfirmModal}
              disabled={isDisabledReject()}
              value={document.comment ? document.comment : ''}
            />
          </div>
        </div>
        <WarningDialog
          open={showConfirm}
          title={`Estás rechazando ${document.label}`}
          contentAlert="Al enviar este comentario, automáticamente notificarás el rechazo del documento al equipo y otras áreas. Al rechazar este documento reiniciarás el proceso de aprobación de documentos y deberá cargarse la nueva promesa de compraventa. "
          cancelButtonText="Mantener documento"
          confirmButtonText="Rechazar documento"
          onCancel={closeConfirm}
          onConfirm={saveComment}
        />
      </Container>
    </DocumentReviewWrapper>
  );
};

export default DocumentReview;
