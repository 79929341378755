import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Container, Hr, Title } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getHighLevelBuyersData } from '../../services/data/highLevelBuyers';
import { HIGH_LEVEL_TABS } from './config/highLevelTabs';
import { HighLevelBuyersWrapper } from './style';

import Button from '../../components/shared/button/button';
import LoadingData from '../../components/LoadingData/loadingData';
import Tabs from '../../components/shared/Tabs/Tabs';
import HighLevelFilters from './components/HighLevelFilters/HighLevelFilters';
import HighLevelTable from './components/HighLevelTable/HighLevelTable';
import Pager from '../../components/Pager/pager';
import EmptyData from '../../components/EmptyData/emptyData';

const HighLevelBuyers = () => {

  const dispatch = useDispatch();

  const [highLevelData, setHighLevelData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [filters, setFilters] = useState({});
  const [activePage, setActivePage] = useState(1);

  const getData = useCallback(
    async ({ page }) => {
      setActivePage(page ? page : 1);
      try {
        dispatch(showLoading());
        const highLevel = await getHighLevelBuyersData({
          page,
          filters,
          tab: HIGH_LEVEL_TABS[currentTab].value
        });
        setHighLevelData(highLevel);
        dispatch(dataFound());
      } catch {
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch, filters, currentTab],
  )

  const refresh = () => {
    setFilters(filters => ({ ...filters }));
  }

  useEffect(() => {
    getData({ page: 1 });
  }, [getData]);

  return (
    <Container>
      <HighLevelBuyersWrapper>
        <div className="title-container">
          <Title marginBottom="0">
            Reporte High Level Operaciones
          </Title>
          <Button typeClass="secondary" size="small" onclick={refresh}>
            Refrescar
          </Button>
        </div>
        <Hr />
        <HighLevelFilters setFilters={setFilters} />
        <Tabs
          tabs={HIGH_LEVEL_TABS}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <LoadingData>
          <EmptyData message="No hay resultados para los filtros seleccionados">
            <HighLevelTable
              tab={HIGH_LEVEL_TABS[currentTab]}
              highLevelData={highLevelData.data}
            />
            <Pager
              numPages={highLevelData.pages}
              activePage={activePage}
              onChange={getData}
            />
          </EmptyData>
        </LoadingData>
      </HighLevelBuyersWrapper>
    </Container>
  )
}

export default HighLevelBuyers;