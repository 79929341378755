import styled from 'styled-components';
import {
  hintPerano100,
  hintPerano500,
  openSansBoldFamily,
  white,
} from '../../StylesConstants';

export const DropDownSectionWrapper = styled.div`
  margin-button: 40px;
  .dropDownButton {
    background-color: ${white};
    height: 40px;
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    border-radius: 8px;
    margin-bottom: 24px;

    .sendIconContainer {
      align-self: center;
      background-color: ${hintPerano100};
      height: 24px;
      width: 24px;
      justify-self: center;
      border-radius: 4px;
      justify-self: left;
      margin-left: 12px;
      svg {
        padding: 4px 0;
        path {
          color: ${hintPerano500};
          transform: rotate(-30deg);
          width: 13px;
          height: 12px;
        }
      }
    }

    .description {
      font-family: ${openSansBoldFamily};
      font-size: 12px;
      line-height: 16px;
      align-self: center;
    }

    .ArrowIconContainer {
      text-align: center;
      align-self: center;
      svg {
        cursor: pointer;
      }
    }
  }
`;
