import styled from 'styled-components';
import { goldenYellow300, montserratBold, openSansBoldFamily, openSansRegular, scarpaGray300, scarpaGray500, scarpaGray700, skyBlue } from '../../../../StylesConstants';

export const NotificationDetailWrapper = styled.div`
  padding: 0 32px 32px 32px;

  .link {
    color ${skyBlue};
    font: 12px ${openSansBoldFamily};
    text-decoration: none;
    cursor: pointer;
  }

  h3 {
    font: 14px ${montserratBold};
    line-height: 21px;
    letter-spacing: 0.02em;
    color: ${scarpaGray700};
    margin-bottom: 16px;
  }

  p {
    font: 14px ${openSansRegular};
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${scarpaGray500};
    margin-bottom: 16px;
  }

  .header-actions {
    display:flex;
    justify-content: space-between;
    margin-bottom: 26px;
  }

  .star {
    text-align: center;
    cursor: pointer;

    &.important {
      color: ${goldenYellow300};
    }

    &.new {
      color: ${scarpaGray300};
    }
  }

  .btn-back{
    cursor: pointer;
  }

  .footer-info {
    display:flex;
    justify-content: space-between;

    .date {
      font: 12px ${openSansRegular};
      color: ${scarpaGray500};
    }
  }
`;
