import API from '../Api';

export const sendEmailNotification = async ({ nid, processName, originUrl, businessType, agent, optionalDocumentsList, status }) => {
  return API.post(
    process.env.REACT_APP_POST_EMAIL_PROCEDURE,
    {
      nid: parseInt(nid),
      origin_url: originUrl,
      process_name: processName,
      business_type_id: businessType,
      agent,
      optional_documents_list: optionalDocumentsList,
      status
    }
  );
};

export const sendPcvEmailNotification = async (nid, businessType, stage, originUrl, comment) => {
  return API.post(
    process.env.REACT_APP_POST_EMAIL_PCV,
    {
      nid: parseInt(nid),
      business_type_id: businessType,
      stage: stage,
      origin_url: originUrl,
      comment: comment
    }
  );
};

export const sendCompleteFieldsPropertyReception = async ({ nid, originUrl, processName, businessType }) => {
  return API.post(
    process.env.REACT_APP_POST_EMAIL_PROPERTY_RECEPTION,
    {
      nid: parseInt(nid),
      origin_url: originUrl,
      process_name: processName,
      business_type_id: businessType,
    }
  );
};

export const sendEmailExternalUser = async (nid, processName, originUrl) => {
  return API.post(
    process.env.REACT_APP_POST_EMAIL_TO_EXTERNAL_USER,
    {
      nid: parseInt(nid),
      process_name: processName,
      origin_url: originUrl
    }
  );
};

export const sendEmailPropertySellerAssignment = async (nid, originUrl, email) => {
  return API.post(
    process.env.REACT_APP_POST_EMAIL_PROPERTY_SELLER_ASSIGNMENT,
    {
      nid: parseInt(nid),
      origin_url: originUrl,
      email
    }
  );
};

export const getNotificationsApi = ({ recipient, state, page, elements }) => {
  return API.get(
    process.env.REACT_APP_GET_NOTIFICATIONS,
    {
      params: {
        recipient,
        state,
        page,
        elements
      }
    }
  );
};

export const updateNotificationApi = ({ id, state }) => {
  return API.put(
    process.env.REACT_APP_PUT_NOTIFICATION,
    { id, state }
  );
};
