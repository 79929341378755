import { informationPrimary, scarpaGray300, warningSecondary } from '@habi/habi-react-components/dist/StylesConstants';
import styled from "styled-components";
import {
  muliBoldFamily,
  nightBlue500,
  nightBlue200,
  openSansRegular,
  warningPrimary,
  scarpaGray600,
  error,
  openSansBoldFamily,
  greySoft,
  scarpaGray200,
  informationSecondary
} from "../../StylesConstants";

export const DocumentWrap = styled.div`

  .icon-document {
    position: relative;
    width: 24px;
    cursor: pointer;
  }

  .name-document {
    text-align: center;
    font-family: ${muliBoldFamily};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${nightBlue500};
    align-self: baseline;
  }

  .date-document {
    font-family: ${openSansRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${nightBlue200};
  }

  .no-document {
    font-family: ${openSansRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${warningPrimary};
  }

  .icon-status {
    position: absolute;
    width: 20px;

    &.view {
      color: ${scarpaGray200};
    }
  }

  .type {
    border-radius: 3px;
    font: 10px ${openSansBoldFamily};
    padding: 4px 8px;
    line-height: 10px;

    &.optional {
      color: ${informationPrimary};
      background-color: ${informationSecondary};
    }
    &.required {
      color: ${warningPrimary};
      background-color: ${warningSecondary};
    }
    &.loaded {
      color: ${scarpaGray300};
      background-color: ${greySoft};
    }
  }

  .locked-document {
    color: ${nightBlue200};
    font-family: ${openSansRegular};
    font-size: 11px;
    line-height: 18px;
    margin: 0;
    text-align: center;
  }

  .description {
    height: 100%;
  }
`;

export const ActionsWrap = styled.div`
  position: absolute;
  top: 28px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 8px;
  padding: 16px 0px;
  min-width: 260px;
  z-index: 1;

  &.last-item {
    right: 0px;
    left: auto;
  }

  .button-option {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 16px;
    align-items: center;
    padding: 0px 16px;
    width: 100%;
    background-color: transparent;
    border: none;
    height: 44px;
    font-family: ${openSansRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    .MuiSvgIcon-root {
      position: initial;
    }
  }

  .download-document-option {
    color: ${scarpaGray600};
  }

  .delete-document-option {
    color: ${error};
  }
`;
