import React from 'react';
import RowPublished from '../../../../pages/ListingWeb/tabsContent/RowPublished/RowPublished';
import RowRequested from '../../../../pages/ListingWeb/tabsContent/RowRequested/RowRequested';
import RowSoldIncomplete from '../../../../pages/ListingWeb/tabsContent/RowSoldIncomplete/RowSoldIncomplete';
import Requests from '../../../../pages/PaymentRequest/components/Requests/Requests';
import {
  STATUS_LISTING_WEB_PROCESS,
  STATUS_PAYMENT_REQUEST,
} from '../../../../utils/constant';

export const tabSelector = {
  [STATUS_LISTING_WEB_PROCESS.REQUESTED]: ({
    index,
    rowData,
    handleUpdateButton,
  }) => (
    <RowRequested
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      handleUpdate={handleUpdateButton}
    />
  ),

  [STATUS_LISTING_WEB_PROCESS.PUBLISHED]: ({
    index,
    rowData,
    handleUpdateSelect,
  }) => (
    <RowPublished
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      handleChange={handleUpdateSelect}
    />
  ),
  [STATUS_LISTING_WEB_PROCESS.SOLD]: ({
    index,
    rowData,
    handleUpdateButton,
  }) => (
    <RowSoldIncomplete
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      handleUpdate={handleUpdateButton}
    />
  ),
  //TODO: Hacer cada uno de las filas
  [STATUS_PAYMENT_REQUEST.REQUESTS]: ({
    index,
    rowData,
    options,
    handleUpdateButton,
  }) => (
    <Requests
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      options={options}
      handleUpdate={handleUpdateButton}
      showButton
    />
  ),
  [STATUS_PAYMENT_REQUEST.APPROVED]: ({
    index,
    rowData,
    options,
    handleUpdateButton,
  }) => (
    <Requests
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      options={options}
      handleUpdate={handleUpdateButton}
      defaultValue={{ label: 'Aprobado', value: 'success' }}
      showButton
    />
  ),
  [STATUS_PAYMENT_REQUEST.REJECTED]: ({
    index,
    rowData,
    options,
    handleUpdateButton,
  }) => (
    <Requests
      key={`row-${index}`}
      index={index}
      rowData={rowData}
      options={options}
      handleUpdate={handleUpdateButton}
      showButton={false}
      defaultValue={{ label: 'Rechazado', value: 'rejected' }}
      disabled
    />
  ),
};
