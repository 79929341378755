import styled from "styled-components";
import { hintPerano000, scarpaGray100 } from "../../StylesConstants";

export const CiPcvWrapper = styled.div`
  height: 100%;

  .document-grid {
    padding: 0 0 24px 40px;
  }

  .dot-line-documents {
    border: 1px dashed ${scarpaGray100};
    position: absolute;
    height: 170px;
    margin-top: 54px;
    margin-left: 13px;
  }

  .dot-line-download {
    border: 1px dashed ${scarpaGray100};
    position: absolute;
    height: 84px;
    margin-top: 108px;
    margin-left: 13px;
  }

  .document-grid .radio {
    background-color: ${hintPerano000} !important;
    z-index: 1;
  }

  .btn {
    cursor: pointer;
  }

  .btn-grid {
    display: flex;
    gap: 24px;
    flex-flow: wrap;
  }

  .btn-disabled {
    opacity: 0.6;
    cursor: default;
  }
`;