import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { updateGenericData } from '../../redux/actions/propertyData';
import { genericDataService } from '../../services/data/genericDataService';
import { ButtonLifeCycleWrapper } from './style';

export const ButtonLifeCycle = ({ nid }) => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;

  const [propertyName, setPropertyName] = useState('');
  const [data, setData] = useState({});

  const saveData = useCallback(() => {
    if (data) {
      dispatch(updateGenericData({ ...data }));
    }
  }, [data, dispatch]);

  useEffect(() => {
    saveData(nid);
  }, [nid, saveData]);

  const getData = useCallback(async () => {
    const data = await genericDataService({ nid });
    setData(data);
    setPropertyName(data.residential_name);
  }, [nid]);

  useEffect(() => {
    getData();
  }, [nid, getData]);

  return (
    <ButtonLifeCycleWrapper selected={pathName === '/home'}>
      <NavLink to={'/home'} className={'button-container'}>
        <div className="vertical-bar"></div>
        <div className="content">
          <span className="title">{propertyName}</span>
          <span className="detail">NID {nid}</span>
        </div>
      </NavLink>
    </ButtonLifeCycleWrapper>
  );
};
