import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconSend from '../../../../static/assets/img/icons/icon-send.svg';

import DocumentsList from '../../../../components/DocumentsList/documentsList';
import OptionalDocument from '../../../../components/OptionalDocument/optionalDocument';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import ButtonCheck from '../../../../components/ButtonCheck/buttonCheck';

import { BUSINESS_TYPE, DOCUMENT_STATUS, EVENT_STATUS, PROCESS, PROCESS_EVENTS, PROCESS_SECTIONS } from '../../../../utils/constant';
import { getEventStatus } from '../../../../services/eventsService';
import { ButtonGrid, DocumentsGrid } from '../../../../components/Styles/genericStyleComponent';
import { StageDocumentWrapper } from './style';

const StageDocuments = ({ section, events, documentsReady, handleCallEvent, handleUpdateDocument, handleAddOptionalDocument }) => {

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    const isSectionApproved = () => {
      return section.name === PROCESS_SECTIONS.PRE_STUDY_TITLE_REQUEST_FIRST_SENDING
        ? getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST) === DOCUMENT_STATUS.SUCCESS
        : getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_SECOND) === DOCUMENT_STATUS.SUCCESS;
    };

    setAccordionExpanded(!isSectionApproved());
    setShowDelete(getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_APROVED) !== DOCUMENT_STATUS.SUCCESS);
  }, [section, events])

  const showDocumentsReady = () => {
    return section.name === PROCESS_SECTIONS.PRE_STUDY_TITLE_REQUEST_SECOND_SENDING
      && getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST)
  };

  return (
    <StageDocumentWrapper>
      <Accordion
        expanded={accordionExpanded}
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="accordion-title"
        >
          <div className="icon-send">
            <img src={IconSend} alt="Icon send" />
          </div>
          <span>
            {section.label}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="detail-container">
            {
              documentsReady &&
              documentsReady.length > 0 &&
              showDocumentsReady() &&
              <DocumentsList
                documents={documentsReady}
                title="Documentos enviados"
              />
            }
            <DocumentsGrid>
              {section.docs.map((document, i) => (
                <DocumentComponent
                  key={`doc_${i}_${document.name}`}
                  documentParam={document}
                  businessTypeId={BUSINESS_TYPE.BUYERS}
                  processName={PROCESS.OTHER_BANKS_TITLE_PRE_STUDY}
                  handleUpload={handleUpdateDocument}
                  handleDelete={handleUpdateDocument}
                  showDelete={showDelete}
                />
              ))}
              <OptionalDocument
                businessType={BUSINESS_TYPE.BUYERS}
                processName={PROCESS.OTHER_BANKS_TITLE_PRE_STUDY}
                sectionName={section.name}
                handleAddDocument={handleAddOptionalDocument}
                documentsToFilter={documentsReady}
              />
            </DocumentsGrid>
            <ButtonGrid>
              {
                section.name === PROCESS_SECTIONS.PRE_STUDY_TITLE_REQUEST_FIRST_SENDING
                  ? <ButtonCheck
                    label="Solicitar Estudio de títulos"
                    status={getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST)}
                    handleChange={() => handleCallEvent(PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_FIRST, EVENT_STATUS.SUCCESS)}
                    disabled={section.docs.some(document => document.required && !document.exist)}
                  />
                  : <ButtonCheck
                    label="Solicitar Estudio de títulos"
                    status={getEventStatus(events, PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_SECOND)}
                    handleChange={() => handleCallEvent(PROCESS_EVENTS.PRE_STUDY_TITLE_REQUEST_SECOND, EVENT_STATUS.SUCCESS)}
                    disabled={
                      section.docs.length === 0 ||
                      section.docs.some(document => document.required && !document.exist)
                    }
                  />
              }
            </ButtonGrid>
          </div>
        </AccordionDetails>
      </Accordion>
    </StageDocumentWrapper>
  )
}

export default StageDocuments;
