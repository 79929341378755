import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import EmptyData from '../../components/EmptyData/emptyData';
import useDynamicForm from '../../hooks/useDynamicForm';
import { Container, FormContainer } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getSignatureAndDeliveryBuyersData } from '../../services/data/signatureAndDeliveryBuyersData';
import Section from '../../components/DynamicForm/Section/section';
import { BUSINESS_TYPE, FIELD_NAME, PROCESS, EVENT_STATUS, PROCESS_EVENTS } from '../../utils/constant';
import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import Button from '../../components/shared/button/button';
import LoadingData from '../../components/LoadingData/loadingData';
import { callEventProcess } from '../../apis/Process/processApis';
import RouteLeavingGuard from '../../components/RouteLeavingGuard/RouteLeavingGuard';

const SignatureAndDeliveryBuyers = () => {

  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const [loading, setLoading] = useState(false);

  const [dataForm, setDataForm] = useState([]);
  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave,
    haveChangeData
  ] = useDynamicForm(dataForm);

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await getSignatureAndDeliveryBuyersData(nid);
      setDataForm(data.form);
      dispatch(dataFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  const save = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      try {
        setLoading(true);
        await updateFormData({
          nid: typeof string ? parseInt(nid) : nid,
          agent,
          process_name: PROCESS.DEED_DELIVERY,
          fields: finalValues,
          business_type_id: BUSINESS_TYPE.BUYERS,
        });
        sendNotifications(finalValues);
      } catch (error) {
        console.error('ERROR:', error);
      } finally {
        setLoading(false);
      }
    }
  }

  const sendNotifications = (fields) => {
    if (fields.some(field => field.field_name === FIELD_NAME.SCHEDULE_DEED_TIMESTAMP)) {
      callEvent(PROCESS_EVENTS.SIGNATURE_SCHEDULED, EVENT_STATUS.SUCCESS);
    }
  };

  const callEvent = async (eventName, status) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.DEED_DELIVERY,
        eventName,
        status,
        originUrl: window.location.href,
      });
    } catch (error) {
      console.error("ERROR:", error);
    }
  };

  const getValidateAndSubmitCallback = (validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        console.log('ERRORS:', errors);
      }
    });
  };

  const validateAndSubmit = async (validateForm, submitForm) => {
    await getValidateAndSubmitCallback(validateForm);
    submitForm();
  };

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <FormContainer>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={save}
            >
              {(props) =>
                sections.length > 0 &&
                <>
                  <Form className="form-container">
                    {sections.map(section =>
                      <Section
                        section={section}
                        formikProps={props}
                        key={`section${section.id}`}
                      />
                    )}
                    <Button
                      type="submit"
                      loading={loading}
                    >
                      Guardar cambios
                    </Button>
                  </Form>
                  <RouteLeavingGuard
                    shouldBlockNavigation={() => haveChangeData(props.values)}
                    handleConfirm={() => validateAndSubmit(props.validateForm, props.submitForm)}
                  />
                </>
              }
            </Formik>
          </FormContainer>
        </Container>
      </LoadingData>
    </EmptyData>
  )
}

export default SignatureAndDeliveryBuyers;
