import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callEventProcess, saveProcessComment } from '../../apis/Process/processApis';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import Comments from '../../components/Comments/comments';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';

import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import RightSection from '../../components/RightSection/rightSection';
import { ButtonGrid, Container, DocumentsGrid, GridForRightSection, Title } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getDraftApprovalRequestData } from '../../services/data/draftApprovalRequestData';
import { updateDocuments } from '../../services/documentsService';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import { BUSINESS_TYPE, EVENT_STATUS, PROCESS, PROCESS_EVENTS, ROLE } from '../../utils/constant';
import DraftApprovalRequestForm from './components/DraftApprovalRequestForm/DraftApprovalRequestForm';

const processName = PROCESS.DRAFT_APPROVAL_REQUEST;
const businessType = BUSINESS_TYPE.SELLERS;

const DraftApprovalRequest = () => {

  const nid = useSelector((state) => state.propertyData.nid);
  const { email, role } = useSelector((state) => state.loginData.data);
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState([]);
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [comments, setComments] = useState([]);
  const [events, setEvents] = useState([]);
  const [form, setForm] = useState([]);

  const mapEvents = (eventsTemp) => {
    setEvents([
      {
        label: 'Solicitar aprobación de minuta a banco',
        name: PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST,
        status: getEventStatus(eventsTemp, PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST),
      },
      {
        label: 'Solicitud aprobada',
        name: PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST_APPROVED,
        status: getEventStatus(eventsTemp, PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST_APPROVED),
      },
    ]);
  };

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getDraftApprovalRequestData(nid, processName, businessType);
        setDocuments(response.documents.filter(doc => doc.required || doc.url));
        setOptionalDocuments(response.documents.filter(doc => !doc.required && !doc.url));
        mapEvents(response.events);
        setForm(response.form);
        setComments(response.comments);
        dispatch(dataFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch],
  );

  const saveComment = async (comment) => {
    try {
      await saveProcessComment({
        nid: nid,
        processName: PROCESS.MORTGAGE,
        agent: email,
        nameReviewerRole: role[0],
        comment,
        originUrl: window.location.href,
        noInteractions: true
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role: role[0],
        },
        ...comments,
      ]);
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: businessType,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
      email
    });
    setEvents((evts) => updateEvents(evts, eventName, status));
  };

  const updateDocument = (status, newDocument) => {
    if (!newDocument.exist
      && getEventStatus(events, PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST) === EVENT_STATUS.SUCCESS
    ) {
      callEvent(PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST, EVENT_STATUS.REVIEW);
    }
    setDocuments((docs) => updateDocuments(newDocument, docs));
  };

  const addOptionalDocument = (document) => {
    setDocuments(docs => {
      return docs.some(doc => doc.label === document.label)
        ? docs
        : [...docs, document];
    });
    setOptionalDocuments(docs => docs.filter(doc => doc.label !== document.label));
  };

  const isEventDisabled = (eventName) => {
    const haveAuthorizedRole = role.some(role => role === ROLE.MORTGAGE_ANALYST_JUNIOR)
      || role.some(role => role === ROLE.MORTGAGE_ANALYST_SENIOR);
    return eventName === PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST_APPROVED
      ? getEventStatus(events, PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST) !== EVENT_STATUS.SUCCESS || !haveAuthorizedRole
      : !haveAuthorizedRole
  }

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [dispatch, nid, getData]);

  return (
    <EmptyData>
      <LoadingData>
        <GridForRightSection>
          <Container>
            <Title>
              Solicitud aprob. de minuta a banco
            </Title>
            <DocumentsGrid>
              {documents.map(doc =>
                <DocumentComponent
                  businessTypeId={businessType}
                  processName={processName}
                  documentParam={doc}
                  handleUpload={updateDocument}
                  handleDelete={updateDocument}
                  showDelete={getEventStatus(events, PROCESS_EVENTS.DRAFT_APPROVAL_REQUEST_APPROVED) !== EVENT_STATUS.SUCCESS}
                />
              )}
              <OptionalDocument
                businessTypeId={businessType}
                processName={processName}
                handleAddDocument={addOptionalDocument}
                optionalDocuments={optionalDocuments}
              />
            </DocumentsGrid>
            <ButtonGrid>
              {events.map((event) => (
                <ButtonCheck
                  dataId={event.name}
                  label={event.label}
                  status={event.status === EVENT_STATUS.SUCCESS ? EVENT_STATUS.SUCCESS : null}
                  handleChange={() => callEvent(event.name, EVENT_STATUS.SUCCESS)}
                  disabled={isEventDisabled(event.name)}
                />
              ))}
            </ButtonGrid>
            <DraftApprovalRequestForm
              form={form}
              processName={processName}
              nid={nid}
              agent={email}
            />
          </Container>
          <RightSection>
            <Comments
              commentsHistory={comments}
              handleSaveComment={saveComment}
            />
          </RightSection>
        </GridForRightSection>
      </LoadingData>
    </EmptyData>
  )
}

export default DraftApprovalRequest;
