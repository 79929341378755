import React from 'react'
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import Button from '../../../../components/shared/button/button';
import { FormContainer } from '../../../../components/Styles/genericStyleComponent';
import useDynamicForm from '../../../../hooks/useDynamicForm';
import Section from '../../../../components/DynamicForm/Section/section';
import { updateFormData } from '../../../../apis/DynamicForm/dynamicFormApis';
import { BUSINESS_TYPE } from '../../../../utils/constant';

const DraftApprovalRequestForm = ({ form, processName, nid, agent }) => {

  const [loading, setLoading] = useState(false);

  const [
    sections,
    initialValues,
    validationSchema,
    cleanDataToSave
  ] = useDynamicForm(form);

  const save = async (values) => {
    const finalValues = cleanDataToSave(values);
    if (finalValues.length > 0) {
      try {
        setLoading(true);
        await updateFormData({
          nid: typeof string ? parseInt(nid) : nid,
          agent,
          process_name: processName,
          fields: finalValues,
          business_type_id: BUSINESS_TYPE.SELLERS,
        });
      } catch (error) {
        console.error('ERROR:', error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <FormContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={save}
      >
        {(props) =>
          sections.length > 0 &&
          <>
            <Form className="form-container">
              {sections.map(section =>
                <Section
                  section={section}
                  formikProps={props}
                  key={`section${section.id}`}
                />
              )}
              <Button
                dataId={'save'}
                type="submit"
                loading={loading}
              >
                Guardar cambios
              </Button>
            </Form>
          </>
        }
      </Formik>
    </FormContainer>
  )
}

export default DraftApprovalRequestForm;
