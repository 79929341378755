export const FINANCIAL_ENTITIES = [
  {
    label: 'Bancolombia',
    value: 'bancolombia',
  },
  {
    label: 'N/A',
    value: 'na',
  },
  {
    label: 'Banco de Bogotá',
    value: 'banco_de_bogota',
  },
  {
    label: 'Banco Caja Social',
    value: 'banco_caja_social',
  },
  {
    label: 'AV Villas',
    value: 'av_villas',
  },
  {
    label: 'Scotiabank Colpatria',
    value: 'scotiabank',
  },
  {
    label: 'Caja Honor',
    value: 'caja_honor',
  },
  {
    label: 'Davivienda',
    value: 'davivienda',
  },
  {
    label: 'Fecolsa',
    value: 'fecolsa',
  },
  {
    label: 'BBVA',
    value: 'bbva',
  },
  {
    label: 'Itau',
    value: 'banco_itau',
  },
  {
    label: 'Bancoomeva',
    value: 'bancoomeva',
  },
  {
    label: 'Fondo de empleados Exito',
    value: 'fondo_de_empleados_exito',
  },
  {
    label: 'Fondo de la Contraloría',
    value: 'fondo_de_la_contraloria',
  },
  {
    label: 'La Hipotecaria',
    value: 'la_hipotecaria',
  },
  {
    label: 'Banco Popular',
    value: 'banco_popular',
  },
  {
    label: 'Banco GNB sudameris',
    value: 'banco_gnb_sudameris',
  },
  {
    label: 'Enel Colombia',
    value: 'enel_colombia',
  },
  {
    label: 'Confiar',
    value: 'confiar',
  },
  {
    label: 'Fondo de Empleados',
    value: 'fondo_de_Empleados',
  },
  {
    label: 'Fondo de Empleados Bavaria',
    value: 'fondo_de_empleados_bavaria',
  },
  {
    label: 'Codema',
    value: 'codema',
  },
  {
    label: 'Alcaldía de Medellín',
    value: 'alcaldia_de_medellín',
  },
  {
    label: 'Cooperativa Sura',
    value: 'cooperativa_sura',
  },
  {
    label: 'Fondo de Empleados Nestlé',
    value: 'fondo_de_empleados_nestle',
  },
  {
    label: 'Banco de la República',
    value: 'banco_de_la_republica',
  },
  {
    label: 'Fondo Empleados Praxa Colombia',
    value: 'fondo_empleados_praxa_colombia',
  },
  {
    label: 'Fondo Empleados Levapan',
    value: 'fondo_empleados_levapan',
  },
  {
    label: 'ISA Intercolombia',
    value: 'isa_intercolombia',
  },
  {
    label: 'Fondo nacional del ahorro',
    value: 'fondo_nacional_del_ahorro',
  },
  {
    label: 'Pepsico',
    value: 'pepsico',
  },
  {
    label: 'Gobernación de Antioquia',
    value: 'gobernación_de_antioquia',
  },
  {
    label: 'EPM',
    value: 'epm',
  },
  {
    label: 'Cavipetrol',
    value: 'cavipetrol',
  },
  {
    label: 'Acueducto',
    value: 'acueducto',
  },
  {
    label: 'FEPEP',
    value: 'fepep',
  },
  {
    label: 'Fondo de los Andes',
    value: 'fondo_de_los_andes',
  },
  {
    label: 'Cooperativa Canapro',
    value: 'cooperativa_canapro',
  },
  {
    label: 'Banco Agrario',
    value: 'banco_agrario',
  },
  {
    label: 'Colsubsidio',
    value: 'colsubsidio',
  },
  {
    label: 'Cooperativa Cooptraiss',
    value: 'cooperativa_cooptraiss',
  },
  {
    label: 'Credifamilia',
    value: 'credifamilia',
  },
  {
    label: 'Banco CorpBanca',
    value: 'banco_corpbanca',
  },
  {
    label: 'Citibank',
    value: 'citibank',
  },
  {
    label: 'Banco de Occidente',
    value: 'banco_de_occidente',
  },
  {
    label: 'Banagrario',
    value: 'banagrario',
  },
  {
    label: 'Banco Credifinanciera',
    value: 'banco_Credifinanciera',
  },
  {
    label: 'Bancamía S.A.',
    value: 'bancamia_sa',
  },
  {
    label: 'Banco W S.A.',
    value: 'banco_w_sa',
  },
  {
    label: 'Banco W S.A.',
    value: 'banco_w_sa',
  },
  {
    label: 'Finandina',
    value: 'finandina',
  },
  {
    label: 'Banco Falabella S.A.',
    value: 'banco_falabella_sa',
  },
  {
    label: 'Banco Pichincha S.A.',
    value: 'banco_pichincha_sa',
  },
  {
    label: 'Banco Santander',
    value: 'banco_santander',
  },
  {
    label: 'Banco Mundo Mujer',
    value: 'banco_mundo_mujer',
  },
  {
    label: 'Banco Serfinanzas',
    value: 'banco_serfinanzas',
  },
  {
    label: 'Corficolombiana',
    value: 'corficolombiana',
  },
  {
    label: 'Bancoldex',
    value: 'bancoldex',
  },
];

export const PURCHASE_FINANCING = [
  {
    label: 'Habi',
    value: 'habi',
  },
  {
    label: 'PA + Dann',
    value: 'pa_mas_dann',
  },
  {
    label: 'PA + Colpatria',
    value: 'pa_mas_colpatria',
  },
  {
    label: 'Leasing Dann',
    value: 'leasing_dann',
  },
];

export const NOTARY_SALE = [
  {
    label: 'Notaría Cali 11',
    value: 'notaria_cali_11',
  },
  {
    label: 'Notaría Medellín 10',
    value: 'notaria_medellin_10',
  },
  {
    label: 'Notaría Bogotá 41',
    value: 'notaria_bogota_41',
  },
  {
    label: 'Notaría Bogotá 68',
    value: 'notaria_bogota_68',
  },
  {
    label: 'Notaría Barranquilla 1',
    value: 'notaria_barranquilla_1',
  },
];

export const PAYMENT_METHOD = [
  {
    label: 'Contado',
    value: 'contado',
  },
  {
    label: 'Leasing',
    value: 'leasing',
  },
  {
    label: 'Crédito',
    value: 'credito',
  },
  {
    label: 'Cesantías',
    value: 'cesantias',
  }
]

export const ANALYST = [
  { label: 'Karen Rojas', value: 'karenrojas@habi.co' },
  { label: 'Milena Barrera', value: 'milenabarrera@habi.co' },
  { label: 'Andres Ogonaga', value: 'andresogonaga@habi.co' },
  { label: 'Mauricio Pedraza', value: 'mauriciopedraza@habi.co' },
  { label: 'Tatiana Aramos', value: 'tatianaramos@habi.co' },
  { label: 'Emir Trujillo', value: 'emirtrujillo@habi.co' },
  { label: 'Eliana Manrique', value: 'elianamanrique@habi.co' },
  { label: 'Carolina Rocha', value: 'carolinarocha@habi.co' },
  { label: 'Carolina Castillo', value: 'carolinacastillo@habi.co' },
  { label: 'Oscar Caballero', value: 'oscarcaballero@habi.co' },
  { label: 'Camila Bonilla', value: 'camilabonilla@habi.co' },
  { label: 'Yessica Pedreros', value: 'yessicapedreros@habi.co' },
  { label: 'Jenifer Poveda', value: 'jeniferpoveda@habi.co' },
  { label: 'Camilo Ramírez', value: 'camiloramirez@habi.co' },
  { label: 'Jorge Salazar', value: 'jorgesalazar@habi.co' },
  { label: 'Loren Bernal', value: 'lorenbernal@habi.co' },
  { label: 'Juan Tuberquia', value: 'juantuberquia@habi.co' },
  { label: 'Laura León', value: 'lauraleon@habi.co' },
  { label: 'Juan Sebastián Cepeda', value: 'juancepeda@habi.co' },
  { label: 'Juliet Moreno', value: 'Julietmoreno@habi.co' },
];
 

