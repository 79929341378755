import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LifeCycle } from '../../../../components/LifeCycle/LifeCycle';
import {
  Container,
  Hr,
} from '../../../../components/Styles/genericStyleComponent';
import { HeaderHome } from './components/headerHome/HeaderHome';
import { ResponsibleDetail } from './components/responsablesDetail/ResponsablesDetail';
import { HomeLifeCycleWrapper } from './style';

export const HomeLifeCycle = ({ nid }) => {
  const [propertyName, setPropertyName] = useState('');
  const genericData = useSelector((state) => state.propertyData.genericData);

  const getData = useCallback(() => {
    if (genericData) {
      setPropertyName(genericData.residential_name);
    }
  }, [genericData]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <HomeLifeCycleWrapper>
      <Container>
        <HeaderHome propertyName={propertyName}></HeaderHome>
        <Hr />
        <LifeCycle title={'Progreso del negocio'} />
        <Hr />
        <ResponsibleDetail
          assessor={genericData?.comercial_email}
          analystSr={genericData?.analyst_email}
          analystJr={genericData?.analyst_jr_email}
        ></ResponsibleDetail>
      </Container>
    </HomeLifeCycleWrapper>
  );
};
