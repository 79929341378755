import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBondingDataApi } from '../../apis/Financing/financingApi';
import { callEventProcess } from '../../apis/Process/processApis';
import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import PageMessage from '../../components/PageMessage/pageMessage';
import { Container, Title, TitleAdd } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { BUSINESS_TYPE, DOCUMENT_STATUS, DUMMY_PROCESS, FUNDING_TYPE, PROCESS_EVENTS, ROUTES } from '../../utils/constant';
import { FUNDING_TYPE_LABEL, PURCHASE_OPTION_PROCESS_BY_FUNDING_TYPE } from '../../utils/dynamic-constant';
import PurchaseOptionLeasingDann from './components/PurchaseOptionLeasingDann/PurchaseOptionLeasingDann';
import PurchaseOptionPaColpatria from './components/PurchaseOptionPaColpatria/PurchaseOptionPaColpatria';
import PurchaseOptionPaDann from './components/PurchaseOptionPaDann/PurchaseOptionPaDann';

const PurchaseOption = () => {

  const nid = useSelector((state) => state.propertyData.nid);
  const dispatch = useDispatch();

  const [sections, setSections] = useState([]);
  const [events, setEvents] = useState([]);
  const [fundingType, setFundingType] = useState(null);

  const getPurchaseOptionData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getBondingDataApi({
          nid,
          businessTypeId: BUSINESS_TYPE.BUYERS,
          dummyProcess: DUMMY_PROCESS.PURCHASE_OPTION
        });
        const { data } = response.data;
        setSections(data.sections);
        setEvents(data.events);
        setFundingType(data.funding_type);
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setSections([]);
          setEvents([]);
        } else {
          console.log("error:::", error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    nid? getPurchaseOptionData(nid): dispatch(dataNotFound());
  }, [nid, getPurchaseOptionData, dispatch]);

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: BUSINESS_TYPE.BUYERS,
      processName: PURCHASE_OPTION_PROCESS_BY_FUNDING_TYPE[fundingType],
      eventName,
      status,
      originUrl: window.location.href
    });
    updateEvents(eventName, status);
  };

  const updateEvents = (eventName, status) => {
    if(events.some(event => event.name === eventName)){
      setEvents(
        events.map(event => 
          event.name === eventName
            ? { name: event.name, status }
            : event
        )
      )
    } else {
      setEvents([
        ...events,
        { name: eventName, status }
      ])
    }
  };

  const addOptionalDocument = (document, sectionName) => {
    setSections(
      sections.map(section =>
        (section.name === sectionName && !section.list_documents.some(doc => doc.name === document.name ))
          ? {
              ...section, 
              list_documents: [...section.list_documents, document]
            }
          : section
      )
    )
  };

  const updateDocument = (status, newDocument) => {

    const newSections = sections.map(section => {
      const newListDocuments = section.list_documents.map(document => {
        return document.label === newDocument.label
          ? newDocument
          : document;
      })
      return {...section, list_documents: newListDocuments}
    });
    setSections(newSections);

    if(fundingType === FUNDING_TYPE.LEASING_DANN || fundingType === FUNDING_TYPE.PA_DANN) {
      sections.forEach((section, indexSection) => {
        section.list_documents.forEach(document => {
          if(document.label === newDocument.label 
            && getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_ONE) === DOCUMENT_STATUS.SUCCESS
            && indexSection===0
          ) {
            callEvent(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_ONE, DOCUMENT_STATUS.REVIEW)
          } else if (document.label === newDocument.label 
            && getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_TWO) === DOCUMENT_STATUS.SUCCESS
            && indexSection===1
          ) {
            callEvent(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION_TWO, DOCUMENT_STATUS.REVIEW)
          }
        })
      });
    } else if (fundingType === FUNDING_TYPE.PA_COLPATRIA) {
      sections.forEach((section, indexSection) => {
        section.list_documents.forEach(document => {
          if(document.label === newDocument.label 
            && getEventStatus(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION) === DOCUMENT_STATUS.SUCCESS
            && indexSection===0
          ) {
            callEvent(PROCESS_EVENTS.REQUEST_PURCHASE_OPTION, DOCUMENT_STATUS.REVIEW)
          }
        });
      });
    }
  };

  const getEventStatus = (eventName) => {
    const event = events.find( event => event.name === eventName);
    return event?.status === DOCUMENT_STATUS.SUCCESS ? event.status : null;
  };

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <Title>
            {
              fundingType === FUNDING_TYPE.PA_DANN
                ? 'Vinculación'
                : 'Aprob. Opción de compra' 
            }
            <TitleAdd>
              { FUNDING_TYPE_LABEL[fundingType]?? '' }
            </TitleAdd>
          </Title>
          {
            fundingType &&
            fundingType === FUNDING_TYPE.LEASING_DANN &&
            <PurchaseOptionLeasingDann
              sections={ sections } 
              events={ events } 
              handleCallEvent={ callEvent }
              addOptionalDocument={ addOptionalDocument } 
              handleUpdateDocument={ updateDocument }
              getEventStatus={ getEventStatus }
            />
          }
          {
            fundingType &&
            fundingType === FUNDING_TYPE.PA_COLPATRIA &&
            <PurchaseOptionPaColpatria
              sections={ sections } 
              events={ events } 
              handleCallEvent={ callEvent }
              addOptionalDocument={ addOptionalDocument } 
              handleUpdateDocument={ updateDocument }
              getEventStatus={ getEventStatus }
            />
          }
          {
            fundingType &&
            fundingType === FUNDING_TYPE.PA_DANN &&
            <PurchaseOptionPaDann
              sections={ sections } 
              events={ events } 
              handleCallEvent={ callEvent }
              addOptionalDocument={ addOptionalDocument } 
              handleUpdateDocument={ updateDocument }
              getEventStatus={ getEventStatus }
            />
          }
        </Container>
        {
          fundingType &&
          fundingType !== FUNDING_TYPE.LEASING_DANN &&
          fundingType !== FUNDING_TYPE.PA_COLPATRIA &&
          fundingType !== FUNDING_TYPE.PA_DANN &&
          <PageMessage 
            title="Vinculación no soportada" 
            description={
              <>
                Actualmente la aplicación no soporta la vinculación con { FUNDING_TYPE_LABEL[fundingType]?? '' }
              </>
            }
          />
        }
        {
          !fundingType &&
          <PageMessage
            title="Vinculación sin asignar"
            description={
              <>
                Para poder continuar, ve al formulario de recepción <Link to={ROUTES.PROPERTY_SELLERS} >Inmuebles vendedores</Link> <br />
                y selecciona el tipo de financiación que aplica para este negocio.
              </>
            }
          />
        }
      </LoadingData>
    </EmptyData>
  )
};

export default PurchaseOption;
