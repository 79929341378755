import styled from "styled-components";
import {
  hintPerano100,
  light_purple,
  openSansBoldFamily,
  openSansRegular,
  scarpaGray100,
  scarpaGray600,
  scarpaGray700,
  white,
} from '../../../../StylesConstants';

export const PropertyDetailWrapper = styled.div`
  height: 40px;
  background-color: ${white};
  margin-bottom: 48px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 280px 1fr;

  .property-name-content {
    border-right: solid 1px ${scarpaGray100};
    display: flex;
    align-items: center;

    .house-icon {
      background-color: ${hintPerano100};
      width: fit-content;
      border-radius: 4px;
      margin-right: 16px;

      svg {
        align-items: center;
        path {
          color: ${light_purple};
        }
      }
    }

    .property-name {
      font-family: ${openSansBoldFamily};
      color: ${scarpaGray700}
      margin-right: 16px;
      font-size: 13px;
    }
  }

  .property-nid-content {
    border-right: solid 1px ${scarpaGray100};
    display: grid;
    align-items: center;
    text-align: center;
    color: ${scarpaGray600};
  }

  .property-price-content {
    display: grid;
    align-items: center;
    justify-content: center;
    font-family: ${openSansRegular};
    color: ${scarpaGray600}
  }
`;