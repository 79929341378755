import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { updatePropertyData } from "../../../redux/actions/propertyData";

import { SearchWrap } from "./style";
import { useDispatch, useSelector } from "react-redux";

const SearchButtton = () => {

  const dispatch = useDispatch();
  const {noData, loadingSearch, nid} = useSelector(state => state.propertyData);

  const [data, setData] = useState(nid);
  const [loading, setLoading] = useState(false);

  const saveData = useCallback(
    () => {
      if (data) {
        dispatch(updatePropertyData({ nid: data }));
      }
    }, [data, dispatch],
  )

  useEffect(() => {
    setData(nid? nid: '');
  }, [nid]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      saveData();
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [saveData])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      saveData();
    }
  };

  useEffect(() => {
    setLoading(loadingSearch);
  }, [loadingSearch]);
  
  return (
    <SearchWrap>
      <div className="search-container">
        <form className={`form-search ${noData ? "error" : ""}`}>
          <input
            className={`input-search ${noData ? "error" : ""}`}
            type="number"
            value={data}
            onChange={(e) => setData(e.target.value)}
            onKeyDown={handleKeyDown}
            required
            placeholder="Busca por NID"
          />
          <div className="button-container">
            { loading 
              ? (
                <CircularProgress 
                  size={18} 
                  className="buttonProgress" 
                />
              ):(
                <button
                  type="button"
                  className={`button-search ${loading ? "disabled" : ""}`}
                  onClick={() => saveData()}
                >
                  <SearchIcon />
                </button>
              )
            }
          </div>
        </form>
      </div>
    </SearchWrap>
  );
};

export default SearchButtton;
