import { store } from "react-notifications-component";

/**
 * Create a notification with feedback to user
 * if nothing is sent, the default values will be taken as the default values.
 * @param {Object} title {title: 'title of the toast', message:'message of the toast'} 
 */
export const successNotification = ({title, message }) => {
    store.addNotification({
      title: title?? "Success",
      message: message?? "¡Datos guardados con éxito!",
      type: "success",
      container: "top-left",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
      },
    });
  };

/**
 * Create a notification with feedback to user
 * if nothing is sent, the default values will be taken as the default values.
 * @param {Object} title {title: 'title of the toast', message:'message of the toast'} 
 */
export const errorNotification = ({ title, message }) => {
    store.addNotification({
      title: title?? "Error!!",
      message: message?? "Contacte a soporte, no se guardaron los datos",
      type: "danger",
      container: "top-left",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
      },
    });
  };