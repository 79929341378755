import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callEventProcess } from '../../apis/Process/processApis';

import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import EmptyData from '../../components/EmptyData/emptyData';
import GroupRadioOption from '../../components/GroupRadioOption/GroupRadioOption';
import LoadingData from '../../components/LoadingData/loadingData';
import RadioCheck from '../../components/RadioCheck/radioCheck';

import { Container, DocumentsGrid, DocumentsStepsDotLine, DocumentsStepsGrid, Hr, Title } from '../../components/Styles/genericStyleComponent';
import { dataFound, dataNotFound, hideLoading, showLoading } from '../../redux/actions/propertyData';
import { getTitleStudyAndDraftsData } from '../../services/data/titleStudyAndDraftsData';
import { updateDocumentsInSections } from '../../services/documentsService';
import { updateEvents } from '../../services/eventsService';
import { ACTIVITY_STATUS, BUSINESS_TYPE, DOCUMENT_TYPE_NAME, DOCUMENT_WRAPPER_TYPE, EVENT_STATUS, PROCESS, PROCESS_EVENTS } from '../../utils/constant';

const processName = PROCESS.OTHER_BANKS_TITLE_STUDY;
const businessTypeId = BUSINESS_TYPE.BUYERS;
const statusOptionsTitleStudy = [
  {
    label: 'Aprobado',
    name: EVENT_STATUS.SUCCESS,
  },
  {
    label: 'Rechazado',
    name: EVENT_STATUS.REJECT,
  },
];

const TitleStudyAndDrafts = () => {

  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);

  const [documents, setDocuments] = useState([]);
  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await getTitleStudyAndDraftsData(nid);
      setDocuments(data.documents);
      setEvents(data.events);
      dispatch(dataFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  const documentUpdate = (status, newDocument) => {
    setDocuments((docs) => updateDocumentsInSections(newDocument, docs));

    if (newDocument.exist) {
      switch (newDocument.name) {
        case DOCUMENT_TYPE_NAME.NOTARY_DRAFT:
          callEvent(PROCESS_EVENTS.LOAD_BILLING, EVENT_STATUS.SUCCESS);
          break;

        case DOCUMENT_TYPE_NAME.LEGAL_DRAFT:
          callEvent(PROCESS_EVENTS.LOAD_LEGAL_BILLING, EVENT_STATUS.SUCCESS);
          break;

        case DOCUMENT_TYPE_NAME.BANK_DRAFT:
          callEvent(PROCESS_EVENTS.APPROVE_BILLING, EVENT_STATUS.SUCCESS);
          break;

        default:
          break;
      }
    } else {
      if (newDocument.name === DOCUMENT_TYPE_NAME.BANK_DRAFT) {
        callEvent(PROCESS_EVENTS.APPROVE_BILLING, EVENT_STATUS.REJECT);
      }
    }
  }

  const isEventDisabled = (eventName) => {

    let isDisabled = false;

    switch (eventName) {
      case PROCESS_EVENTS.SEND_BILLING:
        isDisabled = documents[1].docs.some(doc => !doc.exist);
        break;

      case PROCESS_EVENTS.APPROVE_BILLING:
        isDisabled = documents[2].docs.some(doc => !doc.exist);
        break;

      default:
        break;
    }

    return isDisabled;
  }

  const getEventStatus = (eventName) => {
    const event = events.find(event => event.name === eventName);
    return event ? event.status : null;
  }

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId,
      processName,
      eventName,
      status,
      originUrl: window.location.href,
    });
    setEvents(currentEvents =>
      updateEvents(currentEvents, eventName, status)
    );
  };

  const getDocumentComponent = ({ document, wrapperType, processName }) => {
    return (
      <DocumentComponent
        documentParam={document}
        businessTypeId={businessTypeId}
        wrapperType={wrapperType}
        handleUpload={documentUpdate}
        handleDelete={documentUpdate}
        processName={processName}
        key={`document${document.document_type_id}`}
      />
    );
  }

  const getDocument = (document) => {
    return document &&
      <DocumentsStepsGrid>
        <div>
          <RadioCheck
            status={document.exist
              ? ACTIVITY_STATUS.DONE
              : ACTIVITY_STATUS.OPEN}
          />
        </div>
        {
          getDocumentComponent({
            document,
            wrapperType: DOCUMENT_WRAPPER_TYPE.DOT_LINE,
            processName
          })
        }
      </DocumentsStepsGrid>
  }

  const handleChangeTitleStudy = (status) => {
    callEvent(PROCESS_EVENTS.TITLE_STUDY, status);
  }

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <Title>Estudio de título</Title>
          <GroupRadioOption
            initialValue={getEventStatus(PROCESS_EVENTS.TITLE_STUDY)}
            handleChange={handleChangeTitleStudy}
            options={statusOptionsTitleStudy}
            name="title_study"
          />
          {
            getEventStatus(PROCESS_EVENTS.TITLE_STUDY) === EVENT_STATUS.SUCCESS &&
            <div>
              <Hr />
              <Title>Carga de carta de ratificación</Title>
              <DocumentsGrid>
                <DocumentComponent
                  businessTypeId={businessTypeId}
                  processName={processName}
                  documentParam={documents[0].docs[0]}
                  handleUpload={documentUpdate}
                  handleDelete={documentUpdate}
                />
              </DocumentsGrid>
              <Hr />
              <Title>Minutas Hipoteca y Venta</Title>
              <div>
                {documents[1].docs.map((document, index) =>
                  <>
                    {documents[1].docs.length !== (index + 1)
                      && <DocumentsStepsDotLine />}
                    {getDocument(document)}
                  </>
                )}
              </div>
              <ButtonCheck
                label="Enviar minutas"
                status={getEventStatus(PROCESS_EVENTS.SEND_BILLING)}
                handleChange={() => callEvent(PROCESS_EVENTS.SEND_BILLING, EVENT_STATUS.SUCCESS)}
                disabled={isEventDisabled(PROCESS_EVENTS.SEND_BILLING)}
              />
              <Hr marginTop="32" />
              <Title>Minutas Pre-escritura</Title>
              <div>
                {documents[2].docs.map((document, index) =>
                  <>
                    {documents[2].docs.length !== (index + 1)
                      && <DocumentsStepsDotLine />}
                    {getDocument(document)}
                  </>
                )}
              </div>
              <ButtonCheck
                label="Minuta aprobada"
                status={getEventStatus(PROCESS_EVENTS.APPROVE_BILLING)}
                disabled={isEventDisabled(PROCESS_EVENTS.APPROVE_BILLING)}
              />
            </div>
          }
        </Container>
      </LoadingData>
    </EmptyData>
  )
}

export default TitleStudyAndDrafts;
