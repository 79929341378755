import styled from 'styled-components';
import { openSansRegular, plainlightGray, scarpaGray600, white } from '../../../../StylesConstants';

export const HighLevelFiltersWrapper = styled.div`

  margin-bottom: 32px;

  select, input {
    border: 1px solid ${plainlightGray};
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    height: 40px;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    color: ${scarpaGray600};
    font: 16px ${openSansRegular};
  }

  input:focus {
    background-color: ${white} !important;
    border: 1px solid ${plainlightGray} !important;
  }

  .form > div {
    align-items: end;
  }

  .form-control.is-invalid {
    border: none;
    background: none;
  }

  .form-control {
    height: fit-content !important;
    padding: 0 !important;
  }

  .input-field {
    padding-top: 0 !important;
  }
`;

