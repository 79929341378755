import React from 'react'
import { FieldsGrid } from '../../../../../components/Styles/genericStyleComponent';
import { Field } from '../Field/Field';
import { SectionWrapper } from './style';

const Section = ({ fields, formikProps, dependentList }) => {
  
  const getContentFields = () => {
    let fieldsGroup = [];
    fields.forEach(({ label, type, percentage, buttonsQuantity, name, value }, index) => {
      fieldsGroup = [
        ...fieldsGroup,
        <div key={`field_${index}`}>
          <Field
            type={type}
            label={label}
            name={name}
            percentage={percentage}
            buttonsQuantity={buttonsQuantity}
            formikProps={formikProps}
            value={value}
            dependentList={dependentList}
          >
          </Field>
        </div>
      ]
    });
    return fieldsGroup;
  };

  return (
    <SectionWrapper>
      <FieldsGrid>
        {getContentFields()}
      </FieldsGrid>
    </SectionWrapper>
  )
}

export default Section;
