import styled from 'styled-components';
import {
  hintPerano800,
  montserratBold,
  openSansRegular,
} from '../../../../../../StylesConstants';

export const HeaderHomeWrapper = styled.div`
  display: flex;
  margin-bottom: 38px;
  .double-house {
    margin-right: 16px;
  }
  .description {
    display: grid;
    font-size: 18px;
    font-family: ${montserratBold};
  }
  .property {
    font-family: ${openSansRegular};
    font-size: 12px;
  }
  .nid {
    font-family: ${openSansRegular};
    font-size: 12px;
  }
  .propertyName,
  .nid {
    color: ${hintPerano800};
  }
`;
