import styled from 'styled-components';
import {
  grayBarDisabled,
  grayDisabled,
  hintPerano500,
  openSansBoldFamily,
  scarpaGray300,
  scarpaGray500,
  white,
} from '../../../../../../StylesConstants';

export const ResponsibleDetailWrapper = styled.div`
  background-color: ${white};
  border-radius: 8px;
  width: 100%;
  height: 68px;
  box-shadow: rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px,
    rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  display: flex;

  .icon-container {
    width: 140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    height: 68px;
    img {
      align-self: center;
      justify-self: center;
      grid-column: 2;
    }
  }
  .user-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    height: 68px;
    .user-container {
      align-self: center;
      display: flex;
      height: 68px;
      align-items: center;
      &.empty {
        background-color: ${grayDisabled};
        border-radius: 0 8px 8px 0;
        .vertical-bar {
          background-color: ${grayBarDisabled};
        }
      }
      .vertical-bar {
        height: 36px;
        width: 2px;
        background-color: ${hintPerano500};
        margin-right: 24px;
      }
      .user-content {
        display: grid;
        font-size: 12px;
        line-height: 18px;
        .rol {
          color: ${scarpaGray500};
          font-family: ${openSansBoldFamily};
        }
        .user {
          color: ${scarpaGray500};
          &.empty {
            color: ${scarpaGray300};
          }
        }
      }
    }
  }
`;
