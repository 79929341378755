import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { PagerWrapper } from './style';

const Pager = ({ numPages, onChange, activePage }) => {

  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(activePage);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(true);

  useEffect(() => {
    let listPages = [];
    for (let i=1; i<=numPages; i++) {
      listPages.push(i);
    }
    setPages(listPages);
    setNext(active === pages.length? false: true);
    setPrev(active === 1? false: true);
  }, [numPages, active, pages.length]);

  const setPrevPage = () => {
    if(prev) {
      setNewPage(active-1);
    }
  };

  const setNextPage = () => {
    if(next) {
      setNewPage(active+1);
    }
  };

  const setNewPage = (newPage) => {
    setActive(newPage);
    if(newPage>=1 && newPage<=pages.length && newPage!==active) {
      onChange({page: newPage});
    }
  };

  return (
    <PagerWrapper>
      <div 
        className={`control ${ prev? 'active': 'disabled' }`}
        onClick={setPrevPage}
      >
        <ChevronLeftIcon />
        Anterior
      </div>
     {
       pages.map(page => (
         <div 
          key={`page${page}`} 
          className={`page ${ page===active? 'active': '' }`}
          onClick={() => setNewPage(page)}
        >
           {page}
         </div>
       ))
     }
      <div 
        className={`control ${ next? 'active': 'disabled' }`}
        onClick={setNextPage}
      >
        Siguiente
        <ChevronRightIcon />
      </div>
    </PagerWrapper>
  )
};

export default React.memo(Pager);
