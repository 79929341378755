import styled from 'styled-components';

export const MortgageCancellationWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 360px;
  height: 100%;
  .mount-field {
    width: 240px;
    margin-bottom: 32px;
  }
  .mortgage-state-container {
    display: grid;
    margin-bottom: 32px;
  }
`;
