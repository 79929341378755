import styled from "styled-components";

export const FieldCommentWrapper = styled.div`
  
  display: grid;
  height: 100%;
  place-content: center;
  margin-top: 8px;

  .container-comment {
    position: absolute;
    margin: 18px 0 0 40px;
  }

  .over-backdrop {
    z-index: 1000;
  }

  .flag {
    cursor: pointer
  }

`;