import React from 'react';
import { Label } from '../Styles/genericStyleComponent';
import { Field } from 'formik';
import { CustomSelectWrapper } from './style';

export const CustomSelect = ({
  name,
  label,
  disabled,
  options,
  defaultValue,
  formikError,
  labelError,
}) => {
  return (
    <CustomSelectWrapper>
      <Label>{label}</Label>
      <Field as="select" name={name} disabled={disabled} className="select">
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            defaultValue={defaultValue}
          >
            {option.label}
          </option>
        ))}
      </Field>
      {formikError && <div className="invalid-feedback">{labelError}</div>}
    </CustomSelectWrapper>
  );
};
