const getValues = ({ addValues, substractValues }) => {
  const negativeValues =
    substractValues && substractValues.length
      ? substractValues.reduce((prev, curr) => prev + curr)
      : 0;
  const positiveValues =
    addValues && addValues.length
      ? addValues.reduce((prev, curr) => prev + curr)
      : 0;
  const originalValue = positiveValues - negativeValues;
  return originalValue;
};

export const getValueIfNegativeAdd = ({
  pivote,
  subtractTo,
  addValues,
  substractValues,
}) => {
  const originalValue = getValues({
    subtractTo,
    addValues,
    substractValues,
  });
  if (pivote < 0) {
    return parseFloat(Math.abs(pivote) + originalValue).toFixed(2);
  } else {
    return parseFloat(originalValue).toFixed(2);
  }
};

export const getValueIfNegativeSubtract = ({
  pivote,
  addValues,
  subtractTo,
  substractValues,
}) => {
  const originalValue = getValues({
    subtractTo,
    addValues,
    substractValues,
  });
  if (pivote < 0) {
    return parseFloat(originalValue - Math.abs(pivote)).toFixed(2);
  } else {
    return parseFloat(originalValue).toFixed(2);
  }
};
