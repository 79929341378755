import React, { useCallback, useEffect, useState } from 'react';

// Components
import EmptyData from '../../components/EmptyData/emptyData';
import {
  ButtonGrid,
  Container,
  DocumentsGrid,
  GridForRightSection,
  Title,
} from '../../components/Styles/genericStyleComponent';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  EVENT_STATUS,
  PROCESS,
  ROLE,
  PROCESS_EVENTS,
} from '../../utils/constant';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import RightSection from '../../components/RightSection/rightSection';
import Comments from '../../components/Comments/comments';
import {
  callEventProcess,
  saveProcessComment,
} from '../../apis/Process/processApis';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../components/LoadingData/loadingData';
import { mortgageCancellationDocumentsData } from '../../services/data/mortgageCancellationDocumentsData';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { updateDocuments } from '../../services/documentsService';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import FormWithButton from '../../components/DynamicForm/FormWithButton/FormWithButton';

const businessType = BUSINESS_TYPE.SELLERS;
const processName = PROCESS.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS;

const MortgageCancellationDocuments = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const role = useSelector((state) => state.loginData.data.role[0]);

  const [comments, setComments] = useState([]);
  const [events, setEvents] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [optionalDocuments, setOptionalDocuments] = useState([]);

  const getData = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const mortgageData = await mortgageCancellationDocumentsData(
          nid,
          businessType,
          processName
        );
        setEvents(mortgageData.events);
        setDocuments(
          mortgageData.documents.filter((doc) => doc.required || doc.url)
        );
        setOptionalDocuments(
          mortgageData.documents.filter((doc) => !doc.required)
        );
        setComments(mortgageData.comments);
        dispatch(dataFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [getData, nid, dispatch]);

  const callEvent = async (eventName, status) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: businessType,
        processName,
        eventName,
        status,
        originUrl: window.location.href,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  const saveData = async (values) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: processName,
      fields: values,
      business_type_id: businessType,
    };
    try {
      setLoadingSave(true);
      await updateFormData(data);
    } catch (error) {
      console.error({ error });
    } finally {
      setLoadingSave(false);
    }
  };

  const saveComment = async (comment, interactionsList) => {
    try {
      await saveProcessComment({
        nid,
        processName: PROCESS.MORTGAGE,
        agent,
        nameReviewerRole: role,
        comment,
        originUrl: window.location.href,
        noInteractions: true,
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role,
        },
        ...comments,
      ]);
    } catch (error) {
      console.error({ error });
    }
  };

  const addOptionalDocument = (document) => {
    if (
      documents.some(
        (doc) => doc.document_type_id !== document.document_type_id
      )
    ) {
      setDocuments((prev) => [...prev, document]);
    }
  };

  const updateDocument = (status, newDocument) => {
    setDocuments((docs) => updateDocuments(newDocument, docs));
  };

  const deleteDocument = (status, newDocument) => {
    updateDocument(status, newDocument);
    callEvent(
      PROCESS_EVENTS.REQUEST_CANCELLATION_MORTGAGE,
      DOCUMENT_STATUS.REVIEW
    );
  };

  const getDocumentComponent = (document, index) => {
    return (
      <DocumentComponent
        key={`document-${index}`}
        lastItemRow={(index + 1) % 5 === 0 ? true : false}
        documentParam={document}
        showReview={true}
        reviewer={ROLE.MORTGAGE_ANALYST_JUNIOR}
        handleUpload={updateDocument}
        handleDelete={deleteDocument}
        businessTypeId={BUSINESS_TYPE.SELLERS}
        processName={PROCESS_EVENTS.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS}
        showDelete={
          getEventStatus(events, PROCESS_EVENTS.APPROVED_APPLICATION) !==
          EVENT_STATUS.SUCCESS
        }
        allowReReview
      />
    );
  };

  const isCompleteDisabled = () => {
    return documents.some((doc) => doc.required && !doc.exist);
  };

  return (
    <EmptyData>
      <LoadingData>
        <GridForRightSection>
          <Container>
            <i className="arrow-back" onClick={history.goBack}>
              <ArrowBackIcon />
            </i>
            <Title>Documentos Solicitud de cancelación</Title>
            <DocumentsGrid>
              {documents.map((doc, i) => getDocumentComponent(doc, i))}
              <OptionalDocument
                businessType={BUSINESS_TYPE.SELLERS}
                processName={PROCESS.MORTGAGE_CANCELLATION_REQUEST_DOCUMENTS}
                handleAddDocument={addOptionalDocument}
                optionalDocuments={optionalDocuments}
              />
            </DocumentsGrid>
            {!match.params.onlyRead && (
              <>
                <FormWithButton
                  saveData={saveData}
                  loadingSave={loadingSave}
                  nid={nid}
                  businessType={businessType}
                  processName={processName}
                />
                <ButtonGrid>
                  <ButtonCheck
                    dataId={PROCESS_EVENTS.REQUEST_CANCELLATION_MORTGAGE}
                    label="Solicitar cancelación de hipoteca"
                    disabled={isCompleteDisabled()}
                    status={getEventStatus(
                      events,
                      PROCESS_EVENTS.REQUEST_CANCELLATION_MORTGAGE
                    )}
                    handleChange={() =>
                      callEvent(
                        PROCESS_EVENTS.REQUEST_CANCELLATION_MORTGAGE,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                  />
                  <ButtonCheck
                    dataId={PROCESS_EVENTS.APPROVED_APPLICATION}
                    label="Solicitud aprobada"
                    status={getEventStatus(
                      events,
                      PROCESS_EVENTS.APPROVED_APPLICATION
                    )}
                    handleChange={() =>
                      callEvent(
                        PROCESS_EVENTS.APPROVED_APPLICATION,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                  />
                </ButtonGrid>
              </>
            )}
          </Container>
          {!match.params.onlyRead && (
            <RightSection>
              <Comments
                commentsHistory={comments}
                handleSaveComment={saveComment}
              />
            </RightSection>
          )}
        </GridForRightSection>
      </LoadingData>
    </EmptyData>
  );
};

export default MortgageCancellationDocuments;
