import React, { useCallback, useEffect, useState } from 'react';
import Loader from '@habi/habi-react-components/dist/Loader/loader';
import { useDispatch, useSelector } from 'react-redux';

import { getExternalProcessData, putExternalProcessData } from '../../apis/ExternalProcess/externalProcessApis';
import TabsHeader from '../../components/shared/TabsHeader/tabsHeader';
import { Title, Container, LoaderContainer, SearchContainer } from '../../components/Styles/genericStyleComponent';
import SupplierDocumentControl from '../../components/SupplierDocumentControl/supplierDocumentControl';
import { BUSINESS_TYPE, PROCESS, STATUS_EXTERNAL_PROCESS } from '../../utils/constant';
import { resetPropertyData } from '../../redux/actions/propertyData';
import Pager from '../../components/Pager/pager';
import InputAction from '../../components/shared/InputAction/inputAction';
import SearchIcon from "@material-ui/icons/Search";
import { sendEmailNotification } from '../../apis/Notifications/notificationsApis';

const tabs = [
  { label: 'En curso', value: STATUS_EXTERNAL_PROCESS.IN_PROGRESS },
  { label: 'En revisión', value: STATUS_EXTERNAL_PROCESS.IN_REVIEW },
  { label: 'Corrección Habi', value: STATUS_EXTERNAL_PROCESS.HABI_CORRECTION },
  {
    label: 'Corrección proveedor',
    value: STATUS_EXTERNAL_PROCESS.EXTERNAL_CORRECTION,
  },
  { label: 'Finalizado', value: STATUS_EXTERNAL_PROCESS.FINISHED },
];

const SupplierTitleStudy = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(
    STATUS_EXTERNAL_PROCESS.IN_PROGRESS
  );
  const [data, setData] = useState([]);
  const [additionalData, setAdditionalData] = useState({ pages: 1 });
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [externalUsers, setExternalUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const agent = useSelector((state) => state.loginData.data.email);
  const tabSaved = useSelector((state) => state.documentReviewData.currentTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tabSaved) {
      setTimeout(function () {
        setCurrentTab(tabSaved);
      }, 500);
    }
  }, [tabSaved]);

  useEffect(() => {
    if (location && location.state && location.state.nid) {
      setTimeout(function () {
        setFilter(location.state.nid);
      }, 1000);
    }
  }, [location]);

  const getData = useCallback(
    async ({ page }) => {
      setActivePage(page ? page : 1);
      try {
        setLoading(true);
        const responseData = await getExternalProcessData({
          status: currentTab,
          processName: PROCESS.EXTERNAL_TITLE_STUDY,
          agent,
          page,
          filter: filter === '' ? null : filter,
        });
        setData(responseData.data.data);
        setExternalUsers(responseData.data.external_users);
        setAdditionalData(responseData.data.additional_data);
      } catch (error) {
        setData([]);
        setExternalUsers([]);
        setAdditionalData({ pages: 1 });
        console.log(' ERROR', error);
      } finally {
        setLoading(false);
      }
    },
    [currentTab, agent, filter]
  );

  useEffect(() => {
    dispatch(resetPropertyData());
    getData({ page: 1 });
  }, [getData, dispatch]);

  const handleUpdate = (newData) => {
    putExternalProcessData({
      ...newData,
      originUrl: window.location.href,
      processName: PROCESS.EXTERNAL_TITLE_STUDY,
      agent,
    });
  };

  const handleUploadDocument = async (nid) => {
    setData(data.filter((control) => control.nid !== nid));
    try {
      await sendEmailNotification({
        nid: nid,
        processName: PROCESS.EXTERNAL_TITLE_STUDY,
        originUrl: window.location.href,
        businessType: BUSINESS_TYPE.SELLERS,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Title>Proveedor estudio de títulos</Title>
      <SearchContainer>
        <InputAction
          value={filter}
          placeholder="Busca por NID o Folio de Matrícula"
          iconAction={<SearchIcon />}
          handleAction={setFilter}
        />
      </SearchContainer>
      {filter !== '' && data.length > 0 ? (
        <TabsHeader
          tabs={[
            tabs.find((tab) => tab.value === (data[0] ? data[0].status : '')),
          ]}
          currentTab={data[0] ? data[0].status : ''}
          setCurrentTab={() => {}}
        />
      ) : (
        <TabsHeader
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {loading ? (
        <div className="loading">
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </div>
      ) : (
        <>
          <SupplierDocumentControl
            data={data}
            externalUsers={externalUsers}
            handleUpdate={handleUpdate}
            processName={PROCESS.EXTERNAL_TITLE_STUDY}
            handleUploadDocument={handleUploadDocument}
            handleDeleteDocument={() => {}}
            currentTab={currentTab}
          />
          {data.length > 0 && (
            <Pager
              numPages={additionalData.pages}
              activePage={activePage}
              onChange={getData}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default SupplierTitleStudy;