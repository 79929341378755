import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Formik } from 'formik';
import { DisbursementWrapper } from './style';
import { useDispatch, useSelector } from 'react-redux';
import EmptyData from '../../components/EmptyData/emptyData';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import Section from './components/DynamicFormDisbursement/Section/Section';
import { PropertyDetail } from './components/PropertyDetail/PropertyDetail';
import { Container, Title } from '../../components/Styles/genericStyleComponent';
import { DISBURSEMENT_DYNAMIC_CONFIG } from '../../utils/dynamic-constant';
import {
  BUSINESS_TYPE,
  DISBURSEMENT_CONFIG,
  DOCUMENT_STATUS,
  PROCESS,
  PROCESS_EVENTS,
} from '../../utils/constant';
import { Field } from './components/DynamicFormDisbursement/Field/Field';
import Button from '../../components/shared/button/button';
import { getDisbursementData, postDisbursementData } from '../../apis/Disbursement/disbursementApis';
import { findEventStatus } from '../../utils/Scripts/findEventStatus';
import { updatePropertyData } from '../../redux/actions/propertyData';
import { callEventProcess } from '../../apis/Process/processApis';
import { dataFound, dataNotFound } from '../../redux/actions/propertyData';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const Disbursement = () => {
  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);

  const [data, setData] = useState({});
  const [dependentList, setDependentList] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [events, setEvents] = useState([]);
  const [fundingType, setFundingType] = useState(data.funding_type);

  const getDataDisbursement = useCallback(() => {
    return DISBURSEMENT_DYNAMIC_CONFIG[fundingType] ?? [];
  }, [fundingType]);

  const createInitialValues = useCallback(() => {
    let initialValues = {
      mount: data.agreed_price,
      [DISBURSEMENT_CONFIG.DISBURSEMENT_ACCOUNTING]: 0,
    };
    let dependencies = {};
    getDataDisbursement().forEach(({ fields, dependentValues }) => {
      fields.forEach(({ name, value }) => {
        if (dependentValues) {
          dependencies = { ...dependencies, [name]: value };
        }
        initialValues = {
          ...initialValues,
          [name]: value ? value(initialValues) : 0,
        };
      });
    });
    if (data.disbursement_data) {
      Object.keys(data.disbursement_data).forEach((key) => {
        initialValues = {
          ...initialValues,
          [key]: data.disbursement_data[key],
        };
      });
    }
    setDependentList(dependencies);
    setInitialValues(initialValues);
  }, [data, getDataDisbursement]);

  const getDisbursement = useCallback(
    async (nid) => {
      try {
        const { data } = await getDisbursementData({
          nid,
        });
        setData(data);
        setEvents(data.events);
        setFundingType(data.funding_type);
        dispatch(dataFound());
      } catch (error) {
        console.log(error);
        dispatch(dataNotFound());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    nid ? getDisbursement(nid) : dispatch(dataNotFound());
  }, [nid, getDisbursement, dispatch]);

  useEffect(() => {
    createInitialValues();
  }, [fundingType, createInitialValues]);

  const saveDisbursementData = async (values) => {
    try {
      const { mount, ...newObject } = values;
      for (const prop in newObject) {
        newObject[prop] = parseFloat(newObject[prop]);
      }
      await postDisbursementData({
        nid: +nid,
        disbursement_type: fundingType,
        valuesForm: newObject,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getContentForm = (formikProps) => {
    let sections = [];
    getDataDisbursement().forEach(({ hasHorizontalLine, fields }, index) => {
      sections = [
        ...sections,
        <Section
          key={`section_${index + 1}`}
          hasHorizontalLine={hasHorizontalLine}
          fields={fields}
          formikProps={formikProps}
          dependentList={dependentList}
          handleSaveData={saveDisbursementData}
        ></Section>,
      ];
    });
    return sections;
  };

  const renderDetail = useMemo(() => {
    return <PropertyDetail data={data} nid={nid} />;
  }, [data, nid]);

  const callEvent = async (eventName, status) => {
    dispatch(updatePropertyData({ loadingSearch: true }));
    try {
      await callEventProcess({
        nid,
        businessTypeId: BUSINESS_TYPE.SELLERS,
        processName: PROCESS.DISBURSEMENT,
        eventName,
        status,
        originUrl: window.location.href,
      });
      updateEvents(eventName, status);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updatePropertyData({ loadingSearch: false }));
    }
  };

  const updateEvents = (eventName, status) => {
    if (events.some((event) => event.name === eventName)) {
      setEvents((e) =>
        e.map((event) =>
          event.name === eventName ? { name: event.name, status } : event
        )
      );
    } else {
      setEvents((e) => [...e, { name: eventName, status }]);
    }
  };

  const getEventStatus = useCallback(
    (eventName) => {
      return findEventStatus(eventName, events);
    },
    [events]
  );

  return (
    <EmptyData>
      <DisbursementWrapper>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>Desembolso</Title>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
              saveDisbursementData(values);
            }}
          >
            {(props) => (
              <Form className="form-container">
                {renderDetail}
                {getContentForm(props)}
                <div className="save-button-container">
                  <Button type="submit">{'Guardar datos'}</Button>
                </div>
                <hr className="line-separator" />

                <div className="options-container">
                  <ButtonCheck
                    label={'Datos desembolso completos'}
                    status={getEventStatus(PROCESS_EVENTS.DATOS_COMPLETOS)}
                    handleChange={() =>
                      callEvent(
                        PROCESS_EVENTS.DATOS_COMPLETOS,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                  />
                  <ButtonCheck
                    label={'Datos desembolso aprobados líder trámites'}
                    status={getEventStatus(PROCESS_EVENTS.DATOS_APROBADOS)}
                    handleChange={() =>
                      callEvent(
                        PROCESS_EVENTS.DATOS_APROBADOS,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                  />
                </div>
                <hr className="line-separator" />
                <div className="options-container">
                  <Field
                    type={DISBURSEMENT_CONFIG.MONEY_FREE}
                    label={DISBURSEMENT_CONFIG.DESEMBOLSO_CONTABILIDAD}
                    name={DISBURSEMENT_CONFIG.DISBURSEMENT_ACCOUNTING}
                    formikProps={props}
                  ></Field>
                  <div className="align-center">
                    <ButtonCheck
                      label={'Notificar al cliente'}
                      status={getEventStatus(PROCESS_EVENTS.NOTIFICAR_CLIENTE)}
                      handleChange={() =>
                        callEvent(
                          PROCESS_EVENTS.NOTIFICAR_CLIENTE,
                          DOCUMENT_STATUS.SUCCESS
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </DisbursementWrapper>
    </EmptyData>
  );
};

export default Disbursement;
