import styled from "styled-components";
import { bogotaBlue100, hintPerano100, openSansBoldFamily, openSansRegular, scarpaGray300, skyBlue700, white } from "../../../../StylesConstants";

export const BondingStageDannWrapper = styled.div`

  .accordion-title {
    font: 12px ${ openSansBoldFamily };
    display: flex;
    justify-content: space-between;

    .group-title {
      align-items: center;
      display: flex;
      gap: 12px;
    }

    .stats {
      align-items: center;
      display: flex;
      gap: 6px;
      font: 10px ${ openSansRegular };
      color: ${ scarpaGray300 };

      span {
        border-radius: 3px;
        padding: 4px 8px;
      }

      .loaded {
        background-color: ${ bogotaBlue100 };
        color: ${ skyBlue700 };
      }
    }
  }

  .icon-send {
    padding: 3px 3px 5px 5px;
    background-color: ${ hintPerano100 };
    border-radius: 4px;
    line-height: 4px;
  }

  .detail-container {
    padding: 24px 0 0 0;
  }

  .MuiPaper-root {
    background-color: initial;
  }

  .MuiPaper-root, .MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 16px;
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0px 24px;
    background-color: ${white};
    border-radius: 8px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
  }

  .MuiAccordionDetails-root {
    padding: 0;
    display: unset;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    gap: 16px;
  }

`;