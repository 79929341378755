import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { format } from "date-fns";
import { connect, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { DocumentWrap, ActionsWrap } from "./style";
import successIcon from "../../static/assets/img/icons/icon-check.svg";
import rejectedIcon from "../../static/assets/img/icons/icon-error.svg";
import lockedIcon from "../../static/assets/img/icons/icon-locked.svg";

import { deleteDocument, saveDocumentStatus } from "../../apis/Documents/documentsApis";
import { setDocumentReview } from "../../redux/actions/documentReview";
import { ACTIVITY_STATUS, BUSINESS_TYPE, DOCUMENT_STATUS, DOCUMENT_WRAPPER_TYPE, FILE_EXTENSIONS } from "../../utils/constant";
import UploadFile from "../UploadFile/uploadFile";
import DocumentRow from "./components/DocumentRow/documentRow";
import Button from "../shared/button/button";
import DocumentCard from './components/DocumentCard/documentCard';
import DocumentStep from './components/DocumentStep/documentStep';
import DocumentText from './components/DocumentText/documentText';
import { FILE_TYPE_BY_DOCUMENT, ICON_BY_FILE_TYPE, ICON_EMPTY_BY_FILE_TYPE } from '../../utils/dynamic-constant';

const DocumentComponent = ({
  documentParam,
  userData,
  propertyData,
  lastItemRow,
  showReview,
  showDelete,
  businessTypeId,
  wrapperType,
  reviewer,
  handleUpload,
  handleDelete,
  handleApprove,
  processName,
  locked,
  lockedMessage,
  nid,
  allowReReview,
  eventReview
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);


  useEffect(() => {
    if (documentParam) {
      setDocument(documentParam);
    }
  }, [documentParam]);

  const deleteFile = async (documentId) => {
    try {
      setLoading(true);
      const agent = userData.email;
      const response = await deleteDocument(documentId, agent);
      const data = await response;
      if (data.data.document_id) {
        const documentCopy = {
          ...document,
          exist: false,
          url: '',
          id: '',
          status: '',
          comment: '',
        };
        setDocument(documentCopy);
        handleDelete(ACTIVITY_STATUS.DONE, documentCopy);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const reviewDocument = () => {
    dispatch(
      setDocumentReview({
        document,
        reviewer,
        businessTypeId,
        processName,
        originUrl: window.location.href,
        nid: propertyData.nid ? propertyData.nid : nid,
        showDelete,
        allowReReview,
        eventReview
      })
    );
    history.push('/revision-documento');
  };

  const approveDocument = async () => {
    try {
      setDocument({ ...document, status: DOCUMENT_STATUS.SUCCESS });
      await saveDocumentStatus(
        document.id,
        DOCUMENT_STATUS.SUCCESS,
        userData.email,
        reviewer,
        '',
        nid,
        window.location.href,
        businessTypeId,
        processName
      );
      handleApprove(ACTIVITY_STATUS.DONE, {
        ...document,
        status: DOCUMENT_STATUS.SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getReviewStatus = (showViewIcon = false) => {
    switch (document.status) {
      case DOCUMENT_STATUS.SUCCESS:
        return (
          <img src={successIcon} alt="Icon Success" className="icon-status" />
        );
      case DOCUMENT_STATUS.REJECTED:
        return (
          <img src={rejectedIcon} alt="Icon Rejected" className="icon-status" />
        );
      default:
        return showViewIcon ? (
          <VisibilityOutlinedIcon className="icon-status view" />
        ) : (
          <></>
        );
    }
  };

  const getActions = () => {
    return (
      document.exist &&
      actionOpen && (
        <ActionsWrap
          onBlur={() => {
            setActionOpen(false);
          }}
          className={`action-container ${lastItemRow ? 'last-item' : ''}`}
        >
          {showReview && (
            <button className="button-option" onClick={reviewDocument}>
              <VisibilityOutlinedIcon />
              <span>Revisar documento</span>
            </button>
          )}
          <a
            className="button-option download-document-option"
            href={document.url}
            download={document.label}
            rel="noreferrer"
            target="_blank"
          >
            <GetAppIcon />
            <span>Descargar documento</span>
          </a>
          {showDelete && document.status !== DOCUMENT_STATUS.SUCCESS && (
            <button
              className="button-option delete-document-option"
              onClick={() => deleteFile(document.id)}
            >
              <DeleteIcon />
              <span>Eliminar documento</span>
            </button>
          )}
        </ActionsWrap>
      )
    );
  };

  const getActionIcon = () => {
    return locked ? (
      <div />
    ) : (
      <div className="icon-document">
        {document.exist ? (
          <MoreVertIcon onClick={() => setActionOpen(!actionOpen)} />
        ) : (
          <UploadFile
            nid={propertyData.nid ? propertyData.nid : nid}
            businessTypeId={businessTypeId}
            agent={userData.email}
            document={document}
            setLoading={setLoading}
            setDocument={setDocument}
            handleUpload={handleUpload}
          />
        )}
        {getActions()}
      </div>
    );
  };

  const getNameDocument = () => {
    let description;

    if (locked) {
      description = <p className="locked-document">{lockedMessage}</p>;
    } else if (document.exist) {
      description = (
        <p className="date-document">
          Últ. mod. {format(new Date(document.update_date), 'dd-MM-yyyy')}
        </p>
      );
    } else {
      description = <p className="no-document">No hay documento</p>;
    }

    return (
      <div className="description">
        <h3 className="name-document">{document.label}</h3>
        {description}
      </div>
    );
  };

  const getFileFormat = (fileUrl) => {
    return fileUrl.split('.').slice(-1)[0];
  };

  const getDocumentIcon = () => {
    let icon = lockedIcon;

    if (!locked && document.exist) {
      icon = ICON_BY_FILE_TYPE[`.${getFileFormat(document.url)}`];
    } else if (!locked && !document.exist) {
      const fileType = FILE_TYPE_BY_DOCUMENT[document.name];
      icon = fileType
        ? ICON_EMPTY_BY_FILE_TYPE[fileType]
        : ICON_EMPTY_BY_FILE_TYPE[FILE_EXTENSIONS.PDF];
    }

    return (
      <img
        src={icon}
        className={icon === lockedIcon ? 'lock' : ''}
        alt="File icon"
        width={
          wrapperType === DOCUMENT_WRAPPER_TYPE.WHITE_SQUARE ? '56px' : null
        }
      />
    );
  };

  const getOptional = () => {
    let classname = 'loaded';
    if (!document.exist && document.required) {
      classname = 'required';
    } else if (!document.exist && !document.required) {
      classname = 'optional';
    }
    return (
      <span className={`type ${classname}`}>
        {document.required ? 'Necesario' : 'Opcional'}
      </span>
    );
  };

  const getApprove = () => {
    return (
      <Button
        size="small"
        disabled={document.status === DOCUMENT_STATUS.SUCCESS}
        onClick={approveDocument}
      >
        Aprobar
      </Button>
    );
  };

  return (
    <>
      {document && (
        <DocumentWrap>
          {wrapperType === DOCUMENT_WRAPPER_TYPE.WHITE_SQUARE && (
            <DocumentCard
              document={document}
              getReviewStatus={getReviewStatus}
              getActionIcon={getActionIcon}
              getDocumentIcon={getDocumentIcon}
              getOptional={getOptional}
              loading={loading}
            />
          )}
          {wrapperType === DOCUMENT_WRAPPER_TYPE.DOT_LINE && (
            <DocumentStep
              document={document}
              getActionIcon={getActionIcon}
              getDocumentIcon={getDocumentIcon}
              getNameDocument={getNameDocument}
              loading={loading}
            />
          )}
          {wrapperType === DOCUMENT_WRAPPER_TYPE.TEXT && (
            <DocumentText
              getActionIcon={getActionIcon}
              getNameDocument={getNameDocument}
              loading={loading}
            />
          )}
          {wrapperType === DOCUMENT_WRAPPER_TYPE.ROW && (
            <DocumentRow
              document={document}
              getReviewStatus={getReviewStatus}
              getActionIcon={getActionIcon}
              getApprove={getApprove}
              getOptional={getOptional}
              loading={loading}
            />
          )}
        </DocumentWrap>
      )}
    </>
  );
};

DocumentComponent.defaultProps = {
  showReview: false,
  showDelete: true,
  wrapperType: DOCUMENT_WRAPPER_TYPE.WHITE_SQUARE,
  locked: false,
  allowReReview: false,
  handleApprove: ()=>{}
}

DocumentComponent.propTypes = {
  businessTypeId: PropTypes.oneOf([
    BUSINESS_TYPE.SELLERS, 
    BUSINESS_TYPE.BUYERS
  ]).isRequired,
  wrapperType: PropTypes.oneOf([
    DOCUMENT_WRAPPER_TYPE.WHITE_SQUARE, 
    DOCUMENT_WRAPPER_TYPE.DOT_LINE,
    DOCUMENT_WRAPPER_TYPE.TEXT
  ]).isRequired,
  allowReReview: PropTypes.bool.isRequired,
  handleApprove: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    userData: state.loginData.data,
    propertyData: state.propertyData,
  };
};
export default connect(mapStateToProps, null)(DocumentComponent);
