import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-notifications-component/dist/theme.css";
import "animate.css";

import { dataFound, dataNotFound, hideLoading, showLoading } from "../../redux/actions/propertyData";
import {
  getFormData,
  updateFormData,
} from '../../apis/DynamicForm/dynamicFormApis';
import PropertyForm from './components/PropertyForm/propertyForm';
import { PropertyBuyersWrapper } from './style';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import EmptyData from '../../components/EmptyData/emptyData';
import { BUSINESS_TYPE, FIELD_NAME, PROCESS } from '../../utils/constant';
import { sendEmailPropertySellerAssignment } from '../../apis/Notifications/notificationsApis';
import {
  successNotification,
  errorNotification,
} from '../../utils/notifications';

const PropertyBuyers = () => {
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);

  const getData = useCallback(async (nid) => {
    try {
      dispatch(showLoading());
      const response = await getFormData(
        nid,
        PROCESS.DATA_PROPERTY_BUYER,
        BUSINESS_TYPE.BUYERS
      );
      const data = await response;
      const propertyResponse = data.data.data;
      setProperty(propertyResponse);
      dispatch(dataFound());
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setProperty(undefined);
      } else {
        console.log('error:::', error.response);
      }
      dispatch(dataNotFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch]);

  useEffect(() => {
    nid ? getData(nid) : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  const savePropertyData = async (values) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: PROCESS.DATA_PROPERTY_BUYER,
      fields: values,
      business_type_id: BUSINESS_TYPE.BUYERS,
    };
    try {
      setLoading(true);
      await updateFormData(data);
      sendNotifications(values);
      successNotification({});
    } catch (error) {
      errorNotification({});
    } finally {
      setLoading(false);
    }
  };

  const sendNotifications = (data) => {
    const fieldProcessAnalystList = data.filter(
      (field) =>
        field.field_name === FIELD_NAME.PROCESS_ANALYST ||
        field.field_name === FIELD_NAME.PROCESS_ANALYST_BUYERS
    );
    if (fieldProcessAnalystList) {
      for (const fieldProcessAnalyst in fieldProcessAnalystList) {
        sendEmailPropertySellerAssignment(
          nid,
          window.location.href,
          data[fieldProcessAnalyst].value
        );
      }
    }
  };

  return (
    <EmptyData>
      <Container>
        <Title>Inmuebles Compradores</Title>
        <PropertyBuyersWrapper>
          <PropertyForm
            data={property}
            saveData={savePropertyData}
            loading={loading}
          />
        </PropertyBuyersWrapper>
      </Container>
    </EmptyData>
  );
};

export default PropertyBuyers;
