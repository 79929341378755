import API from '../Api';

export const getDataPaymentRequestApi = ({ tab, page, elements }) => {
  return API.get(process.env.REACT_APP_GET_PAYMENT_REQUESTS, {
    params: {
      tab,
      page,
      elements,
    },
  });
};

export const postPaymentRequest = async ({ nid, status, new_status }) => {
  return API.post(process.env.REACT_APP_POST_LISTING_WEB_DATA, {
    nid,
    status,
    new_status,
  });
};
