import { STATUS_PAYMENT_REQUEST } from '../../../utils/constant';
import { STATUS_LISTING_WEB_PROCESS } from '../../../utils/constant';

export const LISTING_WEB = [
  { label: 'Solicitud', value: STATUS_LISTING_WEB_PROCESS.REQUESTED },
  { label: 'Publicado', value: STATUS_LISTING_WEB_PROCESS.PUBLISHED },
  { label: 'Vendidos/Incompletos', value: STATUS_LISTING_WEB_PROCESS.SOLD },
];

export const PAYMENT_REQUEST = [
  { label: 'Solicitudes', value: STATUS_PAYMENT_REQUEST.REQUESTS },
  { label: 'Aprobados', value: STATUS_PAYMENT_REQUEST.APPROVED },
  { label: 'Rechazados', value: STATUS_PAYMENT_REQUEST.REJECTED },
];
