import styled from "styled-components";
import { hintPerano100, openSansBoldFamily, white } from "../../../../StylesConstants";

export const StageDocumentWrapper = styled.div`

  .accordion-title {
    font: 12px ${openSansBoldFamily};
    display: flex;
  }

  .icon-send {
    padding: 3px 3px 5px 5px;
    background-color: ${hintPerano100};
    border-radius: 4px;
    line-height: 4px;
  }

  .detail-container {
    padding: 24px 0;
  }

  .MuiPaper-root {
    background-color: initial;
  }

  .MuiPaper-root, .MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 16px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0px 24px;
    background-color: ${white};
    border-radius: 8px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit;
  }

  .MuiAccordionDetails-root {
    padding: 0;
    display: unset;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    gap: 16px;
  }

`;