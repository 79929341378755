import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import DocumentComponent from "../../components/DocumentComponent/documentComponent";
import {
  DocumentsGrid,
  Title,
  ButtonGrid,
  Container,
  Section,
  Label,
  FieldMessage,
} from "../../components/Styles/genericStyleComponent";
import ButtonCheck from "../../components/ButtonCheck/buttonCheck";
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  LOCKED_MESSAGE,
  PROCESS
} from "../../utils/constant";
import { sendEmailExternalUser } from "../../apis/Notifications/notificationsApis";
import EmptyData from "../../components/EmptyData/emptyData";
import useDocuments from "../../hooks/useDocuments";
import DatePicker from "../../components/shared/CustomDatepicker/datePicker/DatePicker";
import { saveConfirmationDate } from "../../apis/Process/processApis";
import OptionalDocument from "../../components/OptionalDocument/optionalDocument";
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';

const Draft = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const [documentLocked, setDocumentLocked] = useState(true);
  const { documents, documentUpdate, addOptionalDocument } = useDocuments({
    businessType: BUSINESS_TYPE.SELLERS,
    processName: PROCESS.BILL,
  });
  const {
    list_documents: listDocuments,
    confirmation_date,
    additional_data: additionalData,
  } = documents;
  const [confirmationDate, setConfirmationDate] = useState(confirmation_date);

  useEffect(() => {
    let locked = true;
    if (
      listDocuments.filter(
        (doc) => doc.name !== DOCUMENT_TYPE_NAME.DRAFT && !doc.exist
      ).length === 0 &&
      additionalData?.has_invoice_number
    ) {
      locked = false;
    }
    setDocumentLocked(locked);
  }, [listDocuments, additionalData?.has_invoice_number]);

  useEffect(() => {
    setConfirmationDate(confirmation_date);
  }, [confirmation_date]);

  const setDate = (date) => {
    saveConfirmationDate({
      nid,
      businessTypeId: BUSINESS_TYPE.SELLERS,
      processName: PROCESS.BILL,
      date,
      agent,
    });
    sendEmailExternalUser(nid, PROCESS.BILL, window.location.origin);
    setConfirmationDate(date);
  };

  const getDocumentComponent = (document, index) => {
    return (
      <DocumentComponent
        key={`document-${index}`}
        lastItemRow={(index + 1) % 5 === 0 ? true : false}
        documentParam={document}
        businessTypeId={BUSINESS_TYPE.SELLERS}
        handleUpload={documentUpdate}
        handleDelete={documentUpdate}
        processName={PROCESS.BILL}
        locked={documentLocked && document.name === DOCUMENT_TYPE_NAME.DRAFT}
        lockedMessage={LOCKED_MESSAGE.COMPLETE_DOCUMENTS}
      />
    );
  };

  return (
    <EmptyData>
      <Container>
        <LifeCycle miniLifeCycle />
        <Title>Minuta</Title>
        <DocumentsGrid>
          {listDocuments
            .filter((doc) => doc.name !== DOCUMENT_TYPE_NAME.DRAFT)
            .map((doc, i) => getDocumentComponent(doc, i))}
          {listDocuments
            .filter((doc) => doc.name === DOCUMENT_TYPE_NAME.DRAFT)
            .map((doc, i) => getDocumentComponent(doc, i))}
          <OptionalDocument
            businessType={BUSINESS_TYPE.SELLERS}
            processName={PROCESS.BILL}
            handleAddDocument={addOptionalDocument}
          />
        </DocumentsGrid>
        <Section>
          <Label>Fecha estimada entrega de minuta</Label>
          <DatePicker
            name="documentDate"
            value={
              confirmationDate && !documentLocked
                ? moment(confirmationDate).format()
                : null
            }
            format="yyyy-MM-dd"
            disabled={documentLocked}
            onChange={setDate}
            width="200px"
          />
          {!additionalData?.has_invoice_number && (
            <FieldMessage>
              Recuerda asociar el folio de matrícula para poder continuar con el
              proceso
            </FieldMessage>
          )}
        </Section>
        <ButtonGrid>
          <ButtonCheck
            label="Documentos completos"
            status={
              confirmationDate && !documentLocked
                ? DOCUMENT_STATUS.SUCCESS
                : null
            }
            disabled
          />
        </ButtonGrid>
      </Container>
    </EmptyData>
  );
};

export default Draft;
