import React from 'react';
import PropType from 'prop-types';
import { default as MaterialTabs } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ThemeProvider } from '@material-ui/styles';

import { TabsWrapper } from './style';
import { styledTabs } from './style';

const Tabs = ({ tabs, currentTab, setCurrentTab }) => {

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <TabsWrapper>
      <ThemeProvider theme={styledTabs}>
        <MaterialTabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {
            tabs.map((tab, index) =>
              <Tab label={tab.label} key={index} />
            )
          }
        </MaterialTabs>
      </ThemeProvider>
    </TabsWrapper>
  )
};

Tabs.propTypes = {
  tabs: PropType.array.isRequired,
  currentTab: PropType.any.isRequired,
  setCurrentTab: PropType.any.isRequired
}

export default Tabs;
