import React from 'react'
import Button from '../../../components/shared/button/button';

import { UserExternalFormWrapper } from "./style";
import { Formik } from 'formik';
import InputTextFormik from '../../../components/shared/InputTextFormik/inputTextFormik';
import * as Yup from 'yup';

const UserExternalForm = ({ handleLogin }) => {
  return (
    <UserExternalFormWrapper>
      <Formik 
        initialValues={{ 
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('El email es invalido').required('El email es requerido'),
          password: Yup.string().required('La contraseña es requerida'),
        })}
        onSubmit={handleLogin}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <InputTextFormik 
              id="email"
              type="text"
              placeholder="Correo electrónico"
              autoComplete="on"
              {...formik.getFieldProps('email')}
            />
            <InputTextFormik 
              id="password"
              placeholder="Contraseña"
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('password')}
            />
            <Button type="submit">
              Ingresar
            </Button>
          </form>
        )}
      </Formik>
    </UserExternalFormWrapper>
  )
}

export default UserExternalForm;
