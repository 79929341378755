import { DEPENDENCY_CONDITION } from '../../../../utils/constant';
import { FINANCIAL_ENTITIES, PAYMENT_METHOD } from '../selectOptions';

export const otherBanksPromise = {
  firstBasicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_phone',
      label: 'Numero(s) de Teléfono',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_email',
      label: 'Correo cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'promise_request_date',
      label: 'Fecha solicitud promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'promise_signature_date',
      label: 'Fecha firma promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'scripture_deal_date',
      label: 'Fecha escritura pactada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'latest_otrosi_date',
      label: 'Fecha último otrosí',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'latest_otrosi_reason',
      label: 'Razón último otrosí',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'otrosi_scripture_deal_date',
      label: 'Fecha escritura pactada otrosí',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'procedure_analyst_pob',
      label: 'Analista de trámites',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'payment_method',
      label: 'Forma de pago',
      type_field: 'select',
      multiple_values: {
        options: PAYMENT_METHOD,
      },
      fixed: false,
    },
    {
      name: 'financial_entity',
      label: 'Entidad financiera',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: FINANCIAL_ENTITIES,
      },
    },
  ],
  firstAnalystInformation: [
    {
      name: 'last_client_communication_date_pob',
      label: 'Fecha última comunicación cliente',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'fiduciary_bonding_sending',
      label: 'Envío vinculación fiduciaria',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'aproved_bonding',
      label: 'Vinculación aprobada',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'dann_pa_colpatria_docs_radication',
      label: 'Radicación Docs en Dann/PA Colpatria',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'dann_colpatria_docs_aproval',
      label: 'Aprobación Docs Dann/Colpatria',
      type_field: 'input',
      fixed: true,
    },
  ],
  secondBasicInformation: [
    {
      name: 'convention_pob',
      label: 'Convencionado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 1,
          },
          {
            label: 'No',
            value: 0,
          },
        ],
      },
    },
    {
      name: 'external_lawyer_name_pob',
      label: 'Nombre abogado externo',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'external_lawyer_contact_pob',
      label: 'Contacto abogado externo',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'adviser',
      label: 'Asesor',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'adviser_contact',
      label: 'Contacto asesor',
      type_field: 'input',
      fixed: true,
    },
  ],
  secondAnalystInformation: [
    {
      name: 'et_avaluo_payment_date_pob',
      label: 'Fecha pago Avalúo & ET',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'bank_document_signature_date_pob',
      label: 'Fecha firma de docs en banco',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'avaluo_request_date_pob',
      label: 'Fecha solicitud avalúo',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'avaluo_schedule_date_pob',
      label: 'Fecha programación avalúo',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'avaluo_fulfilled_date_pob',
      label: 'Fecha realización avalúo',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'avaluo_aproved_receipt_pob',
      label: 'Recibo avalúo aprobado',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'et_docs_radication_date_pob',
      label: 'Fecha radicación docs ET',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'et_lawyer',
      label: 'Abogado ET',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'et_lawyer_contact_info',
      label: 'Contacto abogado ET',
      type_field: 'inputNumber',
      fixed: false,
    },
    {
      name: 'et_aproval_date_pob',
      label: 'Fecha de aprobación ET',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'et_aproval_date_more_pob',
      label: 'Fecha aprobación ET, entrega minuta & legalización crédito',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'memorandum_request_pob',
      label: 'Solicitud minuta',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'memorandum_receipt_pob',
      label: 'Recepción minuta',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'memorandum_legal_aproval_habi_pob',
      label: 'Aprobación minuta legal (Habi)',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'memorandum_fidu_dann_colpat_aproval_pob',
      label: 'Apro. Minuta Fidu/Dann/Colpatria',
      type_field: 'inputDate',
      fixed: true,
    },
  ],
  thirdBasicInformation: [
    {
      name: 'real_scripture_date',
      label: 'Fecha escritura real',
      type_field: 'inputDate',
      dependencies: [
        {
          on_field: 'property_address',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'property_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_name',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_id',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_phone',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'client_email',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
        {
          on_field: 'address_residence_notificacion',
          condition: DEPENDENCY_CONDITION.NOT_EMPTY,
        },
      ],
      fixed: true,
    },
    {
      name: 'sale_scripture_number',
      label: 'Número de escritura',
      type_field: 'input',
      fixed: true,
    },
  ],
  thirdAnalystInformation: [
    {
      name: 'good_standing_scripture_pob',
      label: 'Paz y salvo escrituración',
      type_field: 'select',
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
      fixed: false,
    },
    {
      name: 'arras_date',
      label: 'Fecha arras',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'arras_value',
      label: 'Valor arras',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'real_first_payment_date_pob',
      label: 'Fecha real primer pago',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'first_payment_value_pob',
      label: 'Valor primer pago',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_date_pob',
      label: 'Fecha pago contra escritura',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'counter_scripture_payment_value_pob',
      label: 'Valor pago contra escritura',
      type_field: 'inputCurrency',
      fixed: false,
    },
    {
      name: 'pending_value_to_be_disbursed_bank_pob',
      label: 'Valor pendiente desembolso banco',
      type_field: 'inputCurrency',
      fixed: false,
    },
  ],
  fourthBasicInformation: [
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputCurrency',
      fixed: true,
    },
  ],
  fourthAnalystInformation: [
    {
      name: 'selling_price_difference_otros',
      label: 'Diferencia precio de venta - valor pagos',
      type_field: 'inputCurrency',
      fixed: true,
    },
  ],
};
