import styled from 'styled-components';
import {
  lightGrey,
  muliBoldFamily,
  muliRegular,
  nightBlue500,
} from '../../../StylesConstants';

export const FormWrapper = styled.div`
  .form-container {
    text-align: left;

    .document-container {
      display: grid;
      grid-template-columns: 180px;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      margin: 0px 0px 32px;
      min-height: 211px;
    }

    .section {
      .title {
        font-family: ${muliBoldFamily};
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: ${nightBlue500};
        margin: 0px 0px 40px;
      }
      > div {
        > div {
          grid-template-columns: none;
        }
      }
    }
    .form-button-container {
      display: grid;
      grid-template-columns: 1fr 272px 1fr;
      grid-column-gap: 48px;
      button {
        grid-column: 2 / 3;
      }
    }
    .section-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
    }
    .line-separator {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 24px 0px 40px;
      padding: 0;
    }
    .radio-buttons-full-width {
      &-background {
        grid-column: 1 / 3;
        grid-template-columns: 1fr;
        align-items: center;
        padding: 16px;
        justify-content: space-between;
        margin-bottom: 36px;
        border-radius: 12px;
      }
      background-color: ${lightGrey};
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      padding: 16px;
      justify-content: space-between;
      margin-bottom: 32px;
      border-radius: 12px;
      & > div {
        grid-template-columns: none;
      }
      .radio-buttons:first-child {
        margin-right: 16px;
      }
    }
    .static-buttons-full-width {
      background-color: ${lightGrey};
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding: 16px;
      justify-content: space-between;
      margin-bottom: 32px;
      border-radius: 12px;
      label {
        font-family: ${muliRegular};
        font-size: 16px;
        color: ${nightBlue500};
      }
      .radio-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
      }
    }
    .checbox-rounded-container {
      grid-column: 1 / 3;
      display: grid;
      > div {
        > div {
          display: flex;
          flex-flow: wrap;
          .checkbox-rounded {
            margin: 2px;
            > div {
              width: max-content;
            }
          }
        }
      }
    }
  }
`;
