import React, { useEffect } from "react";
import { updateLoginData } from "./redux/actions/loginData";
import Routes from "./utils/routes";
import { useDispatch } from "react-redux";

function App({ pathInitial }) {

  const dispatch = useDispatch();

  useEffect(() => {
    if (pathInitial) {
      dispatch(updateLoginData({
        initialPath: pathInitial,
      }));
    }
  }, [pathInitial, dispatch]);

  return <Routes/>;
}

export default App;
