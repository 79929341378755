import styled from "styled-components";

import { 
  muliBoldFamily, 
  white, 
  scarpaGray100, 
  nightBlue500, 
  successPrimary, 
  errorPrimary, 
  scarpaGray300
} from "../../StylesConstants";
import iconCheckWhite from "../../static/assets/img/icons/icon-check-white.svg";
import iconErrorWhite from "../../static/assets/img/icons/icon-error-white.svg";

export const ButtonCheckWrapper = styled.div`

  background-color: ${white};
  border: 1px solid ${scarpaGray100};
  border-radius: 100px;
  color: ${nightBlue500};
  cursor: ${p => p.disabled? 'no-drop': 'pointer' };
  display: inline-block;
  font: 14px ${muliBoldFamily};
  line-height: 18px;
  letter-spacing: 0.04em;
  padding: 12px 24px;
  
  &.success {
    background-color: ${successPrimary};
    border: 1px solid ${successPrimary};
    color: ${white};
    .icon {
      background-image: ${`url(${iconCheckWhite})`};
    }
  }

  &.rejected {
    background-color: ${errorPrimary};
    border: 1px solid ${errorPrimary};
    color: ${white};
    .icon {
      background-image: ${`url(${iconErrorWhite})`};
    }
  }

  .radio {
    border: 2px solid ${scarpaGray300};
    border-radius: 16px;
    float: left;
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }

  .icon {
    float: left;
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }
`;