import styled from "styled-components";
import {
  aquamarine,
  error,
  plainlightGray,
  scarpaGray600,
  white,
} from '../../StylesConstants';

export const CustomSelectWrapper = styled.div`
  .select {
    font: inherit;
    color: ${scarpaGray600};
    width: 100%;
    border: 1px solid ${plainlightGray};
    cursor: pointer;
    height: 40px;
    margin: 0;
    display: block;
    padding: 0 16px;
    background: ${white};
    border-radius: 8px;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 10px;

    :hover {
      border: 1px solid ${aquamarine};
    }

    :disabled {
      background: transparent;
      color: ${scarpaGray600};
      cursor: default;
    }
  }

  .invalid-feedback {
    color: ${error};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
`;
