import { FINANCIAL_ENTITIES } from '../selectOptions';

export const letterOfIntent = {
  firstBasicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'purchase_financing',
      label: 'Financiación compra',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_phone',
      label: 'Número(s) de teléfono',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_email',
      label: 'Correo cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'intention_chart_date',
      label: 'Fecha carta de intención',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'tentative_promise_date',
      label: 'Fecha tentativa promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'input',
      fixed: true,
    },
  ],
  firstAnalystInformation: [
    {
      name: 'motive',
      label: 'Razón',
      type_field: 'select',
      multiple_values: {
        options: [
          {
            label: 'Caja honor',
            value: 'caja honor',
          },
          {
            label: 'Documento de compra / Hipoteca pendientes',
            value: 'documentos de compra / hipoteca pendientes',
          },
          {
            label: 'Pendiente aprobación crédito',
            value: 'pendiente aprobación crédito',
          },
        ],
      },
      fixed: false,
    },
    {
      name: 'last_client_communication_date_cdi',
      label: 'Fecha última comunicación cliente',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  secondBasicInformation: [
    /* todo: Revisar por que este campo se puede llenar, deberia ser fixed */
    {
      name: 'purchase_status',
      label: 'Estado compra',
      type_field: 'input',
      fixed: false,
      dependencies: [
        {
          on_field: 'ffes_plus_registration_date',
          on_field_value: true,
          action: 'value',
          default_state: 'Con BR Compra',
        },
        {
          on_field: 'ffes_plus_registration_date',
          on_field_value: false,
          action: 'value',
          default_state: 'Sin BR Compra',
        },
      ],
    },
    {
      name: 'ffes_plus_registration_date_cdi',
      label: 'FFES + boleta de registro compra',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'mortgage_status',
      label: 'Estado hipoteca',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'mortgage_bank',
      label: 'Banco hipoteca',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'ffes_mortgage_registration_ticket',
      label: 'FFES boleta de registro hipoteca',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'financial_entity',
      label: 'Entidad financiera',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: FINANCIAL_ENTITIES,
      },
    },
  ],
  secondAnalystInformation: [
    {
      name: 'last_buying_followup_date',
      label: 'Fecha último seguimiento compra/hipo',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'tentative_document_ready_date',
      label: 'Fecha tentativa documentos listos',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'last_ctl_request_date',
      label: 'Fecha última solicitud de CTL',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'actual_document_ready_date',
      label: 'Fecha real documentos listos',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'bank_request_date',
      label: 'Fecha solicitud banco',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'last_bank_followup_date',
      label: 'Fecha último seguimiento banco',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'bank_response_date',
      label: 'Fecha respuesta banco',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'credits_requested_number',
      label: 'Número de créditos solicitados',
      type_field: 'inputNumber',
      fixed: false,
    },
    {
      name: 'promise_request_date',
      label: 'Fecha solicitud promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'comments_cdi',
      label: 'Comentarios',
      type_field: 'input',
      fixed: false,
    },
  ],
};
