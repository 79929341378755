import { useState } from 'react';
import { callEventProcess } from '../apis/Process/processApis';
import { updateEvents } from '../services/eventsService';

export const useEvents = () => {
  const [events, setEvents] = useState([]);

  const callEvent = async ({
    nid,
    businessTypeId,
    processName,
    eventName,
    status,
    originUrl,
    email,
  }) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId,
        processName,
        eventName,
        status,
        originUrl,
        email,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  return [events, setEvents, callEvent];
};
