import styled from 'styled-components';
import {
  brightTurquoise100,
  successPrimary,
  goldenYellow100,
  goldenYellow700,
  openSansRegular,
  primary,
  scarpaGray400,
} from '../../StylesConstants';
import { PAYMENT_REQUESTS } from '../../utils/constant';

export const PaymentRequestWrapper = styled.div`
  .index {
    color: ${scarpaGray400};
  }

  .badge-field {
    min-width: 80px;
  }

  .sub-field {
    display: grid;
    min-width: 80px;

    span:nth-child(2n) {
      font-size: 10px;
      line-height: 10px;
      color: ${scarpaGray400};
      text-overflow: ellipsis;
      height: 10px;
      overflow: hidden;
    }
  }

  .type {
    border-radius: 4px;
    font: 12px ${openSansRegular};
    padding: 3px 4px;
    line-height: 10px;

    &.${PAYMENT_REQUESTS.MORTGAGE_PAYMENT} {
      color: ${successPrimary};
      background-color: ${brightTurquoise100};
    }
    &.${PAYMENT_REQUESTS.NOTARY_PAYMENT} {
      color: ${goldenYellow700};
      background-color: ${goldenYellow100};
    }
  }

  .center-field {
    vertical-align: middle;
  }

  .view-docs-field {
    color: ${primary};
    display: grid;
    gap: 6px;
    cursor: pointer;
    height: 100%;
    grid-template-columns: 24px 52px;
    span:hover {
      text-decoration: underline;
    }
  }
`;
