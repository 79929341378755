export const setDocumentReview = ({ document, reviewer, businessTypeId, processName, originUrl, nid, currentTab, showDelete, allowReReview, eventReview }) => ({
  type: 'SET_DOCUMENT_REVIEW',
  payload: {
    document,
    reviewer,
    businessTypeId,
    processName,
    originUrl,
    nid,
    currentTab,
    showDelete,
    allowReReview,
    eventReview
  }
});

export const setDocumentStatus = (status) => ({
  type: 'SET_DOCUMENT_STATUS',
  payload: {status}
});