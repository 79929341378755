export const setRegistrationTicket = payload => ({
  type: 'SET_REGISTRATION_TICKET',
  payload
});

export const resetRegistrationTicket = () => ({
  type: 'RESET_REGISTRATION_TICKET',
  payload: {}
});

export const updateActivityDate = (idActivity, date) => ({
  type: 'UPDATE_ACTIVITY_DATE',
  payload: {
    idActivity, date
  }
});

export const updateSubActivityDate = (idSubActivity, date) => ({
  type: 'UPDATE_SUB_ACTIVITY_DATE',
  payload: {
    idSubActivity, date
  }
});

export const cleanChanges = () => ({
  type: 'CLEAN_CHANGES',
  payload: {}
});
