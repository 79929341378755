import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendFlagComment, updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import { sendCompleteFieldsPropertyReception } from '../../apis/Notifications/notificationsApis';
import {
  callEventProcess,
  saveProcessComment,
} from '../../apis/Process/processApis';
import { ButtonDesist } from '../../components/ButtonDesist/ButtonDesist';
import Comments from '../../components/Comments/comments';

import EmptyData from '../../components/EmptyData/emptyData';
import { LifeCycle } from '../../components/LifeCycle/LifeCycle';
import RightSection from '../../components/RightSection/rightSection';
import {
  Container,
  Title,
} from '../../components/Styles/genericStyleComponent';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { getPropertyReceptionData } from '../../services/data/propertyReceptionData';
import { updateDocuments } from '../../services/documentsService';
import {
  ALL_LEGAL_ROLES,
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  PROCESS,
  PROCESS_EVENTS,
  RESPONSE_ERRORS,
  ROLE,
} from '../../utils/constant';
import PropertyReceptionForm from './components/PropertyReceptionForm/propertyReceptionForm';
import { PropertyReceptionWrapper } from './style';

const businessType = BUSINESS_TYPE.SELLERS;
const processName = PROCESS.DATA_PROPERTY_RECEPTION;

const PropertyReception = () => {
  const [events, setEvents] = useState([]);
  const [property, setProperty] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [buttonValidationCustomer, setButtonValidationCustomer] = useState({});

  const roles = useSelector((state) => state.loginData.data.role);
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);

  const dispatch = useDispatch();

  const getData = useCallback(
    async (reload) => {
      try {
        if (!reload) {
          dispatch(showLoading());
        } else {
          setLoading(true);
        }
        const data = await getPropertyReceptionData(
          nid,
          businessType,
          processName
        );

        setProperty(data.form);
        setEvents(data.events);
        setDocuments(data.documents);
        setComments(data.comments);
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setProperty(undefined);
        } else {
          console.error('error:::', error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
        setLoading(false);
      }
    },
    [nid, dispatch]
  );

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  const savePropertyReception = async (values) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: PROCESS.DATA_PROPERTY_RECEPTION,
      fields: values,
      business_type_id: BUSINESS_TYPE.SELLERS,
    };
    try {
      setLoading(true);
      await updateFormData(data);
      await callEvent(
        PROCESS_EVENTS.CAMPOS_COMERCIAL_COMPLETOS,
        DOCUMENT_STATUS.REVIEW
      );
      await callEvent(PROCESS_EVENTS.LEGAL_REVIEW, DOCUMENT_STATUS.REVIEW);
    } catch (error) {
      console.error('ERROR:', error);
    } finally {
      setLoading(false);
    }
  };

  const sendNotification = async () => {
    try {
      await sendCompleteFieldsPropertyReception({
        nid,
        originUrl: window.location.href,
        processName: PROCESS.DATA_PROPERTY_RECEPTION,
        businessType: BUSINESS_TYPE.SELLERS,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const callEvent = async (eventName, status) => {
    dispatch(showLoading());
    try {
      await callEventProcess({
        nid,
        businessTypeId: BUSINESS_TYPE.SELLERS,
        processName: PROCESS.DATA_PROPERTY_RECEPTION,
        eventName,
        status,
        originUrl: window.location.href,
      });

      if (
        eventName === PROCESS_EVENTS.CAMPOS_COMERCIAL_COMPLETOS &&
        status === DOCUMENT_STATUS.SUCCESS
      ) {
        await sendNotification();
      }

      updateEvents(eventName, status);
    } catch (error) {
      if (
        error?.response?.data?.message ===
        RESPONSE_ERRORS.COMERCIAL_EMAIL_WAS_NOT_FOUND
      ) {
        updateEvents(eventName, status);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const updateEvents = (eventName, status) => {
    if (events.some((event) => event.name === eventName)) {
      setEvents((e) =>
        e.map((event) =>
          event.name === eventName ? { name: event.name, status } : event
        )
      );
    } else {
      setEvents((e) => [...e, { name: eventName, status }]);
    }
  };

  const saveFlagComment = async (fieldId, comment) => {
    sendFlagComment({
      nid,
      processName: PROCESS.DATA_PROPERTY_RECEPTION,
      originUrl: window.location.href,
      businessType: BUSINESS_TYPE.SELLERS,
      comment,
      fieldId,
      agent,
    });
    await callEvent(
      PROCESS_EVENTS.CAMPOS_COMERCIAL_COMPLETOS,
      DOCUMENT_STATUS.REVIEW
    );
    await callEvent(PROCESS_EVENTS.LEGAL_REVIEW, DOCUMENT_STATUS.REVIEW);
  };

  const saveComment = async (comment) => {
    try {
      await saveProcessComment({
        nid,
        processName: PROCESS.MORTGAGE,
        agent,
        nameReviewerRole: roles[0],
        comment,
        originUrl: window.location.href,
        noInteractions: true,
      });
      setComments([
        {
          comment,
          update_date: new Date(),
          role: roles[0],
        },
        ...comments,
      ]);
    } catch (error) {
      console.error({ error });
    }
  };

  const addMultipleSection = (sectionId) => {
    let multipleSections = property.sections.find(
      (section) => section.id === sectionId
    );
    const multiplePart = multipleSections.fields.length + 1;
    const newSection = {
      multiple_part: multiplePart,
      fields: multipleSections.fields[0].fields.map((field) => ({
        ...field,
        value: null,
        name: `${field.name}_${multiplePart}`,
      })),
    };
    multipleSections.fields.push(newSection);

    setProperty({
      sections: property.sections.map((section) =>
        section.id === sectionId ? multipleSections : section
      ),
    });
  };

  const disabledCustomerValidation = (status, newDocument) => {
    if (documents && newDocument) {
      setDocuments((docs) => updateDocuments(newDocument, docs));
    }
  };

  const changeClientValidation = (clientValidation, complianceId) => {
    callEvent(
      `${PROCESS_EVENTS.VALIDATION}__${complianceId}`,
      clientValidation
    );
    setButtonValidationCustomer((prevState) => {
      return { ...prevState, [complianceId]: clientValidation };
    });
  };

  const haveComplianceOfficerDocument = (complianceId) => {
    const documentTarget = documents.find(
      (doc) => doc.number_seller === complianceId
    );
    const isDocumentUploaded = documentTarget?.exist;
    const isOfficer = roles.includes(ROLE.COMPLIANCE_OFFICER);
    let disabledField = false;
    if (!isOfficer) {
      disabledField = true;
    } else if (isOfficer && !isDocumentUploaded) {
      disabledField = true;
    } else if (isOfficer && isDocumentUploaded) {
      disabledField = false;
    }
    return disabledField;
  };

  const haveLegalRole = () => {
    let haveRole = false;
    ALL_LEGAL_ROLES.forEach((rol) => {
      if (roles.includes(rol)) {
        haveRole = true;
      }
    });
    return haveRole;
  };

  const showCommentsSection = () => {
    return roles.includes(ROLE.COMPLIANCE_OFFICER) || haveLegalRole();
  };

  return (
    <EmptyData>
      <PropertyReceptionWrapper showComment={showCommentsSection()}>
        <Container>
          <LifeCycle miniLifeCycle />
          <Title>Recepción inmueble</Title>
          <PropertyReceptionForm
            roles={roles}
            data={property}
            events={events}
            getData={getData}
            comments={comments}
            documents={documents}
            submitLoading={loading}
            processName={processName}
            businessType={businessType}
            buttonValidationCustomer={buttonValidationCustomer}
            handleComplete={callEvent}
            handleSaveComment={saveFlagComment}
            handleSubmit={savePropertyReception}
            addMultipleSection={addMultipleSection}
            changeClientValidation={changeClientValidation}
            disabledCustomerValidation={disabledCustomerValidation}
            setButtonValidationCustomer={setButtonValidationCustomer}
            haveComplianceOfficerDocument={haveComplianceOfficerDocument}
          />
        </Container>
        {showCommentsSection() && (
          <RightSection>
            <Comments
              commentsHistory={comments}
              handleSaveComment={saveComment}
            />
          </RightSection>
        )}
      </PropertyReceptionWrapper>
    </EmptyData>
  );
};

export default PropertyReception;
