import styled from 'styled-components';
import {
  grayEmpty,
  hintPerano400,
  hintPerano700,
  openSansRegular,
  secondaryGray,
  tomatoCoral300,
  white,
} from '../../../StylesConstants';
import { STEP_STATUS } from '../../../utils/constant';

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  .bar {
    width: 100%;
    height: 3px;
    &.${STEP_STATUS.EMPTY} {
      background-color: ${(p) => (p.miniLifeCycle ? grayEmpty : white)};
    }
    &.${STEP_STATUS.IN_PROGRESS} {
      background-color: ${hintPerano400};
      &.right {
        background-color: ${(p) => (p.miniLifeCycle ? grayEmpty : white)};
      }
      &.right-${STEP_STATUS.IN_PROGRESS} {
        background-color: ${hintPerano400};
      }
    }
    &.${STEP_STATUS.COMPLETED} {
      background-color: ${hintPerano700};
    }
  }
  .no-display {
    width: 100%;
  }
  .icon-container {
    display: grid;
    min-width: 32px;
    min-height: 32px;
    position: relative;
    border-radius: 50%;
    align-content: center;
    place-items: center;
    &.${STEP_STATUS.EMPTY} {
      background-color: ${(p) => (p.miniLifeCycle ? grayEmpty : white)};
    }
    &.${STEP_STATUS.IN_PROGRESS} {
      background-color: ${hintPerano400};
      .icon-step {
        path {
          fill: #333;
          color: #333;
        }
      }
    }
    &.${STEP_STATUS.COMPLETED} {
      background-color: ${hintPerano700};
    }
    .icon-step {
      width: 20px;
      height: 20px;
      align-items: center;
      cursor: pointer;
      border-radius: 50%;
      position: relative;
    }
    .floating-circle {
      &.${STEP_STATUS.IN_PROGRESS} {
        position: absolute;
        top: 0;
        left: 23px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: ${tomatoCoral300};
      }
    }

    .menu-container {
      min-width: 150px;
      border-radius: 6px;
      position: absolute;
      top: 60px;
      left: ${(p) => (p.isLast ? '-120px' : '-20px')};
      background-color: ${white};
      padding: 16px;
      box-shadow: rgb(255 255 255) 0px 0px 0px 0px,
        rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 10px 15px -3px,
        rgb(0 0 0 / 5%) 0px 4px 6px -2px;
      z-index: 1;
      .menu-title {
        font-weight: 600;
      }
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li {
          display: flex;
          .icon-section {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            margin-right: 8px;

            .vertical-bar {
              width: 2px;
              height: 20px;
              &.${STEP_STATUS.EMPTY} {
                background-color: ${secondaryGray};
              }
              &.${STEP_STATUS.COMPLETED} {
                background-color: ${hintPerano700};
              }
            }
          }
          .section-label {
            padding-top: 3px;
            font-family: ${openSansRegular};
            font-size: 10px;
          }
        }
      }
    }
  }
`;
