import React, { useEffect, useState } from 'react'
import { PropertyDetailWrapper } from './style';
import HouseIcon from '@material-ui/icons/House';
import { numberCurrencyFormat } from '../../../../utils/Scripts/numberCurrencyFormat';

export const PropertyDetail = ({data, nid}) => {

  const {agreed_price, residential_name} = data

  const [propertyName, setPropertyName] = useState('')
  const [propertyNID] = useState(nid)
  const [propertyPrice, setPropertyPrice] = useState(undefined)

  useEffect(() => {
    setPropertyName(residential_name);
    setPropertyPrice(agreed_price);
  }, [residential_name, agreed_price])

  return (
    <PropertyDetailWrapper>
      <div className='property-name-content'>
        <div className='house-icon'>
          <HouseIcon />
        </div>
        <div className='property-name'>
          Inmueble: {propertyName}
        </div>
      </div>
      <div className='property-nid-content'>
          NID: {propertyNID}
      </div>
      <div className='property-price-content'>
          Precio de transacción acordado: {numberCurrencyFormat().format(propertyPrice)}
      </div>
    </PropertyDetailWrapper>
  )
}
