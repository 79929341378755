import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { RadioCheckWrapper } from './style';
import iconCheck from "../../static/assets/img/icons/icon-check.svg";
import { ACTIVITY_STATUS } from '../../utils/constant';

const RadioCheck = ({ status, setValue }) => {

  const [checked, setChecket] = useState(status === ACTIVITY_STATUS.DONE);

  const handleClick = () => {
    if (status === ACTIVITY_STATUS.OPEN && !checked && setValue) {
      setChecket(!checked);
      setValue(ACTIVITY_STATUS.DONE);
    }
  };

  useEffect(() => {
    setChecket(status === ACTIVITY_STATUS.DONE)
  }, [status]);

  return <RadioCheckWrapper status={ status } onClick={ handleClick } id="radioCheckWrapper">
    {
      checked?
        <img src={ iconCheck } alt="Icon Check" className='icon-check'/>:
        <div className="radio" />
    }
  </RadioCheckWrapper>;
}

RadioCheck.propTypes = {
  status: PropTypes.oneOf([ACTIVITY_STATUS.OPEN, ACTIVITY_STATUS.LOCK, ACTIVITY_STATUS.DONE]).isRequired
}
 
export default RadioCheck;