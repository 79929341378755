import styled from 'styled-components';
import { montserratBold, openSansRegular, softPurple300, softPurple500, white } from '../../../../StylesConstants';

export const NewsWrapper = styled.div`
  background-color: ${white};
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-self: flex-start;

  p{
    font: 12px ${openSansRegular};
    color: ${softPurple500};
    text-align: center;
    margin: 0;
  }

  .btn-next{
    font: 16px ${montserratBold};
    background-color: ${softPurple300};
    color: ${white};
    padding: 6px 16px;
    margin: 32px 0;
    border-radius: 4px;
  }
`;