import styled from "styled-components";
import { electricPurple600, openSansRegular, scarpaGray200, white } from "../../StylesConstants";

export const TextCommentWrapper = styled.div`
  background-color: ${white};
  border: 1px solid ${scarpaGray200};
  box-sizing: border-box;
  border-radius: 8px;
  font: 13px ${openSansRegular};
  padding: 12px;
  display: grid;
  place-items: flex-end;
  
  textarea {
    font: 13px ${openSansRegular};
    border: 0;
    outline: none;
    padding: 0;
    width: -webkit-fill-available;
  }

  textarea:disabled {
    background-color: ${white};
  }

  .btn-send {
    align-items: center;
    color: ${electricPurple600};
    display: ${p => p.disabled? 'none': 'flex'};
    cursor: ${p => p.disabled? 'default': 'pointer'};
    font-weight: 600;
  }
`;