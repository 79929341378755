import API from '../Api';

export const getBondingDataApi = ({ nid, businessTypeId, fundingType, dummyProcess }) => {
  return API.get(
    process.env.REACT_APP_GET_LIST_PROCEDURE_SECTION_DOCUMENTS,
    {
      params: { 
        nid: parseInt(nid),
        business_type_id: businessTypeId,
        funding_type: fundingType,
        process: dummyProcess
      }
    }
  );
};

export const getSignatureAndDeliveryData = ({ nid }) => {
  return API.get(
    process.env.REACT_APP_GET_SIGNATURE_WRITING_DATA,
    {
      params: { 
        nid: parseInt(nid)
      }
    }
  );
};

export const saveSignatureAndDeliveryData = ({ nid, originUrl, data}) => {
  return API.post(
    process.env.REACT_APP_POST_SIGNATURE_SCHEDULING,
    {
      nid: parseInt(nid),
      origin_url: originUrl,
      data
    }
  );
};