import React from 'react';
import { Hr } from '../../../../components/Styles/genericStyleComponent';
import Field from '../Field/field';
import { SectionWrapper } from './style';

const Section = ({ section, formikProps, lastItem, saveData }) => {
  const getField = (field) => {
    return (
      <Field field={field} formikProps={formikProps} saveData={saveData} />
    );
  };

  return (
    <SectionWrapper>
      <div className="grid-buttons-container">
        {section.fields.map((field, index) => (
          <div key={`${field.id}_${index}`}>{getField(field)}</div>
        ))}
      </div>
      {!lastItem && <Hr />}
    </SectionWrapper>
  );
};

export default Section;
