import styled from "styled-components";
import {
  muliBoldFamily,
  nightBlue500,
} from "../../StylesConstants";

export const RegistrationTicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .registration-ticket-container {
    width: 700px;
    .MuiPaper-root {
      background-color: #fbfaff;
    }
  }

  .title-container {
    color: ${nightBlue500};
    font-family: ${muliBoldFamily};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 32px;
    margin: 0px 0px 32px;
  }

  .button-save {
    display: flex;
    justify-content: center;
  }
`;
