import styled from "styled-components";
import { error, openSansRegular, scarpaGray600 } from "../../../../StylesConstants";

export const DocumentOptionsWrapper = styled.div`
  .btn {
    align-items: center;
    cursor: pointer;
    display: grid;
    font: 14px ${openSansRegular};
    font-weight: 400;
    margin-bottom: 24px;
    grid-template-columns: 24px auto;
    gap: 8px;
  }
  .btn-delete {
    color: ${error}
  }
  a {
    color: ${scarpaGray600};
    text-decoration: none;
  }
`;