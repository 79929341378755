import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../../components/shared/button/button';
import { LISTING_WEB } from '../../../../utils/constant';
import { DYNAMIC_LISTING_WEB } from '../../../../utils/dynamic-constant';
import { RowGenericTableWrapper } from './style';
import moment from 'moment';

const RowRequested = ({ index, rowData, handleUpdate }) => {
  const [tagCurrent, setTagCurrent] = useState('');
  const [typeBadge, setTypeBadge] = useState(LISTING_WEB.CAMBIO_DE_ETIQUETA);

  const getBadgeConfig = () => {
    let badgeConfig = {
      label: typeBadge,
      class: DYNAMIC_LISTING_WEB[typeBadge],
    };
    return badgeConfig;
  };

  const getBadge = () => {
    const BadgeConfig = getBadgeConfig();
    if (BadgeConfig) {
      return (
        <span className={`type ${BadgeConfig.class}`}>{BadgeConfig.label}</span>
      );
    }
  };

  const getCurrentTagProperty = useCallback(() => {
    if (rowData.tag_three) {
      return setTagCurrent(rowData.tag_three_label);
    } else if (rowData.tag_two) {
      return setTagCurrent(rowData.tag_two_label);
    } else if (rowData.tag_one) {
      return setTagCurrent(rowData.tag_one_label);
    } else {
      setTypeBadge(LISTING_WEB.NUEVO);
      return setTagCurrent(LISTING_WEB.NO_DATA);
    }
  }, [rowData]);

  useEffect(() => {
    getCurrentTagProperty();
  }, [getCurrentTagProperty]);

  const getLabelActionButton = () => {
    return tagCurrent === LISTING_WEB.NO_DATA ? 'Publicar' : 'Actualizar';
  };

  return (
    <RowGenericTableWrapper id="row">
      <td>
        <div className="index">
          <span>{index + 1}</span>
        </div>
      </td>
      <td>
        <div className="badge-field">
          {getBadge()}
          <span>{rowData.nid}</span>
        </div>
      </td>
      <td>
        <span>{rowData.property_name}</span>
      </td>
      <td>
        <span>{tagCurrent}</span>
      </td>
      <td className="sub-field">
        <span>
          {moment(new Date(rowData.tag_change_date)).format('DD-MM-YYYY')}
        </span>
        <span>
          {moment(new Date(rowData.tag_change_date)).format('HH:mm:ss')}
        </span>
      </td>
      <td>
        <span>{rowData.tag_change_label}</span>
      </td>
      <td>
        <span>
          <Button
            size="small"
            typeClass="secondary"
            fullWidth
            onClick={() => handleUpdate(rowData.nid)}
          >
            {getLabelActionButton()}
          </Button>
        </span>
      </td>
    </RowGenericTableWrapper>
  );
};

export default RowRequested;
