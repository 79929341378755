export const setMortgageRegistrationTicket = (payload) => ({
  type: 'SET_MORTGAGE_REGISTRATION_TICKET',
  payload,
});

export const resetMortgageRegistrationTicket = () => ({
  type: 'RESET_MORTGAGE_REGISTRATION_TICKET',
  payload: {},
});

export const updateMortgageActivityDate = (idActivity, date) => ({
  type: 'UPDATE_MORTGAGE_ACTIVITY_DATE',
  payload: {
    idActivity,
    date,
  },
});

export const updateMortgageSubActivityDate = (idSubActivity, date) => ({
  type: 'UPDATE_MORTGAGE_SUB_ACTIVITY_DATE',
  payload: {
    idSubActivity,
    date,
  },
});

export const cleanMortgageChanges = () => ({
  type: 'CLEAN_MORTGAGE_CHANGES',
  payload: {},
});
