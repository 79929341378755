import { DISBURSEMENT_CONFIG } from "../../../utils/constant";

export const constantConfig = [
  {
    hasHorizontalLine: true,
    dependentValues: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.PORCENTAJE_DE_ARRAS_HABI,
        name: DISBURSEMENT_CONFIG.PERCENTAGE_ARRAS_HABI,
        type: DISBURSEMENT_CONFIG.RADIO_BUTTON,
        value: ({arras_percent}) => arras_percent,
        buttonsQuantity: ['0', '0.10', '0.15']
      },
      {
        label: DISBURSEMENT_CONFIG.MONTO_ARRAS_HABI,
        name: DISBURSEMENT_CONFIG.MOUNT_ARRAS_HABI,
        type: DISBURSEMENT_CONFIG.MONEY_DEPENDS,
        value: ({ arras_percent, mount }) => arras_percent === 0? '0' : parseFloat(arras_percent * mount).toFixed(2)
      }
    ]
  },
  {
    hasHorizontalLine: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.HIPOTECA,
        name: DISBURSEMENT_CONFIG.MORTGAGE,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
      {
        label: DISBURSEMENT_CONFIG.DEPOSITO,
        name: DISBURSEMENT_CONFIG.DEPOSIT,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
      {
        label: DISBURSEMENT_CONFIG.RETENCIÓN_EN_LA_FUENTE,
        name: DISBURSEMENT_CONFIG.WITHHOLDING_TAX,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
      {
        label: DISBURSEMENT_CONFIG.ADMINISTRACION,
        name: DISBURSEMENT_CONFIG.MANAGEMENT,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
      {
        label: DISBURSEMENT_CONFIG.SERVICIOS_PUBLICOS,
        name: DISBURSEMENT_CONFIG.PUBLIC_SERVICES,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
      {
        label: DISBURSEMENT_CONFIG.PREDIALES,
        name: DISBURSEMENT_CONFIG.PREDIALS,
        type: DISBURSEMENT_CONFIG.MONEY_FREE,
      },
    ]
  },
]