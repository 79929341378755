import styled from "styled-components";
import { hintPerano100, nightBlue500, openSansBoldFamily, openSansRegular, primary, scarpaGray600 } from "../../StylesConstants";

export const SupplierDocumentControlWrapper = styled.div`

  padding-top: 16px;
  margin-bottom: 25px;

  a {
    color: ${ primary };
  }

  table {
    border-collapse: collapse;
    color: ${ nightBlue500 };
    font-size: 12px;
    width: 100%;
  }

  thead tr {
    background: #FFFFFF;
    border-radius: 4px;
    font-family: ${ openSansBoldFamily };
    line-height: 16px;
  }

  th {
    padding: 10px;
    text-align: left;
    color: ${ scarpaGray600 };
  }

  td {
    font-family: ${ openSansRegular };
    font-size: 12px;
    line-height: 24px;
    padding: 10px;
    vertical-align: initial;
  }

  tbody tr:nth-child(even){
    background-color: ${ hintPerano100 };
  }

  .agent {
    min-width: 150px;
  }

`;