import { Formik } from 'formik';
import React, { useState, useCallback, useEffect } from 'react';
// Constants
import {
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  FIELD_TYPE,
  PROCESS,
  PROCESS_EVENTS,
} from '../../utils/constant';
// Form
// Redux
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import { useDispatch, useSelector } from 'react-redux';
// Services
import { appraisalAppointmentService } from '../../services/data/appraisalAppointmentService';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import { updateFormData } from '../../apis/DynamicForm/dynamicFormApis';
import { callEventProcess } from '../../apis/Process/processApis';
// Components
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import { DropDownSection } from '../../components/DropDownSection/DropDownSection';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import EmptyData from '../../components/EmptyData/emptyData';
import {
  Container,
  Hr,
  Label,
  Title,
} from '../../components/Styles/genericStyleComponent';
import { AppraisalAppointmentWrapper } from './style';
import Section from './components/section/Section';
import LoadingData from '../../components/LoadingData/loadingData';

const AppraisalAppointment = () => {
  const nid = useSelector((state) => state.propertyData.nid);
  const agent = useSelector((state) => state.loginData.data.email);
  const dispatch = useDispatch();

  const [sections, setSections] = useState([]);
  const [events, setEvents] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await appraisalAppointmentService(
        nid,
        BUSINESS_TYPE.BUYERS,
        PROCESS.APPRAISAL_APPOINTMENT
      );
      setSections(data.sections);
      setEvents(data.events);
      addDocuments(data.documents);
      dispatch(dataFound());
    } catch (error) {
      dispatch(dataNotFound());
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const addDocuments = (sections) => {
    let newDocuments = [];
    sections.forEach((section) => {
      newDocuments = [...newDocuments, ...section.docs];
    });
    setDocuments(newDocuments);
  };

  const validateValue = (field) => {
    if (field.type_field === FIELD_TYPE.INPUT_DATE) {
      return field.value ?? null;
    } else {
      return field.value ?? '';
    }
  };

  const createInitialValues = useCallback(() => {
    const values = {};
    sections.forEach((section) => {
      section.fields.forEach((field) => {
        values[field.name] = validateValue(field);
      });
    });

    setInitialValues(values);
  }, [sections]);

  useEffect(() => {
    createInitialValues();
  }, [createInitialValues]);

  const callEvent = async (eventName, status) => {
    try {
      await callEventProcess({
        nid,
        businessTypeId: BUSINESS_TYPE.BUYERS,
        processName: PROCESS.APPRAISAL_APPOINTMENT,
        eventName,
        status,
        originUrl: window.location.href,
      });
      setEvents((evts) => updateEvents(evts, eventName, status));
    } catch (error) {
      console.error(error);
    }
  };

  const addOptionalDocument = (document) => {
    if (
      documents.some(
        (doc) => doc.document_type_id !== document.document_type_id
      )
    ) {
      setDocuments((prev) => [...prev, document]);
    }
  };

  const formatValueToSave = (value, field) => {
    const data = {
      type_fields_procedure_id: field.id,
      value,
      type_field: field.type_field,
      field_name: field.name,
    };
    return [data];
  };

  const saveData = async (value, field) => {
    const data = {
      nid: typeof string ? parseInt(nid) : nid,
      agent,
      process_name: PROCESS.APPRAISAL_APPOINTMENT,
      fields: formatValueToSave(value, field),
      business_type_id: BUSINESS_TYPE.BUYERS,
    };
    try {
      await updateFormData(data);
      if (field.name === 'appraisal_scheduling_date') {
        callEvent(PROCESS_EVENTS.APPRAISER_ASSIGNED, DOCUMENT_STATUS.SUCCESS);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const chooseDocuments = (isOptional) => {
    let filterDocuments;
    if (isOptional) {
      filterDocuments = documents.filter(
        (doc) => doc.name !== PROCESS.APPRAISAL
      );
    } else {
      filterDocuments = documents.filter(
        (doc) => doc.name === PROCESS.APPRAISAL
      );
    }
    return filterDocuments;
  };

  const uncheckDocumentsReady = () => {
    callEvent(PROCESS_EVENTS.DOCUMENTS_READY, DOCUMENT_STATUS.REVIEW);
  };

  return (
    <EmptyData>
      <LoadingData>
        <AppraisalAppointmentWrapper>
          <Container>
            <Title>Cita Avalúo</Title>
            <DropDownSection>
              <div className="optional-document-container margin-bottom row-gap">
                {chooseDocuments(true).map((document, index) => (
                  <DocumentComponent
                    key={`document-${index}`}
                    lastItemRow={(index + 1) % 5 === 0 ? true : false}
                    documentParam={document}
                    showReview={true}
                    handleUpload={uncheckDocumentsReady}
                    handleDelete={uncheckDocumentsReady}
                    businessTypeId={BUSINESS_TYPE.BUYERS}
                    processName={PROCESS.APPRAISAL_APPOINTMENT}
                  />
                ))}
                <OptionalDocument
                  businessType={BUSINESS_TYPE.BUYERS}
                  processName={PROCESS.APPRAISAL_APPOINTMENT}
                  handleAddDocument={addOptionalDocument}
                  documentsToFilter={documents}
                />
              </div>
              <div className="grid-buttons-container check-buttons">
                <ButtonCheck
                  label={'Documentos listos'}
                  status={getEventStatus(
                    events,
                    PROCESS_EVENTS.DOCUMENTS_READY
                  )}
                  handleChange={() =>
                    callEvent(
                      PROCESS_EVENTS.DOCUMENTS_READY,
                      DOCUMENT_STATUS.SUCCESS
                    )
                  }
                />
                <ButtonCheck
                  label={'Documentos aprobados'}
                  status={getEventStatus(
                    events,
                    PROCESS_EVENTS.APPROVED_DOCUMENTS
                  )}
                  handleChange={() =>
                    callEvent(
                      PROCESS_EVENTS.APPROVED_DOCUMENTS,
                      DOCUMENT_STATUS.SUCCESS
                    )
                  }
                />
              </div>
            </DropDownSection>
            <Hr />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={saveData}
            >
              {(props) => (
                <>
                  {sections.map((section, index) => (
                    <div key={`${section.id}_${index}`}>
                      <Section
                        section={section}
                        formikProps={props}
                        lastItem={index + 1 === sections.length}
                        saveData={saveData}
                        initialValues={initialValues}
                      />
                    </div>
                  ))}
                </>
              )}
            </Formik>
            <div className={'margin-bottom'}>
              <ButtonCheck
                label={'Enviar información'}
                status={getEventStatus(events, PROCESS_EVENTS.SEND_INFORMATION)}
                handleChange={() =>
                  callEvent(
                    PROCESS_EVENTS.SEND_INFORMATION,
                    DOCUMENT_STATUS.SUCCESS
                  )
                }
              />
            </div>
            <Hr />
            <div className="appraisal-container">
              <Label>Carga de avalúo definitivo</Label>
              <div className="optional-document-container margin-bottom">
                {chooseDocuments(false).map((document, index) => (
                  <DocumentComponent
                    key={`document-${index}`}
                    lastItemRow={(index + 1) % 5 === 0 ? true : false}
                    documentParam={document}
                    showReview={true}
                    handleUpload={() =>
                      callEvent(
                        PROCESS_EVENTS.APPRAISAL_SUBMITTED,
                        DOCUMENT_STATUS.SUCCESS
                      )
                    }
                    businessTypeId={BUSINESS_TYPE.BUYERS}
                    processName={PROCESS.APPRAISAL_APPOINTMENT}
                  />
                ))}
              </div>
            </div>
          </Container>
        </AppraisalAppointmentWrapper>
      </LoadingData>
    </EmptyData>
  );
};

export default AppraisalAppointment;

