import { white } from '@habi/habi-react-components/dist/StylesConstants';
import styled from "styled-components";
import {
  openSansRegular,
  nightBlue300,
  electricPurple500,
  openSansBoldFamily,
  hintPerano000,
  nightBlue500,
  light_grey,
} from "../../StylesConstants";

export const SideMenuWrapper = styled.div`
  background-color: ${white};

  .nid-container{
    display: grid;
    grid-template-rows: min-content min-content auto;
    grid-gap: 10px;
    margin-top: 28px;
    position:fixed;
    width: 256px;
  }
`;

export const SideNavWrapper = styled.div`
  position: fixed;
  width: 256px;
  overflow-y: auto;
  margin-top: ${(p) => (p.nid ? '126px' : '94px')};
  height: calc(100% - 94px);
  background-color: ${white};
  display: grid;

  .title {
    font-family: ${openSansRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.08em;
    color: ${nightBlue300};
    margin: 0px 0px 8px;
    padding: 9px 24px;
    background-color: ${light_grey};
  }
  .container-sections {
    margin-bottom: 16px;
    .sidenav {
      padding: 0;
      margin: 0;

      .section-container {
        padding: 0px 20px;
        display: block;
        text-decoration: none;
        &.no-available {
          pointer-events: none;
          cursor: not-allowed;
        }
        .square-selected {
          display: none;
        }
        &.selected {
          display: grid;
          background: ${hintPerano000};
          grid-template-columns: 6px 1fr;
          grid-column-gap: 20px;
          padding: 0;

          .menu-option {
            .MuiSvgIcon-root,
            .section-name {
              font-family: ${openSansBoldFamily};
              color: ${electricPurple500};
            }
          }

          .square-selected {
            display: block;
            background: ${electricPurple500};
            width: 6px;
            height: 40px;
          }
        }
        .menu-option {
          display: grid;
          align-items: center;
          grid-template-columns: 15px 1fr;
          grid-column-gap: 10px;
          .MuiSvgIcon-root {
            width: 15px;
            height: 12px;
            color: ${nightBlue300};
          }
          .section-name {
            text-decoration: none;
            font-family: ${openSansRegular};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 40px;
            color: ${nightBlue300};
          }
        }
      }

      .subsections-container {
        .accordion {
          .section-with-subsection {
            display: grid;
            align-items: center;
            grid-template-columns: 15px 1fr;
            grid-column-gap: 10px;
            .MuiSvgIcon-root {
              width: 15px;
              height: 12px;
              color: ${nightBlue300} !important;
            }
          }
          .MuiAccordion-rounded {
            box-shadow: none;

            .MuiAccordionSummary-root {
              padding: 0px 20px;
              min-height: 40px;
            }
            .MuiAccordionSummary-expandIcon {
              padding: 0px;
              min-height: 40px;
            }
            .MuiAccordionSummary-content {
              margin: 0px;
              font-family: ${openSansRegular};
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 40px;
              color: ${nightBlue300};
              &.Mui-expanded {
                font-family: ${openSansBoldFamily};
                color: ${nightBlue500};
              }
            }
            .MuiButtonBase-root {
              .MuiSvgIcon-root {
                color: ${nightBlue500};
              }
            }
            .MuiAccordionDetails-root {
              padding: 0;
              display: grid;
              .link-subsection {
                padding: 0 20px 0 48px;
                display: block;
                text-decoration: none;
                font-family: ${openSansRegular};
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 40px;
                color: ${nightBlue300};

                .square-selected {
                  display: none;
                }

                &.selected {
                  font-family: ${openSansBoldFamily};
                  color: ${electricPurple500};
                  display: grid;
                  background: ${hintPerano000};
                  grid-template-columns: 6px 1fr;
                  grid-column-gap: 40px;
                  padding: 0;
                  .square-selected {
                    display: block;
                    background: ${electricPurple500};
                    width: 6px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
