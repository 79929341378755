import moment from 'moment';
import React from 'react';
import SelectWithConfirmation from '../../../../components/shared/SelectWithConfirmation/selectWithConfirmation';
import { LISTING_WEB } from '../../../../utils/constant';
import { RowGenericTableWrapper } from './style';

const RowPublished = ({ index, rowData, handleChange }) => {
  const getChangeTag = (label, date) => {
    if (label) {
      return (
        <div className="sub-field">
          <span>{label}</span>
          <span>{moment(new Date(date)).format('DD-MM-YYYY')}</span>
        </div>
      );
    } else {
      return LISTING_WEB.NO_DATA;
    }
  };

  return (
    <RowGenericTableWrapper id="row">
      <td>
        <div className="index">
          <span>{index + 1}</span>
        </div>
      </td>
      <td>
        <span>{rowData.nid}</span>
      </td>
      <td>
        <span>{rowData.property_name}</span>
      </td>
      <td>
        <div className="sub-field">
          <span>{rowData.tag_one_label}</span>
          <span>
            {moment(new Date(rowData.tag_one_date)).format('DD-MM-YYYY')}
          </span>
        </div>
      </td>
      <td>{getChangeTag(rowData.tag_two_label, rowData.tag_two_date)}</td>
      <td>{getChangeTag(rowData.tag_three_label, rowData.tag_three_date)}</td>
      <td>
        <span>
          <SelectWithConfirmation
            options={[
              { value: 'sold', label: 'Vendido' },
              { value: 'incomplete', label: 'Incompleto' },
            ]}
            handleConfirm={(e) => handleChange(e, rowData.nid)}
          />
        </span>
      </td>
    </RowGenericTableWrapper>
  );
};

export default RowPublished;
