import styled from "styled-components";

export const DocumentReviewWrapper = styled.div`

  height: 100%;

  .document-review-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto 480px auto;
  }

  .document-review-grid > div:first-child {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .arrow-back {
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .options-container {
    display: grid;
    gap: 40px;
    margin-bottom: 10px;
  }
`;