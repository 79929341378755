import React from 'react';

import { PageMessageWrap } from './style';

const PageMessage = ({ title, description }) => {
  return (
    <PageMessageWrap>
      <div className="title">
        <span>
          {title}
        </span>
      </div>
      <div className="message">
        <span>
          {description}
        </span>
      </div>
    </PageMessageWrap>
  )
};

export default PageMessage;
