import { DISBURSEMENT_CONFIG } from "../../../utils/constant";
import {
  getValueIfNegativeAdd,
  getValueIfNegativeSubtract,
} from '../scripts/configOperations';
import { constantConfig } from './constantConfig';

const dann = [
  {
    hasHorizontalLine: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.MONTO_DESEMBOLSADO_POR_DANN,
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_MOUNT_BY_DANN,
        type: DISBURSEMENT_CONFIG.MONEY_PERCENTAGE,
        value: ({ mount }) => (mount * 0.85).toFixed(2),
        percentage: 85,
      },
      {
        label: DISBURSEMENT_CONFIG.MONTO_DESEMBOLSADO_POR_HABI + ' (15%)',
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_MOUNT_BY_HABI,
        type: DISBURSEMENT_CONFIG.MONEY_PERCENTAGE,
        value: ({ mount }) => (mount * 0.15).toFixed(2),
        percentage: 15,
      },
    ],
  },
  ...constantConfig,
  {
    hasHorizontalLine: true,
    dependentValues: true,
    fields: [
      {
        label: DISBURSEMENT_CONFIG.CUENTA_POR_COBRAR_A_DANN,
        name: DISBURSEMENT_CONFIG.ACCOUNT_RECEIVABLE_TO_DANN,
        type: DISBURSEMENT_CONFIG.MONEY_DEPENDS,
        value: ({
          arras_percent,
          mortgage_amount,
          deposit_amount,
          withholding_source_amount,
          admin_amount,
          public_services_amount,
          praedial_amount,
          disbursement_customer_habi,
          disbursement_customer_dann,
        }) =>
          arras_percent !== 0.15
            ? getValueIfNegativeAdd({
                pivote: disbursement_customer_habi,
                subtractTo: disbursement_customer_dann,
                addValues: [mortgage_amount, deposit_amount],
              })
            : parseFloat(
                +mortgage_amount +
                  +deposit_amount +
                  +withholding_source_amount +
                  +admin_amount +
                  +public_services_amount +
                  +praedial_amount
              ).toFixed(2),
      },
      {
        label: DISBURSEMENT_CONFIG.DESEMBOLSO_A_CLIENTE_POR_DANN,
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_TO_CLIENT_BY_DANN,
        type: DISBURSEMENT_CONFIG.MONEY_DEPENDS,
        value: ({
          arras_percent,
          amount_disbursed_dann,
          mortgage_amount,
          deposit_amount,
          withholding_source_amount,
          admin_amount,
          public_services_amount,
          praedial_amount,
          disbursement_customer_dann,
          disbursement_customer_habi,
        }) =>
          arras_percent !== 0.15
            ? getValueIfNegativeSubtract({
                pivote: disbursement_customer_habi,
                subtractTo: disbursement_customer_dann,
                addValues: [amount_disbursed_dann, deposit_amount],
                substractValues: [mortgage_amount],
              })
            : parseFloat(
                +amount_disbursed_dann -
                  +mortgage_amount -
                  +deposit_amount -
                  +withholding_source_amount -
                  +admin_amount -
                  +public_services_amount -
                  +praedial_amount
              ).toFixed(2),
      },
      {
        label: DISBURSEMENT_CONFIG.DESEMBOLSO_A_CLIENTE_POR_HABI,
        name: DISBURSEMENT_CONFIG.DISBURSEMENT_TO_CLIENT_BY_HABI,
        type: DISBURSEMENT_CONFIG.MONEY_DEPENDS,
        value: ({
          arras_percent,
          amount_disbursed_habi,
          arras_amount,
          withholding_source_amount,
          admin_amount,
          public_services_amount,
          praedial_amount,
        }) =>
          arras_percent !== 0.15
            ? parseFloat(
                +amount_disbursed_habi -
                  +arras_amount -
                  +withholding_source_amount -
                  +admin_amount -
                  +public_services_amount -
                  +praedial_amount
              ).toFixed(2)
            : amount_disbursed_habi - arras_amount === 0
            ? '0'
            : parseFloat(amount_disbursed_habi - arras_amount).toFixed(2),
      },
    ],
  },
];

export default dann;