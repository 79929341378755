import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import {
  ACTIVITY_STATUS,
  BUSINESS_TYPE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE_NAME,
  DOCUMENT_WRAPPER_TYPE,
  LOCKED_MESSAGE,
  PCV_STAGES,
  PROCESS,
  PROCESS_EVENTS,
  REQUEST_PCV,
  ROLE,
  ROUTES,
  ALL_LEGAL_ROLES,
} from '../../utils/constant';
import { getCiPcvData } from '../../services/data/ciPcvData';
import { updateDocuments } from '../../services/documentsService';
import { getEventStatus, updateEvents } from '../../services/eventsService';
import { sendPcvEmailNotification } from '../../apis/Notifications/notificationsApis';
import { callEventProcess } from '../../apis/Process/processApis';
import { setDocumentReview } from '../../redux/actions/documentReview';
import {
  dataFound,
  dataNotFound,
  hideLoading,
  showLoading,
} from '../../redux/actions/propertyData';
import {
  Container,
  DocumentsGrid,
  DocumentsStepsGrid,
  Hr,
  Title,
} from '../../components/Styles/genericStyleComponent';
import { CiPcvWrapper } from './style';

import EmptyData from '../../components/EmptyData/emptyData';
import LoadingData from '../../components/LoadingData/loadingData';
import RadioCheck from '../../components/RadioCheck/radioCheck';
import DocumentComponent from '../../components/DocumentComponent/documentComponent';
import ButtonCheck from '../../components/ButtonCheck/buttonCheck';
import OptionalDocument from '../../components/OptionalDocument/optionalDocument';
import PageMessage from '../../components/PageMessage/pageMessage';
import { Link } from 'react-router-dom';
import ChoiceContractType from '../../components/ChoiceContractType/ChoiceContractType';
import DownloadDocument from '../../components/DownloadDocument/DownloadDocument';

const businessType = BUSINESS_TYPE.BUYERS;

const CiPcv = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const nid = useSelector((state) => state.propertyData.nid);
  const { role } = useSelector((state) => state.loginData.data);

  const [documents, setDocuments] = useState([]);
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [events, setEvents] = useState([]);
  const [business, setBusiness] = useState({});
  const [reviews, setReviews] = useState({ ci: [], pcv: [] });
  const [urlTemplateCi, setUrlTemplateCi] = useState('');
  const [urlTemplatePcv, setUrlTemplatePcv] = useState('');

  const getData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const data = await getCiPcvData({ nid, businessType });
      setDocuments(data.documents);
      setOptionalDocuments(data.optionalDocuments);
      setEvents(data.events);
      setReviews(data.reviews);
      setBusiness(data.business);
      dispatch(dataFound());
    } catch {
      dispatch(dataNotFound());
    } finally {
      dispatch(hideLoading());
    }
  }, [nid, dispatch]);

  const getDocument = (
    documentName,
    documents,
    processName,
    locked,
    lockedMessage
  ) => {
    const document = documents.find((doc) => doc.name === documentName);
    const documentLocked = isDocumentLocked(documentName);

    return (
      document && (
        <DocumentsStepsGrid>
          <div>
            <RadioCheck
              status={
                document.exist ? ACTIVITY_STATUS.DONE : ACTIVITY_STATUS.OPEN
              }
            />
          </div>
          {getDocumentComponent({
            document,
            wrapperType: DOCUMENT_WRAPPER_TYPE.DOT_LINE,
            processName,
            locked: documentLocked.locked,
            lockedMessage: documentLocked.message,
          })}
        </DocumentsStepsGrid>
      )
    );
  };

  const sendNotification = async (stage, comment) => {
    try {
      await sendPcvEmailNotification(
        nid,
        businessType,
        stage,
        window.location.href,
        comment
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateFile = (status, newDocument) => {
    setDocuments((docs) => updateDocuments(newDocument, docs));

    if (newDocument.exist) {
      if (newDocument.name === DOCUMENT_TYPE_NAME.CI) {
        sendNotification(PCV_STAGES.UPLOAD_CI, '');
      } else if (newDocument.name === DOCUMENT_TYPE_NAME.SIGN_CI) {
        sendNotification(PCV_STAGES.UPLOAD_SIGNED_CI, '');
      } else if (newDocument.name === DOCUMENT_TYPE_NAME.OTHER_YES_CI) {
        sendNotification(PCV_STAGES.UPLOAD_OTHER_YES_CI, '');
      } else if (newDocument.name === DOCUMENT_TYPE_NAME.PCV_BUYERS) {
        sendNotification(PCV_STAGES.UPLOAD_BUYER_PCV, '');
      } else if (newDocument.name === DOCUMENT_TYPE_NAME.SIGN_PCV_BUYERS) {
        sendNotification(PCV_STAGES.UPLOAD_SIGNED_BUYER_PCV, '');
      } else if (newDocument.name === DOCUMENT_TYPE_NAME.OTHER_YES_PCV) {
        sendNotification(PCV_STAGES.UPLOAD_OTHER_YES_PCV, '');
      }
    }
  };

  const getDocumentComponent = ({
    document,
    wrapperType,
    processName,
    locked,
    lockedMessage,
  }) => {
    return (
      <DocumentComponent
        documentParam={document}
        businessTypeId={businessType}
        wrapperType={wrapperType}
        handleUpload={handleUpdateFile}
        handleDelete={handleUpdateFile}
        processName={processName}
        locked={locked}
        lockedMessage={lockedMessage}
        key={`document${document.document_type_id}`}
      />
    );
  };

  const isDocumentLocked = (documentName) => {
    switch (documentName) {
      case DOCUMENT_TYPE_NAME.CI:
        return {
          locked: !business.allow_documents_ci_pcv,
          message: LOCKED_MESSAGE.CI_PCV_DOCUMENT,
        };
      case DOCUMENT_TYPE_NAME.SIGN_CI:
        return {
          locked: !reviews.ci.some(
            (rev) =>
              rev.role === ROLE.COMMERCIAL &&
              rev.status === DOCUMENT_STATUS.SUCCESS
          ),
          message: LOCKED_MESSAGE.CI_PCV_DOCUMENT,
        };
      case DOCUMENT_TYPE_NAME.PCV_BUYERS:
        return {
          locked: !business.allow_documents_ci_pcv,
          message: LOCKED_MESSAGE.CI_PCV_DOCUMENT,
        };
      case DOCUMENT_TYPE_NAME.SIGN_PCV_BUYERS:
        return {
          locked: !reviews.pcv.some(
            (rev) =>
              (rev.role === ROLE.COMMERCIAL ||
                rev.role === ROLE.PROCEDURES_ANALYST_BUYERS) &&
              rev.status === DOCUMENT_STATUS.SUCCESS
          ),
          message: LOCKED_MESSAGE.CI_PCV_DOCUMENT,
        };
      default:
        return true;
    }
  };

  const isReviewDisabled = (documentName) => {
    const documentPcv = documents.find((doc) => doc.name === documentName);
    return documentPcv?.exist ? false : true;
  };

  const reviewDocument = (review, documentName, processName, reviewer) => {
    const documentPcv = documents.find(
      (document) => document.name === documentName
    );
    if (!isReviewDisabled(documentName)) {
      dispatch(
        setDocumentReview({
          document: { ...documentPcv, status: review.status },
          reviewer,
          businessTypeId: businessType,
          processName,
          originUrl: window.location.href,
          nid,
        })
      );
      history.push('/revision-documento');
    }
  };

  const getReview = (
    review = { role: ROLE.COMMERCIAL },
    documentName,
    label,
    processName,
    reviewer
  ) => {
    return (
      <div className="document-grid">
        <div
          onClick={() =>
            reviewDocument(review, documentName, processName, reviewer)
          }
          className={`btn-grid ${
            isReviewDisabled(documentName) ? 'btn-disabled' : 'btn'
          }`}
        >
          <ButtonCheck
            label={label}
            status={review.status}
            disabled={isReviewDisabled(documentName)}
          />
        </div>
      </div>
    );
  };

  const callEvent = async (eventName, status) => {
    callEventProcess({
      nid,
      businessTypeId: businessType,
      processName: PROCESS.CI_PURCHASE_AND_LEGAL,
      eventName,
      status,
      originUrl: window.location.href,
    });
    setEvents((evts) => updateEvents(evts, eventName, status));
  };

  const addOptionalDocument = (document) => {
    setDocuments((docs) => {
      return docs.some(
        (doc) => doc.document_type_id === document.document_type_id
      )
        ? docs
        : [...docs, document];
    });
  };

  useEffect(() => {
    nid ? getData() : dispatch(dataNotFound());
  }, [nid, getData, dispatch]);

  const handleSetPcvType = (url) => {
    setUrlTemplatePcv(url);
  };

  const handleSetCiType = (url) => {
    setUrlTemplateCi(url);
  };

  const getDownloadDocument = (label, url) => {
    return (
      <DocumentsStepsGrid>
        <div>
          <RadioCheck
            status={
              document.exist ? ACTIVITY_STATUS.DONE : ACTIVITY_STATUS.OPEN
            }
          />
        </div>
        <DownloadDocument label={label} url={url} />
        <div className="dot-line-download" />
      </DocumentsStepsGrid>
    );
  };

  return (
    <EmptyData>
      <LoadingData>
        <Container>
          <CiPcvWrapper>
            <ChoiceContractType
              disabled={ALL_LEGAL_ROLES.includes[role]}
              handleChange={handleSetCiType}
              nid={nid}
              businessTypeId={BUSINESS_TYPE.BUYERS}
              processName={PROCESS.CI_PURCHASE_AND_LEGAL}
              label={'Elección Tipo CI'}
            />
            <Hr />
            {business.pcv_ci_option === REQUEST_PCV.CI_PCV && urlTemplateCi && (
              <div>
                <Title>CI (carta de intención de compra)</Title>
                {getDownloadDocument('Descargar plantilla CI', urlTemplateCi)}
                {documents.length > 0 && <div className="dot-line-documents" />}
                {getDocument(
                  DOCUMENT_TYPE_NAME.CI,
                  documents,
                  PROCESS.CI_PURCHASE_AND_LEGAL
                )}
                {getReview(
                  reviews.ci.find((review) => review.role === ROLE.COMMERCIAL),
                  DOCUMENT_TYPE_NAME.CI,
                  'Revisión comercial',
                  PROCESS.CI_PURCHASE_AND_LEGAL,
                  ROLE.COMMERCIAL
                )}
                {getDocument(
                  DOCUMENT_TYPE_NAME.SIGN_CI,
                  documents,
                  PROCESS.CI_PURCHASE_AND_LEGAL
                )}
                <DocumentsGrid>
                  {documents.some(
                    (doc) => doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_CI
                  ) && (
                    <DocumentComponent
                      businessTypeId={businessType}
                      processName={PROCESS.CI_PURCHASE_AND_LEGAL}
                      documentParam={documents.find(
                        (doc) => doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_CI
                      )}
                      handleUpload={handleUpdateFile}
                      handleDelete={handleUpdateFile}
                    />
                  )}
                  <OptionalDocument
                    businessTypeId={businessType}
                    processName={PROCESS.CI_PURCHASE_AND_LEGAL}
                    optionalDocuments={optionalDocuments.filter(
                      (doc) =>
                        !doc.exist &&
                        doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_CI
                    )}
                    handleAddDocument={addOptionalDocument}
                  />
                </DocumentsGrid>
                <Hr />
                <ButtonCheck
                  label="Solicitud trámites PCV"
                  status={getEventStatus(
                    events,
                    PROCESS_EVENTS.PCV_PROCEDURES_REQUEST
                  )}
                  handleChange={() =>
                    callEvent(
                      PROCESS_EVENTS.PCV_PROCEDURES_REQUEST,
                      DOCUMENT_STATUS.SUCCESS
                    )
                  }
                />
                <Hr marginTop="32" />
              </div>
            )}
            <ChoiceContractType
              disabled={ALL_LEGAL_ROLES.includes[role]}
              handleChange={handleSetPcvType}
              nid={nid}
              businessTypeId={BUSINESS_TYPE.BUYERS}
              processName={PROCESS.PCV_PURCHASE_AND_LEGAL}
              label={'Elección Tipo PCV'}
            />
            <Hr />
            {business.pcv_ci_option && urlTemplatePcv && (
              <div>
                <Title>PCV</Title>
                {getDownloadDocument('Descargar plantilla PCV', urlTemplatePcv)}
                {documents.length > 0 && <div className="dot-line-documents" />}
                {getDocument(
                  DOCUMENT_TYPE_NAME.PCV_BUYERS,
                  documents,
                  PROCESS.PCV_PURCHASE_AND_LEGAL
                )}
                {business.pcv_ci_option === REQUEST_PCV.CI_PCV
                  ? getReview(
                      reviews.pcv.find(
                        (review) =>
                          review.role === ROLE.PROCEDURES_ANALYST_BUYERS
                      ),
                      DOCUMENT_TYPE_NAME.PCV_BUYERS,
                      'Revisión trámites',
                      PROCESS.PCV_PURCHASE_AND_LEGAL,
                      ROLE.PROCEDURES_ANALYST_BUYERS
                    )
                  : getReview(
                      reviews.pcv.find(
                        (review) => review.role === ROLE.COMMERCIAL
                      ),
                      DOCUMENT_TYPE_NAME.PCV_BUYERS,
                      'Revisión comercial',
                      PROCESS.PCV_PURCHASE_AND_LEGAL,
                      ROLE.COMMERCIAL
                    )}
                {getDocument(
                  DOCUMENT_TYPE_NAME.SIGN_PCV_BUYERS,
                  documents,
                  PROCESS.PCV_PURCHASE_AND_LEGAL
                )}
                <DocumentsGrid>
                  {documents.some(
                    (doc) => doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_PCV
                  ) && (
                    <DocumentComponent
                      businessTypeId={businessType}
                      processName={PROCESS.PCV_PURCHASE_AND_LEGAL}
                      documentParam={documents.find(
                        (doc) => doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_PCV
                      )}
                      handleUpload={handleUpdateFile}
                      handleDelete={handleUpdateFile}
                    />
                  )}
                  <OptionalDocument
                    businessTypeId={businessType}
                    processName={PROCESS.PCV_PURCHASE_AND_LEGAL}
                    optionalDocuments={optionalDocuments.filter(
                      (doc) =>
                        !doc.exist &&
                        doc.name === DOCUMENT_TYPE_NAME.OTHER_YES_PCV
                    )}
                    handleAddDocument={addOptionalDocument}
                  />
                </DocumentsGrid>
              </div>
            )}
            {!business.pcv_ci_option && (
              <>
                <Title>CI / PCV</Title>
                <PageMessage
                  title="Opción CI / PCV sin asignar"
                  description={
                    <>
                      Para poder continuar, ve al formulario de recepción{' '}
                      <Link to={ROUTES.BUYER_RECEPTION}>
                        Recepción Comprador
                      </Link>{' '}
                      <br />y selecciona el tipo de contrato que aplica para
                      este negocio.
                    </>
                  }
                />
              </>
            )}
          </CiPcvWrapper>
        </Container>
      </LoadingData>
    </EmptyData>
  );
};

export default CiPcv;
