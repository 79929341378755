import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { DocumentCardWrapper } from './style';
import Loader from '@habi/habi-react-components/dist/Loader/loader';

const DocumentCard = ({ document, getReviewStatus, getActionIcon, getDocumentIcon, getOptional, loading }) => {
  return (
    <DocumentCardWrapper>
      {
        !loading &&
        <div className="document-card">
          <div className="status-section">
            <div>
              { getReviewStatus() }
            </div>
            <div>
              { getActionIcon() }
            </div>
          </div>
          <div className="document-section">
            { getDocumentIcon() }
            <div className="title">
              { document.label }
            </div>
          </div>
          <div className="properties-section">
            <div>
              { 
                document.exist  
                  ? <div className="update_date">
                      <span>Últ. mod.</span> <br />
                      <span className="date"> { ` ${format(new Date(document.update_date), 'dd-MM-yyyy')}` } </span>
                    </div>
                  : <div className="empty">
                      No hay <br /> documento
                    </div>
              }
            </div>
            <div>
              { getOptional() }
            </div>
          </div>
        </div>
      }
      {loading && <div className="document-card loading"><Loader /></div>}
      
    </DocumentCardWrapper>
  )
};

DocumentCard.defaultProps = {
  loading: false
};

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
  getReviewStatus: PropTypes.func.isRequired, 
  getActionIcon: PropTypes.func.isRequired, 
  getDocumentIcon: PropTypes.func.isRequired, 
  getOptional: PropTypes.func.isRequired, 
  loading: PropTypes.bool
}

export default DocumentCard;
