import React, { useState } from 'react';
import { deletePropertyApi } from '../../apis/DesistProperty/desisyProperty';
import Button from '../shared/button/button';

export const ButtonDesist = ({ nid }) => {
  const [loading, setLoading] = useState(false);

  const deleteProperty = async () => {
    try {
      setLoading(true);
      await deletePropertyApi({ nid });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      dataId={'desist-property'}
      typeClass={'secondary'}
      size={'middle'}
      loading={loading}
      onClick={() => deleteProperty()}
    >
      Desistir inmueble
    </Button>
  );
};
