import { getFormDataDependencies } from '../../apis/DynamicForm/dynamicFormApis';
import {
  getDocumentsProcess,
  getEventsProcess,
} from '../../apis/Process/processApis';
import { HTTP_STATUS } from '../../utils/constant';

export const appraisalAppointmentService = (nid, businessType, processName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await getFormDataDependencies(
        nid,
        processName,
        businessType
      );
      const events = await getEventsProcess({
        nid,
        businessTypeId: businessType,
        processName,
      });
      const documents = await getDocumentsProcess({
        nid,
        businessType,
        processName,
        sections: true,
      });

      resolve({
        sections: data.data.sections,
        documents: documents.data.docs,
        events: events.data.data.events,
      });
    } catch (error) {
      if (error.response && error.response.status === HTTP_STATUS.NOT_FOUND) {
        resolve({
          sections: [],
          documents: [],
          events: [],
        });
      } else {
        console.error({ error });
        reject();
      }
    }
  });
};
