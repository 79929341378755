import React from 'react';

import BondingStageColpatria from '../BondingStageColpatria/bondingStageColpatria';
import { BondingColpatriaWrapper } from './style';

const BondingColpatria = ({ sections, events, handleCallEvent, addOptionalDocument, handleUpdateDocument }) => {
  return (
    <BondingColpatriaWrapper>
      { sections && 
        sections.map(section => 
        <BondingStageColpatria 
          section={ section }
          events={ events }
          handleCallEvent={ handleCallEvent }
          documentsReady={ sections[0].list_documents.filter(document => document.exist) }
          addOptionalDocument={ addOptionalDocument }
          handleUpdateDocument={ handleUpdateDocument }
          key={`section_${ section.id }`}
        />
      )}
    </BondingColpatriaWrapper>
  )
};

export default BondingColpatria;
