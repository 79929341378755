import { ANALYST, FINANCIAL_ENTITIES } from '../selectOptions';

export const sales = {
  basicInformation: [
    {
      name: 'nid',
      label: 'NID',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'property_name',
      label: 'Nombre',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'purchase_financing',
      label: 'Financiación compra',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'obligation_number',
      label: 'Número de obligación',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'registration_sheet',
      label: 'Folio matrícula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'city',
      label: 'Ciudad',
      type_field: 'input',
      fixed: true,
    },
  ],
  firstAnalystInformation: [
    {
      name: 'property_address',
      label: 'Dirección del inmueble',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_name',
      label: 'Nombre del cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_id',
      label: 'Cédula',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_phone',
      label: 'Numero(s) de Teléfono',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'client_email',
      label: 'Correo cliente',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'address_residence_notification',
      label: 'Dirección cliente',
      type_field: 'input',
      fixed: true,
    },
  ],
  firstLegalInformation: [
    {
      name: 'intention_chart_date',
      label: 'Fecha carta de intención',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'tentative_promise_date',
      label: 'Fecha tentativa promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'promise_request_date',
      label: 'Fecha solicitud promesa',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'promise_signature_date',
      label: 'Fecha firma promesa',
      type_field: 'inputDate',
      fixed: true,
    },
  ],
  firstLeaderBuyersProceduresInformation: [
    {
      name: 'flux_team',
      label: 'Flujo (Equipo)',
      type_field: 'select',
      multiple_values: {
        options: [
          {
            label: 'Anterior HL',
            value: 'value hl',
          },
          {
            label: 'Bogotá',
            value: 'bogotá',
          },
          {
            label: 'Caja Honor / Fondos',
            value: 'caja honor / fondos',
          },
          {
            label: 'Otros bancos',
            value: 'otros bancos',
          },
          {
            label: 'Contado',
            value: 'contado',
          },
        ],
      },
      fixed: false,
    },
    {
      name: 'procedure_analyst',
      label: 'Analista de trámites',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: ANALYST,
      },
    },
  ],
  secondLegalInformation: [
    {
      name: 'commercial_executive',
      label: 'Comercial Buyers',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: [
          {
            label: 'Prueba QA',
            value: 'gabrielarincon@habi.co',
          },
          {
            label: 'Andres Gómez',
            value: 'andresgomez@habi.co',
          },
          {
            label: 'Andres Restrepo',
            value: 'andresrestrepo@habi.co',
          },
          {
            label: 'Carolina Gonzalez',
            value: 'carolinagonzalez@habi.co',
          },
          {
            label: 'Catalina Botero',
            value: 'catalinabotero@habi.co',
          },
          {
            label: 'Daniel Bermudez',
            value: 'danielbermudez@habi.co',
          },
          {
            label: 'Daniela Alvarez',
            value: 'danielaalvarez@habi.co',
          },
          {
            label: 'Diana Peña',
            value: 'dianapena@habi.co',
          },
          {
            label: 'Diana Triana',
            value: 'dianatriana@habi.co',
          },
          {
            label: 'Elaina Rodriguez ',
            value: 'elainerodriguez@habi.co',
          },
          {
            label: 'Esteban Vasquez ',
            value: 'estebanvasquez@habi.co',
          },
          {
            label: 'Flor Diez',
            value: 'flordiez@habi.co',
          },
          {
            label: 'Gina Vargas',
            value: 'ginavargas@habi.co',
          },
          {
            label: 'Jennifer Morales',
            value: 'jennifermorales@habi.co',
          },
          {
            label: 'Johana Sanchez',
            value: 'johanasanchez@habi.co',
          },
          {
            label: 'Johanna Espitia',
            value: 'Johannaespitia@habi.co',
          },
          {
            label: 'John Aranda',
            value: 'johnaranda@habi.co',
          },
          {
            label: 'Jorge Arboleda',
            value: 'jorgearboleda@habi.co',
          },
          {
            label: 'Juan Carlos Velasquez',
            value: 'juanvelasquez@habi.co',
          },
          {
            label: 'Juan Jose Florez',
            value: 'juanflorez@habi.co',
          },
          {
            label: 'Juliana Estupiñan',
            value: 'julianaestupinan@habi.co',
          },
          {
            label: 'Laura Fernández',
            value: 'laurafernandez@habi.co',
          },
          {
            label: 'Monica Fritz',
            value: 'monicafritz@habi.co',
          },
          {
            label: 'Nataly Preciado',
            value: 'nataliepreciado@habi.co',
          },
          {
            label: 'Paola Rubio',
            value: 'paolarubio@habi.co',
          },
          {
            label: 'Rafael Uribe ',
            value: 'rafaeluribe@habi.co',
          },
          {
            label: 'Ricardo bello ',
            value: 'ricardobello@habi.co',
          },
          {
            label: 'Samantha Vargas',
            value: 'samanthavargas@habi.co',
          },
          {
            label: 'Samy Cuevas',
            value: 'kevincuevas@habi.co',
          },
          {
            label: 'Sofia Casas',
            value: 'sofiacasas@habi.co',
          },
          {
            label: 'Stephania Bernal',
            value: 'stephaniabernal@habi.co',
          },
          {
            label: 'Viviana Chacon',
            value: 'vivianachacon@habi.co',
          },
          {
            label: 'Meyerh Suarez',
            value: 'meyerhsuarez@habi.co',
          },
        ],
      },
    },
    {
      name: 'notaria_venta',
      label: 'Notaría venta',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: [
          {
            label: 'Notaría Cali 11',
            value: 'notaria_cali_11',
          },
          {
            label: 'Notaría Medellín 10',
            value: 'notaria_medellin_10',
          },
          {
            label: 'Notaría Bogotá 41',
            value: 'notaria_bogota_41',
          },
          {
            label: 'Notaría Bogotá 68',
            value: 'notaria_bogota_68',
          },
          {
            label: 'Notaría Barranquilla 1',
            value: 'notaria_barranquilla_1',
          },
        ],
      },
    },
    {
      name: 'scripture_deal_date',
      label: 'Fecha escritura pactada',
      type_field: 'inputDate',
      fixed: true,
    },
  ],
  secondAnalystInformation: [
    {
      name: 'latest_otrosi_date',
      label: 'Fecha último otrosí',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'latest_otrosi_reason',
      label: 'Razón último otrosí',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'otrosi_scripture_deal_date',
      label: 'Fecha escritura pactada otrosí',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  thirdLegalInformation: [
    {
      name: 'severance_entity',
      label: 'Entidad cesantías',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'payment_method',
      label: 'Forma de pago',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Contado',
            value: 'Contado',
          },
          {
            label: 'Leasing',
            value: 'Leasing',
          },
          {
            label: 'Crédito',
            value: 'Crédito',
          },
          {
            label: 'Cesantías',
            value: 'Cesantías',
          },
        ],
      },
    },
    {
      name: 'selling_price',
      label: 'Precio de venta',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'financial_entity',
      label: 'Entidad financiera',
      type_field: 'select',
      fixed: true,
      multiple_values: {
        options: FINANCIAL_ENTITIES,
      },
    },
  ],
  thirdAnalystInformation: [
    {
      name: 'convention_hlv',
      label: 'Convencionado',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 1,
          },
          {
            label: 'No',
            value: 0,
          },
        ],
      },
    },
    {
      name: 'external_lawyer_name_hlv',
      label: 'Nombre abogado externo',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'external_lawyer_contact_hlv',
      label: 'Contacto abogado externo',
      type_field: 'input',
      fixed: false,
    },
    {
      name: 'adviser',
      label: 'Asesor',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'adviser_contact',
      label: 'Contacto asesor',
      type_field: 'input',
      fixed: true,
    },
    {
      name: 'real_scripture_date',
      label: 'Fecha escritura real',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'sale_scripture_number',
      label: 'Número de escritura',
      type_field: 'inputNumber',
      fixed: true,
    },
    {
      name: 'registry_ticket',
      label: 'Boleta de registro',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'ctl',
      label: 'CTL',
      type_field: 'inputDate',
      fixed: false,
    },
  ],
  fourthLegalInformation: [
    {
      name: 'seedbed_hlv',
      label: 'Semilleros de propietarios',
      type_field: 'select',
      fixed: false,
      multiple_values: {
        options: [
          {
            label: 'Si',
            value: 'si',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
    },
  ],
  fourthAnalystInformation: [
    {
      name: 'arras_date',
      label: 'Fecha arras',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'arras_value',
      label: 'Valor arras',
      type_field: 'inputCurrency',
      fixed: false,
    },
  ],
  secondLeaderBuyersProceduresInformation: [
    {
      name: 'commercial_closing_month',
      label: 'Mes cierre comercial',
      type_field: 'inputDate',
      fixed: false,
    },
    {
      name: 'estimated_date_written',
      label: 'Fecha estimada escritura',
      type_field: 'inputDate',
      fixed: true,
    },
    {
      name: 'comments_hlv',
      label: 'Comentarios',
      type_field: 'input',
      fixed: false,
    },
  ],
};
