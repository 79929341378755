function documentReviewData(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_DOCUMENT_REVIEW': {
      return { ...state, ...action.payload };
    }
    case 'SET_DOCUMENT_STATUS': {
      const document = {
        ...state.document,
        status: action.payload.status
      };
      return { ...state, document };
    }
    default:
      return state;
  }
}

export default documentReviewData;