import { brightTurquoise000, brightTurquoise500, openSansRegular, scarpaGray200, white } from '../../../StylesConstants';

export const getReactSelectStyles = (withBorder) => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      fontFamily: openSansRegular,
      outlineColor: 'red',
      border: isFocused
        ? `2px solid ${brightTurquoise500}`
        : withBorder
          ? `1px solid ${scarpaGray200}`
          : `2px solid ${white}`,
      boxShadow: '0 !important',
      ':hover': {
        ...styles[':hover'],
        borderColor: brightTurquoise500
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = brightTurquoise500;
      return {
        ...styles,
        fontFamily: openSansRegular,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? color
            : isFocused
              ? color
              : null,
        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? color : null),
        },
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: brightTurquoise000,
      };
    },
    multiValueRemove: styles => ({
      ...styles,
      ':hover': {
        backgroundColor: brightTurquoise500
      },
    }),
    menu: styles => ({
      ...styles,
      border: withBorder ? `1px solid ${scarpaGray200}` : '0',
      boxShadow: 0,
    }),
  };
};
