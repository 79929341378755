import React, { useRef, useState } from 'react';
import flag from '../../../static/assets/img/icons/icon-flag.svg';
import redflag from '../../../static/assets/img/icons/icon-flag-red.svg';

import { FieldCommentWrapper } from './style';
import TextComment from '../../../components/TextComment/textComment';
import { Backdrop } from '../../../components/Styles/genericStyleComponent';
import useOutsideClick from '../../../hooks/useOutsideClick';

const FieldComment = ({ comment, handleShowHide, handleSaveComment }) => {

  const [showComment, setShowComment] = useState(false);
  const [open, isOpen] = useState(false);
  
  const ref = useRef();

  useOutsideClick(ref, () => {
    open? close(): isOpen(true);
  });

  const showHide = () => {
    setShowComment(!showComment);
    handleShowHide(!showComment);

    if(!showComment) {
      isOpen(false);
    }
  }

  const close = () => {
    setShowComment(false);
    handleShowHide(false);
    isOpen(false);
  }

  const saveComment = (comment) => {
    handleSaveComment(comment);
    close();
  }

  return (
    <FieldCommentWrapper showComment={showComment}>
      {
        <img 
          src={comment? redflag: flag} 
          alt='Indicador comentario' 
          onClick={showHide}
          className={`flag ${showComment? 'over-backdrop': ''}`}
        />
      }
      {
        showComment && 
          <>
            <div className={`container-comment ${showComment? 'over-backdrop': '' }`} ref={ref}>
              <TextComment 
                value={comment}
                handleSubmit={saveComment}
                disabled={false}
                allowEmpty
              />
            </div>
            <Backdrop />
          </>
      }
    </FieldCommentWrapper>
  )
};

export default FieldComment;
