function loginData(state = {}, action = {}) {
    switch (action.type) {
      case 'UPDATE_USER_LOGIN': {
        return { ...state, ...action.payload };
      }
      default:
        return state;
    }
  }
  
  export default loginData;
  