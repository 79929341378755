import styled from 'styled-components';
import {
  electricPurple500,
  hintPerano300,
  nightBlue300,
  openSansBoldFamily,
  openSansRegular,
  purple,
} from '../../StylesConstants';

export const ButtonLifeCycleWrapper = styled.div`
  .button-container {
    display: flex;
    max-height: 32px;
    margin-left: 23px;
    margin-bottom: 14px;
    text-decoration: none;
    .vertical-bar {
      width: 4px;
      height: 32px;
      border-radius: 2px;
      margin-right: 12px;
      background-color: ${(p) => (p.selected ? purple : hintPerano300)};
    }
    .content {
      display: grid;
      .title {
        font-size: 12px;
        text-decoration: none;
        color: ${(p) => (p.selected ? electricPurple500 : nightBlue300)};
        font-family: ${openSansBoldFamily};
      }
      .detail {
        font-size: 10px;
        color: ${(p) => (p.selected ? electricPurple500 : nightBlue300)};
        font-family: ${openSansRegular};
      }
    }
  }
`;
