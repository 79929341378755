import styled from "styled-components";
import { openSansRegular, scarpaGray400 } from '../../StylesConstants';

export const SignatureAndDeliveryWrapper = styled.div`
  .signature-type {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }

  .funding-type {
    color: ${ scarpaGray400 };
    font: 10px ${ openSansRegular };
    line-height: 24px;
    margin-left: 8px;
  }

`;
