import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import GoogleLogin from "react-google-login";
import Loader from "@habi/habi-react-components/dist/Loader/loader";

import { LoginWrapper } from "./style";
import logo from "../../static/assets/logo/habi.svg";
import loginDecorationStart from "../../static/assets/img/home/login-decoration-start.svg";
import loginDecorationEnd from "../../static/assets/img/home/login-decoration-end.svg";
import { updateLoginData } from "../../redux/actions/loginData";
import { getRolCognito, loginExternalUser } from "../../apis/Login/loginApis";
import { updatePropertyData } from "../../redux/actions/propertyData";
import UserExternalForm from "./components/userExternalForm";

const LogIn = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { initialPath, isAuthenticated } = useSelector(state => state.loginData);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/home");
    }
  }, [history, isAuthenticated])

  const onRejectLogin = () => {
    history.push("/");
  };

  const redirectToApplication = () => {
    if (initialPath !== "/") {
      const index_parameter = initialPath.lastIndexOf("/");
      if (index_parameter > 0) {
        const nid = initialPath.substring(
          index_parameter + 1,
          initialPath.lenght
        );
        const path = initialPath.substring(0, index_parameter);
        const nidNumber = parseInt(nid);
        dispatch(updatePropertyData({ nid: nidNumber }));
        history.push({
          pathname: path,
          state: { nid: nidNumber },
        });
      } else {
        history.push(initialPath);
      }
    } else {
      history.push("/home");
    }
  };

  const onSuccessLogin = (response) => {
    const profile = response.profileObj;
    
    setLoading(true);

    if (profile.googleId) {
      const data = {
        userId: profile.googleId,
      };

      getRolCognito(data)
        .then((response) => {
          if (response.data.success) {
            dispatch(updateLoginData({
              isAuthenticated: true,
              data: {
                role: response.data.roles,
                email: profile.email,
                name: profile.givenName,
                lastName: profile.familyName,
                imageProfile: profile.imageUrl,
                userId: profile.googleId,
              },
            }));
            redirectToApplication();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleLoginExternalUser = async (formData) => {
    try {
      setLoading(true);
      const { data } = await loginExternalUser(formData);
      dispatch(updateLoginData({
        isAuthenticated: true,
        data: {
          role: [data.role],
          email: data.email,
          name: data.name,
          lastName: data.last_name,
          imageProfile: data.image_profile,
          userId: data.user_id,
        },
      }));
      redirectToApplication();
    } catch (error) {
      console.log('ERROR', error);
    } finally {
      setLoading(false);
    }
  };

  const getLoginCard = () => {
    return (
      <div className="login-card animate__animated animate__backInUp">
        <img src={logo} alt="logo-img" />
        <span className="title">
          Portal de inmuebles
        </span>
        <div className="google-button-container">
          <div className="google-button">
            <GoogleLogin
              clientId="1050153132365-2ucv8d21tdc2hotlbtesmup12lfki83t.apps.googleusercontent.com"
              buttonText="Iniciar Sesión"
              onSuccess={onSuccessLogin}
              onFailure={onRejectLogin}
              cookiePolicy={"single_host_origin"}
              className="google-button"
            />
          </div>
        </div>
        <span className="alternative-text">
          O inicia sesión con correo y contraseña
        </span>
        <UserExternalForm handleLogin={handleLoginExternalUser} />
      </div>
    )
  };

  const getLoading = () => {
    return (
      <div className="loader-container">
        <Loader size="middle" />
      </div>
    )
  };

  return (
    <LoginWrapper>
      <div className="decoration">
        <img src={loginDecorationStart} alt="Habi decoration" />
        <img src={loginDecorationEnd} alt="Habi decoration" />
      </div>
      <div className="login-container">
        <div />
        {
          loading
            ? getLoading()
            : getLoginCard()
        }
        <div>
          <a href="https://habi.co" target="blank" className="habi-link">
            Habi.co
          </a>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default LogIn;
