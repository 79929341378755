function configurationData(state = {}, action = {}) {
  switch (action.type) {
    case 'TOGGLE_VISIBILITY_LIFECYCLE': {
      return {
        ...state,
        showLifeCycle: !state.showLifeCycle
      };
    }
    default:
      return state;
  }
}

export default configurationData;