import styled from "styled-components";
import { muliBoldFamily, white } from "../../StylesConstants";

export const WarningDialogWrapper = styled.div`

  background-color: ${white};
  border-radius: 16px;
  display: grid;
  outline:none;
  padding: 56px 88px 40px 88px;
  place-items: center;
  width: 948px;
  
  .modal-title {
    font: 20px ${muliBoldFamily};
    line-height: 28px;
    margin-bottom: 24px;
    width: 100%;
  }

  .confirmation-text {
    font: 16px ${muliBoldFamily};
    margin: 32px 0 42px 0;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

`;