import React, { useEffect, useState, useCallback } from 'react';

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IconSend from '../../../../static/assets/img/icons/icon-send.svg';
import { DocumentsGrid } from '../../../../components/Styles/genericStyleComponent';
import { BUSINESS_TYPE, DOCUMENT_STATUS, PROCESS, PROCESS_EVENTS, PROCESS_SECTIONS } from '../../../../utils/constant';
import DocumentComponent from '../../../../components/DocumentComponent/documentComponent';
import { BondingStageDannWrapper } from './style';

const BondingStageDann = ({ section, events, handleUpdateDocument }) => {

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const getEventStatus = useCallback(
    (eventName) => {
      const event = events.find( event => event.name === eventName);
      return event?.status === DOCUMENT_STATUS.SUCCESS ? event.status : null;
    },
    [events],
  )

  useEffect(() => {
    const isSectionApproved = () => {
      return section.name === PROCESS_SECTIONS.BONDING_COLPATRIA_FIRST
        ? getEventStatus(PROCESS_EVENTS.FIRST_REQUEST_BONDING) === DOCUMENT_STATUS.SUCCESS
        : getEventStatus(PROCESS_EVENTS.SECOND_FUNDING_APPROVE) === DOCUMENT_STATUS.SUCCESS;
    };

    setAccordionExpanded(!isSectionApproved());
    setShowDelete(getEventStatus(PROCESS_EVENTS.SECOND_FUNDING_APPROVE) !== DOCUMENT_STATUS.SUCCESS);
  }, [events, getEventStatus, section.name])

  return (
    <BondingStageDannWrapper>
      <Accordion 
        expanded={ accordionExpanded } 
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          className="accordion-title"
        >
          <div className="group-title">
            <div className="icon-send">
              <img src={IconSend} alt="Icon send" />
            </div>
            <span>
              { section.label }
            </span>
          </div>
          <div className="stats">
            <span>
              Documentos:
            </span>
            <span className="loaded">
              Cargados: 
              { section.list_documents.filter(doc => doc.exist).length }/{ section.list_documents.length }
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="detail-container">
            <DocumentsGrid>
              {section.list_documents.map((document, i) => (
                <DocumentComponent
                  key={`document_${i}`}
                  lastItemRow={(i + 1) % 5 === 0 ? true : false}
                  documentParam={document}
                  businessTypeId={BUSINESS_TYPE.SELLERS}
                  processName={PROCESS.BONDING_DANN}
                  handleUpload={handleUpdateDocument}
                  handleDelete={handleUpdateDocument}
                  showDelete={showDelete}
                />
              ))}
            </DocumentsGrid>
          </div>
        </AccordionDetails>
      </Accordion>
    </BondingStageDannWrapper>
  )
};

export default BondingStageDann;
