export const REQUESTED_COLUMNS = [
  '',
  'NID',
  'Nombre del inmueble',
  'Etiqueta actual del inmueble',
  'Fecha de solicitud',
  'Asignar etiqueta',
  'Estado del inmueble',
];

export const PUBLISHED_COLUMNS = [
  '',
  'NID',
  'Nombre del inmueble',
  'Etiqueta inicial del inmueble',
  'Primer cambio de etiqueta',
  'Segundo cambio de etiqueta',
  'Cambio de estado del inmueble',
];

export const SOLD_INCOMPLETE = [
  '',
  'NID',
  'Nombre del inmueble',
  'Etiqueta inicial del inmueble',
  'Primer cambio de etiqueta',
  'Segundo cambio de etiqueta',
  'Revivir inmueble',
];

export const REQUESTS_PAYMENT_REQUESTS = [
  '',
  'Tipo de solicitud',
  'NID',
  'Nombre conjunto',
  'Fecha de solicitud',
  'Documentos adjuntos',
  'Aprobación Sr. Trámites',
  '',
];

export const REQUESTS_PAYMENT_APPROVED = [
  '',
  'Tipo de solicitud',
  'NID',
  'Nombre conjunto',
  'Fecha de solicitud',
  'Documentos adjuntos',
  'Aprobación Sr. Trámites',
  '',
];
export const REQUESTS_PAYMENT_REJECTED = [
  '',
  'Tipo de solicitud',
  'NID',
  'Nombre conjunto',
  'Fecha de solicitud',
  'Documentos adjuntos',
  'Aprobación Sr. Trámites',
];
