import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListDocumentsApi } from '../apis/Documents/documentsApis';
import { dataFound, dataNotFound, hideLoading, showLoading  } from '../redux/actions/propertyData';

const useDocuments = ({ businessType, processName }) => {

  const dispatch = useDispatch();
  const nid = useSelector((state) => state.propertyData.nid);
  const [documents, setDocuments] = useState({list_documents:[]});
  const documentsRef = useRef([]);

  const getListDocument = useCallback(
    async (nid) => {
      try {
        dispatch(showLoading());
        const response = await getListDocumentsApi(
          nid,
          businessType,
          processName
        );
        setDocuments(response.data);
        documentsRef.current = response.data.list_documents;
        dispatch(dataFound());
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setDocuments({list_documents:[]});
        } else {
          console.log("error:::", error.response);
        }
        dispatch(dataNotFound());
      } finally {
        dispatch(hideLoading());
      }
    },
    [dispatch, businessType, processName],
  );

  useEffect(() => {
    if (nid) {
      getListDocument(nid);
    } else {
      setDocuments({list_documents:[]});
      dispatch(dataNotFound());
    }
  }, [nid, getListDocument, dispatch]);

  const documentUpdate = (_, newDocument) => {
    documentsRef.current = documentsRef.current.map((document) => {
      return document.document_type_id === newDocument.document_type_id
        ? newDocument
        : document;
    });
    setDocuments({...documents, list_documents: [...documentsRef.current]} );
  };

  const addOptionalDocument = (document) => {
    if(!documentsRef.current.some(doc => doc.document_type_id === document.document_type_id)) {
      documentsRef.current.push(document);
      setDocuments({...documents, list_documents: [...documentsRef.current]} );
    }
  };

  return {
    documents,
    documentUpdate, 
    addOptionalDocument
  };
}

export default useDocuments;
