import { scarpaGray500 } from '@habi/habi-react-components/dist/StylesConstants';
import styled from "styled-components";
import { plainlightGray, openSansBoldFamily, openSansRegular, scarpaGray100, scarpaGray600, white, muliBoldFamily } from "../../../../StylesConstants";

export const DocumentCardWrapper = styled.div`
  
  height: 100%;

  .document-card {
    background-color: ${ white };
    width: 100%;
    border: 1px solid ${plainlightGray};
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(48, 49, 51, 0.03);
    border-radius: 16px;
  }

  .status-section {
    display: flex;
    justify-content: space-between;
    min-height: 46px;

    > div {
      padding: 16px;
    }
  }

  .document-section {
    display: grid;
    margin-top: -50px;
    justify-items: center;
    grid-gap: 12px;
    padding: 16px;

    .title {
      color: ${ scarpaGray600 };
      font: 12px ${ muliBoldFamily };
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.03em;
      min-height: 50px;
      border-bottom: 1px solid ${ scarpaGray100 };
      width: 100%;
    }
  }

  .properties-section {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 16px 16px;
    align-items: center;
  }

  .update_date {
    color: ${scarpaGray500};
    line-height: 12px;
    font: 10px ${ openSansRegular };
    .date {
      font: 10px ${ openSansBoldFamily };
    }
  }

  .empty {
    color: ${scarpaGray500};
    font: 10px ${ openSansRegular };
  }

  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
